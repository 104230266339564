import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import { GroupResponse, OrganisationResponse, PractitionerData } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import SectionHeader from 'components/SectionHeader';
import { isGroupManager, isSuperAdmin } from 'utils/role';
import { styled } from '@mui/material';
import isGroupResponse from 'utils/typeGuards/isGroupResponse';
import AccountOrganisationCard from 'components/OrganisationInfoCard/AccountOrganisationCard';
import { createGroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import OrganisationInfoCardList from 'components/OrganisationInfoCardList';
import { sortGroupsAndOrgs, sortOrgs } from 'utils/mappers/sorting';

type ItemType = 'Organisation' | 'Group';

type Props = {
  organisations: OrganisationResponse[];
  groups?: GroupResponse[];
  practitioner?: PractitionerData;
  allowDemotion?: boolean;
  onOrganisationRemove?: (id: string) => void;
};

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  width: theme.spacing(spacingSizeMap.M),
  height: theme.spacing(spacingSizeMap.M),
}));

const OrganisationInfoSection = ({
  organisations = [],
  practitioner,
  groups = [],
  allowDemotion = false,
  onOrganisationRemove,
}: Props) => {
  const { t } = useTranslation();
  let displayedOrganisations: (OrganisationResponse | GroupResponse)[] = [
    ...organisations,
  ];
  const organisationsInGroups = createGroupOrganisationHashMap(groups);
  let showGroups = isGroupManager(practitioner?.roles);

  if (isSuperAdmin(practitioner?.roles)) {
    displayedOrganisations = organisations.filter(({ supportedRoles }) =>
      isSuperAdmin(supportedRoles),
    );

    showGroups = false;
  }

  if (isGroupManager(practitioner?.roles)) {
    displayedOrganisations = [
      ...displayedOrganisations.filter(({ id }) => !organisationsInGroups[id]),
      ...groups,
    ];
  }

  displayedOrganisations = displayedOrganisations.sort(sortOrgs);

  const items = displayedOrganisations
    .map((organisation) => {
      const { id, name } = organisation;
      if (isGroupResponse(organisation)) {
        return {
          id,
          name,
          orgCount: organisation.organisations.length,
          type: 'Group' as ItemType,
          groupType: organisation.type,
          allowDemotion: allowDemotion && displayedOrganisations.length > 1,
          onClick: () => {
            if (onOrganisationRemove) onOrganisationRemove(id);
          },
        };
      }
      return {
        id,
        name,
        type: 'Organisation' as ItemType,
        allowDemotion: allowDemotion && displayedOrganisations.length > 1,
        parent: organisationsInGroups[id],
        onClick: () => {
          if (onOrganisationRemove) onOrganisationRemove(id);
        },
      };
    })
    .sort(sortGroupsAndOrgs);

  let listElement;

  if (items.length > 6) {
    listElement = <OrganisationInfoCardList items={items} />;
  } else {
    listElement = items.map((organisation) => {
      const { id, name, orgCount, groupType } = organisation;

      if (groupType) {
        return (
          <AccountOrganisationCard
            key={id}
            id={id}
            name={name}
            type="Group"
            groupType={groupType}
            orgCount={orgCount}
            allowDemotion={allowDemotion}
          />
        );
      }
      return (
        <AccountOrganisationCard
          key={id}
          id={id}
          name={name}
          type="Organisation"
          allowDemotion={allowDemotion && displayedOrganisations.length > 1}
          parent={organisationsInGroups[id]}
          onClick={() => {
            if (onOrganisationRemove) onOrganisationRemove(id);
          }}
        />
      );
    });
  }

  return (
    <>
      <SectionHeader
        icon={<StyledOrganisationIcon />}
        title={
          showGroups
            ? t('ui.event-management.my-account.organisations-and-groups', {
                count: displayedOrganisations.length,
              })
            : t('ui.event-management.my-account.organisation')
        }
      />
      <Grid
        container
        sx={{
          alignItems: 'stretch',
          paddingTop: '0.5rem',
          paddingBottom: '1.5rem',
          '& > div': {
            paddingBottom: '1.0rem',
          },
          '& > div:nth-of-type(odd)': {
            paddingRight: '0.5rem',
          },
          '& > div:nth-of-type(even)': {
            paddingLeft: '0.5rem',
          },
        }}
      >
        {listElement}
      </Grid>
    </>
  );
};

export default OrganisationInfoSection;
