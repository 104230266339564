import { Menu, MenuItem, Tab, styled } from '@mui/material';
import React, { useState, MouseEvent } from 'react';
import SvgArrowDown1 from '@insights-ltd/design-library/src/components/Svgs/streamline-regular/ArrowDown1V2';
import getTheme from '@insights-ltd/design-library/src/themes';
import { useTranslation } from 'react-i18next';
import { spacingSizeMap } from '@insights-ltd/design-library';
import { NavBarItem } from './types';

const StyledDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

type Props = {
  navBarItems: NavBarItem[];
  onSelect: (value: number) => void;
  otherShowingButtons?: number;
  tabsValue?: number;
};
const MoreDropDownTab = ({
  navBarItems,
  onSelect,
  otherShowingButtons = 3,
  tabsValue = 3,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const {
    brandColours: { darkBlue },
    grey: { 700: darkGrey },
  } = getTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelect = (value: number) => {
    onSelect(otherShowingButtons + value);
    handleClose();
  };

  return (
    <>
      <Tab
        sx={(theme) => ({
          minWidth: '4.5rem',
          margin: `0`,
          padding: '8px 16px 20px',
          color:
            open || tabsValue >= otherShowingButtons
              ? `${theme.palette.grey[700]} !important`
              : theme.palette.blue.main,
          opacity: '1',
        })}
        value={-1}
        label={
          <StyledDiv>
            <span>{t('ui.event-management.events.nav.more')}</span>{' '}
            <SvgArrowDown1
              stroke={open ? darkGrey : darkBlue}
              height="8"
              width="11"
              fill="none"
              strokeWidth={4}
              style={{ transform: open ? 'rotate(180deg)' : 'rotate(0)' }}
            />
          </StyledDiv>
        }
        onClick={handleClick}
      />
      <Menu
        sx={{
          '.MuiMenu-paper  > ul': {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
          },
        }}
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {navBarItems.map(({ text }, index) => {
          return (
            <MenuItem
              sx={(theme) => ({
                color:
                  tabsValue === index + otherShowingButtons
                    ? theme.palette.grey[700]
                    : theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                background: theme.palette.common.white,
                padding: `10px ${theme.spacing(spacingSizeMap.S)}`,
                width: '260px',
              })}
              key={text}
              onClick={() => {
                handleOnSelect(index);
              }}
            >
              {text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MoreDropDownTab;
