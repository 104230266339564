import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
  PractitionerSummaryData,
  RequestError,
  useTransferUnitsBetweenWallets,
  WalletResponse,
} from 'api';
import { styled } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import SelectUser from 'components/UserPicker/UserAutocomplete';
import {
  InputText,
  InputType,
  Span,
  Text,
  P,
} from '@insights-ltd/design-library';
import { queryStatus } from 'utils/queryStatus';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BarChartIcon from 'components/Svgs/icons/AnalyticsBars';
import PersonIcon from 'components/Svgs/icons/SingleNeutral';
import useGetPrevious from 'components/hooks/useGetPrevious';
import { useGetPractitionersSummary } from 'api/organisations/organisationHooks';
import { useGetPractitionerWallet } from 'api/practitioners/practitionerHooks';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { getErrorMessage } from 'components/EventForms/validation';
import { NUMERICAL_ONLY_REGEX } from 'variables';

const StyledBarChartIcon = styled(BarChartIcon)(({ theme }) => ({
  fill: theme.palette.blue.dark,
  height: '20px',
}));

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.M,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
}));

const StyledInputText = styled(InputText)({
  width: '100%',
});

type FormData = {
  transferToAmount: number;
  transferFromAmount: number;
  comment: string;
};
type Transaction = 'TRANSFER_TO' | 'TRANSFER_FROM';
type TransactionTranslation = Record<Transaction, string>;
type TransferUnitsProps = {
  myWallet: WalletResponse;
  organisationId: string;
  userId: string;
  initialPractitionerId?: string;
};

const MINIMUM_TRANSACTION_AMOUNT = 1;
const MAXIMUM_TRANSACTION_AMOUNT = 1000000;
const TRANSACTION_TRANSLATION: TransactionTranslation = {
  TRANSFER_TO: 'ui.event-management.transfer-units.transfer-to',
  TRANSFER_FROM: 'ui.event-management.transfer-units.transfer-from',
};

const getTransferErrors = (
  isError: boolean,
  error: unknown | null,
  transactionType: 'TRANSFER_TO' | 'TRANSFER_FROM',
): string[] => {
  if (isError && error instanceof RequestError) {
    const insufficientFundsKey =
      transactionType === 'TRANSFER_TO'
        ? 'ui.event-management.transfer-units.amount.to.insufficient-funds.error'
        : 'ui.event-management.transfer-units.amount.from.insufficient-funds.error';
    return error.getRequestErrorKeys(
      'ui.event-management.transfer-units.transfer.error.title',
      {},
      {
        INSUFFICIENT_UNITS: insufficientFundsKey,
      },
    );
  }
  return [];
};

const SummaryMessage = ({ transaction }: { transaction: any }) => {
  if (transaction.operation === 'TRANSFER_TO') {
    if (transaction.exceedsThreshold) {
      return (
        <Trans i18nKey="ui.event-management.transfer-units.my.balance.transfer-to.error" />
      );
    }
    return (
      <Trans
        i18nKey="ui.event-management.transfer-units.my.balance.transfer-to"
        values={{
          fullName: transaction.fullName,
          userCurrentBalance: transaction.userCurrentBalance,
          userNewBalance: transaction.userNewBalance,
          myCurrentBalance: transaction.myCurrentBalance,
          myNewBalance: transaction.myNewBalance,
        }}
      />
    );
  }
  if (transaction.exceedsThreshold) {
    return (
      <Trans
        i18nKey="ui.event-management.transfer-units.my.balance.transfer-from.error"
        values={{ fullName: transaction.fullName }}
      />
    );
  }
  return (
    <Trans
      i18nKey="ui.event-management.transfer-units.my.balance.transfer-from"
      values={{
        fullName: transaction.fullName,
        userCurrentBalance: transaction.userCurrentBalance,
        userNewBalance: transaction.userNewBalance,
        myCurrentBalance: transaction.myCurrentBalance,
        myNewBalance: transaction.myNewBalance,
      }}
    />
  );
};

const TransferUnitsForm = ({
  myWallet,
  userId,
  organisationId,
  initialPractitionerId,
}: TransferUnitsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isWalletError, setIsWalletError] = useState(false);
  const [practitionerId, setPractitionerId] = useState<string | undefined>(
    initialPractitionerId,
  );
  const practitionerSelected = Boolean(typeof practitionerId !== 'undefined');
  const [transactionType, setTransactionType] =
    useState<Transaction>('TRANSFER_TO');

  const { status: getPractitionersStatus, data: practitioners } =
    useGetPractitionersSummary(String(organisationId), {
      enabled: !!organisationId,
    });

  const { status: getWalletStatus, data: wallet } = useGetPractitionerWallet(
    String(practitionerId),
    {
      enabled: practitionerSelected,
    },
  );

  useEffect(
    () => setPractitionerId(initialPractitionerId),
    [initialPractitionerId, setPractitionerId],
  );

  const { mutate, isError, reset, error, isPending } =
    useTransferUnitsBetweenWallets();

  const transferErrors = getTransferErrors(isError, error, transactionType);

  const isDebiting = transactionType === 'TRANSFER_TO';

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      transferToAmount: MINIMUM_TRANSACTION_AMOUNT,
      transferFromAmount: MINIMUM_TRANSACTION_AMOUNT,
    },
  });

  const handlePractitionerChange = (value?: string) => {
    setPractitionerId(value);
    reset();
  };
  const handleTransactionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionType(e.target.value as Transaction);
    setValue('transferToAmount', MINIMUM_TRANSACTION_AMOUNT);
    setValue('transferFromAmount', MINIMUM_TRANSACTION_AMOUNT);
    reset();
    clearErrors();
  };

  const selectedPractitioner = practitioners?.find(
    (practitioner: PractitionerSummaryData) =>
      practitioner.id === practitionerId,
  );
  const fullName = selectedPractitioner?.fullName || '';
  const transferToAmount = Number(watch('transferToAmount', 0));
  const transferFromAmount = Number(watch('transferFromAmount', 0));
  const myCurrentBalance: number = myWallet?.availableUnits || 0;
  const userCurrentBalance: number = wallet?.availableUnits || 0;
  let myNewBalance: number;
  let userNewBalance: number;
  if (isDebiting) {
    myNewBalance =
      myCurrentBalance > 0 ? myCurrentBalance - transferToAmount : 0;
    userNewBalance = userCurrentBalance + transferToAmount;
  } else {
    myNewBalance = myCurrentBalance + transferFromAmount;
    userNewBalance =
      userCurrentBalance > 0 ? userCurrentBalance - transferFromAmount : 0;
  }

  let maxTransferToAmount: number =
    myCurrentBalance < MAXIMUM_TRANSACTION_AMOUNT
      ? myCurrentBalance
      : MAXIMUM_TRANSACTION_AMOUNT;
  maxTransferToAmount = maxTransferToAmount > 0 ? maxTransferToAmount : 1;

  let maxTransferFromAmount: number =
    userCurrentBalance < MAXIMUM_TRANSACTION_AMOUNT
      ? userCurrentBalance
      : MAXIMUM_TRANSACTION_AMOUNT;
  maxTransferFromAmount = maxTransferFromAmount > 0 ? maxTransferFromAmount : 1;

  const exceedsUserUnitRemoveThreshold =
    userNewBalance < 0 || userCurrentBalance === 0;

  const exceedsMyUnitRemoveThreshold =
    myNewBalance < 0 || myCurrentBalance === 0;

  const showSummary = transferToAmount > 0 || transferFromAmount > 0;

  let transaction = {
    operation: transactionType,
    exceedsThreshold: exceedsMyUnitRemoveThreshold,
    fullName,
    userCurrentBalance,
    userNewBalance,
    myCurrentBalance,
    myNewBalance,
  };

  if (!isDebiting) {
    transaction = {
      ...transaction,
      ...{ exceedsThreshold: exceedsUserUnitRemoveThreshold },
    };
  }

  let transferToAmountError = null;
  let transferFromAmountError = null;
  if (exceedsMyUnitRemoveThreshold) {
    transferToAmountError = t(
      'ui.event-management.manage-units.remove.amount.threshold.error',
    );
  }
  if (exceedsUserUnitRemoveThreshold) {
    transferFromAmountError = t(
      'ui.event-management.manage-units.remove.amount.threshold.error',
    );
  }

  const previousOrganisationId = useGetPrevious(organisationId);

  if (
    practitionerSelected &&
    !!previousOrganisationId &&
    previousOrganisationId !== organisationId
  ) {
    setPractitionerId(undefined);
    reset();
  }

  const loadingStatus = practitionerSelected
    ? queryStatus(getPractitionersStatus, getWalletStatus)
    : getPractitionersStatus;
  if (loadingStatus === 'pending') {
    return (
      <FullScreenSpinner inline message={t('ui.event-management.loading')} />
    );
  }
  if (loadingStatus === 'error') {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  const filteredPractitioners = practitioners?.filter(
    (practitioner: PractitionerSummaryData) => practitioner.id !== userId,
  );

  const errorMessage =
    transactionType === 'TRANSFER_TO'
      ? t('ui.event-management.transfer-units.transfer-to.error', { fullName })
      : t('ui.event-management.transfer-units.transfer-from.error', {
          fullName,
        });

  const handleResetError = () => {
    reset();
    setIsWalletError(false);
  };

  return (
    <StyledForm
      onSubmit={handleSubmit(
        ({
          transferToAmount: credit,
          transferFromAmount: removal,
          comment,
        }: FormData) => {
          if (wallet) {
            const params = {
              practitionerWalletId: wallet!.id,
              fromWalletId:
                transactionType === 'TRANSFER_TO' ? myWallet.id : wallet!.id,
              toWalletId:
                transactionType === 'TRANSFER_TO' ? wallet!.id : myWallet.id,
              amount: transactionType === 'TRANSFER_TO' ? credit : removal,
              comment,
              organisationId,
            };
            mutate(params, {
              onSuccess: () => {
                navigate(
                  `/organisations/${organisationId}/practitioners/${practitionerId}`,
                );
              },
            });
          } else {
            setIsWalletError(true);
          }
        },
      )}
      noValidate
    >
      <FormHeader
        icon={StyledPersonIcon}
        title={t('ui.event-management.transfer-units.user.title')}
      />
      {practitionerSelected && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
              spacingSizeMap.S,
            )}`,
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '4px',
          })}
        >
          <Grid item>
            <Text variant="body-bold">{selectedPractitioner?.fullName}</Text>
            <Text> - {selectedPractitioner?.emailAddress}</Text>
          </Grid>
          <Grid item>
            {wallet && (
              <>
                <Text color="textSecondary" variant="body-bold">
                  {t('ui.event-management.transfer-units.user.unit-balance')}:{' '}
                </Text>
                <Text variant="body-bold">{wallet.availableUnits}</Text>
              </>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => setPractitionerId(undefined)}
            >
              {t('ui.event-management.transfer-units.user.change')}
            </Button>
          </Grid>
        </Grid>
      )}
      {!practitionerSelected && (
        <SelectUser
          options={filteredPractitioners!}
          onSelect={(practitioner) => {
            handlePractitionerChange(practitioner.id);
          }}
          selectedValue={practitionerId}
          loading={false}
          disableClearable
        />
      )}
      {practitionerSelected ? (
        <>
          <FormHeader
            icon={StyledBarChartIcon}
            title={t('ui.event-management.transfer-units.actions.title')}
            color="blue"
          />
          <RadioGroup
            aria-label="transactionType"
            value={transactionType}
            onChange={handleTransactionChange}
          >
            {Object.entries(TRANSACTION_TRANSLATION).map(
              ([value, labelKey]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio color="primary" />}
                  label={t(labelKey, { fullName })}
                />
              ),
            )}
          </RadioGroup>
          <Grid container>
            <Grid item xs={12} md={6}>
              {transactionType === 'TRANSFER_TO' ? (
                <InputText
                  id="transferToAmount"
                  label={t(
                    'ui.event-management.transfer-units.transfer.amount.label',
                  )}
                  key="transferToAmount"
                  type={InputType.NUMBER}
                  min={MINIMUM_TRANSACTION_AMOUNT}
                  max={maxTransferToAmount}
                  step="1"
                  helperText={
                    errors.transferToAmount?.type === 'pattern'
                      ? t(
                          'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
                        )
                      : transferToAmountError
                  }
                  error={Boolean(errors.transferToAmount)}
                  required
                  fullWidth
                  {...register('transferToAmount', {
                    required: true,
                    min: MINIMUM_TRANSACTION_AMOUNT,
                    pattern: {
                      value: NUMERICAL_ONLY_REGEX,
                      message: t(
                        'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
                      ),
                    },
                  })}
                />
              ) : (
                <InputText
                  id="transferFromAmount"
                  label={t(
                    'ui.event-management.transfer-units.transfer.amount.label',
                  )}
                  key="transferFromAmount"
                  type={InputType.NUMBER}
                  min={MINIMUM_TRANSACTION_AMOUNT}
                  max={maxTransferFromAmount}
                  step="1"
                  helperText={
                    errors.transferFromAmount?.type === 'pattern'
                      ? t(
                          'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
                        )
                      : transferFromAmountError
                  }
                  error={Boolean(errors.transferFromAmount)}
                  required
                  fullWidth
                  {...register('transferFromAmount', {
                    required: true,
                    min: MINIMUM_TRANSACTION_AMOUNT,
                    pattern: {
                      value: NUMERICAL_ONLY_REGEX,
                      message: t(
                        'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
                      ),
                    },
                  })}
                />
              )}
              <P>
                {t('ui.event-management.transfer-units.transfer-unit-label')}
              </P>
            </Grid>
          </Grid>
          <StyledInputText
            id="comment"
            label={t('ui.event-management.transfer-units.comment.label')}
            placeholder={t(
              'ui.event-management.transfer-units.comment.placeholder',
            )}
            fullWidth
            helperText={t(getErrorMessage(errors.comment))}
            error={Boolean(errors.comment)}
            type={InputType.TEXT}
            {...register('comment', { required: true })}
          />
          {!errors.comment ? (
            <P>{t('ui-event-management.transfer-units.information.label')}</P>
          ) : null}
          {showSummary && (
            <StyledDiv>
              <Span variant="body-bold">
                <SummaryMessage transaction={transaction} />
              </Span>
            </StyledDiv>
          )}
          {transferErrors.map((errorKey) => (
            <Alert
              key={errorKey}
              severity="error"
              variant="standard"
              icon={false}
            >
              <Span variant="body-bold" color="error">
                {t(errorKey)}
              </Span>
            </Alert>
          ))}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            sx={(theme) => ({
              float: 'right',
              marginTop: theme.spacing(spacingSizeMap.L),
            })}
            disabled={isPending || transaction.exceedsThreshold}
            endIcon={isPending && <CircularProgress size={16} />}
          >
            {transactionType === 'TRANSFER_TO'
              ? t('ui.event-management.transfer-units.button.label.to')
              : t('ui.event-management.transfer-units.button.label.from')}
          </Button>
          <Snackbar
            open={
              isWalletError || (isError && !(error instanceof RequestError))
            }
            autoHideDuration={6000}
            onClose={handleResetError}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleResetError}
            >
              <AlertTitle>
                {t('ui.event-management.transfer-units.transfer.error.title')}
              </AlertTitle>
              {errorMessage}
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </StyledForm>
  );
};

export default TransferUnitsForm;
