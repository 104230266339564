import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation, useNavigate } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library';
import { TProfilesCount } from 'pages/Event/types';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import ProfilesIcon from 'components/Svgs/Profiles';

type Props = {
  profilesStatusCount: TProfilesCount;
  disabled?: boolean;
};

const DownloadProfilesCard = ({
  profilesStatusCount,
  disabled = false,
}: Props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { noOfProfilesReadyToPurchase, noOfProfilesPurchased } =
    profilesStatusCount;

  const inactive =
    (noOfProfilesReadyToPurchase === 0 && noOfProfilesPurchased === 0) ||
    disabled;

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        border: inactive ? '0' : `1px solid ${theme.palette.grey[500]}`,
        borderBottom: inactive
          ? 'undefined'
          : `0.2em solid ${theme.palette.primary.main}`,
        opacity: inactive ? '0.5' : 'undefined',
      })}
    >
      <CardActionArea
        disabled={inactive}
        onClick={() => navigate(`${pathname}/download-profiles`)}
        sx={{
          fontFamily: 'inherit',
        }}
      >
        <CardContent>
          <ProfilesIcon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <P variant="h3" color={inactive ? 'textPrimary' : 'primary'}>
              {t('ui.event-management.events.download-profiles')}
            </P>
          </Box>
          <P variant="body-bold" color="textSecondary">
            {t(
              'ui.event-management.events.overview.summary.profiles-available-to-purchase',
              {
                count: noOfProfilesReadyToPurchase,
              },
            )}
          </P>
          <P variant="body-bold" color="textSecondary">
            {t(
              'ui.event-management.events.overview.summary.profiles-available',
              {
                count: noOfProfilesPurchased,
              },
            )}
          </P>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DownloadProfilesCard;
