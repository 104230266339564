import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from 'components/Svgs/icons/LiveHelp';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { useAuth } from 'contexts/AuthContext';
import { InputText, TextAreaV2 } from '@insights-ltd/design-library';
import { ContactFormData } from './types';

type Props = {
  onSubmit: (formData: ContactFormData) => void;
};

const StyledForm = styled('form')(({ theme }) => ({
  '& .input-text': {
    marginBottom: theme.spacing(2),
  },
  '& .submit-button': {
    marginTop: theme.spacing(3),
  },
  maxWidth: theme.spacing(90),
}));

const ContactForm = ({ onSubmit }: Props) => {
  const { user } = useAuth();
  const SUBJECT_MAX_LENGTH = 100;
  const MESSAGE_MAX_LENGTH = 500;

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = useForm<ContactFormData>({
    defaultValues: { practitionerId: user?.id },
  });
  const { t } = useTranslation();

  const submitHandler = (formData: ContactFormData) => {
    const processedData = { ...formData };

    setValue('practitionerId', user!.id);
    setValue('subject', processedData.subject);
    setValue('formBody', processedData.formBody);

    return onSubmit(processedData);
  };

  return (
    <StyledForm
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(submitHandler)}
    >
      <FormHeader
        icon={PersonIcon}
        color="pink"
        title={t('ui.event-management.contact.details-header')}
        showMargin={false}
        marginBottom="1rem"
      />
      <InputText
        id="userId"
        label={t('ui.event-management.contact.email-label')}
        value={user?.emailAddress}
        placeholder={user?.emailAddress}
        disabled
        fullWidth
        maxLength={SUBJECT_MAX_LENGTH}
      />
      <FormHeader
        icon={HelpIcon}
        color="green"
        title={t('ui.event-management.contact.body-header')}
        showMargin={false}
        marginTop="2.5rem"
        marginBottom="1rem"
      />
      <InputText
        id="subject"
        className="input-text"
        label={t('ui.event-management.contact.subject-label')}
        placeholder={t('ui.event-management.contact.subject-placeholder')}
        fullWidth
        maxLength={SUBJECT_MAX_LENGTH}
        error={Boolean(errors.subject)}
        helperText={
          errors.subject && t('ui.event-management.contact.subject-error')
        }
        {...register('subject', { required: true })}
      />
      <Controller
        name="formBody"
        rules={{
          required: {
            value: true,
            message: t('ui.event-management.contact.message-required-error'),
          },
          maxLength: {
            value: MESSAGE_MAX_LENGTH,
            message: t('ui.event-management.contact.message-maxlength-error'),
          },
        }}
        control={control}
        render={({ field: { onChange } }) => (
          <TextAreaV2
            id="formBody"
            label={t('ui.event-management.contact.message-label')}
            placeholder={t('ui.event-management.contact.message-placeholder')}
            fullWidth
            rows={5}
            onChange={onChange}
            error={Boolean(errors.formBody)}
            additionalMessage={errors.formBody && errors.formBody.message}
            maxLength={MESSAGE_MAX_LENGTH}
            inputProps={register('formBody')}
          />
        )}
      />
      <Grid container justifyContent="flex-end">
        <Button
          data-testid="submit-contact-form"
          className="submit-button"
          type="submit"
          variant="contained"
          color="primary"
        >
          {t('ui.event-management.contact.submit-button')}
        </Button>
      </Grid>
    </StyledForm>
  );
};

export default ContactForm;
