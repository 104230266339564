import React, { useState } from 'react';
import { InputText, Span } from '@insights-ltd/design-library';
import { Grid, styled } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateNewEvaluatorLink, useGetAllOrganisations } from 'api';
import FullScreenError from 'components/FullScreen/FullScreenError';
import { useForm } from 'react-hook-form';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { ENABLED_EVALUATOR_DIALECTS_NO_PREFERRED } from 'variables';
import { OrganisationAutocompleteV2 } from 'components/OrganisationPicker/OrganisationAutocomplete';
import { OrganisationResponse } from 'api/httpEntities';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  organisationId?: string | null;
  defaultDialect: string;
  disabled?: boolean;
};

interface StyledInputTextProps {
  disabled?: boolean;
}

const StyledSpan = styled(Span)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledInputText = styled(InputText)<StyledInputTextProps>(
  ({ theme, disabled }) => ({
    width: '100%',
    '& > div': {
      maxWidth: '30rem',
      height: '3rem',
      backgroundColor: !disabled ? theme.palette.common.white : null,
    },
    '& > span': {
      marginTop: '0.25rem',
    },
  }),
);

const CreateEvaluatorLink: React.FC<Props> = ({
  organisationId,
  defaultDialect,
  disabled,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: createLink, isError } =
    useCreateNewEvaluatorLink(queryClient);
  const [dialect, setDialect] = useState(defaultDialect);
  const [selectedOrganisation, setSelectOrganisation] =
    useState<OrganisationResponse | null>(null);
  const { data } = useGetAllOrganisations({
    enabled: !organisationId,
  });

  const selectedOrganisationId = organisationId ?? selectedOrganisation?.id;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      evaluatorName: '',
    },
    mode: 'onChange',
  });

  const handleLanguageChange = (newDialect: string) => {
    setDialect(newDialect);
  };

  const handleCreateLink = ({ evaluatorName }: { evaluatorName: string }) => {
    if (evaluatorName !== '' && !!selectedOrganisationId) {
      createLink(
        {
          organisationId: selectedOrganisationId,
          newEvaluatorLinkName: evaluatorName,
          dialect,
        },
        { onSuccess: () => reset({ evaluatorName: '' }) },
      );
    }
  };

  if (isError) {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  return (
    <>
      <Grid
        mb={(theme) => theme.spacing(spacingSizeMap.L)}
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginBottom: '2rem',
          '@media screen and (max-width: 600px)': {
            flexDirection: 'column',
          },
        }}
      >
        {!organisationId && (
          <Grid item xs={12} sm={12} md={3.45}>
            <OrganisationAutocompleteV2
              labelKey="ui.event-management.events.nav.my-org"
              options={data}
              selectedValue={selectedOrganisation?.id ?? null}
              onSelect={setSelectOrganisation}
              loading={false}
              inputSx={{
                maxWidth: '30rem',
              }}
            />
          </Grid>
        )}
        <Grid item sm={12} md={3.45}>
          <StyledInputText
            label={t('ui.event-management.evaluator-links.name.input-label')}
            id="createEvaluatorLinks"
            placeholder={t(
              'ui.event-management.evaluator-links.name.placeholder',
            )}
            {...register('evaluatorName', { required: true })}
            fullWidth
            maxLength={100}
            error={Boolean(errors.evaluatorName)}
            disabled={disabled}
            helperText={
              errors.evaluatorName &&
              t(
                'ui.event-management.evaluator-links.name.input-validation-message',
              )
            }
          />
        </Grid>

        <Grid item sm={12} md={3.45}>
          <LanguageSelector
            dialects={ENABLED_EVALUATOR_DIALECTS_NO_PREFERRED}
            handleChange={handleLanguageChange}
          />
        </Grid>
        <Grid item md={1} sx={{ paddingTop: '4px' }}>
          <Button
            sx={{
              width: '146px ',
              height: '3rem',
              margin: '1.75rem 1rem 0',
              '@media screen and (max-width: 768px)': {
                width: '22rem',
                margin: ' 1.0rem 0 0.5rem 0',
              },
              padding: '0.5rem 1rem',
              '&.Mui-disabled': {
                color: 'white',
              },
            }}
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleCreateLink)}
            disabled={disabled || !selectedOrganisationId}
          >
            {t('ui.event-management.evaluator-links.create-link.button.label')}
          </Button>
        </Grid>
      </Grid>
      {disabled ? (
        <StyledSpan>
          {t('ui.event-management.evaluator-links.no-permissions')}
        </StyledSpan>
      ) : null}
    </>
  );
};

export default CreateEvaluatorLink;
