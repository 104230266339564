import { Container, Link, styled, Typography } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL } from 'variables';

const StyledFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(spacingSizeMap.M),
  paddingBottom: theme.spacing(spacingSizeMap.L),
  color: theme.palette.grey[100],
  backgroundColor: theme.palette.blue.main,
}));

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container
        maxWidth="lg"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography>
          &copy;&nbsp;
          {t('ui.event-management.footer.copyright-message', {
            Year: new Date().getUTCFullYear(),
          })}
        </Typography>
        <Typography>
          <Link
            color="inherit"
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener"
          >
            {t('ui.event-management.footer.privacy-notice')}
          </Link>
        </Typography>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
