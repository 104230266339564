import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Text } from '@insights-ltd/design-library';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import { styled } from '@mui/material';

export type Props = {
  open: boolean;
  onClose: () => void;
  handleConfirmDelete: () => void;
};

const ImportantText = styled(Text)({ fontSize: '18px !important' });
const Subheader = styled(Text)({ margin: `0.25rem 0 0 !important` });
const Content = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const DeleteCompletedEvaluatorsDialog = ({
  open,
  onClose,
  handleConfirmDelete,
}: Props) => {
  const { t } = useTranslation();

  const content = (
    <Content>
      <ImportantText>
        {t(
          'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.body-text',
        )}
      </ImportantText>
      <br />
      <Subheader>
        {t(
          'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.description',
        )}
      </Subheader>
      <br />
      <Alert kind="error" iconType="warning">
        {t(
          'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.warning',
        )}
      </Alert>
    </Content>
  );
  const title = t(
    'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.title',
  );
  const confirmText = t(
    'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.confirm-button',
  );
  const cancelText = t(
    'ui.event-management.evaluator-links.completed-evaluators.confirm-delete.cancel-button',
  );
  return (
    <Dialog
      dialogProps={{
        title,
        maxWidth: 'md',
      }}
      title={title}
      content={content}
      open={open}
      primaryButtonText={confirmText}
      secondaryButtonText={cancelText}
      onSubmit={handleConfirmDelete}
      onClose={onClose}
      variant="delete"
    />
  );
};

export default DeleteCompletedEvaluatorsDialog;
