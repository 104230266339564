import React, { useEffect, useRef } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { FormControlLabel, FormGroup, styled, SxProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { CheckboxV2, FormLabel, Text } from '@insights-ltd/design-library';
import getTheme from '@insights-ltd/design-library/src/themes';
import { getDefaultSelectedProducts } from 'pages/AddUser/AddUserForm/helper';
import { Options } from 'types/types';

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type SetValueOptions = { shouldValidate: boolean };
type CheckboxGroupProps<T extends FieldValues> = {
  title: string;
  name: Path<T>;
  control: Control<T>;
  errorMessage: string;
  options: Options;
  error: boolean;
  rules: RegisterOptions;
  setValue: (name: Path<T>, newList: Path<T>, options: SetValueOptions) => void;
  sx?: SxProps;
};

const localTheme = getTheme();

const StyledText = styled(Text)({
  display: 'block',
  transition: 'color 200ms',
});

const CheckboxGroup = <T extends FieldValues>({
  title,
  name,
  options,
  control,
  errorMessage,
  error,
  rules,
  setValue,
  sx = {},
}: CheckboxGroupProps<T>) => {
  const { register } = control;
  const formValueRegistered = useRef<boolean>(false);
  useEffect(() => {
    if (!formValueRegistered.current) {
      register(name, rules);
      formValueRegistered.current = true;
    }
  }, [name, register, rules]);

  return (
    <Controller
      render={({ field: { value, onBlur } }) => {
        const handleChange = async (change: string, checked: boolean) => {
          if (!getDefaultSelectedProducts(options).includes(change)) {
            const newList = checked
              ? value.concat(change)
              : value.filter((product: string) => product !== change);
            setValue(name, newList, {
              shouldValidate: true,
            });
          }
        };
        return (
          <FormControl
            sx={{
              '& > div': { marginLeft: '6px' },
              '& > div > label > span:nth-of-type(2)': {
                marginLeft: '12px',
                color: `${localTheme.grey[700]} !important`,
              },
              ...sx,
            }}
            component="fieldset"
          >
            <StyledFormLabel label={title} htmlFor={name} />
            <FormGroup sx={{ marginTop: '10px' }}>
              {options.map(({ value: id, label, disabled }) => {
                return (
                  <FormControlLabel
                    key={id}
                    control={
                      <CheckboxV2
                        name={id}
                        onChange={(check) => {
                          handleChange(id, check);
                        }}
                        onBlur={onBlur}
                        checked={value.includes(id)}
                        disabled={disabled}
                      />
                    }
                    label={label}
                  />
                );
              })}
            </FormGroup>
            {error && (
              <StyledText variant="body-small" color="error">
                {errorMessage}
              </StyledText>
            )}
          </FormControl>
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default CheckboxGroup;
