import { Group, OrganisationResponse, PractitionerData } from 'api';
import { InviteUserOrgOptions } from 'types/types';
import { ROLES } from 'variables';
import { FormData, PermissionRoleTextType } from './types';

export type RoleOptions = { value: string; label: string; disabled: boolean }[];

export const getRoleOptions = (
  t: (key: string) => string,
  selectedOrgs: OrganisationResponse[] = [],
): RoleOptions => {
  const allowInviteCSUser = !!selectedOrgs?.find(
    (org) => !!org?.supportedRoles?.includes('SUPER_ADMIN'),
  );
  const options = [
    {
      value: 'STANDARD',
      label: t(ROLES.STANDARD),
      disabled: false,
    },
  ];
  if (allowInviteCSUser) {
    options.push({
      value: 'CLIENT_SERVICES',
      label: t(ROLES.SUPER_ADMIN),
      disabled: false,
    });
  } else {
    options.push(
      {
        value: 'ADVANCED_USER',
        label: t(ROLES.ADVANCED_USER),
        disabled: false,
      },
      {
        value: 'GROUP_MANAGER',
        label: t(ROLES.GROUP_MANAGER),
        disabled: false,
      },
    );
  }

  return options;
};
export const getPermissionRolesText = (
  t: (key: string) => string,
): PermissionRoleTextType => ({
  STANDARD: [
    t(
      'ui.event-management.users.invite.permission-roles.standard-user.description.one',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.standard-user.description.two',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.standard-user.description.three',
    ),
  ],
  ADVANCED_USER: [
    t(
      'ui.event-management.users.invite.permission-roles.advanced-user.description.one',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.advanced-user.description.two',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.advanced-user.description.three',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.advanced-user.description.four',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.advanced-user.description.five',
    ),
  ],
  GROUP_MANAGER: [
    t(
      'ui.event-management.users.invite.permission-roles.group-manager.description.one',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.group-manager.description.two',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.group-manager.description.three',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.group-manager.description.four',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.group-manager.description.five',
    ),
  ],
  CLIENT_SERVICES: [
    t(
      'ui.event-management.users.invite.permission-roles.client-services.description.one',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.client-services.description.two',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.client-services.description.three',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.client-services.description.four',
    ),
    t(
      'ui.event-management.users.invite.permission-roles.client-services.description.five',
    ),
  ],
});

export const getDefaultSelectedProducts = (
  options: { disabled?: boolean; defaultSelected?: boolean; value: string }[],
) =>
  options
    .filter((option) => option.defaultSelected)
    .map((product) => product.value);

export const updateCurrentOrg = (
  organisations: OrganisationResponse[],
  setCurrentOrg: (currentOrgById: OrganisationResponse) => void,
  currentOrgId: string,
  practitionerOrganisations?: any,
) => {
  const currentOrgById = organisations.find((org) => org.id === currentOrgId);
  if (
    currentOrgById &&
    !practitionerOrganisations?.find((porg: any) => currentOrgId === porg?.id)
  ) {
    setCurrentOrg(currentOrgById);
  }
};

export const getNavigatePath = (
  selectedGroup: Group | null,
  requestData: FormData & { organisationIds: string[] },
  existingUser = false,
) => {
  if (selectedGroup) {
    return { path: `/groups/${selectedGroup.id}`, activeTab: null };
  }
  return {
    path: `/organisations/${requestData.organisationIds[0]}`,
    activeTab: existingUser ? 'practitioners' : 'invites',
  };
};

export const getUserOptions = (
  t: (value: string) => string,
  existingPractitioner: PractitionerData,
  isGroupManager: boolean,
) => {
  if (existingPractitioner && isGroupManager) {
    return [
      {
        label: t(
          'ui.event-management.users.invite.select-user-option.gm.single-org',
        ),
        value: InviteUserOrgOptions.ONE_ORG,
      },
      {
        label: t(
          'ui.event-management.users.invite.select-user-option.gm.multi-org',
        ),
        value: InviteUserOrgOptions.MULTI_ORG,
      },
    ];
  }
  return [
    {
      label: t(
        'ui.event-management.users.invite.select-user-option.single-org',
      ),
      value: InviteUserOrgOptions.ONE_ORG,
    },
    {
      label: t('ui.event-management.users.invite.select-user-option.multi-org'),
      value: InviteUserOrgOptions.MULTI_ORG,
    },
  ];
};
