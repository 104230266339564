import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library';
import { InviteeResponse } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';
import { sortChapters } from 'utils/mappers/sorting';
import AssignChapterIcon from 'components/Svgs/AssignChapters';

const summariseChapters = (
  invitees: InviteeResponse[],
): Array<[string, number]> =>
  Object.entries(
    invitees.reduce((acc: any, curr) => {
      const a = { ...acc };
      curr.chapters.forEach((chapter) => {
        if (a[chapter.name]) {
          a[chapter.name] += 1;
        } else {
          a[chapter.name] = 1;
        }
      });
      return a;
    }, {}),
  );

type ChapterSummaryProps = {
  invitees: InviteeResponse[];
};

const StyledP = styled(P)({
  textAlign: 'right',
});

const ChapterSummary = ({ invitees }: ChapterSummaryProps) => {
  const { t } = useTranslation();

  if (invitees.length === 0) {
    return (
      <P variant="body-bold" color="textSecondary">
        {t('ui.event-management.events.summary.add-learner-for-chapters')}
      </P>
    );
  }

  return (
    <Grid container>
      {summariseChapters(invitees)
        .sort(([chapterA], [chapterB]) => sortChapters(chapterA, chapterB))
        .map(([chapter, learners]) => {
          return (
            <Grid key={`chapter-${chapter}`} container>
              <Grid item xs={7}>
                <P variant="body-bold" color="textSecondary">
                  {t(
                    `ui.event-management.events.assign-chapters.${chapter
                      .replace('_', '-')
                      .toLowerCase()}`,
                  )}
                </P>
              </Grid>
              <Grid id={`learners-${chapter}`} item xs={5}>
                <StyledP variant="body-bold" color="primary">
                  {t(
                    'ui.event-management.events.overview.summary.no-of-learners',
                    {
                      count: learners,
                    },
                  )}
                </StyledP>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

type Props = {
  disabled: boolean;
  invitees: InviteeResponse[];
};

const AssignChaptersCard = ({ invitees, disabled }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const disabledCard = disabled || invitees.length === 0;

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        border: disabledCard ? '0' : `1px solid ${theme.palette.grey[500]}`,
        borderBottom: disabledCard
          ? 'undefined'
          : `0.2em solid ${theme.palette.primary.main}`,
        opacity: disabledCard ? '0.5' : 'undefined',
      })}
    >
      <CardActionArea
        disabled={disabledCard}
        onClick={() => navigate(`${pathname}/assign-chapters`)}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          fontFamily: 'inherit',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <AssignChapterIcon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <P variant="h3" color={disabledCard ? 'textPrimary' : 'primary'}>
              {t('ui.event-management.events.summary.assign-chapters')}
            </P>
          </Box>
          <ChapterSummary invitees={invitees} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AssignChaptersCard;
