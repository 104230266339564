import React, { useState, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Trans, useTranslation } from 'react-i18next';
import { P, Span } from '@insights-ltd/design-library';
import { UserPicker } from 'components/UserPicker';
import { useGetPractitioners } from 'api/practitioners/practitionerHooks';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { PractitionerData } from 'api';
import { EventType } from 'types/types';
import { Practitioner, EventForm } from './types';

type Props = {
  event: EventForm;
  onSubmit: (practitioners: Practitioner[]) => void;
  children: React.ReactNode;
};

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const getAvailablePractitioners = (
  practitioners: PractitionerData[],
  eventType?: EventType | '',
) => {
  if (eventType === 'INSIGHTS_DISCOVERY_ACCREDITATION') {
    return practitioners.filter(({ roles }) => roles.includes('SUPER_ADMIN'));
  }
  return practitioners;
};

const PractitionersForm = ({ event, onSubmit, children }: Props) => {
  const [practitioners, setPractitioners] = useState(event.practitioners);
  const { status, error, isError, data } = useGetPractitioners(
    event?.organisation?.id || '',
    { enabled: !!event?.organisation },
  );

  const availablePractitioners = useMemo(
    () => getAvailablePractitioners(data ?? [], event?.eventType ?? ''),
    [data, event.eventType],
  );
  const { t } = useTranslation();

  const submit = (submitEvent: React.FormEvent<HTMLFormElement>) => {
    submitEvent.preventDefault();
    onSubmit(practitioners);
  };

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={(e) => submit(e)}>
      <P>
        <Trans
          t={t}
          i18nKey="ui.event-management.events.edit.practitioners.description.bold"
          components={{
            strong: <Span variant="body-bold" color="textSecondary" />,
          }}
        />
      </P>
      <UserPicker
        users={availablePractitioners}
        selectedUsers={practitioners}
        onChange={setPractitioners}
        loading={status === 'pending'}
        isLoadingError={isError}
      />
      {children}
      <Snackbar open={Boolean(error)} autoHideDuration={6000}>
        <Alert elevation={6} variant="filled" severity="error">
          {t('ui.event-management.events.edit.practitioners.request-error')}
        </Alert>
      </Snackbar>
    </StyledForm>
  );
};

export default PractitionersForm;
