import React from 'react';
import Box from '@mui/material/Box';
import { H3, Span, Dialog } from '@insights-ltd/design-library';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

export type Props = {
  open: boolean;
  onClose: () => void;
};

const StyledH3 = styled(H3)({
  fontSize: '18px !important',
});

const baseUrl =
  import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;

const DialogContent = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <img
        src={`${baseUrl}/img/waiting_animation.gif`}
        alt="progress-graphic"
        width={100}
      />
      <Box
        mt={1}
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        <StyledH3>{title}</StyledH3>
        <Trans
          i18nKey="ui.event-management.events.download-info-dialog.info-description"
          components={{
            styles: <Span color="textSecondary" variant="body-bold" />,
          }}
        />
      </Box>
    </Box>
  );
};

const DownloadingProfilesDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      title={t('ui.event-management.events.download-info-dialog.title')}
      content={
        <DialogContent
          title={t(
            'ui.event-management.events.download-info-dialog.info-header',
          )}
        />
      }
      secondaryButtonText={t(
        'ui.event-management.events.download-or-share-profiles.confirmation-modal.close',
      )}
      onClose={onClose}
    />
  );
};

export default DownloadingProfilesDialog;
