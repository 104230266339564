import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { Helmet } from 'react-helmet';
import { H1, P, passwordValidationRules } from '@insights-ltd/design-library';

import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import ValidationTags from 'components/ValidationTags';
import { useResetPassword } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type ResetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

const PasswordReset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate, isPending, isError } = useResetPassword();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordFormData>({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = ({ password }: ResetPasswordFormData) => {
    const token = hash.slice('#token='.length);
    return mutate(
      { password, token },
      { onSuccess: () => navigate('/signin') },
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.reset-password')}</title>
      </Helmet>
      <SelfServiceLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            sx={(theme) => ({
              maxWidth: '31rem',
              padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
            })}
          >
            <CardContent>
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  sx={(theme) => ({
                    width: '100%',
                    margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                  item
                  xs={12}
                >
                  <H1 variant="h3">
                    {t('ui.event-management.reset-password.heading')}
                  </H1>
                </Grid>
                <Grid item xs={12}>
                  <P variant="body-bold" color="textSecondary">
                    {t(
                      'ui.event-management.authentication.password.instruction',
                    )}
                  </P>
                  <ValidationTags
                    value={watch('password', '')}
                    errors={errors.password}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    sx={(theme) => ({
                      width: '100%',
                      margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                    })}
                  >
                    <InputLabel
                      htmlFor="password"
                      error={Boolean(errors.password)}
                    >
                      {t(
                        'ui.event-management.reset-password.password.input-label',
                      )}
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      autoComplete="off"
                      label={t(
                        'ui.event-management.reset-password.password.input-label',
                      )}
                      type={showPassword ? 'text' : 'password'}
                      {...register('password', passwordValidationRules)}
                      error={Boolean(errors.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={t(
                              'ui.event-management.authentication.password.visibility',
                            )}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    sx={(theme) => ({
                      width: '100%',
                      margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                    })}
                  >
                    <InputLabel
                      htmlFor="confirmPassword"
                      error={Boolean(errors.password)}
                    >
                      {t(
                        'ui.event-management.reset-password.password-confirm.input-label',
                      )}
                    </InputLabel>
                    <OutlinedInput
                      id="confirmPassword"
                      label={t(
                        'ui.event-management.reset-password.password-confirm.input-label',
                      )}
                      type={showPassword ? 'text' : 'password'}
                      {...register('confirmPassword', {
                        ...passwordValidationRules,
                        validate: {
                          matches: (val: string) =>
                            val === watch('password', ''),
                        },
                      })}
                      error={Boolean(errors.confirmPassword)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={t(
                              'ui.event-management.authentication.password.visibility',
                            )}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText error variant="outlined">
                      {errors.confirmPassword &&
                        t(
                          'ui.event-management.reset-password.password.error.confirm',
                        )}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isPending}
                    type="submit"
                    size="large"
                  >
                    {t('ui.event-management.reset-password.cta')}
                  </Button>
                </Grid>
              </Grid>
              <Snackbar open={isError} autoHideDuration={6000}>
                <Alert elevation={6} variant="filled" severity="error">
                  {t('ui.event-management.reset-password.token.error')}
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </form>
      </SelfServiceLayout>
    </>
  );
};

export default PasswordReset;
