import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import { H1 } from '@insights-ltd/design-library';
import { queryStatus } from 'utils/queryStatus';
import { FormData } from 'components/EventForms';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  eventResponseToForm,
  toEventRequest,
} from 'utils/mappers/entityMappers';
import { useQueryClient } from '@tanstack/react-query';
import { useGetEvent, useEventInvitees, useUpdateEvent } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import EditLocation from './EditLocation';
import EditEventDetails from './EditEventDetails';
import EditPractitioners from './EditPractitioners';
import EditCoreDetails from './EditCoreDetails';

const EditEvent = () => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { status: getEventStatus, data: event } = useGetEvent(eventId || '');
  const { status: inviteesStatus, data: invitees } = useEventInvitees(
    eventId || '',
  );
  const {
    mutate,
    status: updateEventStatus,
    reset,
  } = useUpdateEvent(queryClient);
  const loadingStatus = queryStatus(getEventStatus, inviteesStatus);

  if (loadingStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (loadingStatus === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.events.overview.error-loading-event')}
      />
    );
  }

  const eventForm = eventResponseToForm(event!);
  const invitedCount = invitees!.filter(
    ({ status }) => status !== 'ADDED',
  ).length;
  const onCancel = () => navigate(`/experiences/${eventId}`);

  const saveEvent = (formData: FormData) => {
    const requestForm = { ...eventForm, ...formData };
    const eventRequest = toEventRequest(requestForm);
    mutate(
      { eventId: eventId || '', event: eventRequest },
      { onSuccess: () => navigate(`/experiences/${eventId}`) },
    );
  };

  const saving = updateEventStatus === 'pending';
  const editFormProps = { eventForm, onCancel, saveEvent, saving };

  return (
    <>
      <Helmet>
        <title>
          {t('ui.event-management.title.edit-event', {
            eventName: event!.name,
          })}
        </title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.events.nav.home'),
                '/experiences': t('ui.event-management.events.title'),
                [`/experiences/${eventId}`]: t(event!.name),
              }}
              activeText={t('ui.event-management.events.edit.title')}
            />
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <H1 variant="h2">{t('ui.event-management.events.edit.title')}</H1>
              <Box my={(theme) => theme.spacing(spacingSizeMap.L)}>
                <Divider />
              </Box>
              <Routes>
                <Route
                  path="core-details"
                  element={
                    <EditCoreDetails
                      {...editFormProps}
                      invitedCount={invitedCount}
                    />
                  }
                />
                <Route
                  path="practitioners"
                  element={<EditPractitioners {...editFormProps} />}
                />
                <Route
                  path="event-details"
                  element={
                    <EditEventDetails
                      {...editFormProps}
                      invitedCount={invitedCount}
                    />
                  }
                />
                <Route
                  path="event-location"
                  element={<EditLocation {...editFormProps} />}
                />
                <Route
                  path="*"
                  element={<Navigate to={`/experiences/${eventId}`} />}
                />
              </Routes>
            </Grid>
          </Grid>
        </Container>
        <Snackbar
          open={updateEventStatus === 'error'}
          autoHideDuration={6000}
          onClose={reset}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={reset}
            severity="error"
          >
            {t('ui.event-management.events.create.save-event-error')}
          </Alert>
        </Snackbar>
      </FullHeight>
    </>
  );
};

export default EditEvent;
