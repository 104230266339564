import OrganisationIcon from 'components/Svgs/icons/Organisation';
import GroupIcon from 'components/Svgs/icons/Group';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';
import React from 'react';
import { P } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';

export type OrganisationCardIcon = 'Organisation' | 'Group';

export const StyledOrganisationIcon = styled(OrganisationIcon)({
  width: '30.03px',
  height: '26.25px',
});

export const StyledGroupIcon = styled(GroupIcon)({
  width: '30.03px',
  height: '26.25px',
});

export const OrganisationIconWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  fill: theme.palette.grey[500],
  borderRadius: '0',
  background: theme.palette.common.white,
  minWidth: '40px !important',
  minHeight: '40px !important',
  width: '40px !important',
  height: '40px !important',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const OrganisationIconHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: theme.spacing(spacingSizeMap.XS),
  alignItems: 'top',
}));

export const OrganisationTitle = styled(P)(({ theme }) => ({
  marginLeft: '12px !important',
  fontSize: '24px !important',
  fontWeight: `${theme.typography.fontWeightBold} !important`,
  wordBreak: 'break-word',
  lineHeight: '32px !important',
}));

export const GroupDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: theme.spacing(spacingSizeMap.XS),
  alignItems: 'flex-start',
  color: theme.palette.grey[700],
  fontSize: '16px',
}));

export const RoleText = styled('span')(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: '16px',
}));

export const BoldDataText = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export const IconWrapper = ({
  iconType,
}: {
  iconType?: OrganisationCardIcon | null;
}) => {
  const { t } = useTranslation();
  switch (iconType) {
    case 'Organisation':
      return (
        <OrganisationIconWrapper>
          <StyledOrganisationIcon
            title={t('ui.event-management.organisations.alt.icon')}
          />
        </OrganisationIconWrapper>
      );
    case 'Group':
      return (
        <OrganisationIconWrapper>
          <StyledGroupIcon
            title={t('ui.event-management.organisations.group.alt.icon')}
          />
        </OrganisationIconWrapper>
      );
    default:
      return null;
  }
};
