import React, { ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import {
  DatePickerV2,
  FullScreenSpinner,
  TextAreaV2,
  TimePickerV2,
} from '@insights-ltd/design-library';
import { useAuth } from 'contexts/AuthContext';
import { useBetaEnabled } from 'features';
import {
  getEventDialects,
  isDFCExperience,
  isExploreExperience,
  isIdtlExperience,
} from 'domain/event';
import { FREQUENCY_OPTIONS, Model, PRODUCT_TYPES, TIMEZONES } from 'variables';
import { LicensedProduct, ProductTypes } from 'types/types';
import { useGetSupportedDialects } from 'api';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { DateTime } from 'luxon';
import {
  getErrorMessage,
  startDateFieldRules,
  startTimeFieldRules,
  endDateFieldRules,
  endTimeFieldRules,
  deadlineFieldRules,
} from './validation';
import { EventDetailsData, EventForm } from './types';
import { Select } from './Select';

type Props = {
  event: EventForm;
  onSubmit: (formData: EventDetailsData) => void;
  children: ReactNode;
  invitedCount: number;
  isCreating?: boolean;
};

type UserProducts = Partial<typeof PRODUCT_TYPES>;

const {
  DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE,
  DISCOVERY_FULL_CIRCLE_PROFILE,
  DISCOVERY_SELF_AWARE_PROFILE,
  DISCOVERY_PERSONAL_PROFILE,
  DISCOVERY_EXPLORE_PROFILE,
} = PRODUCT_TYPES;
const CHARACTER_LIMIT = 500;

const useProductTypesForEvent = (
  event: EventForm,
  isCreating: boolean,
): UserProducts => {
  const in3drsBeta = useBetaEnabled('default-3drs');

  if (isIdtlExperience(event.eventType)) {
    return {
      DISCOVERY_TRANSFORMATIONAL_LEADERSHIP_PROFILE,
    };
  }

  if (isDFCExperience(event.eventType)) {
    return {
      DISCOVERY_FULL_CIRCLE_PROFILE,
    };
  }

  if (isExploreExperience(event.eventType)) {
    return {
      DISCOVERY_EXPLORE_PROFILE,
    };
  }

  if (in3drsBeta && isCreating) {
    return {
      DISCOVERY_SELF_AWARE_PROFILE,
    };
  }

  return {
    DISCOVERY_SELF_AWARE_PROFILE,
    DISCOVERY_PERSONAL_PROFILE,
  };
};

const useGetProductTypesForEvent = (
  event: EventForm,
  userProducts: LicensedProduct[],
  isCreating: boolean,
): Partial<ProductTypes> => {
  const productTypes = useProductTypesForEvent(event, isCreating);

  let productTypesAvailableToUser: UserProducts;
  if (isCreating) {
    productTypesAvailableToUser = Object.fromEntries(
      Object.entries(productTypes).filter(([productKey]) =>
        userProducts.includes(productKey as LicensedProduct),
      ),
    );
  } else {
    productTypesAvailableToUser = Object.fromEntries(
      Object.entries(productTypes).filter(
        ([productKey]) => productKey === event.productType,
      ),
    );
  }

  return productTypesAvailableToUser;
};

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: `${theme.spacing(spacingSizeMap.L)} !important`,
  },
}));

const EventDetailsForm = ({
  event,
  onSubmit,
  children,
  invitedCount,
  isCreating = false,
}: Props) => {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const products = useGetProductTypesForEvent(
    event,
    user?.licensedProducts || [],
    isCreating,
  );

  const productTypeKeys = Object.keys(products);
  const defaultProductType =
    productTypeKeys.length === 1
      ? (productTypeKeys[0] as LicensedProduct)
      : event.productType;

  let model: Model;
  if (event.eventType === 'INSIGHTS_EXPLORE') {
    model = 'EXPLORE';
  } else if (event.eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE') {
    model = 'DFC';
  } else {
    model = 'ALL_DIALECTS';
  }
  const lang = i18n.language;
  const { data, isPending } = useGetSupportedDialects(model, lang);
  const supportedDialects = data?.dialects;
  const dialects = supportedDialects || getEventDialects(event);

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    trigger,
    register,
  } = useForm<EventDetailsData>({
    defaultValues: {
      ...event,
      startTime: event.startTime
        ? event.startTime
        : DateTime.fromISO('1970-01-01T09:00:00.000Z', { zone: 'default' }),
      endTime: event.endTime
        ? event.endTime
        : DateTime.fromISO('1970-01-01T17:00:00.000Z', { zone: 'default' }),
      productType: defaultProductType,
      ...(dialects.length === 1 && { dialect: dialects[0].value }),
    },
  });
  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Select
        disabled={invitedCount > 0 || productTypeKeys.length === 1}
        id="productType"
        name="productType"
        label={t('ui.event-management.events.create.label.profile-type')}
        control={control}
        error={Boolean(errors.productType)}
        rules={{ required: true }}
        errorMessage={t(
          'ui.event-management.events.create.error.value-required',
        )}
      >
        {Object.entries(products).map(([productType, textKey]) => (
          <MenuItem key={productType} value={productType}>
            {t(textKey)}
          </MenuItem>
        ))}
      </Select>

      <Select
        id="dialect"
        name="dialect"
        label={t('ui.event-management.events.create.label.event-language')}
        control={control}
        disabled={dialects.length === 1}
        error={Boolean(errors.dialect)}
        rules={{ required: true }}
        errorMessage={t(getErrorMessage(errors.dialect))}
      >
        {dialects.map((dialect) => (
          <MenuItem data-dialect key={dialect.value} value={dialect.value}>
            {t(dialect.textKey)}
          </MenuItem>
        ))}
      </Select>

      <Select
        id="timezone"
        name="timezone"
        label={t('ui.event-management.events.create.label.timezone')}
        control={control}
        error={Boolean(errors.timezone)}
        rules={{ required: true }}
        errorMessage={t(getErrorMessage(errors.timezone))}
      >
        {TIMEZONES.map((value: string) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <DatePickerV2
                  disablePast
                  error={Boolean(errors.startDate)}
                  errorMessage={t(getErrorMessage(errors.startDate))}
                  labelText={t(
                    'ui.event-management.events.create.label.start-date',
                  )}
                  onChange={(newValue) => {
                    onReactHookFormChange(newValue);
                    trigger([
                      'startDate',
                      'startTime',
                      'endDate',
                      'endTime',
                      'deadlineAt',
                    ]);
                  }}
                  timezone="default"
                  value={value}
                  placeholder={t(
                    'ui.event-management.events.create.label.select-date',
                  )}
                />
              );
            }}
            rules={startDateFieldRules}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            name="startTime"
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <TimePickerV2
                  data-testid="time-picker-startTime"
                  error={Boolean(errors.startTime)}
                  errorMessage={t(getErrorMessage(errors.startTime))}
                  labelText={t(
                    'ui.event-management.events.create.label.start-time',
                  )}
                  onChange={(newValue) => {
                    onReactHookFormChange(newValue);
                    trigger([
                      'startDate',
                      'startTime',
                      'endDate',
                      'endTime',
                      'deadlineAt',
                    ]);
                  }}
                  timezone="default"
                  value={value}
                />
              );
            }}
            rules={startTimeFieldRules}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <DatePickerV2
                  disablePast
                  error={Boolean(errors.endDate)}
                  errorMessage={t(getErrorMessage(errors.endDate))}
                  labelText={t(
                    'ui.event-management.events.create.label.end-date',
                  )}
                  onChange={(newValue) => {
                    onReactHookFormChange(newValue);
                    trigger([
                      'startDate',
                      'startTime',
                      'endDate',
                      'endTime',
                      'deadlineAt',
                    ]);
                  }}
                  timezone="default"
                  value={value}
                  placeholder={t(
                    'ui.event-management.events.create.label.select-date',
                  )}
                />
              );
            }}
            rules={endDateFieldRules(getValues)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            name="endTime"
            render={({ field: { onChange: onReactHookFormChange, value } }) => {
              return (
                <TimePickerV2
                  data-testid="time-picker-endTime"
                  error={Boolean(errors.endTime)}
                  errorMessage={t(getErrorMessage(errors.endTime))}
                  labelText={t(
                    'ui.event-management.events.create.label.end-time',
                  )}
                  onChange={(newValue) => {
                    onReactHookFormChange(newValue);
                    trigger([
                      'startDate',
                      'startTime',
                      'endDate',
                      'endTime',
                      'deadlineAt',
                    ]);
                  }}
                  timezone="default"
                  value={value}
                />
              );
            }}
            rules={endTimeFieldRules(getValues)}
          />
        </Grid>
      </Grid>
      {!isIdtlExperience(event.eventType) && (
        <>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="deadlineAt"
                render={({
                  field: { onChange: onReactHookFormChange, value },
                }) => {
                  return (
                    <DatePickerV2
                      disablePast
                      error={Boolean(errors.deadlineAt)}
                      errorMessage={t(getErrorMessage(errors.deadlineAt))}
                      labelText={t(
                        'ui.event-management.events.create.label.evaluator-deadline',
                      )}
                      onChange={(newValue) => {
                        onReactHookFormChange(newValue);
                        trigger([
                          'startDate',
                          'startTime',
                          'endDate',
                          'endTime',
                          'deadlineAt',
                        ]);
                      }}
                      timezone="default"
                      value={value}
                      placeholder={t(
                        'ui.event-management.events.create.label.select-date',
                      )}
                    />
                  );
                }}
                rules={deadlineFieldRules(getValues)}
              />
            </Grid>
          </Grid>
          <Select
            id="notificationFrequency"
            name="notificationFrequency"
            label={t(
              'ui.event-management.events.create.label.notification-frequency',
            )}
            control={control}
            error={Boolean(errors.notificationFrequency)}
            rules={{ required: true }}
            errorMessage={t(getErrorMessage(errors.notificationFrequency))}
          >
            {FREQUENCY_OPTIONS.map(({ textKey, value }) => (
              <MenuItem key={value} value={value}>
                {t(textKey)}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {isExploreExperience(event.eventType) && (
        <Controller
          name="customEmailMessage"
          rules={{ maxLength: CHARACTER_LIMIT }}
          control={control}
          render={({ field: { onChange } }) => (
            <TextAreaV2
              id="customEmailMessage"
              label={t('ui.event-management.events.create.label.custom-email')}
              rows={3}
              fullWidth
              maxLength={CHARACTER_LIMIT}
              optionalText={t('ui.event-management.events.optional.field')}
              additionalMessage={t(
                'ui.event-management.events.create.label.custom-email.helper',
              )}
              placeholder={t(
                'ui.event-management.events.create.label.custom-email.placeholder',
              )}
              onChange={onChange}
              inputProps={register('customEmailMessage')}
            />
          )}
        />
      )}
      {children}
    </StyledForm>
  );
};

export default EventDetailsForm;
