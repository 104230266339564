import React, { useState } from 'react';
import { Avatar, Box, styled } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  TeamLearnerResponse,
  TeamResponse,
  useAddAllLearnersToTeam,
  useAddTeamLearner,
  useRemoveAllLearnersToTeam,
  useRemoveTeamLearner,
} from 'api';
import { TeamLearners } from 'pages/EditTeam/types';
import { useTranslation } from 'react-i18next';
import { User } from 'types/types';
import SvgHierarchy8 from '@insights-ltd/design-library/src/components/Svgs/streamline-bold/Hierarchy8V2';
import {
  AddRemoveModalProps,
  P,
  Text,
  sortOrgsAlphabetically,
  spacingSizeMap,
} from '@insights-ltd/design-library';
import PrivateTag from 'components/PrivateTag/PrivateTag';

const getTeamLearners = (teamLearners: TeamLearnerResponse[]) => {
  return (
    teamLearners?.map((learner: TeamLearners) => ({
      ...learner,
      emailAddress: learner.primaryEmail,
    })) || []
  );
};

const getTranslations = (
  team: TeamResponse | undefined,
  learnerCount: number,
  filteredLearnerCount: number,
  searchTerm: string,
  t: any,
  availableLearnerCount: number,
): AddRemoveModalProps<any>['translations'] => {
  const isMultiOrg = team?.organisationContext?.type === 'MULTIPLE';
  const teamTitle = isMultiOrg
    ? 'ui.event-management.team.add-remove-modal.multi-org.title'
    : 'ui.event-management.team.add-remove-modal.title';
  return {
    add: t('ui.event-management.team.add-remove-modal.add'),
    remove: t('ui.event-management.team.add-remove-modal.remove'),
    finish: t('ui.event-management.team.add-remove-modal.finish'),
    title: t(teamTitle, {
      teamName: team?.name || '',
    }),
    clear: t('ui.event-management.team.add-remove-modal.clear'),
    errorMsg: undefined,
    addAllLoading: t(
      'ui.event.management.team.add-remove-learners.dialog.add-all-loading',
      { teamName: team?.name || '' },
    ),
    addAll: {
      addAllText: t('ui.event-management.group.add-remove.add-all', {
        count: availableLearnerCount || 0,
      }),
      confirmAddAllAction: t(
        'ui.event-management.group.add-remove.add-all.confirm',
      ),
      cancelAddAllAction: t(
        'ui.event-management.group.add-remove.add-all.cancel',
      ),
      confirmationTitle: t(
        'ui.event.management.team.add-remove-learners.dialog.add-all-confirm',
        { count: availableLearnerCount || 0 },
      ),
    },
    removeAll: {
      removeAllText: t('ui.event-management.group.add-remove.remove-all'),
      cancelRemoveAllAction: t(
        'ui.event-management.group.add-remove.removal-all.cancel',
      ),
      confirmRemoveAllAction: t(
        'ui.event-management.group.add-remove.removal-all.confirm',
      ),
      confirmationTitle: t(
        'ui.event.management.team.add-remove-learners.dialog.remove-all-confirm',
        { count: learnerCount },
      ),
    },
    findItem: t(
      'ui.event-management.team.add-remove-modal.find-learners-title',
    ),
    findItemPlaceHolder: t(
      'ui.event-management.team.add-remove-modal.search-learners.placeholder',
    ),
    itemsInGroup: t(
      'ui.event-management.team.add-remove-modal.learners-in-group-title',
      { teamName: team?.name || '', count: learnerCount || 0 },
    ),
    itemsInGroupPlaceholder: t(
      'ui.event-management.team.add-remove-modal.search-learners-in-team.placeholder',
    ),
    itemsInGroupResults: {
      plural: (count: number) =>
        t(
          'ui.event-management.team.add-remove-modal.team-learner-results-plural',
          { count },
        ),
      singular: (count: number) =>
        t(
          'ui.event-management.team.add-remove-modal.team-learner-results-singular',
          { count },
        ),
      none: () =>
        t(
          'ui.event-management.team.add-remove-modal.learners-in-group-no-search-result',
        ),
    },
    findItemResults: {
      prompt: t('ui.event-management.team.add-remove-modal.search-prompt'),
      plural: () =>
        t('ui.event-management.team.add-remove-modal.results.plural', {
          count: filteredLearnerCount,
        }),
      singular: () =>
        t('ui.event-management.team.add-remove-modal.results.singular', {
          count: filteredLearnerCount,
        }),
      none: () =>
        t('ui.event-management.team.add-remove-modal.results.none', {
          searchTerm,
        }),
    },
  };
};

export const useAddRemoveModal = (
  team: TeamResponse | undefined,
  learners: User[],
  teamLearners: TeamLearnerResponse[],
  refetchTeam: any,
  searchTerm: string,
  availableLearnerCount: number,
): {
  onAdd: (userId: string) => void;
  onRemove: (userId: string) => void;
  onClose: () => void;
  onRemoveAll: () => void;
  onAddAll: () => void;
  toggleAddRemoveModal: () => void;
  availableItems: User[];
  itemsInGroup: User[];
  isOpen: boolean;
  translations: AddRemoveModalProps<any>['translations'];
  isError: string | false | undefined;
  isAddingAll: boolean;
  isRemovingAll: boolean;
} => {
  const teamId = team?.id ?? '';
  const queryClient = useQueryClient();
  const itemsInGroup = getTeamLearners(teamLearners);
  const [isAddRemoveModalOpen, setIsAddRemoveModalOpen] = useState(false);
  const { mutateAsync: addLearner } = useAddTeamLearner(queryClient);
  const { mutateAsync: removeLearner } = useRemoveTeamLearner(
    queryClient,
    teamId,
  );
  const {
    mutate: addAll,
    isPending: isAddingAll,
    isError: isAddingAllError,
    reset: resetAddAllLearners,
  } = useAddAllLearnersToTeam(queryClient);
  const {
    mutate: removeAll,
    isPending: isRemovingAll,
    isError: isRemovingAllError,
    reset: resetRemoveAllLearners,
  } = useRemoveAllLearnersToTeam(queryClient);

  const { t } = useTranslation();

  const onAdd = (userId: string) => {
    addLearner({ learnerId: userId, teamId }).then(() => refetchTeam());
  };

  const onRemove = (userId: string) => {
    removeLearner({ learnerId: userId, teamId }).then(() => refetchTeam());
  };

  const onClose = () => {
    resetAddAllLearners();
    resetRemoveAllLearners();
    setIsAddRemoveModalOpen(false);
  };

  const getErrorMessage = () => {
    if (isAddingAllError) {
      return t('ui.event-management.team.add-remove-learners.error.add-all');
    }
    if (isRemovingAllError) {
      return t('ui.event-management.team.add-remove-learners.error.remove-all');
    }

    return undefined;
  };

  const errorMsg = getErrorMessage();

  const availableItems = learners?.filter(
    (learner) => !itemsInGroup.find((user) => user?.id === learner.id),
  );

  return {
    onAdd,
    onRemove,
    onClose,
    onRemoveAll: () => removeAll(team?.id || ''),
    onAddAll: () => addAll(team?.id || ''),
    toggleAddRemoveModal: () => setIsAddRemoveModalOpen(!isAddRemoveModalOpen),
    availableItems,
    itemsInGroup,
    isOpen: isAddRemoveModalOpen,
    translations: {
      ...getTranslations(
        team,
        team?.learnerCount ?? 0,
        availableItems?.length,
        searchTerm,
        t,
        availableLearnerCount,
      ),
      errorMsg,
    },
    isError: (isAddingAllError || isRemovingAllError) && errorMsg,
    isAddingAll,
    isRemovingAll,
  };
};
const StyledHierarchy8Bold = styled(SvgHierarchy8)(({ theme }) => ({
  width: '1.5rem',
  fill: theme.palette.blue.dark,
}));

const StyledOrgList = styled(Text)({
  marginLeft: '.5rem !important',
});

const StyledTeamTitle = styled(P)({
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
});

export const TeamHeader = ({ team }: { team: TeamResponse }) => {
  const { t } = useTranslation();
  const {
    name: teamName,
    organisationContext: { type: teamType } = { type: 'SINGLE' },
    visibility,
  } = team;
  const getOrgsText = () => {
    const organisations = team?.organisationContext?.organisations || [];
    const teamOrgsToDisplay = organisations
      .sort(sortOrgsAlphabetically)
      .map((org, i) => {
        if (i < 2) {
          return org.name;
        }
        return null;
      })
      .filter((value) => value != null);
    const orgslist = `${teamOrgsToDisplay.join(', ')}`;
    const moreText =
      organisations.length > 2
        ? `+${organisations.length - 2} ${t('ui.event-management.team.more')}`
        : '';
    return (
      <>
        <Text variant="body-bold" color="primary">
          {orgslist}
        </Text>
        <Text color="primary"> {moreText}</Text>
      </>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="baseline">
        {teamType === 'MULTIPLE' && (
          <Avatar
            data-testid="multi-org-icon"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              width: '2.5rem',
              marginRight: '.5rem',
            })}
          >
            <StyledHierarchy8Bold />
          </Avatar>
        )}
        <StyledTeamTitle variant="h2" data-testid="team-title">
          {teamName}
        </StyledTeamTitle>
        {visibility === 'PRIVATE' && teamType === 'SINGLE' && (
          <PrivateTag
            sx={(theme) => ({
              marginLeft: theme.spacing(spacingSizeMap.XS),
              '> div': {
                padding: `${theme.spacing(spacingSizeMap.XS)} 10px`,
              },
            })}
          />
        )}
      </Box>
      <Box display="flex" alignItems="baseline" mt="1rem">
        <Text variant="body-bold">
          {teamType === 'SINGLE'
            ? t('ui.event-management.organisation.subtitle')
            : t('ui.event-management.organisations.subtitle')}
        </Text>
        <StyledOrgList variant="body-bold" sx={{ marginLeft: '1rem' }}>
          {getOrgsText()}
        </StyledOrgList>
      </Box>
    </>
  );
};
