import React, { useState } from 'react';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { ChaptersStatus } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import PurchaseDialog, {
  PurchaseSummaryLine,
} from './PurchaseDialog/PurchaseDialog';
import DFCPurchaseDialog from './PurchaseDialog/DFCPurchaseDialog';

export type Props = {
  inviteeIds: string[];
  handleConfirmPurchase: () => void;
  handleSuccessClose: () => void;
  chapterSummaries: PurchaseSummaryLine[];
  purchaseChapters: ChaptersStatus[];
  contributorsCount?: number;
  unitBalance: number;
  event: string | undefined;
  deselectAllForPurchase: () => void;
  newContributors: number;
  showNewContributors: boolean;
};

const PurchaseSelectionBar = ({
  inviteeIds,
  handleConfirmPurchase,
  handleSuccessClose,
  chapterSummaries,
  purchaseChapters,
  contributorsCount = NaN,
  unitBalance,
  event,
  deselectAllForPurchase,
  newContributors,
  showNewContributors,
}: Props) => {
  const { t } = useTranslation();
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState<boolean>(false);
  const aboveMobile = useIsAboveMobile();

  const handlePurchaseClick = () => setOpenPurchaseDialog(true);

  const [firstModalVisible, setFirstModalVisible] = useState<boolean>(true);
  const [secondModalVisible, setSecondModalVisible] = useState<boolean>(true);

  const handleConfirmDFC = () => {
    setFirstModalVisible(false);
    setSecondModalVisible(true);
  };

  const handleDecline = () => {
    setFirstModalVisible(false);
    setSecondModalVisible(true);
    deselectAllForPurchase();
  };

  return (
    <>
      <AppBar
        component="div"
        position="sticky"
        sx={(theme) => ({
          zIndex: 0,
          top: 'auto',
          bottom: '0',
          height: '72px',
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            sx={(theme) => ({
              padding: `${theme.spacing(spacingSizeMap.S)} 0`,
            })}
          >
            {aboveMobile ? (
              <Grid item>
                <P variant="body-bold">
                  {
                    // TODO: add unit cost with translation
                  }
                </P>
              </Grid>
            ) : null}
            <Grid item sx={{ flexGrow: '1' }} />
            <Grid
              item
              sx={(theme) => ({
                '> * + *': {
                  marginLeft: theme.spacing(spacingSizeMap.S),
                },
              })}
            >
              {inviteeIds.length > 0 ? (
                <Button
                  sx={{ maxHeight: '48px' }}
                  disabled={
                    event !== 'INSIGHTS_DISCOVERY_FULL_CIRCLE'
                      ? inviteeIds.length === 0
                      : !secondModalVisible
                  }
                  variant="contained"
                  color="primary"
                  onClick={handlePurchaseClick}
                >
                  {t(
                    'ui.event-management.events.purchase-profiles.purchase-button',
                  )}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      {event === 'INSIGHTS_DISCOVERY_FULL_CIRCLE' ? (
        <DFCPurchaseDialog
          unitBalance={unitBalance}
          open={openPurchaseDialog}
          onClose={() => setOpenPurchaseDialog(false)}
          chapterSummaries={chapterSummaries}
          purchaseChapters={purchaseChapters}
          contributorsCount={contributorsCount}
          handleConfirmPurchase={handleConfirmPurchase}
          firstModalOpen={firstModalVisible}
          OnConfirm={handleConfirmDFC}
          OnDecline={handleDecline}
          newContributors={newContributors}
          showNewContributors={showNewContributors}
        />
      ) : (
        <PurchaseDialog
          unitBalance={unitBalance}
          open={openPurchaseDialog}
          onClose={() => setOpenPurchaseDialog(false)}
          chapterSummaries={chapterSummaries}
          purchaseChapters={purchaseChapters}
          handleConfirmPurchase={handleConfirmPurchase}
          onSuccess={handleSuccessClose}
        />
      )}
    </>
  );
};

export default PurchaseSelectionBar;
