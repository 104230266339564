import { Box, Button, Grid } from '@mui/material';
import { Score } from 'api';
import React from 'react';
import downloadSvg from 'pages/DownloadTeamWheel/downloadSVG';
import {
  filterNonActiveScores,
  getInitialsFromScores,
} from 'pages/DownloadTeamWheel/scoreUtils';
import TableControls from 'pages/DownloadTeamWheel/TeamTableControls';
import TeamTable from 'pages/DownloadTeamWheel/TeamTable';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { Text } from '@insights-ltd/design-library';
import TabControls from './TabControls';

const SVG_TABLE_ID = 'team-table-svg';
const RETENTION_CODE = 'Team_Wheel_Product_WC_TW_RETENTION';

type TeamWheelType = 'Experience' | 'Team';

type TabTeamNamesProps = {
  type: TeamWheelType;
  test: boolean;
  rawScores: (Score & { organisationId?: string; organisationName?: string })[];
  name: string;
  availableCount: number;
  setDownloadErrorFileName: (x: string) => void;
};

const TabTeamNames = ({
  type,
  test,
  rawScores,
  name,
  availableCount,
  setDownloadErrorFileName,
}: TabTeamNamesProps) => {
  const { t } = useTranslation();
  const [{ scoreType, i18n, isLearnerCountExceeded, activeOrganisations }] =
    useWheelContext();
  const activeScores = filterNonActiveScores(rawScores, activeOrganisations);
  const scoresWithInitials = getInitialsFromScores(activeScores);

  const handleTeamTableDownload = () => {
    const filename = `${name}-${type}-${scoreType}-table-${RETENTION_CODE}.png`;
    downloadSvg(filename, SVG_TABLE_ID, true).catch(() => {
      setDownloadErrorFileName(filename);
    });
  };

  if (isLearnerCountExceeded) {
    return (
      <Box pt={3} pb={3}>
        <Text variant="body-bold" color="textSecondary">
          {t('ui.event-management.events.team-wheel.cant-display-name-table')}
        </Text>
      </Box>
    );
  }
  return (
    <>
      <TabControls>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleTeamTableDownload}
          disabled={availableCount < 1}
        >
          {t('ui.event-management.events.team-wheel.download-image')}
        </Button>
      </TabControls>
      <Grid container>
        <Grid item xs={12} md={4}>
          <TableControls type="names" />
        </Grid>
        <Grid item xs={12} md={8}>
          <I18nextProvider i18n={i18n}>
            <TeamTable
              svgId={SVG_TABLE_ID}
              scores={scoresWithInitials}
              test={test}
            />
          </I18nextProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default TabTeamNames;
