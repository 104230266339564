import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CrossIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import { Tag } from '@insights-ltd/design-library';
import { styled, SxProps } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { determineTagState, ErrorState } from './validationUtils';

const ERROR_ICON_MAP = {
  [ErrorState.INVALID]: <CrossIcon />,
  [ErrorState.VALID]: <CheckIcon />,
  [ErrorState.NEUTRAL]: undefined,
} as const;

const ERROR_COLOR_MAP = {
  [ErrorState.INVALID]: 'red',
  [ErrorState.VALID]: 'darkGreen',
  [ErrorState.NEUTRAL]: 'grey',
} as const;

export type Props = {
  errors?: FieldError;
  confirmErrors?: FieldError;
  value: string;
  confirmationValue?: string;
  sx?: SxProps;
};

type TagProps = {
  label: string;
  value: string;
  errors?: FieldError;
  errorKey: string;
  sx?: SxProps;
};

const StyledTag = styled(Tag)(({ theme }) => ({
  marginRight: theme.spacing(spacingSizeMap.XS),
  marginBottom: theme.spacing(spacingSizeMap.S),
  padding: '0',
  backgroundColor: 'white',
  borderRadius: '0',
}));

const ValidationTag = ({ label, value, errors, errorKey, sx }: TagProps) => {
  const tagState = determineTagState(
    value,
    errors?.types?.required,
    !errors?.types?.[errorKey],
  );

  return (
    <StyledTag
      sx={sx}
      label={label}
      color={ERROR_COLOR_MAP[tagState]}
      disabled={tagState === ErrorState.NEUTRAL}
      icon={ERROR_ICON_MAP[tagState]}
    />
  );
};

const ValidationTags = ({
  value,
  confirmationValue,
  errors,
  confirmErrors,
  sx,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      {confirmationValue !== undefined && (
        <ValidationTag
          label={t('ui.event-management.sign-up.password.validation.unique')}
          value={value}
          errors={errors}
          errorKey="unique"
          sx={sx}
        />
      )}
      <ValidationTag
        label={t('ui.event-management.sign-up.password.validation.length')}
        value={value}
        errors={errors}
        errorKey="minLength"
        sx={sx}
      />
      <ValidationTag
        label={t(
          'ui.event-management.sign-up.password.validation.loweruppercase-char',
        )}
        value={value}
        errors={errors}
        errorKey="lowerUppercaseChar"
        sx={sx}
      />
      <ValidationTag
        label={t(
          'ui.event-management.sign-up.password.validation.special-char',
        )}
        value={value}
        errors={errors}
        errorKey="specialChar"
        sx={sx}
      />
      <ValidationTag
        label={t('ui.event-management.sign-up.password.validation.number')}
        value={value}
        errors={errors}
        errorKey="number"
        sx={sx}
      />
      {confirmationValue !== undefined && (
        <ValidationTag
          label={t('ui.event-management.sign-up.password.validation.matches')}
          value={confirmationValue}
          errors={confirmErrors}
          errorKey="matches"
          sx={sx}
        />
      )}
    </Grid>
  );
};

export default ValidationTags;
