import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import { H1, P } from '@insights-ltd/design-library';
import { TabView } from '@insights-ltd/design-library/src/components/TabView';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import { LocationStateProvider } from 'components/hooks/useHistoryState';
import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import Grid from '@mui/material/Grid';
import { ChangeOrganisation } from 'components/ChangeOrganisation';
import { queryStatusIgnoreIdle } from 'utils/combineQueryStatus';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import LearnersTab from './LearnersTab';
import TeamsTab from './TeamsTab';

type LearnerView = 'learners' | 'teams';

const Learners = () => {
  const { t } = useTranslation();

  const [isOrgPickerOpen, setIsOrgPickerOpen] = useState(false);
  const {
    searchState: { resource: tabValue, organisation },
    setSearchState,
  } = useSearchContext();

  const handleSearchState = (state: LearnerView) => {
    if (state !== tabValue) {
      setSearchState({ resource: state, searchTerm: '', checkbox: undefined });
    }
  };

  const myOrganisationsQuery = useGetMyOrganisations();
  const responseStatus = queryStatusIgnoreIdle(myOrganisationsQuery);
  const selectedOrganisation =
    !myOrganisationsQuery.hasMultiOrgsWithoutGroup &&
    myOrganisationsQuery.allMyOrganisations?.[0]
      ? myOrganisationsQuery.allMyOrganisations[0].id
      : organisation?.id ?? null;

  if (responseStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (responseStatus === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.learners.error-loading-learners')}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.my-learners')}</title>
      </Helmet>
      <FullHeight>
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
              }}
              activeText={t('ui.event-management.learners-and-teams.title')}
            />
          </Box>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                <H1 variant="h2">
                  {t('ui.event-management.learners-and-teams.title')}
                </H1>
              </Box>
              <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
                <P color="textSecondary">
                  {t('ui.event-management.learners-and-teams.sub-title')}
                </P>
              </Box>
            </Grid>
            <Grid item xs={12} md="auto">
              {myOrganisationsQuery.hasMultiOrgsWithoutGroup && (
                <ChangeOrganisation
                  disabled={false}
                  organisation={organisation ?? null}
                  handleOrganisationChange={(value) => {
                    setSearchState({ organisation: value });
                    setIsOrgPickerOpen(false);
                  }}
                  handleOnClose={() => {
                    setIsOrgPickerOpen(false);
                  }}
                  open={isOrgPickerOpen}
                  hasAllOrgsOption={
                    myOrganisationsQuery.hasMultiOrgsWithoutGroup
                  }
                />
              )}
            </Grid>
          </Grid>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <TabView
              onChange={(_, value: LearnerView) => handleSearchState(value)}
              value={(tabValue as LearnerView) ?? 'learners'}
              options={[
                {
                  header: t('ui.event-management.learners.learners-tab'),
                  value: 'learners',
                  content: (
                    <LearnersTab
                      organisationId={selectedOrganisation}
                      handleOpenOrgSelector={() => {
                        setIsOrgPickerOpen(true);
                      }}
                    />
                  ),
                },
                {
                  header: t('ui.event-management.learners.all-teams'),
                  value: 'teams',
                  content: (
                    <TeamsTab
                      organisationId={organisation?.id}
                      key={organisation?.id}
                      handleOpenOrgSelector={() => {
                        setIsOrgPickerOpen(true);
                      }}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Container>
      </FullHeight>
    </>
  );
};

const WrappedLearners = () => (
  <LocationStateProvider>
    <Learners />
  </LocationStateProvider>
);

export default WrappedLearners;
