import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PronounsDialectLabel from 'components/PronounsDialectLabel';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { formatDateStyle2, P, Tag } from '@insights-ltd/design-library';
import { ChaptersStatus, ChapterStatus } from 'api';
import { Dialects, Pronoun } from 'types/dialects';
import { INVITEE_STATUSES } from 'variables';
import { InviteeListOptions } from 'types/types';
import { getChapterKey } from 'utils/getChapterKey';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';

export type Props = {
  className?: string;
  id: string;
  fullName: string;
  email: string;
  status: InviteeListOptions;
  editMode: boolean;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
  dialect?: Dialects;
  showPronounsAndDialect?: boolean;
  chapterStatusToDisplay?: ChapterStatus[];
  chapters: ChaptersStatus[];
  profileLastSentAt?: string;
  showLastSharedDate?: boolean;
  pronoun?: Pronoun;
};

const ChapterStatusWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.XS),
}));

const StyledChapterStatus = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  span: {
    lineHeight: '1.8rem',
  },
});

const Complete = styled('div')(({ theme }) => ({
  position: 'static',
  top: theme.spacing(spacingSizeMap.XS),
  left: '0',
  width: '12px',
  height: '12px',
  marginRight: theme.spacing(spacingSizeMap.XS),
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
}));

const Incomplete = styled('div')(({ theme }) => ({
  position: 'static',
  top: theme.spacing(spacingSizeMap.XS),
  left: '0',
  width: '12px',
  height: '12px',
  marginRight: theme.spacing(spacingSizeMap.XS),
  borderRadius: '50%',
  border: `2px solid ${theme.palette.orange.main}`,
}));

const ChaptersByStatus = ({
  status,
  chapters,
}: {
  status?: ChapterStatus;
  chapters: ChaptersStatus[];
}) => {
  const { t } = useTranslation();

  return (
    <ChapterStatusWrapper color="textSecondary">
      <StyledChapterStatus>
        {status === 'PURCHASED' ? <Complete /> : <Incomplete />}
        <span>
          &nbsp;
          {status === 'PURCHASED'
            ? t('ui.event-management.events.assign-chapters.purchased')
            : t('ui.event-management.events.assign-chapters.assigned')}{' '}
        </span>
      </StyledChapterStatus>
      <b>
        {chapters.map((chapter) => t(getChapterKey(chapter.name))).join(', ')}
      </b>
    </ChapterStatusWrapper>
  );
};

interface LastSharedInfoProps {
  checked: boolean;
  disabled: boolean;
  editMode: boolean;
}

const LastSharedInfo = styled('div', {
  shouldForwardProp: (prop) => prop !== 'checked' && prop !== 'editMode',
})<LastSharedInfoProps>(({ checked, disabled, editMode, theme }) => {
  let backgroundColor = theme.palette.grey[300];
  const disabledBackground = theme.palette.grey[200];
  let border = theme.palette.blue.main;

  if (editMode && checked) {
    border = theme.palette.blue.dark;
  }

  if (disabled) {
    backgroundColor = disabledBackground;
    border = '1px solid transparent !important';
  }

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '100%',
    height: theme.spacing(spacingSizeMap.L),
    marginTop: -theme.spacing(spacingSizeMap.L),
    padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
      spacingSizeMap.M,
    )}`,
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
    backgroundColor,
    borderRight: `2px solid ${border}`,
    borderBottom: `2px solid ${border}`,
    borderLeft: `2px solid ${border}`,
    borderRadius: '0 0 4px 4px',
  };
});

const InviteeCard = ({
  id,
  fullName,
  email,
  status: inviteeStatus,
  editMode,
  checked,
  setChecked,
  dialect,
  className,
  showPronounsAndDialect = false,
  disabled = false,
  chapterStatusToDisplay = ['PENDING', 'PURCHASED'],
  chapters,
  profileLastSentAt,
  showLastSharedDate = false,
  pronoun,
}: Props) => {
  const { t } = useTranslation();
  const { textKey, color } = INVITEE_STATUSES[inviteeStatus];

  return (
    <>
      <Card
        variant="outlined"
        sx={(theme) => {
          const disabledBackground = theme.palette.grey[200];
          const blueBorder = `2px solid ${theme.palette.blue.main}`;
          let background;
          let border;
          let borderBottom;
          let borderRadius;
          let height;

          if (editMode && checked) {
            background = theme.palette.blue.light;
            border = theme.palette.blue.dark;
          }

          if (disabled) {
            background = disabledBackground;
            border = 'transparent !important';
          }

          if (showLastSharedDate && profileLastSentAt) {
            borderBottom = `0px`;
            borderRadius = '4px 4px 0px 0px';
            height = theme.spacing(spacingSizeMap.L * 9);
          } else {
            borderBottom = `2px solid ${theme.palette.blue.main}`;
            borderRadius = '4px';
            height = theme.spacing(spacingSizeMap.L * 10);
          }

          return {
            height,
            padding: `0 ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
              spacingSizeMap.S,
            )} ${theme.spacing(spacingSizeMap.XS)}`,
            backgroundColor: background,
            borderColor: border,
            borderRadius,
            borderBottom,
            borderTop: blueBorder,
            borderLeft: blueBorder,
            borderRight: blueBorder,
          };
        }}
        className={className}
      >
        <>
          <CardContent
            sx={(theme) => ({
              paddingBottom: theme.spacing(spacingSizeMap.XS),
            })}
          >
            <Grid container justifyContent="space-between" wrap="nowrap">
              <Grid
                item
                sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                <P variant="body-bold">{fullName}</P>
                <P
                  color="textSecondary"
                  aria-label={`invitee-card-label-email-${email}`}
                >
                  {email}
                </P>
              </Grid>
              <Grid item>
                {editMode ? (
                  <Checkbox
                    onChange={(e) => setChecked(e.target.checked)}
                    checked={checked}
                    name={id}
                    color="primary"
                    disabled={disabled}
                    inputProps={{
                      'aria-label': fullName,
                    }}
                  />
                ) : (
                  <Tag label={t(textKey)} color={color} />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent
            sx={(theme) => ({
              paddingTop: theme.spacing(spacingSizeMap.XS),
            })}
          >
            {chapterStatusToDisplay?.map((statusToDisplay) => {
              const filteredChapters =
                chapters?.filter(({ status }) => status === statusToDisplay) ||
                [];
              return filteredChapters.length === 0 ? null : (
                <ChaptersByStatus
                  status={statusToDisplay}
                  chapters={filteredChapters}
                  key={`chap-status-${statusToDisplay}`}
                />
              );
            })}
            {showPronounsAndDialect && pronoun && dialect && (
              <PronounsDialectLabel pronoun={pronoun} dialect={dialect} />
            )}
          </CardContent>
        </>
      </Card>
      {showLastSharedDate && profileLastSentAt && (
        <LastSharedInfo
          checked={checked}
          disabled={disabled}
          editMode={editMode}
        >
          {t('ui.event-management.invite-list.profile-last-shared', {
            profileLastSentAt: formatDateStyle2(profileLastSentAt),
          })}
        </LastSharedInfo>
      )}
    </>
  );
};

export default InviteeCard;
