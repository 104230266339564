import { cond } from '@insights-ltd/design-library';
import { ColourScore } from 'api';
import getTheme from '@insights-ltd/design-library/src/themes';

const theme = getTheme();

const LARGE_ARC = (Math.PI * 16) / 180;
const SMALL_ARC = (Math.PI * 13) / 180;

export type CoordPair = { x: number; y: number };

export const getSegmentFromScore = (score: number) => {
  // radius is based upon which range the score is in
  const radius = cond([
    [score % 100 > 40, 0],
    [score % 100 > 20, 1],
    [true, 2],
  ]);
  // segment is calculated based on the score mod 20  1 - 16
  const scoreOffset = (score % 20) - 1;
  const segs = Math.ceil(scoreOffset * 1.5);
  const narrowSegment = score > 100;
  const segment = cond([
    [!narrowSegment, segs], // normal segments
    [scoreOffset % 2 === 0, segs + 1], // narrow and even
    [true, segs - 1], // narrow and odd
  ]);
  return { segment, radius };
};

const toRadians = (angle: number) => angle * (Math.PI / 180);

const DISTRIBUTION_ANGLE = 60;
const COMPLETE_ANGLE = 360;
const MAX_DOTS_PER_LAYER = COMPLETE_ANGLE / DISTRIBUTION_ANGLE;

const xPointForAngle = (
  angle: number,
  duplicateNumber: number,
  layerDistance: number,
) => {
  const layerNumber = Math.ceil(duplicateNumber / MAX_DOTS_PER_LAYER);
  return layerDistance * layerNumber * Math.cos(toRadians(angle));
};

const yPointForAngle = (
  angle: number,
  duplicateNumber: number,
  layerDistance: number,
) => {
  const layerNumber = Math.ceil(duplicateNumber / MAX_DOTS_PER_LAYER);
  return layerDistance * layerNumber * Math.sin(toRadians(angle));
};

const duplicateAngle = (duplicateNumber: number) =>
  (duplicateNumber * DISTRIBUTION_ANGLE) % COMPLETE_ANGLE;

const adjustForDuplicateScore = (
  point: { x: number; y: number },
  duplicateNumber: number,
  layerDistance: number,
) => {
  if (duplicateNumber === 0) {
    return point;
  }

  const x =
    point.x +
    xPointForAngle(
      duplicateAngle(duplicateNumber),
      duplicateNumber,
      layerDistance,
    );
  const y =
    point.y +
    yPointForAngle(
      duplicateAngle(duplicateNumber),
      duplicateNumber,
      layerDistance,
    );

  return { x, y };
};

export const INNER_LAYER_DISTANCE = 48;
export const LAYER_DISTANCE = 60;

export const getPositionFromScore = (
  score: number,
  centerCoords: CoordPair,
  radiuses: number[],
  duplicateNumber = 0,
  scale = 0.5,
) => {
  const { segment, radius } = getSegmentFromScore(score);
  const angleBase = (Math.floor(segment / 3) * Math.PI) / 4;
  // arc length depends on which 1/3 of a sem-quadrant we are in
  const angle = cond([
    [segment % 3 === 0, LARGE_ARC / 2],
    [segment % 3 === 1, LARGE_ARC + SMALL_ARC / 2],
    [segment % 3 === 2, LARGE_ARC * 1.5 + SMALL_ARC],
    [true, 0],
  ]);
  const x = centerCoords.x + radiuses[radius] * Math.sin(angleBase + angle);
  const y = centerCoords.y - radiuses[radius] * Math.cos(angleBase + angle);
  const layerDistance = scale * LAYER_DISTANCE;

  return adjustForDuplicateScore({ x, y }, duplicateNumber, layerDistance);
};

// zero based index i.e. score % 20 - 1
type Color = Record<number, string>;
export const segmentColorTable: Color = {
  /* Reformer Segment */
  0: theme.eightColourProfileColours.reformer,
  15: theme.eightColourProfileColours.reformer,
  /* Observer/Scholar Segment */
  14: theme.eightColourProfileColours.scholar,
  13: theme.eightColourProfileColours.scholar,
  /* Coordinator/Sage Segment */
  12: theme.eightColourProfileColours.sage,
  11: theme.eightColourProfileColours.sage,
  /* Supporter/Companion Segment */
  10: theme.eightColourProfileColours.companion,
  9: theme.eightColourProfileColours.companion,
  /* Helper/Guide Segment */
  8: theme.eightColourProfileColours.guide,
  7: theme.eightColourProfileColours.guide,
  /* Inspirer/Storyteller Segment */
  6: theme.eightColourProfileColours.storyteller,
  5: theme.eightColourProfileColours.storyteller,
  /* Motivator/Innovator Segment */
  4: theme.eightColourProfileColours.innovator,
  3: theme.eightColourProfileColours.innovator,
  /* Director/Initiator Segment */
  2: theme.eightColourProfileColours.initiator,
  1: theme.eightColourProfileColours.initiator,
};

export const getColourFromScore = (score: number) =>
  segmentColorTable[(score % 20) - 1];

export const generateScores = () => {
  const range = Array.from(Array(16).keys()).map((i) => i + 1);
  const scores: number[] = [];
  range.forEach((i) => {
    scores.push(i);
    scores.push(i + 20);
    scores.push(i + 40);
    if (i % 2 === 1) {
      const digit = i % 4 === 3 ? i + 1 : i;
      scores.push(digit + 100);
      scores.push(digit + 120);
      scores.push(digit + 140);
    }
  });
  return scores;
};

type ColourMap = { [K in keyof ColourScore]: string };

export const colourMap: ColourMap = {
  red: theme.fourColourProfileColours.fieryRed,
  green: theme.fourColourProfileColours.earthGreen,
  blue: theme.fourColourProfileColours.coolBlue,
  yellow: theme.fourColourProfileColours.sunshineYellow,
};

// This scaling function stops large numbers of duplicateNumbers taking up the entire screen
// The scaling factor of 1.3 is arbitrary and was determined visually
export const arctanScale = (n: number) => ((1.3 * 2) / Math.PI) * Math.atan(n);

const angleFromSegment = (segmentNumber: number) =>
  toRadians(15 * segmentNumber);

const getRadiusBounds = (r: number) =>
  INNER_LAYER_DISTANCE + LAYER_DISTANCE * r;

const getBoundsFromScore = (score: number) => {
  const { segment, radius } = getSegmentFromScore(score);
  /*
   getSegmentFromScore counts segments from 12 o'clock clockwise
   but for boundary calculations it's easier to start from 3 o'clock and go counter-clockwise
   because angles on a circle are measured in the same way.
   Normalise `segment` to count from 3 o'clock counter-clockwise
  */
  const normalizedSegment = (24 - segment + 5) % 24;

  return {
    maxRadius: getRadiusBounds(radius + 1),
    minRadius: getRadiusBounds(radius),
    maxAngle: angleFromSegment(normalizedSegment + 1),
    minAngle: angleFromSegment(normalizedSegment),
  };
};

const cartesianToPolar = (x: number, y: number) => ({
  radius: Math.sqrt(x * x + y * y),
  // Convert from atan2's range [-pi, pi) to [0, 2pi) which is easier to calculate with
  angle: (Math.atan2(y, x) + 2 * Math.PI) % (2 * Math.PI),
});

const polarToCartesian = (r: number, angle: number) => ({
  x: r * Math.cos(angle),
  y: r * Math.sin(angle),
});

const clamp = (x: number, min: number, max: number) =>
  Math.min(Math.max(x, min), max);

const closestPointOnLineSegment = (
  p: CoordPair,
  q: CoordPair,
  c: CoordPair,
): CoordPair => {
  /*
   In vector notation the closest point S on a line segment PQ to point X is
   found by computing lambda where
   lambda = (X - P) . (Q - P) / ((Q - P) . (Q - P))
   S, P, Q, X are vectors, and `.` is the dot product
   if lambda <= 0 then S = P
   if lambda >= 1 then S = Q
   otherwise S = P + lambda * (Q - P)
  */

  const distanceAlongSegment =
    (c.x - p.x) * (q.x - p.x) + (c.y - p.y) * (q.y - p.y);
  const norm = (q.x - p.x) ** 2 + (q.y - p.y) ** 2;
  const lambda = clamp(distanceAlongSegment / norm, 0, 1);
  return {
    x: p.x + lambda * (q.x - p.x),
    y: p.y + lambda * (q.y - p.y),
  };
};

export const clampToSegment = (
  position: number,
  { x, y }: CoordPair, // center
  { x: cx, y: cy }: CoordPair,
) => {
  /*
   Given a wheel position and a target coordinate, clamp the target to within the
   wheel segment. This is used in drag-drop so that the user can't drag the dot
   outside of the segment bounds.
  */
  const { minRadius, maxRadius, minAngle, maxAngle } =
    getBoundsFromScore(position);
  const centeredCoords = { x: x - cx, y: cy - y };
  const { radius: r, angle: theta } = cartesianToPolar(
    centeredCoords.x,
    centeredCoords.y,
  );
  /*
   Some maths so that we can compare angles consistently
   e.g. 359 degrees is very close to 1 degree on a circle, but if
   we compare naively, very far apart.
   Add/subtract 360 degrees (2*pi) to make the comparison consistent
  */
  const distanceToMaxAngle = Math.min(
    Math.abs(theta - maxAngle),
    Math.abs(theta - maxAngle - 2 * Math.PI),
    Math.abs(theta - maxAngle + 2 * Math.PI),
  );
  const distanceToMinAngle = Math.min(
    Math.abs(theta - minAngle),
    Math.abs(theta - minAngle - 2 * Math.PI),
    Math.abs(theta - minAngle + 2 * Math.PI),
  );
  const angleOutsideSegment = theta > maxAngle || theta < minAngle;

  let newCoords: CoordPair = centeredCoords;

  // Find the closest point to the target on either the left or right edge
  // of the segment depending on which is closest
  if (angleOutsideSegment && distanceToMaxAngle <= distanceToMinAngle) {
    const topLeft = polarToCartesian(maxRadius, maxAngle);
    const bottomLeft = polarToCartesian(minRadius, maxAngle);
    newCoords = closestPointOnLineSegment(bottomLeft, topLeft, centeredCoords);
  } else if (angleOutsideSegment && distanceToMaxAngle > distanceToMinAngle) {
    const bottomRight = polarToCartesian(minRadius, minAngle);
    const topRight = polarToCartesian(maxRadius, minAngle);
    newCoords = closestPointOnLineSegment(
      bottomRight,
      topRight,
      centeredCoords,
    );
  } else if (r < minRadius || r > maxRadius) {
    newCoords = polarToCartesian(clamp(r, minRadius, maxRadius), theta);
  }
  return {
    x: cx + newCoords.x,
    y: cy - newCoords.y,
  };
};
