import {
  QueryClient,
  QueryKey,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { QUERY_KEY_ROOTS } from 'variables';
import { TeamResponse, Score, GetTeamLearnersResponse } from 'api/httpEntities';
import { QueryOptionsConfig } from 'api/hooks';
import {
  addAllLearnersToTeam,
  addTeamLearner,
  deleteTeam,
  getTeam,
  getTeamLearners,
  getTeamWheelScores,
  getTeams,
  removeTeamLearner,
  removeAllLearnersToTeam,
  getTeamScoreSummary,
  SummaryResponse,
  updateSingleOrgTeam,
  updateMultiOrgTeam,
  getTeamScoreSummaryByOrganisation,
  getDiscoveryProfileIds,
  shareProfiles,
  downloadTeamProfiles,
} from './teams';
import {
  MultiOrgTeamEditRequest,
  SingleOrgTeamEditRequest,
  TeamLearnerData,
  WheelPositionSummaryByOrganisationResponse,
  GetTeamLearnersRequest,
  ProfileIds,
} from './teamApiTypes';

export const useGetTeams = (
  organisationId?: string,
  searchTerm?: string,
  enabled: boolean = true,
): UseQueryResult<
  {
    teams: TeamResponse[];
    limitExceeded: boolean;
  },
  Error
> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.TEAMS_DATA, organisationId, searchTerm],
    queryFn: async () => getTeams({ organisationId, searchTerm }),
    enabled: Boolean(searchTerm || organisationId) && enabled,
  });

export const useGetTeam = (
  teamId: string,
  config: QueryOptionsConfig<TeamResponse> = {},
): UseQueryResult<TeamResponse, Error> =>
  useQuery<TeamResponse, Error>({
    queryKey: [QUERY_KEY_ROOTS.TEAM_DATA, teamId],
    queryFn: () => getTeam(teamId),
    ...config,
  });

export const useGetTeamLearners = (
  teamLearnerRequest: GetTeamLearnersRequest,
  config: QueryOptionsConfig<GetTeamLearnersResponse> = {},
): UseQueryResult<GetTeamLearnersResponse, Error> =>
  useQuery<GetTeamLearnersResponse, Error>({
    queryKey: [
      QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA,
      ...Object.values(teamLearnerRequest),
    ],
    queryFn: () => getTeamLearners(teamLearnerRequest),
    ...config,
  });

export const useAddTeamLearner = (
  queryClient: QueryClient,
): UseMutationResult<void, Error, TeamLearnerData, unknown> =>
  useMutation({
    mutationFn: addTeamLearner,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA] })
        .then();
    },
  });

export const useRemoveTeamLearner = (
  queryClient?: QueryClient,
  teamId?: string,
): UseMutationResult<void, Error, TeamLearnerData, unknown> =>
  useMutation({
    mutationFn: removeTeamLearner,
    onSuccess:
      queryClient && teamId
        ? () => {
            queryClient
              .invalidateQueries({
                queryKey: [QUERY_KEY_ROOTS.TEAM_DATA, teamId],
              })
              .then();
            queryClient
              .invalidateQueries({
                queryKey: [QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA],
              })
              .then();
          }
        : undefined,
  });

export const useGetTeamWheelSummary = (
  teamId: string,
  config: QueryOptionsConfig<SummaryResponse> = {},
): UseQueryResult<SummaryResponse, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.TEAM_WHEEL_SUMMARY, teamId] as QueryKey,
    queryFn: async () => {
      return getTeamScoreSummary(teamId);
    },
    ...config,
  });

export const useGetTeamWheelSummaryByOrganisation = (
  teamId: string,
  config: QueryOptionsConfig<WheelPositionSummaryByOrganisationResponse> = {},
): UseQueryResult<WheelPositionSummaryByOrganisationResponse, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.TEAM_WHEEL_SUMMARY, teamId] as QueryKey,
    queryFn: async () => {
      return getTeamScoreSummaryByOrganisation(teamId);
    },
    ...config,
  });

export const useGetTeamWheelScores = (
  teamId: string,
  config: QueryOptionsConfig<{ scores: Score[] }> = {},
): UseQueryResult<{ scores: Score[] }, Error> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.TEAM_SCORES_DATA, teamId] as QueryKey,
    queryFn: () => getTeamWheelScores(teamId),
    ...config,
  });

export const useDeleteTeam = (
  queryClient: QueryClient,
): UseMutationResult<void, Error, string, Error> =>
  useMutation({
    mutationFn: deleteTeam,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAMS_DATA] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.MY_TEAMS_DATA] })
        .then();
    },
  });

export const useAddAllLearnersToTeam = (
  queryClient: QueryClient,
): UseMutationResult<void, Error, string, Error> =>
  useMutation({
    mutationFn: addAllLearnersToTeam,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_DATA] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA] })
        .then();
    },
  });

export const useRemoveAllLearnersToTeam = (
  queryClient: QueryClient,
): UseMutationResult<void, Error, string, Error> =>
  useMutation({
    mutationFn: removeAllLearnersToTeam,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_DATA] })
        .then();
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_LEARNERS_DATA] })
        .then();
    },
  });

export const useUpdateSingleOrgTeam = (
  queryClient: QueryClient,
): UseMutationResult<TeamResponse, Error, SingleOrgTeamEditRequest, Error> =>
  useMutation({
    mutationFn: updateSingleOrgTeam,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_DATA] })
        .then();
    },
  });

export const useUpdateMultiOrgTeam = (
  queryClient: QueryClient,
): UseMutationResult<TeamResponse, Error, MultiOrgTeamEditRequest, Error> =>
  useMutation({
    mutationFn: updateMultiOrgTeam,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QUERY_KEY_ROOTS.TEAM_DATA] })
        .then();
    },
  });
export const useGetDiscoveryProfileIds = (
  teamId: string,
  learnerIds: string[],
  config = {},
): UseQueryResult<ProfileIds, unknown> =>
  useQuery({
    queryKey: [QUERY_KEY_ROOTS.PROFILES_DATA, learnerIds],
    queryFn: async () => getDiscoveryProfileIds(teamId, { learnerIds }),
    ...config,
  });

export const useShareProfiles = () =>
  useMutation({
    mutationFn: shareProfiles,
  });

export const useDownloadTeamProfiles = () =>
  useMutation({
    mutationFn: downloadTeamProfiles,
  });
