import React from 'react';
import { Group } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import OrganisationGroupLink from 'components/OrganisationGroupLink/OrganisationGroupLink';
import { styled, SxProps, Theme } from '@mui/material';

const TranslationContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
  maxWidth: '100%',
  textAlign: 'center',
  wordBreak: 'break-word',
}));

const ParentGroupLink = ({
  group,
  sx,
}: {
  group: Group;
  sx?: SxProps<Theme>;
}) => (
  <TranslationContainer sx={sx}>
    <OrganisationGroupLink group={group} kind="orgCard" />
  </TranslationContainer>
);

export default ParentGroupLink;
