import { Dialog, P, Span } from '@insights-ltd/design-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
};

const Content = () => {
  return (
    <P>
      <Trans
        i18nKey="ui.event-management.generic.error-modal.content"
        components={{ strong: <Span variant="body-bold" color="primary" /> }}
      />
    </P>
  );
};

const RequestErrorModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      title={t('ui.event-management.generic.error-modal.title')}
      secondaryButtonText={t(
        'ui.event-management.generic.error-modal.confirm-button',
      )}
      content={<Content />}
      onClose={onClose}
    />
  );
};

export default RequestErrorModal;
