import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import FormHeader from 'components/FormHeader';
import {
  newPasswordValidationRules,
  oldPasswordValidationRules,
  P,
  passwordMatchRules,
} from '@insights-ltd/design-library';
import {
  InputType,
  InputText,
} from '@insights-ltd/design-library/src/components/InputText';
import ValidationTags from 'components/ValidationTags';
import { useChangePassword } from 'api';
import { useQueryClient } from '@tanstack/react-query';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';

type FormData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const ChangePassword = () => {
  const [queryParameters] = useSearchParams();
  const dialect = queryParameters.get('dialect') ?? 'en-GB';
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending,
    error: changePasswordError,
  } = useChangePassword(queryClient);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = ({ oldPassword, newPassword }: FormData) =>
    mutate(
      {
        dialect,
        oldPassword,
        newPassword,
      },
      { onSuccess: () => navigate('/account') },
    );

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.change-password"
      activeTextKey="ui.event-management.my-account.change-password"
      crumbs={{
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }}
      headingKey="ui.event-management.my-account.change-password"
    >
      <FormHeader
        icon={LockIcon}
        title={t('ui.event-management.my-account.my-password')}
        color="green"
      />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <InputText
              id="oldPassword"
              type={InputType.PASSWORD}
              fullWidth
              label={t(
                'ui.event-management.my-account.old-password.input-label',
              )}
              error={
                Boolean(changePasswordError) || Boolean(errors.oldPassword)
              }
              {...register('oldPassword', oldPasswordValidationRules)}
              placeholder={t(
                'ui.event-management.my-account.old-password.input-placeholder',
              )}
              helperText={
                changePasswordError &&
                t('ui.event-management.my-account.old-password.error')
              }
            />
          </Grid>
          <Grid item md={12}>
            <P variant="body-bold" color="textSecondary">
              {t('ui.event-management.my-account.change-password.instruction')}
            </P>
          </Grid>
          <Grid item>
            <ValidationTags
              value={watch('newPassword', '')}
              confirmationValue={watch('confirmPassword', '')}
              errors={errors.newPassword}
              confirmErrors={errors.confirmPassword}
            />
          </Grid>
          <Grid item>
            <InputText
              id="newPassword"
              type={InputType.PASSWORD}
              fullWidth
              label={t(
                'ui.event-management.my-account.new-password.input-label',
              )}
              error={Boolean(errors.newPassword)}
              {...register(
                'newPassword',
                newPasswordValidationRules(getValues),
              )}
              placeholder={t(
                'ui.event-management.my-account.new-password.input-placeholder',
              )}
            />
          </Grid>
          <Grid item>
            <InputText
              id="confirmPassword"
              type={InputType.PASSWORD}
              fullWidth
              label={t(
                'ui.event-management.my-account.confirm-password.input-label',
              )}
              error={Boolean(errors.confirmPassword)}
              {...register('confirmPassword', passwordMatchRules(getValues))}
              placeholder={t(
                'ui.event-management.my-account.confirm-password.input-label',
              )}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isPending}
              endIcon={
                isPending && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )
              }
            >
              {t('ui.event-management.my-account.change-password')}
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </BreadcrumbLayout>
  );
};

export default ChangePassword;
