import React from 'react';
import { Props as ItemProps } from 'components/OrganisationInfoCard/AccountOrganisationCard';
import { Button, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Span, Tag } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { GroupType } from 'api';
import { Link as RouterLink } from 'react-router-dom';

type Props = { items: ItemProps[] };

const StyledSpan = styled(Span)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold} !important`,
}));

const StyledTag = styled(Tag)(({ theme }) => ({
  margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
  backgroundColor: theme.palette.blue.light,
  color: theme.palette.blue.dark,
}));

const StyledDiv = styled('div')({});

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  display: 'inline-block',
  marginLeft: '4px',
  marginRight: '4px',
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledItem = styled(Grid)(({ theme }) => ({
  minHeight: '54px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '13px 20px',
  border: `1px solid ${theme.palette.grey[500]}`,
  borderTop: 'none',
}));

const OrganisationItem = ({
  id,
  name,
  orgCount,
  parent,
  type,
  groupType = parent?.type,
  allowDemotion,
  onClick,
  sx,
}: ItemProps) => {
  const { t } = useTranslation();

  const labelColor = (theme: any): string => {
    return groupType !== 'PARTNER' ? `${theme.palette.orange.dark}` : `#6E750A`;
  };

  const labelBackgroundColor = (theme: any): string => {
    return groupType !== 'PARTNER'
      ? `${theme.palette.orange.light}`
      : `${theme.palette.green.light}`;
  };

  const groupTypeLabel: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.create-group.group-type.customer'),
    PARTNER: t('ui.event-management.create-group.group-type.partner'),
  };

  const demotionLink =
    type === 'Organisation' ? (
      <Button
        onClick={() => {
          if (onClick) onClick(id);
        }}
        sx={{ padding: '0 !important', minWidth: '0' }}
        color="error"
      >
        {t('ui.event-management.my-account.revoke')}
      </Button>
    ) : (
      <StyledSpan color="textSecondary">
        {t('ui.event-management.my-account.group.demote-to-revoke')}
      </StyledSpan>
    );
  if (type === 'Organisation') {
    return (
      <StyledItem
        sx={{
          ...sx,
        }}
        key={id}
      >
        <Grid
          item
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightBold,
          })}
          xs={5}
        >
          {name}
        </Grid>
        <Grid
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {parent ? (
            <StyledDiv sx={(theme) => ({ color: theme.palette.text.primary })}>
              {t('ui.event-management.my-account.group.group-name', {
                groupName: parent.name,
                groupType: groupTypeLabel[groupType ?? 'PARTNER'],
              })}
            </StyledDiv>
          ) : null}
          {allowDemotion ? (
            <StyledDiv sx={{ marginLeft: '12px' }}>{demotionLink}</StyledDiv>
          ) : null}
        </Grid>
      </StyledItem>
    );
  }
  return (
    <StyledItem
      key={id}
      sx={{
        ...sx,
      }}
    >
      <Grid
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary,
        })}
      >
        {name}
        <StyledTag
          color="white"
          label={groupTypeLabel[groupType ?? 'PARTNER']}
          sx={(theme) => ({
            color: labelColor(theme),
            backgroundColor: labelBackgroundColor(theme),
            margin: '0 8px !important',
          })}
        />
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {orgCount ? (
          <StyledLink to={`/groups/${id}`}>
            {orgCount > 1
              ? t('ui.event-management.my-account.group.count.plural', {
                  count: orgCount,
                })
              : t('ui.event-management.my-account.group.count.singular', {
                  count: orgCount,
                })}
          </StyledLink>
        ) : null}
        {allowDemotion ? (
          <StyledDiv sx={{ marginLeft: '12px' }}>{demotionLink}</StyledDiv>
        ) : null}
      </Grid>
    </StyledItem>
  );
};

const OrganisationInfoCardList = ({ items }: Props) => {
  return (
    <Grid
      item
      data-testid="org-card-list"
      xs={12}
      sx={{ padding: '0 0 2rem 0 !important' }}
    >
      {items.map((item, i) => {
        let sx = {};
        if (i === 0) {
          sx = {
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTop: '1px solid #B3B3B3 !important',
          };
        }

        if (i === items.length - 1) {
          sx = {
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
          };
        }
        return <OrganisationItem key={item.id} {...item} sx={sx} />;
      })}
    </Grid>
  );
};

export default OrganisationInfoCardList;
