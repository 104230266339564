import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import { dateInPast } from '@insights-ltd/design-library';
import { queryStatus } from 'utils/queryStatus';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { sortCancelledExperiences } from 'utils/mappers/sorting';
import {
  useGetOpenPractitionerInvites,
  useGetOrganisation,
  useGetOrgEvents,
  useGetPractitionersSummary,
} from 'api/organisations/organisationHooks';
import {
  EventSummaryResponse,
  OrganisationResponse,
  PractitionerSummaryData,
  UserInvite,
} from 'api';
import { QueryStatus } from '@tanstack/react-query';
import { OrganisationViewType } from './typeGuard';

const useGetOrganisationData = (
  organisationId: string,
  tabValue: OrganisationViewType,
): {
  allMyOrganisations?: OrganisationResponse[];
  organisation?: OrganisationResponse;
  invites?: UserInvite[];
  practitioners?: PractitionerSummaryData[];
  hasMultiOrgs?: boolean;
  status: QueryStatus;
  upcomingEvents?: EventSummaryResponse[];
  pastEvents?: EventSummaryResponse[];
  canViewAllOrgs?: boolean;
  cancelledEvents?: EventSummaryResponse[];
} => {
  const {
    allMyOrganisations,
    hasMultiOrgs,
    status: getMyOrganisationStatus,
  } = useGetMyOrganisations();

  const { status: isPermittedStatus, isPermitted: canViewAllOrgs } =
    useIsPermitted([
      {
        action: 'Organisation_ReadAll',
        scope: 'Global',
      },
    ]);
  const { data: organisation, status: getOrganisationStatus } =
    useGetOrganisation(organisationId);

  const { data: invites, status: getInvitesStatus } =
    useGetOpenPractitionerInvites(organisationId);
  const { data: practitioners, status: getPractitionersStatus } =
    useGetPractitionersSummary(organisationId);

  const eventStatus = tabValue === 'cancelled' ? 'CANCELLED' : 'ACTIVE';
  const { data: events, status: getEventsStatus } = useGetOrgEvents(
    organisationId,
    eventStatus,
  );

  const status = queryStatus(
    getEventsStatus,
    getOrganisationStatus,
    getInvitesStatus,
    getPractitionersStatus,
    getMyOrganisationStatus,
    isPermittedStatus,
  );

  const upcomingEvents = events?.filter(
    (ev) => !dateInPast(ev.endsAt, ev.timezone),
  );
  const pastEvents = events?.filter((ev) => dateInPast(ev.endsAt, ev.timezone));

  const cancelledEvents = sortCancelledExperiences(events);

  return status === 'success'
    ? {
        allMyOrganisations,
        organisation,
        invites,
        practitioners,
        hasMultiOrgs,
        status,
        upcomingEvents,
        pastEvents,
        canViewAllOrgs,
        cancelledEvents,
      }
    : {
        status,
      };
};
export default useGetOrganisationData;
