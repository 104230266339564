import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { Tag } from '@insights-ltd/design-library';
import {
  EventResponseWithChapters,
  InviteeResponse,
  useSendInvites,
} from 'api';
import { readyToPurchase } from 'domain/invitee';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';
import { INVITEE_STATUSES } from 'variables';
import EmailProgressDialog from 'components/EmailProgressDialog';
import { InviteeListOptions } from 'types/types';
import LearnersListRow from './LearnersListRow';

type LearnersStatusListProps = {
  event: EventResponseWithChapters;
  invitees: InviteeResponse[];
  listStatus: InviteeListOptions;
  statusFilter: InviteeListOptions | '';
  deleteInvitee: (selectedInviteeId: string) => Promise<void>;
  isPastExperience: boolean;
};

const handleStatusFilter =
  (listStatus: InviteeListOptions) =>
  (invitee: InviteeResponse): boolean => {
    const hasProfileGenerated =
      readyToPurchase(invitee) &&
      (invitee.profileStatus === 'REUSED' ||
        invitee.chapters?.some(
          (chapterStatus) => chapterStatus.status === 'PURCHASED',
        ));

    if (hasProfileGenerated) {
      return listStatus === 'PROFILE_GENERATED';
    }

    return invitee.status === listStatus;
  };

const StyledTag = styled(Tag)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} 0`,
}));

const LearnersStatusList = ({
  event,
  invitees,
  deleteInvitee,
  listStatus,
  statusFilter,
  isPastExperience,
}: LearnersStatusListProps) => {
  const { t } = useTranslation();
  const [openEmailProgressDialog, setOpenEmailProgressDialog] =
    useState<boolean>(false);

  const { mutate: mutateSendInvites } = useSendInvites();

  const showLearners = statusFilter === '' || statusFilter === listStatus;
  if (!showLearners) return null;

  const statusElement = INVITEE_STATUSES[listStatus];
  const learners = invitees.filter(handleStatusFilter(listStatus));
  if (!learners.length) return null;

  const handleResendInvite = (inviteeId: string) => {
    setOpenEmailProgressDialog(true);
    mutateSendInvites({ eventId: event.id, inviteeIds: [inviteeId] });
  };

  const handleOnClose = () => setOpenEmailProgressDialog(false);

  return (
    <>
      <StyledTag label={t(statusElement.textKey)} color={statusElement.color} />
      <Paper variant="outlined">
        {learners.map((invitee, index) => (
          <LearnersListRow
            deleteInvitee={deleteInvitee}
            resendInvite={
              listStatus === 'INVITE_SENT' ? handleResendInvite : undefined
            }
            invitee={invitee}
            key={invitee.id}
            isLastRow={index === learners.length - 1}
            isPastExperience={isPastExperience}
            event={event}
            readyToPurchase={listStatus === 'READY_TO_PURCHASE'}
            readyToSend={listStatus === 'PROFILE_GENERATED'}
          />
        ))}
      </Paper>
      <EmailProgressDialog
        open={openEmailProgressDialog}
        onClose={handleOnClose}
        learnerInvites
        singleProfile
      />
    </>
  );
};

export default LearnersStatusList;
