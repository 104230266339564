import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled, Theme } from '@mui/material/styles';
import { MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material';
import {
  CheckboxV2,
  FormLabel,
  InputText,
  InputType,
  P,
} from '@insights-ltd/design-library';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'components/Svgs/icons/Search';
import SortSelect from '@insights-ltd/design-library/src/components/SortSelect/SortSelect';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import SearchInput from './SearchInput';

const SearchLabelContainer = styled('div')({
  marginRight: '1rem',
  '& > div': {
    minWidth: '0',
  },
  '& > div > p': {
    padding: '1px 0 5px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '300',
  },
});

const Tag = styled(P)(({ theme }) => ({
  fontWeight: `${theme.typography.fontWeightBold} !important`,
}));

const StyledForm = styled('form')({
  position: 'relative',
  display: 'inline-flex',
  flexShrink: '0',
});

const FieldLabel = styled('div')({
  display: 'inline-flex',
  flexShrink: '0',
  paddingRight: '1rem',
  paddingTop: '0.5rem',
});

const MinCharacterWarning = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.blue.dark,
  color: theme.palette.common.white,
  width: '230px !important',
  position: 'absolute',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '28px',
  padding: '10px 10px 6px 13px',
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  marginTop: '-2px',
  [theme.breakpoints.down('sm')]: {
    width: `100%`,
  },
}));

type SearchFilterProps<T> = {
  sx?: SxProps<Theme>;
  label: string;
  placeholder: string;
  initialSearchFilter?: T;
  filterOptions?: { label: string; value: T }[];
  handleFilterChange?: (value: T) => void;
  handleSearch?: (value: string) => void;
  handleTermChange?: (s: string) => void;
  fieldNameKey: string;
  showButton?: boolean;
  allowEmptySearches?: boolean;
  inputState?: string;
  initialValue?: string;
  inputType?: InputType;
};

const SearchFilter = <T extends string>({
  sx,
  label,
  placeholder,
  handleSearch,
  handleTermChange,
  handleFilterChange,
  fieldNameKey,
  showButton = true,
  allowEmptySearches = true,
  inputState,
  initialValue = '',
  initialSearchFilter,
  inputType = InputType.TEXT,
  filterOptions,
}: SearchFilterProps<T>) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);

  const handleSelectOnChange = (value: string) => {
    setSearchFilter(value as T);
    if (handleFilterChange) {
      handleFilterChange(value as T);
    }
  };
  const clearSearchNow = () => {
    setSearchTerm('');
    if (filterOptions) {
      handleSelectOnChange(filterOptions[0].value);
    }
    if (handleSearch) {
      handleSearch('');
    }

    if (handleTermChange) {
      handleTermChange('');
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    if (handleTermChange) {
      handleTermChange(e.currentTarget.value);
    }
  };

  const handleSearchKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      clearSearchNow();
    }
    if (e.key === 'Enter' && handleSearch) {
      handleSearch(searchTerm);
    }
  };

  const showClearButton = inputState ? inputState !== '' : searchTerm !== '';

  const formLabel = (
    <FieldLabel>
      <P>{label}</P>
      <P variant="body-bold">
        &nbsp;
        {t(fieldNameKey)}
      </P>
    </FieldLabel>
  );

  return (
    <Grid container alignItems="baseline" justifyContent="space-between">
      <Paper
        sx={[
          (theme) => ({
            flexGrow: 1,
            margin: `${theme.spacing(spacingSizeMap.M)} 0`,
            padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
              spacingSizeMap.XS,
            )}`,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={8} wrap="nowrap" container justifyContent="flex-start">
            <StyledForm
              autoComplete="off"
              sx={{ marginLeft: '1rem' }}
              onSubmit={(event) => event.preventDefault()}
            >
              <FormLabel label={formLabel} htmlFor="search-filter-input" />
              <InputText
                icon={<SearchIcon />}
                id="search-filter-input"
                label=""
                data-testid="search-input"
                placeholder={placeholder}
                value={inputState ?? searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeypress}
                type={inputType}
              />
              {filterOptions ? (
                <Select
                  data-testid="search-filter"
                  sx={(theme) => ({
                    '> div': {
                      padding: '10px 16px',
                    },
                    boxShadow: `inset 0 0 0 1px ${theme.palette.grey[600]}`,
                    maxWidth: '270px',
                    marginLeft: '1rem',
                  })}
                  value={searchFilter}
                  onChange={(event: SelectChangeEvent<T>) => {
                    handleSelectOnChange(event.target.value);
                  }}
                >
                  {filterOptions.map(({ label: optionLabel, value }) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {optionLabel}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </StyledForm>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              '& > * + *': {
                marginLeft: '0.5rem',
              },
            }}
          >
            {showClearButton && (
              <Button
                onClick={clearSearchNow}
                variant="outlined"
                color="primary"
                data-testid="filter-clear-button"
                sx={(theme) => ({ marginRight: theme.spacing(1) })}
              >
                {t('ui.event-management.list-filter.clear-search')}
              </Button>
            )}
            {showButton ? (
              <Button
                onClick={() => {
                  if (handleSearch) {
                    handleSearch(searchTerm);
                  }
                }}
                sx={{
                  padding: '0.5rem 1rem',
                  '&.Mui-disabled': {
                    color: 'white',
                  },
                }}
                variant="contained"
                color="primary"
                data-testid="filter-search-button"
                disabled={!allowEmptySearches && searchTerm === ''}
              >
                {t('ui.event-management.list-filter.search')}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

type SearchFilterV2Props<T> = {
  sx?: SxProps<Theme>;
  placeholder: string;
  initialSearchFilter?: T;
  label?: string;
  fieldName?: string;
  filterOptions?: { label: string; value: T }[];
  categoryOptions?: { label: string; value: T }[];
  handleFilterChange?: (value: T) => void;
  handleSearch?: (value: string) => void;
  handleTermChange?: (s: string) => void;
  showButton?: boolean;
  allowEmptySearches?: boolean;
  inputState?: string;
  initialValue?: string;
  inputType?: InputType;
  minCharacters?: number;
  checkbox?: {
    checked: boolean;
    label: string;
    onChange: (value: boolean) => void;
  };
};

export const RawSearchFilterV2 = <T extends string>(
  {
    sx,
    placeholder,
    handleSearch,
    handleTermChange,
    handleFilterChange,
    showButton = true,
    allowEmptySearches = true,
    inputState,
    initialValue = '',
    initialSearchFilter,
    inputType = InputType.TEXT,
    filterOptions,
    categoryOptions,
    minCharacters,
    label,
    fieldName,
    checkbox,
  }: SearchFilterV2Props<T>,
  ref?: Ref<any>,
) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);

  const searchInputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      searchInputRef.current?.focus();
    },
  }));

  const handleSelectOnChange = (value: string) => {
    setSearchFilter(value as T);
    if (handleFilterChange) {
      handleFilterChange(value as T);
    }
  };
  const clearSearchNow = () => {
    setSearchTerm('');
    if (handleSearch) {
      handleSearch('');
    }

    if (handleTermChange) {
      handleTermChange('');
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    if (handleTermChange) {
      handleTermChange(e.currentTarget.value);
    }
  };

  const handleSearchKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      clearSearchNow();
    }
    if (e.key === 'Enter' && handleSearch) {
      handleSearch(searchTerm);
    }
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      searchInputRef.current?.focus();
    },
  }));

  const formLabel =
    label && fieldName ? (
      <SearchLabelContainer>
        <FieldLabel>
          <P>{label}</P>
          <Tag variant="body-bold">
            &nbsp;
            {fieldName}
          </Tag>
        </FieldLabel>
      </SearchLabelContainer>
    ) : (
      <SearchLabelContainer>
        <SortSelect
          value={searchFilter as string}
          onChange={(event) => handleSelectOnChange(event.target.value)}
          labelText={t('ui.event-management.list-filter.search-for')}
          sortOptions={filterOptions ?? []}
        />
      </SearchLabelContainer>
    );

  return (
    <Grid
      container
      alignItems="baseline"
      justifyContent="space-between"
      sx={{
        margin: '22px 0 32px',
        '& > div': { padding: `12px 24px !important` },
      }}
    >
      <Paper
        sx={[
          () => ({
            flexGrow: 1,
            margin: '0 !important',
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={8} wrap="nowrap" container justifyContent="flex-start">
            <StyledForm
              autoComplete="off"
              onSubmit={(event) => event.preventDefault()}
            >
              <FormLabel
                sx={{ alignSelf: 'center' }}
                label={formLabel}
                htmlFor="search-filter-input"
              />
              <div>
                <SearchInput
                  ref={searchInputRef}
                  id="search-filter-input"
                  name="search"
                  testId="search-input"
                  placeholder={placeholder}
                  value={inputState ?? searchTerm}
                  onChange={(value) =>
                    handleSearchChange({
                      currentTarget: {
                        value,
                      },
                    } as any)
                  }
                  onKeyDown={handleSearchKeypress}
                  type={inputType}
                  onClear={() => {
                    clearSearchNow();
                  }}
                  clearText={t('ui.event-management.list-filter.clear')}
                  showClearNowButton={
                    !!(
                      Number(minCharacters) > 0 &&
                      inputState &&
                      inputState?.length >= Number(minCharacters)
                    )
                  }
                />
                {minCharacters &&
                inputState &&
                inputState?.length > 0 &&
                minCharacters > inputState?.trim().length ? (
                  <MinCharacterWarning>
                    {t('ui.event-management.list-filter.min-characters', {
                      count: minCharacters,
                    })}
                  </MinCharacterWarning>
                ) : null}
              </div>
              {categoryOptions ? (
                <Select
                  data-testid="search-filter"
                  sx={(theme) => ({
                    '> div': {
                      padding: '10px 16px',
                    },
                    boxShadow: `inset 0 0 0 1px ${theme.palette.grey[600]}`,
                    maxWidth: '270px',
                    marginLeft: '1rem',
                  })}
                  value={searchFilter}
                  onChange={(event: SelectChangeEvent<T>) => {
                    handleSelectOnChange(event.target.value);
                  }}
                >
                  {categoryOptions!.map(({ label: optionLabel, value }) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {optionLabel}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </StyledForm>
          </Grid>
          {checkbox?.label ? (
            <Grid
              item
              xs={8}
              md="auto"
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                '> label > span:nth-of-type(1)': {
                  marginRight: theme.spacing(spacingSizeMap.XS),
                },
              })}
            >
              <CheckboxV2
                onChange={() => checkbox.onChange(!checkbox.checked)}
                checked={checkbox.checked}
                label={checkbox.label}
                data-testid="search-filter-checkbox"
              />
            </Grid>
          ) : null}
          {showButton ? (
            <Grid
              item
              xs={12}
              md="auto"
              sx={{
                '& > * + *': {
                  marginLeft: '0.5rem',
                },
              }}
            >
              <Button
                onClick={() => {
                  if (handleSearch) {
                    handleSearch(searchTerm);
                  }
                }}
                variant="contained"
                color="primary"
                data-testid="filter-search-button"
                disabled={!allowEmptySearches && searchTerm === ''}
              >
                {t('ui.event-management.list-filter.search')}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </Grid>
  );
};

export const SearchFilterV2 = forwardRef<any, SearchFilterV2Props<any>>(
  RawSearchFilterV2,
);

export default SearchFilter;
