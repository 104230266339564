import { spacingSizeMap } from '@insights-ltd/design-library';
import getTheme from '@insights-ltd/design-library/src/themes';
import { Alert, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const localTheme = getTheme();

const StyledDivAlert = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
}));

const alertProps = {
  border: '0',
  '&.MuiAlert-filledInfo': {
    border: '0',
    backgroundColor: localTheme.brandColours.lightBlue,
    color: localTheme.grey[700],
  },
  '&.MuiAlert-filledError': {
    border: '0',
    backgroundColor: localTheme.validationColours.lightError,
    color: localTheme.validationColours.error,
  },
};

type Props = {
  selectedGroupName: string;
};

const OrganisationRemovalWarning = ({ selectedGroupName }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledDivAlert>
      <Alert sx={alertProps} variant="filled" severity="info">
        {t('ui.event-management.organisations.remove.from-group-message', {
          selectedGroup: selectedGroupName,
        })}
      </Alert>
    </StyledDivAlert>
  );
};

export default OrganisationRemovalWarning;
