import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@insights-ltd/design-library';
import { ChapterCost } from 'api';
import { chapterNameMap } from './ChapterNameMap';

const ChapterCheckboxes = ({
  chapters,
  checkedChapters,
  handleCheckedChapter,
  prePurchasedChapters = [],
}: {
  chapters: ChapterCost[];
  checkedChapters: { [name: string]: boolean | undefined };
  handleCheckedChapter: (name: string, selected: boolean) => void;
  prePurchasedChapters?: string[];
}) => {
  const { t } = useTranslation();

  const isDisabled = (chapterName: string) => {
    return (
      chapterName === 'FOUNDATION' || prePurchasedChapters.includes(chapterName)
    );
  };

  return (
    <>
      {chapters.map((chapter) => {
        return (
          <Checkbox
            sx={(theme) => ({
              color: theme.palette.blue.main,
              '&.Mui-disabled': {
                color: theme.palette.grey[600],
              },
            })}
            disabled={isDisabled(chapter.name)}
            key={chapter.name}
            label={t(chapterNameMap[chapter.name])}
            checked={!!checkedChapters[chapter.name]}
            onClick={() => {
              handleCheckedChapter(
                chapter.name,
                !!checkedChapters[chapter.name],
              );
            }}
          />
        );
      })}
    </>
  );
};

export default ChapterCheckboxes;
