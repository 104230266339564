import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';
import { P, Span } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PermissionGuard from 'components/PermissionGuard';
import AddIcon from 'components/Svgs/icons/Add';
import TransferIcon from 'components/Svgs/icons/DataTransferHorizontal';
import { useAuth } from 'contexts/AuthContext';
import { isSuperAdmin } from 'utils/role';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

export type WalletProps = {
  availableUnits: number;
  organisationId?: string;
  practitionerId?: string;
  isMyWallet?: boolean;
  openRequestUnitsDialog?: () => void;
};

const StyledWallet = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.M,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '80px',
  },
}));

const StyledWalletTitle = styled(P)(({ theme }) => ({
  paddingBottom: theme.spacing(spacingSizeMap.XS),
}));

const StyledAvailableUnits = styled(Span)(({ theme }) => ({
  marginLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
  paddingBottom: theme.spacing(spacingSizeMap.M),
}));

interface StyledUnitsLinkProps {
  transfer?: boolean;
}

const StyledUnitsLink = styled(P, {
  shouldForwardProp: (prop) => prop !== 'transfer',
})<StyledUnitsLinkProps>(({ transfer = false, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: transfer
    ? theme.spacing(spacingSizeMap.S)
    : theme.spacing(spacingSizeMap.XS),
  fontFamily: `${theme.typography.fontFamily} !important`,
  fontWeight: `${theme.typography.fontWeightBold} !important`,
  fontSize: '0.875rem !important',
}));

const StyledUnitsLinkIcon = styled('span')(({ theme }) => ({
  marginLeft: `${theme.spacing(spacingSizeMap.XS)} !important`,
}));

const Wallet = ({
  availableUnits,
  organisationId,
  practitionerId,
  isMyWallet = false,
  openRequestUnitsDialog = () => {},
}: WalletProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleOpenRequestUnitsDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    openRequestUnitsDialog();
  };

  const titleKey = isMyWallet
    ? 'ui.event-management.wallet.title.my-wallet'
    : 'ui.event-management.wallet.title';

  const isAdmin = isSuperAdmin(user?.roles);
  return (
    <StyledWallet>
      <StyledWalletTitle variant="body-bold">{t(titleKey)}</StyledWalletTitle>
      <Span data-testid="available-units" variant="h3">
        {availableUnits}
      </Span>
      <StyledAvailableUnits variant="body-small" color="textSecondary">
        {t('ui.event-management.wallet.balance', {
          count: availableUnits,
        })}
      </StyledAvailableUnits>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Unit_Transfer',
            scope: 'Local',
          },
        ]}
      >
        <Link
          component={RouterLink}
          to="/account/transfer-units"
          state={
            isMyWallet ? { organisationId } : { practitionerId, organisationId }
          }
        >
          <StyledUnitsLink transfer color="primary">
            <TransferIcon />
            <StyledUnitsLinkIcon>
              {t('ui.event-management.wallet.transfer-units')}
            </StyledUnitsLinkIcon>
          </StyledUnitsLink>
        </Link>
      </PermissionGuard>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Unit_Update',
            scope: 'Global',
          },
        ]}
      >
        <Link
          component={RouterLink}
          to="/account/manage-units"
          state={{ practitionerId, organisationId }}
        >
          <StyledUnitsLink color="primary">
            <AddIcon />
            <StyledUnitsLinkIcon>
              {t('ui.event-management.wallet.manage-units')}
            </StyledUnitsLinkIcon>
          </StyledUnitsLink>
        </Link>
      </PermissionGuard>
      {isMyWallet && !isAdmin && (
        <PermissionGuard
          requiredPermissions={[
            {
              action: 'Unit_Request',
              scope: 'Local',
            },
          ]}
        >
          <ButtonBase
            disableTouchRipple
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightBold,
              '&:hover, &:focus': {
                textDecoration: 'underline',
              },
            })}
            onClick={handleOpenRequestUnitsDialog}
          >
            <StyledUnitsLink color="primary">
              <AddIcon />
              <StyledUnitsLinkIcon>
                {t('ui.event-management.my-account.request-units')}
              </StyledUnitsLinkIcon>
            </StyledUnitsLink>
          </ButtonBase>
        </PermissionGuard>
      )}
    </StyledWallet>
  );
};

export default Wallet;
