import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { H2 } from '@insights-ltd/design-library';
import { EventSummaryResponse } from 'api';
import EventsList from 'components/EventsList';
import ListFilter from 'components/ListFilter';
import usePagination, { PaginationType } from 'components/hooks/usePagination';
import useSearchFilter from 'components/hooks/useSearchFilter';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import { styled } from '@mui/material';
import { DeleteType } from 'pages/Event/types';
import SearchCount from 'components/SearchCount/SearchCount';

type OrganisationEventsProps = {
  events: EventSummaryResponse[];
  title: string;
  showDeletedDialog?: DeleteType;
  deletedExperienceName?: string;
  experienceView?: string;
  ascending?: boolean;
};

type PaginationGridProps = {
  pageCount: number;
  getPaginationAriaLabel: (
    type: PaginationType,
    page: number,
    selected: boolean,
  ) => string;
  pageNumber: number;
  handlePageChange: (page: number) => void;
};

const ListItemWrapperDiv = styled('div')(({ theme: t }) => ({
  '& > :first-of-type': {
    marginBottom: `${t.spacing(spacingSizeMap.M)}`,
  },
  '& > * + *': {
    marginBottom: `${t.spacing(spacingSizeMap.XS)} !important`,
  },
}));

const EXPERIENCES_SEARCH_FIELD_NAME = 'name';

const sortByDate = (items: EventSummaryResponse[], ascending = false) =>
  items.sort((a, b) => {
    const diff =
      new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime();
    return ascending ? -diff : diff;
  });

const PaginationGrid = ({
  pageCount,
  getPaginationAriaLabel,
  pageNumber,
  handlePageChange,
}: PaginationGridProps) => {
  if (pageCount) {
    return (
      <Grid item xs={12} sm="auto">
        <Pagination
          count={pageCount}
          getItemAriaLabel={getPaginationAriaLabel}
          hidePrevButton
          onChange={(_, page) => handlePageChange(page)}
          page={pageNumber}
        />
      </Grid>
    );
  }
  return null;
};

const OrganisationEvents = ({
  events,
  title,
  experienceView,
  ascending,
  showDeletedDialog,
  deletedExperienceName = '',
}: OrganisationEventsProps) => {
  const { t } = useTranslation();
  const { filteredItems, handleTermChange, searchTerm } = useSearchFilter(
    events,
    [EXPERIENCES_SEARCH_FIELD_NAME],
  );
  const [experienceName, setExperienceName] = useState('');

  const isCancelledExperience = showDeletedDialog === 'cancel';
  if (
    isCancelledExperience &&
    experienceView === 'cancelled' &&
    experienceName !== deletedExperienceName
  ) {
    setExperienceName(deletedExperienceName);
  } else if (experienceView !== 'cancelled' && experienceName !== '') {
    setExperienceName('');
  }

  const sortedEvents =
    experienceView === 'cancelled'
      ? filteredItems
      : sortByDate(filteredItems!, ascending);

  const {
    pageNumber,
    pageCount,
    pagedItems: pagedEvents,
    handlePageChange,
    getPaginationAriaLabel,
  } = usePagination(sortedEvents as EventSummaryResponse[]);

  const onTermChange = (newTerm: string) => {
    handlePageChange(1);
    handleTermChange(newTerm);
  };

  return (
    <ListItemWrapperDiv>
      <ListFilter
        label={t('ui.event-management.organisation.practitioners.list.search')}
        fieldLabel={t(
          'ui.event-management.organisation.practitioners.list.search.by-name',
        )}
        placeholder={t(
          'ui.event-management.organisation.practitioners.list.search.placeholder',
        )}
        searchTerm={searchTerm}
        handleTermChange={onTermChange}
      />
      {experienceName && (
        <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              sx={{
                paddingTop: '0 !important',
                paddingBottom: '1rem',
                maxWidth: '688px !important',
              }}
              xs={12}
              md={8}
            >
              <Alert>
                {t('ui.event-management.events.cancel-success', {
                  experienceName,
                })}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container alignItems="baseline" direction="column" spacing={3}>
        <Grid item xs={12} sm="auto">
          <H2 variant="h3">
            {title}
            <SearchCount
              sx={{ marginLeft: '0.5rem' }}
              count={filteredItems!.length}
            />
          </H2>
        </Grid>
        <PaginationGrid
          pageCount={pageCount}
          getPaginationAriaLabel={getPaginationAriaLabel}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      </Grid>
      <Box>
        <EventsList events={pagedEvents} experienceView={experienceView} />
      </Box>
      <Grid container alignItems="baseline">
        <PaginationGrid
          pageCount={pageCount}
          getPaginationAriaLabel={getPaginationAriaLabel}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
        />
      </Grid>
    </ListItemWrapperDiv>
  );
};
export default OrganisationEvents;
