import React from 'react';
import SelectOrganisation from 'components/SelectOrganisation/SelectOrganisation';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import getMuiTheme from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';
import { P, RadioGroupControl } from '@insights-ltd/design-library';
import { OrgsInGroupSearch } from 'components/OrgsInGroupSearch';
import FormHeader from 'components/FormHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form';
import { OrganisationResponse } from 'api';
import { styled } from '@mui/material';
import useIsPermitted from 'components/hooks/useIsPermitted';
import { TeamFormData } from './types';
import { TeamVariant } from './CreateTeam.helper';

export const StyledIcon = (icon: any, color: string) =>
  styled(icon)({
    fill: color,
    height: '20px',
  });

type OrgOptionProps = {
  setTeamType: (value: TeamVariant) => void;
  teamType: TeamVariant;
  reset: () => void;
};

export const OrgsOptionsSelector = ({
  setTeamType,
  teamType,
  reset,
}: OrgOptionProps) => {
  const { t } = useTranslation();
  const { isPermitted } = useIsPermitted([
    { action: 'Multi_Organisation_Team_Create', scope: 'Global' },
  ]);
  const teamTypeOptions = [
    {
      label: t('ui.event-management.learners.teams.create.single-org-option'),
      value: 'single-org',
    },
    {
      label: t('ui.event-management.learners.teams.create.multi-org-option'),
      value: 'multi-org',
    },
  ];
  if (isPermitted) {
    return (
      <>
        <P variant="body-bold" style={{ marginBottom: '1rem' }}>
          {t('ui.event-management.learners.teams.create.team-type-label')}
        </P>
        <RadioGroupControl
          name="multi-or-single-org-selector"
          title="selector"
          variant="boxed"
          options={teamTypeOptions}
          onChange={(option) => {
            setTeamType(option as TeamVariant);
            reset();
          }}
          value={teamType}
        />
      </>
    );
  }
  return null;
};

export type OrgSelectorProp = {
  isMultiOrg: boolean;
  setSelectedOrgs: (org: any) => void;
  selectedOrgs: OrganisationResponse[];
  removeOrgs: (org: any) => void;
  organisations: OrganisationResponse[];
  showOrganisationDropdown: boolean;
  handleOrganisationChange: (value: any) => void;
  errors: FieldErrors<TeamFormData>;
  organisationsInGroups?: GroupOrganisationHashMap;
  organisationId: string;
};
export const OrgSelector = ({
  isMultiOrg,
  setSelectedOrgs,
  selectedOrgs,
  removeOrgs,
  organisations,
  showOrganisationDropdown,
  handleOrganisationChange,
  errors,
  organisationsInGroups,
  organisationId,
}: OrgSelectorProp) => {
  const { t } = useTranslation();
  const localTheme = getMuiTheme();
  const title = isMultiOrg
    ? t('ui.event-management.organisations.title')
    : t('ui.event-management.users.create.organisation');
  return (
    <div>
      {!!organisations?.length && showOrganisationDropdown && (
        <div>
          <FormHeader
            icon={StyledIcon(OrganisationIcon, localTheme.palette.orange.dark)}
            color="orange"
            title={title}
          />
          {isMultiOrg ? (
            <>
              <P variant="body-bold">{title}</P>
              <OrgsInGroupSearch
                onSelect={setSelectedOrgs}
                dataTestId="multi-org-selector"
                loading={false}
                options={organisations}
                labelKey="ui.event-management.organisations.select-organisations"
                selectedMultiOrgs={selectedOrgs}
                selectedGroup={null}
                t={t}
                onRemoveOrg={removeOrgs}
                showOrgList
              />
            </>
          ) : (
            <SelectOrganisation
              options={organisations}
              onSelect={(organisation) => {
                handleOrganisationChange(organisation.id);
              }}
              onChange={() => {
                handleOrganisationChange('');
              }}
              associatedGroup={organisationsInGroups?.[organisationId]}
              selectedId={organisationId}
              isError={Boolean(errors?.organisationId)}
              errorText={t(
                'ui.event-management.events.create.error.value-required',
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};
