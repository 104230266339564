import React from 'react';
import { TeamLearnerResponse } from 'api';
import { LICENSED_PRODUCTS_ABREV } from 'variables';
import { locale } from 'pages/Event/test-data/data';
import { P } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { isoToLocaleString } from './dates';

const LatestProfileContainer = ({
  latestProfile,
  onClick,
  migrated = false,
  children,
}: {
  latestProfile: TeamLearnerResponse['latestProfile'];
  migrated?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  if (
    (latestProfile?.profileType === 'DISCOVERY_PERSONAL_PROFILE' ||
      latestProfile?.profileType === 'IDTL_PROFILE' ||
      latestProfile?.profileType === 'DISCOVERY_PROFILE') &&
    onClick &&
    !migrated
  ) {
    return (
      <Button
        color="primary"
        variant="text"
        data-testid="profile-info-button"
        onClick={onClick}
        sx={(theme) => ({
          padding: '0 !important',
          height: theme.spacing(0.5),
          textAlign: 'left !important',
        })}
      >
        {children}
      </Button>
    );
  }

  return (
    <P color="textSecondary" variant="body-bold" data-testid="profile-info">
      {children}
    </P>
  );
};

export const LatestProfileText = ({
  latestProfile,
  latestEvaluation,
  onClick,
  migrated = false,
}: {
  latestProfile: TeamLearnerResponse['latestProfile'];
  latestEvaluation: TeamLearnerResponse['latestEvaluation'];
  onClick?: () => void;
  migrated?: boolean;
}) => {
  const { t } = useTranslation();

  if (latestProfile) {
    if (latestProfile.evaluation?.id === latestEvaluation?.id) {
      return (
        <LatestProfileContainer
          latestProfile={latestProfile}
          onClick={onClick}
          migrated={migrated}
        >
          {t('ui.event-management.learners.list.profile-date', {
            date: isoToLocaleString(latestProfile.createdAt, locale),
            product: latestProfile.profileType
              ? t(LICENSED_PRODUCTS_ABREV[latestProfile.profileType])
              : '',
          })}
        </LatestProfileContainer>
      );
    }
    return (
      <P variant="body-bold" data-testid="profile-info">
        {t('ui.event-management.learners.list.profile-new-evaluation', {
          date: isoToLocaleString(latestEvaluation?.submittedOn ?? '', locale),
        })}
      </P>
    );
  }
  return (
    <P color="textSecondary" variant="body-bold">
      {t('ui.event-management.learners.list.profile-ready-to-purchase')}
    </P>
  );
};
