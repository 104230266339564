import React from 'react';
import Grid from '@mui/material/Grid';
import { OrganisationResponse, GroupResponse } from 'api';
import isGroupResponse from 'utils/typeGuards/isGroupResponse';
import GroupCard from 'components/GroupCard/GroupCard';
import OrganisationCard from 'components/OrganisationCard/OrganisationCard';
import { Group } from 'api/httpEntities';
import MainOrganisationCard from 'components/OrganisationCard/MainOrganisationCard';

type OrganisationListProps = {
  organisations: (OrganisationResponse | GroupResponse)[];
  showBanners: boolean;
  groupOrganisations?: Record<string, Group>;
  group?: Group;
};

const OrganisationList = ({
  organisations,
  showBanners,
  groupOrganisations,
  group,
}: OrganisationListProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={() => ({
        '> :nth-of-type(-n + 3)': {
          paddingTop: 0,
        },
      })}
    >
      {organisations.map((item) => {
        if (isGroupResponse(item)) {
          return <GroupCard key={item.id} group={item} />;
        }
        let parent: Group | undefined;
        const { id, name, supportedRoles } = item;

        if (groupOrganisations && groupOrganisations[id]) {
          parent = groupOrganisations[id];
        }

        if (group) {
          parent = group;
        }

        if (supportedRoles?.includes('SUPER_ADMIN')) {
          return <MainOrganisationCard key={id} id={id} name={name} />;
        }
        return (
          <OrganisationCard
            key={id}
            id={id}
            name={name}
            showBanner={showBanners}
            parent={parent}
            supportedRoles={supportedRoles}
          />
        );
      })}
    </Grid>
  );
};
export default OrganisationList;
