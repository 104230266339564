import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import TeamWheelIcon from 'components/Svgs/Wheel';

type Props = {
  noOfProfilesPurchased: number;
  disabled?: boolean;
};

const DownloadTeamWheelCard = ({
  noOfProfilesPurchased,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const inactive = noOfProfilesPurchased === 0 || disabled;

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        border: inactive ? '0' : `1px solid ${theme.palette.grey[500]}`,
        borderBottom: inactive
          ? 'undefined'
          : `0.2em solid ${theme.palette.primary.main}`,
        opacity: inactive ? '0.5' : 'undefined',
      })}
    >
      <CardActionArea
        disabled={inactive}
        onClick={() => navigate('download-team-wheel')}
        sx={{
          fontFamily: 'inherit',
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <TeamWheelIcon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <P variant="h3" color={inactive ? 'textPrimary' : 'primary'}>
              {t('ui.event-management.events.download-team-wheel')}
            </P>
          </Box>
          <P variant="body-bold" color="textSecondary">
            {noOfProfilesPurchased === 0
              ? t(
                  'ui.event-management.events.download-team-wheel.no-profiles-purchased',
                )
              : t(
                  'ui.event-management.events.overview.summary.learners-shown',
                  {
                    count: noOfProfilesPurchased,
                  },
                )}{' '}
          </P>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DownloadTeamWheelCard;
