import React from 'react';
import { Button, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library';

export type Props = {
  open: boolean;
  onClose: () => void;
};

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledDialogContent = styled(DialogContent)({
  minHeight: '13rem',
});

const ContactEmailErrorDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="contact-email-error-dialog-title"
      aria-describedby="contact-email-error-dialog-description"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>
        <P variant="h4">
          {t('ui.event-management.contact.error-dialog-title')}
        </P>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <DialogContentText
          id="contact-email-error-dialog-description"
          color="textSecondary"
          variant="body1"
        >
          {t('ui.event-management.contact.error-dialog-description')}
        </DialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary" variant="outlined">
          {t('ui.event-management.contact.error-dialog-ok-button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactEmailErrorDialog;
