import {
  GetTeamLearnersResponse,
  OrganisationResponse,
  TeamLearnerResponse,
  TeamResponse,
  WheelPositionByOrganisation,
  WheelPositionSummaryByOrganisationResponse,
  WheelPositionSummaryResponse,
} from 'api';

export const generateTeamMockLearners = (
  numberOfLearnersInTeam = 1,
): TeamLearnerResponse[] => {
  const learners: TeamLearnerResponse[] = [];

  for (let i = 0; i < numberOfLearnersInTeam; i += 1) {
    learners.push({
      id: `test-learner-${i}`,
      primaryEmail: `${i}@testenv.email`,
      fullName: `test-learner-${i}`,
      knownAs: `test-learner-${i}`,
      latestProfile: {
        id: `learner-${i}`,
        createdAt: '2023-06-07T15:32:44.656623Z',
        leadingColourEnergy: 'SunshineYellow',
        organisation: {
          id: 'test',
          name: 'Test organisation',
        },
      },
      latestEvaluation: {
        id: `test-learner-${i}`,
        submittedOn: '2023-06-07T15:32:44.656623Z',
        organisation: {
          id: 'test',
          name: 'Test organisation',
        },
      },
      preferredDialect: 'en-GB',
    });
  }
  return learners;
};

type OrgScore = {
  id: string;
  score: string;
  conscious: number;
  lessConscious: number;
};

export const generateTeamWheelSummaryScore = (orgs: OrgScore[]) =>
  orgs.reduce(
    (prev, { id: organisationId, score, conscious, lessConscious }) => {
      const item = { organisationId, conscious, lessConscious };
      if (prev[score]) {
        prev[score].scoreCounts.push(item);
      } else {
        // eslint-disable-next-line no-param-reassign
        prev[score] = { scoreCounts: [item] };
      }
      return prev;
    },
    {} as WheelPositionByOrganisation,
  );

export const generateTeamWheelSummaryMock = (
  orgs: OrgScore[],
): WheelPositionSummaryByOrganisationResponse => {
  const rawWheelPositions = generateTeamWheelSummaryScore(orgs);

  const wheelPositions = Object.keys(rawWheelPositions).reduce(
    (prev, key) => {
      prev.push({ [key]: rawWheelPositions[key] });
      return prev;
    },
    [] as WheelPositionSummaryByOrganisationResponse['wheelPositions'],
  );

  return {
    wheelPositions,
  };
};

export const teamWheelSummaryMock: WheelPositionSummaryResponse = {
  wheelPositions: [
    {
      '156': {
        scoreCount: {
          conscious: 16,
          lessConscious: 41,
        },
      },
    },
    {
      '34': {
        scoreCount: {
          conscious: 18,
          lessConscious: 120,
        },
      },
    },
  ],
};

export const generateMockTeamResponses = (
  numberOfTeams = 1,
  organisation?: OrganisationResponse,
  numberOfLearnersInTeam?: number,
): TeamResponse[] => {
  const teams: TeamResponse[] = [];

  for (let i = 0; i < numberOfTeams; i += 1) {
    teams.push({
      id: `teams-${i}`,
      name: `teams-${i}`,
      learnerCount: numberOfLearnersInTeam ?? 0,
      visibility: 'PRIVATE',
      organisationContext: {
        type: 'MULTIPLE',
        distinctLearnerCount: numberOfLearnersInTeam ?? 0,
        organisations: [
          {
            name: 'Insights',
            id: 'insights',
            supportedRoles: [
              'SUPER_ADMIN',
              'STANDARD',
              'GROUP_MANAGER',
              'ADVANCED_USER',
            ],
          },
        ],
      },
      location: '',
      migrated: false,
    });
  }

  return teams;
};

export const generateMockGetTeamsResponses = (
  numberOfTeams = 1,
  organisation?: OrganisationResponse,
  numberOfLearnersInTeam?: number,
): TeamResponse[] => {
  const teams: TeamResponse[] = [];

  for (let i = 0; i < numberOfTeams; i += 1) {
    teams.push({
      id: `teams-${i}`,
      name: `teams-${i}`,
      learnerCount: numberOfLearnersInTeam ?? 0,
      visibility: 'PRIVATE',
      organisationContext: {
        distinctLearnerCount: 2,
        type: 'SINGLE',
        organisations: [
          {
            name: 'Insights',
            id: 'insights',
            supportedRoles: [
              'SUPER_ADMIN',
              'STANDARD',
              'GROUP_MANAGER',
              'ADVANCED_USER',
            ],
          },
        ],
      },
      location: '',
      migrated: false,
    });
  }

  return teams;
};

export const mockTeamResponse: TeamResponse = {
  id: 'fbfde3a4-097e-4bb7-b110-ce08813815ce',
  name: 'Random 1',
  visibility: 'PRIVATE',
  organisationContext: {
    type: 'SINGLE',
    distinctLearnerCount: 2,
    organisations: [
      {
        name: 'Insights',
        id: 'insights',
        supportedRoles: [
          'SUPER_ADMIN',
          'STANDARD',
          'GROUP_MANAGER',
          'ADVANCED_USER',
        ],
      },
    ],
  },
  location: '',
  migrated: false,
  learnerCount: 2,
};

export const mockTeamLearners = {
  learners: [
    {
      id: '3b5fb21b-add6-4c3f-9c3a-4af64c8d263f',
      primaryEmail: 'PiotrBudzisz@testenv.email',
      fullName: 'Piotr Budzisz',
      knownAs: 'Peter',
      latestProfile: {
        id: 'af8c62a6-242a-4aa6-bbe6-adda4d9e02e2',
        createdAt: '2023-06-07T15:32:44.656623Z',
        leadingColourEnergy: 'SunshineYellow',
      },
      latestEvaluation: {
        id: '831bb56c-159e-11ee-be56-0242ac120002',
        submittedOn: '2023-06-07T15:32:44.656623Z',
        organisation: {
          id: 'insights',
          name: 'Insights',
        },
      },
    },
    {
      id: 'f58b4559-6e45-4cc4-b749-a2b0f83c5408',
      primaryEmail: 'yakoobhayat@testenv.email',
      fullName: 'Yakoob Hayat',
      knownAs: 'Yakoob',
      latestProfile: {
        id: 'a3bc8add-3fb0-4d1b-829e-b9d1a61ee402',
        createdAt: '2023-06-07T15:30:17.274772Z',
        leadingColourEnergy: 'CoolBlue',
      },
      latestEvaluation: {
        id: 'a4e3b0fa-159e-11ee-be56-0242ac120002',
        submittedOn: '2023-06-07T15:32:44.656623Z',
        organisation: {
          id: 'insights',
          name: 'Insights',
        },
      },
    },
  ],
  limitExceeded: false,
};

export const lotsOfTeams: TeamResponse[] = Array.from(Array(100).keys()).map(
  (_, index) => ({
    id: `some-id-${index}`,
    name: `Name - ${index}`,
    learnerCount: index % 2 === 0 ? 0 : 1,
    visibility: index % 2 === 0 ? 'PRIVATE' : 'ORGANISATION',
    organisationContext: {
      distinctLearnerCount: 10,
      type: 'SINGLE',
      organisations: [
        {
          id: 'org-1-id',
          name: 'Org 1',
          supportedRoles: ['STANDARD'],
        },
      ],
    },
    organisation: {
      id: 'org-1-id',
      name: 'Org 1',
      supportedRoles: ['STANDARD'],
    },
    migrated: false,
    location: null,
  }),
);

export const exceededLimitTeamLearnersResponse: GetTeamLearnersResponse = {
  learners: generateTeamMockLearners(300),
  limitExceeded: true,
};
