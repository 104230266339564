import OrganisationIcon from 'components/Svgs/icons/Organisation';
import TextField from '@mui/material/TextField';
import SvgLock2 from '@insights-ltd/design-library/src/components/Svgs/streamline-bold/Lock2V2';
import {
  OrganisationResponse,
  TeamLearnerResponse,
  TeamResponse,
  TeamVisibility,
} from 'api';
import useLearnerOrgSearch from 'components/hooks/useLearnerOrgSearch';
import FormHeader from 'components/FormHeader';
import {
  Dialog,
  P,
  RadioGroupControl,
  Span,
} from '@insights-ltd/design-library';
import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import UsersIcon from 'components/Svgs/icons/Users';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { TeamFormData } from 'pages/CreateTeam/types';
import { User } from 'types/types';
import BulkUploadTeam from 'components/BulkUploadLearners/BulkUploadLearners';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import { styled } from '@mui/material';
import { useGetViewPermissionOptions } from 'pages/CreateTeam/CreateTeam.helper';
import { OrgsListSearch } from 'components/OrgsSearchList/OrgsSearchList';
import { TeamData, TeamLearners } from './types';
import OrganisationSection from './OrganisationSection';

const StyledP = styled(P)({
  fontWeight: '600 !important',
  lineHeight: '28px !important',
  marginTop: '0 !important',
  marginBottom: '8px !important',
});

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.orange.dark,
  height: '20px',
}));

const StyledUsersIcon = styled(UsersIcon)(({ theme }) => ({
  fill: theme.palette.blue.dark,
  height: '20px',
}));

const StyledLockIcon = styled(SvgLock2)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const RemoveDialectOrgContent = () => {
  return (
    <P color="textSecondary">
      <Trans
        i18nKey="ui.event-management.teams.edit.remove-org.content"
        components={{
          bold: <Span color="textSecondary" variant="body-bold" />,
        }}
      />
    </P>
  );
};

type Props = {
  team: TeamResponse;
  learners: TeamLearnerResponse[];
  onSubmit: (formData: TeamData) => Promise<void>;
  children: ReactNode;
};

const sortOrgs = (
  { name: nameA }: OrganisationResponse,
  { name: nameB }: OrganisationResponse,
) => (nameA > nameB ? 1 : -1);

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: '40px',
  },
  '> * > *': {
    marginTop: theme.spacing(spacingSizeMap.M),
  },
  '> div > fieldset': {
    marginTop: '8px !important',
  },
}));

const EditTeamForm = ({ team, learners, onSubmit, children }: Props) => {
  const { t } = useTranslation();
  const { organisationsInGroups, organisations } =
    useGetAllOrganisationsAndGroups();

  const [openRemoveOrgDialog, setOpenRemoveOrgDialog] = useState<{
    open: boolean;
    orgId?: string;
  }>({ open: false, orgId: undefined });

  const isMultiOrg = team.organisationContext?.type === 'MULTIPLE';
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<TeamFormData>({
    defaultValues: {
      ...team,
      selectedOrgs: team.organisationContext?.organisations.sort(sortOrgs),
    },
  });

  const selectedOrgs = watch('selectedOrgs') ?? [];

  const teamLearners: User[] =
    learners?.map((learner: TeamLearners) => ({
      ...learner,
      emailAddress: learner.primaryEmail,
    })) || [];

  const { setSearchOrganisation } = useLearnerOrgSearch(team);

  const [listedLearners, setListedLearners] = useState<User[]>(teamLearners);
  const [selectedVisibility, setSelectedVisibility] = useState<TeamVisibility>(
    team.visibility || 'PRIVATE',
  );

  const visibilityOptions = useGetViewPermissionOptions(isMultiOrg);

  // temporary measure until we implement multi-org edit in https://insightsgroup.atlassian.net/jira/software/c/projects/NP/boards/35?selectedIssue=NP-9084
  const teamOrgId = team.organisationContext?.organisations[0].id ?? '';

  register('visibility', { required: true });

  useEffect(() => {
    setSearchOrganisation(teamOrgId);
  }, [setSearchOrganisation, teamOrgId]);

  const messages = {
    required: t('ui.event-management.teams.edit.error.name-required'),
    maxLength: t('ui.event-management.teams.edit.error.max-length', {
      maxLength: 100,
    }),
  };

  const submitHandler = handleSubmit((form: TeamData) =>
    onSubmit({ ...form, learners: listedLearners }),
  );

  const onBulkAddLearners = (data: User[]) => {
    const deDupedLearners = data.filter(
      (row) => !listedLearners.find((item) => item.id === row.id),
    );
    setListedLearners([...listedLearners, ...deDupedLearners]);
  };

  const errorText =
    errors.name && messages[errors.name.type as keyof typeof messages];

  const handleVisibilityChange = (value: string) => {
    setSelectedVisibility(value as TeamVisibility);
    setValue('visibility', value as TeamVisibility);
    trigger('visibility').then();
  };

  const handleOnRemove = (orgId: string) => {
    setOpenRemoveOrgDialog({ open: true, orgId });
  };

  const handleConfirmRemove = (orgId: string) => {
    setValue(
      'selectedOrgs',
      selectedOrgs.filter(({ id }) => id !== orgId),
    );
  };

  return (
    <StyledForm noValidate autoComplete="off" onSubmit={submitHandler}>
      {/* Organisation */}
      <div>
        <FormHeader
          icon={StyledOrganisationIcon}
          color="orange"
          title={t('ui.event-management.users.create.organisation', {
            count: team.organisationContext?.organisations?.length ?? 0,
          })}
          showMargin={!isMultiOrg}
        />
        {!isMultiOrg ? (
          <OrganisationSection
            items={team.organisationContext!.organisations}
            organisationsInGroups={organisationsInGroups}
          />
        ) : (
          <OrgsListSearch
            onSelect={(value: OrganisationResponse) =>
              setValue('selectedOrgs', [...selectedOrgs, value].sort(sortOrgs))
            }
            onRemoveOrg={selectedOrgs.length > 2 ? handleOnRemove : undefined}
            options={organisations}
            selectedOrgs={selectedOrgs}
            dataTestId="team-org-picker"
            groupHashMap={organisationsInGroups}
          />
        )}
      </div>

      {/* Team name */}
      <div>
        <FormHeader
          icon={StyledUsersIcon}
          title={t('ui.event-management.teams.edit-team-info')}
          color="blue"
          showMargin
        />
        <TextField
          id="name"
          label={t('ui.event-management.teams.team-name')}
          placeholder={t('ui.event-management.teams.add.placeholder.team-name')}
          variant="outlined"
          fullWidth
          sx={{ marginTop: '0 !important' }}
          type="text"
          {...register('name', { required: true, maxLength: 100 })}
          error={Boolean(errors.name)}
          helperText={errorText}
        />
      </div>

      {/* Learners */}
      {!team.migrated && team.organisationContext?.type !== 'MULTIPLE' && (
        <div>
          <BulkUploadTeam
            organisationId={teamOrgId}
            onBulkAddLearners={onBulkAddLearners}
          />
        </div>
      )}

      {/* Visibility permissions */}
      <div>
        <FormHeader
          icon={StyledLockIcon}
          title={t('ui.event-management.teams.view-permissions')}
          color="pink"
          showMargin
        />
        <StyledP>
          {t('ui.event-management.teams.view-permissions.view-help-text')}
        </StyledP>
        <RadioGroupControl
          options={visibilityOptions}
          name="visibility"
          title={t('ui.event-management.users.create.permission-roles')}
          value={selectedVisibility}
          onChange={handleVisibilityChange}
          variant="boxed"
        />
      </div>

      {children}

      <Dialog
        open={openRemoveOrgDialog.open}
        title={t('ui.event-management.teams.edit.remove-org.title')}
        primaryButtonText={t(
          'ui.event-management.teams.edit.remove-org.confirm',
        )}
        secondaryButtonText={t(
          'ui.event-management.teams.edit.remove-org.cancel',
        )}
        dialogProps={{
          sx: {
            '> div > div > div': {
              minHeight: '0',
            },
          },
        }}
        content={<RemoveDialectOrgContent />}
        onSubmit={() => {
          handleConfirmRemove(openRemoveOrgDialog.orgId ?? '');
          setOpenRemoveOrgDialog({ open: false, orgId: undefined });
        }}
        onClose={() => {
          setOpenRemoveOrgDialog({ open: false, orgId: undefined });
        }}
      />
    </StyledForm>
  );
};

export default EditTeamForm;
