import { authenticatedFetch, unauthenticatedFetch } from 'api/fetch';
import {
  AddContributorRequest,
  EventReminderResponse,
  EventResponseWithChapters,
  EventSummaryResponse,
  GetContributorsResponse,
} from 'api/httpEntities';
import { AllDialectOptions, ProfileDialectStrategies } from 'types/dialects';
import {
  DeleteInviteeData,
  EmailEventProfilesRequest,
  GetEvents,
  InviteeChaptersRequest,
  InviteeMutationData,
  InviteesResponse,
  ScoresResponse,
  SendEventInvitesData,
  UpdateEventData,
  UpdateInviteeData,
} from 'api/events/eventApiTypes';
import { DialectKeyValue, Model } from 'variables';
import { AnonymisationType } from 'types/types';

const baseUrl = '/api/v1/events';

export type Languages = {
  dialects: Array<DialectKeyValue>;
};

export const getSupportedDialects = async (
  model: Model,
  dialect?: string,
): Promise<Languages> => {
  const query = dialect ? `?dialect=${dialect}` : '';
  const path = `/api/v1/dialects/${model}${query}`;

  const response = await unauthenticatedFetch(path);

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

export const emailEventProfiles = async ({
  eventId,
  inviteeIds,
  dialect,
  generateUsing,
  format,
  anonymisationType,
}: EmailEventProfilesRequest) => {
  const url = `${baseUrl}/${eventId}/profiles/emails`;
  const formatString = format ? `?format=${format}` : '';
  const { success, json } = await authenticatedFetch(`${url}${formatString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      dialect,
      generateUsing,
      inviteeIds,
      anonymisationType,
    }),
  });

  await json();

  return success;
};

export const updateEvent = async ({
  eventId,
  event,
}: UpdateEventData): Promise<void> => {
  const { json } = await authenticatedFetch(`${baseUrl}/${eventId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(event),
  });

  await json();
};

export const getAllEvents = async ({
  status,
  from,
  to,
}: GetEvents): Promise<EventSummaryResponse[]> => {
  const eventsUrl = `${baseUrl}?status=${status}`;
  const eventsUrlWithFrom = from ? `${eventsUrl}&from=${from}` : eventsUrl;
  const apiUrl = to ? `${eventsUrlWithFrom}&to=${to}` : eventsUrlWithFrom;

  const { json } = await authenticatedFetch<{ events: EventSummaryResponse[] }>(
    apiUrl,
  );
  const { events } = await json();

  return events;
};

export const getEventInvitees = async (
  eventId: string,
): Promise<InviteesResponse> => {
  const { json } = await authenticatedFetch<InviteesResponse>(
    `${baseUrl}/${eventId}/invitees`,
  );

  return json();
};

export const downloadProfilesViaEmail = async ({
  eventId,
  profileIds,
  dialect,
  generateUsing,
  anonymisationType,
}: {
  eventId: string;
  profileIds: string[];
  dialect: AllDialectOptions;
  generateUsing: ProfileDialectStrategies;
  anonymisationType: AnonymisationType;
}) => {
  const url = `${baseUrl}/${eventId}/profiles/email/me`;

  await authenticatedFetch(`${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      profileIds,
      dialect,
      generateUsing,
      anonymisationType,
    }),
  });
};

export const getEvent = async (
  eventId: string,
): Promise<EventResponseWithChapters> => {
  const { json } = await authenticatedFetch(`${baseUrl}/${eventId}`);

  return json();
};

export const deleteEvent = async (variables: {
  eventId: string;
}): Promise<void> => {
  const { eventId } = variables;
  const url = `${baseUrl}/${eventId}`;
  const { json } = await authenticatedFetch(url, {
    method: 'DELETE',
  });
  await json();
};

export const cancelEvent = async (variables: {
  eventId: string;
  refundedPractitioner?: string;
}): Promise<void> => {
  const { eventId, refundedPractitioner } = variables;
  const url = `${baseUrl}/${eventId}/cancellation`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(refundedPractitioner !== '' && {
      body: JSON.stringify({ id: refundedPractitioner }),
    }),
  });
  await json();
};

export const sendEventInvites = async ({
  eventId,
  inviteeIds,
}: SendEventInvitesData): Promise<void> => {
  const options = inviteeIds
    ? {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inviteeIds }),
      }
    : {};

  const { json } = await authenticatedFetch(
    `${baseUrl}/${eventId}/invitees/invite/schedule`,
    { method: 'POST', ...options },
  );

  await json();
};

export const addInvitee = async ({
  eventId,
  formData,
}: InviteeMutationData): Promise<void> => {
  const { json } = await authenticatedFetch(`${baseUrl}/${eventId}/invitees`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  await json();
};

export const updateInvitee = async ({
  eventId,
  invitee,
}: UpdateInviteeData): Promise<void> => {
  const url = `${baseUrl}/${eventId}/invitees/${invitee.id}`;
  const { json } = await authenticatedFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(invitee),
  });

  await json();
};

export const deleteInvitee = async ({
  eventId,
  inviteeId,
}: DeleteInviteeData): Promise<void> => {
  const url = `${baseUrl}/${eventId}/invitees/${inviteeId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });

  await json();
};

export const updateInviteeChapters = async ({
  eventId,
  inviteeChapters,
}: InviteeChaptersRequest): Promise<Response> => {
  const url = `${baseUrl}/${eventId}/invitees/chapters`;
  const { json } = await authenticatedFetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(inviteeChapters),
  });

  return json();
};

export const getEventTeamScores = async (
  eventId: string,
): Promise<ScoresResponse> => {
  const url = `${baseUrl}/${eventId}/invitees/scores`;
  const { json } = await authenticatedFetch<ScoresResponse>(url);

  return json();
};

export const downloadProfilesZIPForEvent = async ({
  eventId,
  inviteeIds,
  dialect,
  generateUsing,
  signal,
  anonymisationType,
}: {
  eventId: string;
  inviteeIds: string[];
  dialect: AllDialectOptions;
  generateUsing: ProfileDialectStrategies;
  signal?: AbortSignal;
  anonymisationType: AnonymisationType;
}) => {
  const url = `${baseUrl}/${eventId}/profiles/download`;

  const { success, blob } = await authenticatedFetch(`${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      dialect,
      generateUsing,
      inviteeIds,
      anonymisationType,
    }),
    signal,
  });

  const objectUrl = URL.createObjectURL(await blob());
  const a = document.createElement('a');
  a.href = objectUrl;
  a.download = `${eventId}.zip`;
  a.click();

  return success;
};

export const purchaseProfilesForEvent = async ({
  eventId,
  inviteeIds,
}: {
  eventId: string;
  inviteeIds: string[];
}) => {
  const url = `${baseUrl}/${eventId}/profiles/purchase`;

  const { success } = await authenticatedFetch(`${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inviteeIds }),
  });

  return success;
};

export const getEventReminder = async (
  eventId: string,
): Promise<EventReminderResponse> => {
  const { json, status } = await authenticatedFetch<EventReminderResponse>(
    `${baseUrl}/${eventId}/reminders`,
  );

  if (status === 204 || status === 404) {
    return {};
  }

  return json();
};

export const setEventReminder = async ({
  eventId,
  reminderAt,
}: {
  eventId: string;
  reminderAt: string;
}): Promise<EventReminderResponse> => {
  const { json } = await authenticatedFetch<EventReminderResponse>(
    `${baseUrl}/${eventId}/reminders`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reminderAt }),
    },
  );
  return json();
};

export const cancelEventReminder = async ({ eventId }: { eventId: string }) => {
  await authenticatedFetch(`${baseUrl}/${eventId}/reminders`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getContributors = async (
  eventId: string,
): Promise<GetContributorsResponse> => {
  const { json } = await authenticatedFetch<GetContributorsResponse>(
    `${baseUrl}/${eventId}/contributors`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return json();
};

export const addContributor = async (
  eventId: string,
  contributor: AddContributorRequest,
): Promise<void> => {
  const { json } = await authenticatedFetch(
    `${baseUrl}/${eventId}/contributors`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contributor),
    },
  );

  await json();
};

export const removeContributor = async (
  eventId: string,
  contributorId: string,
): Promise<void> => {
  return deleteInvitee({ eventId, inviteeId: contributorId });
};

export const addLearnerToAll = async (
  eventId: string,
  learnerId: string,
  inviteeIds: string[],
): Promise<void> => {
  const { json } = await authenticatedFetch(
    `${baseUrl}/${eventId}/contributors/invitee/${learnerId}/contributors`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inviteeIds }),
    },
  );

  await json();
};
