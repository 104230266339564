import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, styled, Tab, Tabs } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FullHeight from 'components/FullHeight';
import EventsList from 'components/EventsList';
import {
  EventSummaryResponse,
  ExperienceViewType,
  useGetEvents,
  useGetPractitioner,
  useGetPractitionerOrganisations,
} from 'api';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import Breadcrumbs from 'components/Breadcrumbs';
import { H2 } from '@insights-ltd/design-library';
import { queryStatus } from 'utils/queryStatus';
import { isMultiOrg as hasMultiOrgs } from 'utils/isMultiOrg';
import { useAuth } from 'contexts/AuthContext';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import { SearchFilterV2 } from 'components/SearchFilter/SearchFilter';
import { isCategory } from 'utils/typeGuards/isCategory';

const EXPERIENCE_VIEW = ['active', 'archived', 'cancelled'] as const;
type LabelType = Record<ExperienceViewType, { all: string; filtered: string }>;

function isExperienceViewType(view?: string): view is ExperienceViewType {
  const categories: ExperienceViewType[] = [...EXPERIENCE_VIEW];
  return isCategory<ExperienceViewType>(view, categories);
}

const noResultsTranslations: Record<ExperienceViewType, string> = {
  cancelled: 'ui.event-management.event.cancelled.no-results',
  active: 'ui.event-management.event.upcoming.no-results',
  archived: 'ui.event-management.event.past.no-results',
};

const experienceTypeLabels: LabelType = {
  active: {
    all: 'ui.event-management.events.upcoming-events',
    filtered: 'ui.event-management.events.upcoming-events-filtered',
  },
  archived: {
    all: 'ui.event-management.events.past-events',
    filtered: 'ui.event-management.events.past-events-filtered',
  },
  cancelled: {
    all: 'ui.event-management.events.cancelled-events',
    filtered: 'ui.event-management.events.cancelled-events-filtered',
  },
};

const StyledSpan = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.grey[100],
  backgroundColor: theme.palette.pink.main,
  borderRadius: '4px',
}));

const Events = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { state, pathname } = useLocation();
  const {
    searchState: { category, searchTerm = '' },
    setSearchState,
  } = useSearchContext();
  const locationState: any = state;
  const [experienceName, setExperienceName] = useState(
    locationState?.experienceName ?? '',
  );
  const isCancelledExperience =
    locationState && locationState.showDeletedDialog === 'cancel';

  const defaultCategory =
    locationState?.activeTab ?? isCancelledExperience ? 'cancelled' : 'active';
  const experiencesView = isExperienceViewType(category)
    ? category
    : defaultCategory;

  function setSearchTerm(text: string) {
    setSearchState({ searchTerm: text });
  }

  function setExperiencesView(view: ExperienceViewType) {
    setSearchState({ category: view, searchTerm: '' });
    setExperienceName('');

    if (locationState && locationState.showDeletedDialog === 'cancel') {
      const { showDeletedDialog, ...rest } = locationState;
      navigate(pathname, { replace: true, state: { ...rest } });
    }
  }

  const { status: eventsStatus, data } = useGetEvents(experiencesView);
  const { status: practitionerStatus, data: practitioner } =
    useGetPractitioner();
  const {
    status: practitionerOrganisationsStatus,
    data: practitionerOrganisations,
  } = useGetPractitionerOrganisations(practitioner?.id, {
    enabled: !!practitioner?.id,
  });
  const status = queryStatus(
    eventsStatus,
    practitionerStatus,
    practitionerOrganisationsStatus,
  );

  const isMultiOrg =
    hasMultiOrgs(practitionerOrganisations) ||
    user?.roles.includes('SUPER_ADMIN');

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.events.error-loading-events')}
      />
    );
  }

  const sortCancelledDate = (items: EventSummaryResponse[]) =>
    items.sort((a, b) => {
      const EPOCH = new Date(0);
      const cancelledAtB = b.cancelledAt!
        ? new Date(b.cancelledAt).getTime()
        : EPOCH.getTime();
      const cancelledAtA = a.cancelledAt!
        ? new Date(a.cancelledAt).getTime()
        : EPOCH.getTime();
      return cancelledAtB - cancelledAtA!;
    });

  const events = data!;
  const title =
    searchTerm.length === 0
      ? t(experienceTypeLabels[experiencesView].all)
      : t(experienceTypeLabels[experiencesView].filtered);

  const filteredEvents = !searchTerm
    ? events
    : events.filter((event) =>
        event.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
  const filteredNumberOfEvents = filteredEvents.length;

  const sortedEvents =
    experiencesView === 'cancelled'
      ? sortCancelledDate(filteredEvents!)
      : filteredEvents;

  const CancelledMessageStyledDiv = styled('div')(() => ({
    width: '688px',
  }));

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.events')}</title>
      </Helmet>
      <FullHeight>
        <Container maxWidth="lg">
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.events.nav.home'),
              }}
              activeText={t('ui.event-management.events.title')}
            />
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <H2 variant="h2">{t('ui.event-management.events.title')}</H2>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Tabs
              value={experiencesView}
              onChange={(_, value) => setExperiencesView(value)}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                label={t('ui.event-management.events.upcoming-events')}
                value="active"
              />
              <Tab
                label={t('ui.event-management.events.past-events')}
                value="archived"
              />
              <Tab
                label={t('ui.event-management.events.cancelled-events')}
                value="cancelled"
              />
            </Tabs>
          </Box>
          <SearchFilterV2
            label={t('ui.event-management.list-filter.search')}
            fieldName={t(
              'ui.event-management.events.search.label.by-experience-name',
            )}
            placeholder={t('ui.event-management.events.search.placeholder')}
            showButton={false}
            handleTermChange={(value) => setSearchTerm(value)}
            inputState={searchTerm}
            minCharacters={1}
          />
          {experienceName && (
            <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <CancelledMessageStyledDiv>
                    <Alert>
                      {t('ui.event-management.events.cancel-success', {
                        experienceName,
                      })}
                    </Alert>
                  </CancelledMessageStyledDiv>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md="auto">
                <H2 variant="h3">
                  {title} <StyledSpan>{filteredNumberOfEvents}</StyledSpan>
                </H2>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  component={RouterLink}
                  to="/experiences/create"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ padding: '0.5rem 1rem' }}
                >
                  {t('ui.event-management.events.create-new-event')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <EventsList
              events={sortedEvents}
              showOrgs={isMultiOrg}
              experienceView={experiencesView}
              noResultsText={t(noResultsTranslations[experiencesView])}
            />
          </Box>
        </Container>
      </FullHeight>
    </>
  );
};

export default Events;
