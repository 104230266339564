import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, styled } from '@mui/material';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import { H1, P, Span } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

const StyledTransactionBox = styled('div')(({ theme }) => ({
  width: '369px',
  margin: '0 0 2.5rem',
  '> :first-of-type': {
    paddingTop: '0 !important',
  },
  '& div': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0.875rem 0 0.75rem',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '> :last-child': {
      marginLeft: 'auto',
    },
  },
  '& div:last-child': {
    borderBottom: 'none',
  },
}));

const ConfirmPurchase = ({
  transactionTotal,
  userUnitBalance,
  postTransactionBalance,
  isInvalidTransaction,
}: {
  transactionTotal: number;
  userUnitBalance: number;
  postTransactionBalance: number;
  isInvalidTransaction: boolean;
}) => {
  const { t } = useTranslation();

  const unitsMessage = isInvalidTransaction ? (
    <Grid
      item
      sx={(theme) => ({
        width: '650px',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      })}
    >
      <Alert iconType="warning" kind="error">
        <Span color="error" variant="body-bold">
          {t(
            'ui.event-management.learners.purchase-profiles.confirm-purchase.need-units',
          )}{' '}
        </Span>
        <Link component={RouterLink} to="/account" color="textPrimary">
          <Span color="primary" variant="body-bold">
            {t(
              'ui.event-management.learners.purchase-profiles.confirm-purchase.request-units.link-label',
            )}
          </Span>
        </Link>
      </Alert>
    </Grid>
  ) : (
    <Grid
      sx={(theme) => ({
        width: '650px',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
        padding: `0.75rem ${theme.spacing(spacingSizeMap.S)}`,
        background: theme.palette.primary.light,
        borderRadius: '4px',
        '&.invalid': {
          backgroundColor: theme.palette.error.main,
        },
      })}
    >
      <P variant="body-bold">
        {t(
          'ui.event-management.learners.purchase-profiles.confirm-purchase.enough-units',
        )}
      </P>
    </Grid>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        sx={(theme) => ({ marginBottom: theme.spacing(spacingSizeMap.XS) })}
      >
        <H1 variant="h3">
          {t(
            'ui.event-management.learners.purchase-profiles.confirm-purchase.heading',
          )}
        </H1>
      </Grid>
      <Grid
        container
        ml="0.5rem"
        justifyContent="space-between"
        paddingTop="0.5rem"
        flexDirection="row"
      >
        <Grid item>{unitsMessage}</Grid>
        <Grid item sx={{ padding: 0 }}>
          <StyledTransactionBox>
            <div>
              <Span color="textSecondary">
                {t('ui.event-management.events.purchase-profiles.unit-balance')}
              </Span>
              <Span
                variant="body-bold"
                color={isInvalidTransaction ? 'error' : 'textSecondary'}
              >
                {userUnitBalance}&nbsp;
                {t(
                  'ui.event-management.events.assign-chapters.summary-box.units',
                )}
              </Span>
            </div>
            <div>
              <Span variant="body-bold">
                {t(
                  'ui.event-management.events.purchase-profiles.transaction-total',
                )}
              </Span>
              <Span variant="body-bold" data-testid="transaction-total">
                {transactionTotal}&nbsp;
                {t(
                  'ui.event-management.events.assign-chapters.summary-box.units',
                )}
              </Span>
            </div>
            {!isInvalidTransaction && (
              <div>
                <Span>
                  {t(
                    'ui.event-management.events.purchase-profiles.post-transaction-balance',
                  )}
                </Span>
                <Span variant="body-bold">
                  {postTransactionBalance}&nbsp;
                  {t(
                    'ui.event-management.events.assign-chapters.summary-box.units',
                  )}
                </Span>
              </div>
            )}
          </StyledTransactionBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmPurchase;
