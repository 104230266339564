import { Box, Container, styled } from '@mui/material';
import {
  CompletedEvaluation,
  FlattenedCompletedEvaluation,
  useGetCompletedEvaluators,
} from 'api';
import { FullScreenSpinner } from 'components/FullScreen';
import { SearchFilterV2 as SearchFilter } from 'components/SearchFilter/SearchFilter';
import { H2, Span } from '@insights-ltd/design-library';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types/types';
import EvaluationsTable, {
  evaluationDataTransformer,
} from 'pages/CompletedEvaluators/EvaluationsTable/EvaluationsTable';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { EvaluatorSearchFilter } from 'api/evaluatorLinks/evaluatorLinksApiTypes';
import { sortChapters } from 'utils/mappers/sorting';
import { booleanizeCheckedItems } from 'utils/mappers/entityMappers';
import SearchCount from 'components/SearchCount/SearchCount';
import SearchResultInfo from 'components/SearchResultInfo/SearchResultInfo';
import { useDebounceValue } from 'components/hooks/useDebouncedValue';

const ItemsCount = ({
  title,
  count,
  limitExceeded,
}: {
  title: string;
  count: number;
  limitExceeded?: boolean;
}) => (
  <Box
    maxWidth="lg"
    sx={(theme) => ({
      margin: `2.5rem 0 ${theme.spacing(spacingSizeMap.M)}`,
      fontSize: '1.5rem',
    })}
  >
    <H2 variant="h3">
      {title}
      <SearchCount
        sx={(theme) => ({
          backgroundColor: count
            ? theme.palette.pink.main
            : theme.palette.grey[500],
          marginLeft: theme.spacing(spacingSizeMap.XS),
        })}
        count={limitExceeded ? '300+' : count}
      />
    </H2>
  </Box>
);

const StyledTableWrapper = styled('div')(({ theme }) => ({
  margin: '0 0',
  padding: `0 ${theme.spacing(spacingSizeMap.XS)} 0`,
}));

const StyledNoEvaluations = styled(Span)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: theme.typography.fontWeightBold,
}));

type Props = {
  id: string;
  organisationid: string;
  checkedItems: Record<string, User>;
  setCheckedItems: (checkedItemsMap: Record<string, User>) => void;
  searchTerm: string;
  setSearchInputValue: (value: string) => void;
  searchInputValue: string;
  initialSearchFilter?: EvaluatorSearchFilter;
};

const EvaluatorResults = ({
  id,
  organisationid,
  checkedItems,
  setCheckedItems,
  searchTerm,
  setSearchInputValue,
  searchInputValue,
  initialSearchFilter = 'ALL',
}: Props) => {
  const { t, i18n } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const { language: locale } = i18n;
  const [filter, setFilter] =
    useState<EvaluatorSearchFilter>(initialSearchFilter);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const commonDataStructure = {
    fullName: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.name',
    ),
    emailAddress: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.email',
    ),
    referralCode: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.referral-code',
    ),
    dateCompleted: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.date-completed',
    ),
  };

  const dataStructure = {
    ...commonDataStructure,
    latestPurchase: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.latest-purchase',
    ),
    colourScores: t(
      'ui.event-management.evaluator-links.completed-evaluators.table-header.colour-scores',
    ),
  };

  const categoryOptions: { label: string; value: EvaluatorSearchFilter }[] = [
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.all',
      ),
      value: 'ALL',
    },
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.purchased',
      ),
      value: 'PURCHASED',
    },
    {
      label: t(
        'ui.event-management.evaluator-links.completed-evaluators.search-filter.label.unpurchased',
      ),
      value: 'UNPURCHASED',
    },
  ];

  const debouncedSearchTerm = useDebounceValue(searchTerm);

  const { data: completedEvaluationsResponse, isPending } =
    useGetCompletedEvaluators(
      id,
      debouncedSearchTerm.length >= 2 ? debouncedSearchTerm : '',
      filter,
    );

  const completedEvaluations: CompletedEvaluation[] =
    completedEvaluationsResponse?.evaluations ?? [];

  const sortedEvaluations = completedEvaluations.sort(
    ({ createdAt: a }, { createdAt: b }) => (b > a ? 1 : -1),
  );

  const items: FlattenedCompletedEvaluation[] = evaluationDataTransformer(
    sortedEvaluations,
    locale,
  ).map(({ chapters, ...rest }) => {
    return {
      ...rest,
      chapters: chapters.sort(sortChapters).map((chapter) => chapter),
    };
  });

  const handleSetCheckedItems = (checkedItemsMap: Record<string, boolean>) => {
    const selectedLearners = Object.entries(checkedItemsMap).reduce(
      (prev, [itemId]) => {
        const { learner } =
          sortedEvaluations.find(({ id: evalId }) => evalId === itemId) ?? {};
        return {
          ...prev,
          [itemId]: learner,
        };
      },
      {},
    );

    setCheckedItems(selectedLearners);
  };

  const handlePageChange = ({ lastPage }: { lastPage?: boolean }) => {
    setIsLastPage(!!lastPage);
  };

  const handleSearchMessageClick = () => {
    ref.current?.focus();
  };

  const tableElement =
    items.length > 0 ? (
      <StyledTableWrapper>
        <EvaluationsTable
          items={items}
          dataStructure={dataStructure}
          checkedItems={booleanizeCheckedItems(checkedItems)}
          onCheckedItems={handleSetCheckedItems}
          organisationId={organisationid}
          onPageChange={handlePageChange}
        />
      </StyledTableWrapper>
    ) : (
      <StyledNoEvaluations>
        {searchTerm === ''
          ? t(
              'ui.event-management.evaluator-links.completed-evaluators.no-evaluators',
            )
          : t(
              'ui.event-management.evaluator-links.completed-evaluators.no-evaluators-results',
            )}
      </StyledNoEvaluations>
    );

  const filterProps = {
    categoryOptions,
    initialSearchFilter: filter,
    handleFilterChange: (value: EvaluatorSearchFilter) => {
      setFilter(value);
    },
  };

  const getSearchResultMessage = () => {
    if (completedEvaluationsResponse?.limitExceeded) {
      if (isLastPage) {
        return 'ui.event-management.evaluator-links.result.limit-exceeded.last-page';
      }
      if (searchTerm) {
        return 'ui.event-management.evaluator-links.result.limit-exceeded.search-term';
      }
      return 'ui.event-management.evaluator-links.result.limit-exceeded';
    }

    return 'ui.event-management.evaluator-links.result.limit-exceeded';
  };

  return (
    <>
      <Container maxWidth="xl">
        <Container maxWidth="lg">
          <SearchFilter
            ref={ref}
            sx={(theme) => ({
              margin: '-3rem 0 0 !important',
              padding: `${theme.spacing(spacingSizeMap.S)} !important`,
            })}
            placeholder={t(
              'ui.event-management.evaluator-links.search.placeholder',
            )}
            handleTermChange={setSearchInputValue}
            handleSearch={() => {}}
            showButton={false}
            inputState={searchInputValue}
            fieldName={t('ui.event-management.list-filter.by-name-email')}
            label={t('ui.event-management.evaluator-links.search-bar.search')}
            minCharacters={2}
            {...filterProps}
          />
        </Container>
      </Container>
      <Container maxWidth="xl">
        {isPending ? (
          <FullScreenSpinner message={t('ui.event-management.loading')} />
        ) : (
          <Container maxWidth="lg">
            <ItemsCount
              title={t(
                'ui.event-management.evaluator-links.completed-evaluators.completed-evaluators',
              )}
              count={items.length}
              limitExceeded={completedEvaluationsResponse?.limitExceeded}
            />
            {completedEvaluationsResponse?.limitExceeded ? (
              <SearchResultInfo
                onClick={handleSearchMessageClick}
                i18nKey={getSearchResultMessage()}
              />
            ) : null}
            {tableElement}
          </Container>
        )}
      </Container>
    </>
  );
};

export default EvaluatorResults;
