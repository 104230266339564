import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { P, Span } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';
import { getChapterKey } from 'utils/getChapterKey';
import { sortChapters } from 'utils/mappers/sorting';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';

export type StatusSummary = {
  chapterName: string;
  count: number;
  price: number;
};

export type ChaptersSummary = StatusSummary[];

export type SummaryBoxProps = {
  className?: string;
  title: string;
  chapterSummary: ChaptersSummary;
};

const StyledDiv = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.M,
  )}`,
  borderRadius: '4px',
  '> p': {
    fontWeight: theme.typography.fontWeightBold,
  },
  '& .summaryRow': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '> :first-of-type': {
      minWidth: '2.25rem',
    },
    '> :last-child': {
      marginLeft: 'auto',
    },
  },
}));

const StyledSpan = styled(Span)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightBold,
}));

const SummaryBox: React.FC<SummaryBoxProps> = ({
  className,
  title,
  chapterSummary,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDiv className={className}>
      <P color="textPrimary">{title}</P>
      <List disablePadding>
        {chapterSummary
          .sort(({ chapterName: nameA }, { chapterName: nameB }) =>
            sortChapters(nameA, nameB),
          )
          .map(({ chapterName, count, price }) => {
            const titleKey = getChapterKey(chapterName);
            return (
              <ListItem
                sx={{
                  paddingTop: '0',
                  paddingBottom: '0',
                }}
                disableGutters
                key={titleKey}
              >
                <div className="summaryRow">
                  <Span color="textSecondary">{count}x</Span>
                  <Span color="textSecondary">{t(titleKey)}</Span>
                  <StyledSpan color="textSecondary">
                    {price}&nbsp;
                    {t(
                      'ui.event-management.events.assign-chapters.summary-box.units',
                    )}
                  </StyledSpan>
                </div>
              </ListItem>
            );
          })}
      </List>
    </StyledDiv>
  );
};

export default SummaryBox;
