import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { Helmet } from 'react-helmet';
import { H3, P } from '@insights-ltd/design-library';

import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { FullScreenSpinner } from 'components/FullScreen';
import { useQueryClient } from '@tanstack/react-query';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useSignUp, useValidateInvite } from 'api';
import { FormData } from './types';
import SignUpForm from './SignUpForm/SignUpForm';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(spacingSizeMap.L),
  width: '500px',
  color: `${theme.palette.common.white} !important`,
}));

const SignUp = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error: signUpError } = useSignUp(queryClient);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const token = hash.slice('#token='.length);
  const { data: isValidToken, status } = useValidateInvite(token);

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }

  const onSubmit = ({
    fullName,
    password,
    preferredDialect = 'en-GB',
  }: FormData) =>
    mutate(
      {
        fullName,
        password,
        preferredDialect,
        token,
      },
      { onSuccess: () => navigate('/') },
    );

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.sign-up')}</title>
      </Helmet>
      <SelfServiceLayout>
        {isValidToken ? (
          <SignUpForm
            onSubmit={onSubmit}
            signUpError={signUpError}
            isLoading={isPending}
          />
        ) : (
          <StyledCard>
            <H3>{t('ui.event-management.sign_up.error.heading')}</H3>
            <Box height={spacingSizeMap.XL} />
            <P>{t('ui.event-management.sign_up.error.body')}</P>
            <Box height={spacingSizeMap.S} />
            <P variant="body-bold">
              <Link href="mailto://customerservice@insights.com">
                customerservice@insights.com
              </Link>
            </P>
          </StyledCard>
        )}
      </SelfServiceLayout>
    </>
  );
};

export default SignUp;
