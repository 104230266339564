import { Box, FormLabel, MenuItem } from '@mui/material';
import { H3 } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialects } from 'types/dialects';
import { DIALECT_MAP, ENABLED_WHEEL_DIALECTS } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { InputSelectV2 } from '@insights-ltd/design-library/src/components/InputSelectV2';
import { useWheelContext, WheelState } from './hooks/useWheelContext';

const SelectTypeControl = () => {
  const { t } = useTranslation();
  const [{ changeLanguage, languageType }, setState] = useWheelContext();

  const handleChange = (newValue: string) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      fontSize: DIALECT_MAP.find(({ value }) => value === newValue)!
        .wheelFontSize,
      tableFontSize: DIALECT_MAP.find(({ value }) => value === newValue)!
        .tableFontSize,
      languageType: newValue as Dialects,
    }));
    changeLanguage(newValue);
  };

  return (
    <>
      <FormLabel component="legend">
        <H3 variant="h3">
          {t('ui.event-management.events.team-wheel.select-language')}
        </H3>
      </FormLabel>
      <Box sx={(theme) => ({ mb: theme.spacing(spacingSizeMap.M) })} />
      <InputSelectV2
        labelText=""
        id="language-select"
        value={languageType}
        onChange={(event) => handleChange(event.target.value as string)}
      >
        {ENABLED_WHEEL_DIALECTS.map(({ textKey, value }) => (
          <MenuItem key={value} value={value}>
            {t(textKey)}
          </MenuItem>
        ))}
      </InputSelectV2>
    </>
  );
};

export default SelectTypeControl;
