import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { H2, P, Span } from '@insights-ltd/design-library';
import {
  EventResponseWithChapters,
  InviteeResponse,
  useDeleteInvitee,
} from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { isExploreExperience, isIdtlExperience } from 'domain/event';
import { INVITEE_STATUSES } from 'variables';
import DeleteLearnerDialog from 'pages/EventLearners/DeleteLearnerDialog';
import { InviteeListOptions } from 'types/types';
import LearnersStatusList from './LearnersStatusList';
import LearnersSearchBox from './LearnersSearchBox';
import LearnersStatusFilter from './LearnersStatusFilter';

type LearnersListProps = {
  invitees: InviteeResponse[];
  event: EventResponseWithChapters;
  isPastExperience: boolean;
};

const LearnersList = ({
  invitees,
  event,
  isPastExperience,
}: LearnersListProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState<InviteeListOptions | ''>('');
  const { mutate, isPending, isError, reset } = useDeleteInvitee();
  const [inviteeId, setInviteeId] = useState('');
  const isDiscoveryExperience =
    !isIdtlExperience(event?.eventType) &&
    !isExploreExperience(event?.eventType);

  const sortedReadyToPurchaseInvitees = invitees
    .filter(
      (x: InviteeResponse) =>
        x.status === 'READY_TO_PURCHASE' && x.profileStatus === 'NONE',
    )
    .sort((a: InviteeResponse, b: InviteeResponse) => {
      return (
        Date.parse(b.evaluation?.submittedOn || '') -
        Date.parse(a.evaluation?.submittedOn || '')
      );
    });
  const unsortedInvitees = invitees.filter(
    (x: InviteeResponse) =>
      !(x.status === 'READY_TO_PURCHASE' && x.profileStatus === 'NONE'),
  );
  const sortedInvitees = unsortedInvitees.concat(sortedReadyToPurchaseInvitees);

  const filteredInvitees =
    searchText === ''
      ? sortedInvitees
      : sortedInvitees.filter(
          (invitee) =>
            invitee.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
            invitee.email.toLowerCase().includes(searchText.toLowerCase()),
        );
  const inviteesCount = sortedInvitees.length;
  const filteredCount = filteredInvitees.length;

  const handleDeleteInvitee = async (selectedInviteeId: string) => {
    setInviteeId(selectedInviteeId);
  };

  const handleConfirmDelete = () =>
    mutate(
      {
        eventId: event.id,
        inviteeId,
      },
      { onSuccess: () => setInviteeId('') },
    );

  return (
    <>
      {inviteesCount > 0 ? (
        <>
          <H2 variant="h3">
            {t('ui.event-management.events.learners-list.heading.learners')} (
            {inviteesCount > filteredCount
              ? `${filteredCount}/${inviteesCount}`
              : inviteesCount}
            )
          </H2>
          <Box mt={(theme) => theme.spacing(spacingSizeMap.S)} />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={4}>
              <LearnersSearchBox value={searchText} onChange={setSearchText} />
            </Grid>
            <Grid item xs={12} md={3}>
              <LearnersStatusFilter
                onChange={setStatusFilter}
                value={statusFilter}
              />
            </Grid>
          </Grid>
          <Box mt={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Grid
            spacing={1}
            container
            sx={(theme) => ({
              padding: `0 ${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
                spacingSizeMap.XS,
              )}`,
              color: theme.palette.grey[600],
              backgroundColor: theme.palette.grey[300],
            })}
          >
            <Grid item xs={3} sx={{ marginRight: '1rem' }}>
              <P variant="body-bold">
                {t('ui.event-management.events.list.heading.full-name-email')}
              </P>
            </Grid>
            {isDiscoveryExperience && (
              <Grid item xs={4} sx={{ marginLeft: '1rem' }}>
                <P variant="body-bold">
                  {t(
                    'ui.event-management.events.learners-list.heading.chapters',
                  )}
                  &nbsp;&nbsp;
                  {inviteesCount > 0 && !isPastExperience && (
                    <Span variant="body-bold" color="primary">
                      <Link
                        component={RouterLink}
                        to={`/experiences/${event.id}/assign-chapters`}
                      >
                        {t(
                          'ui.event-management.events.learners-list.assign-link-label',
                        )}
                      </Link>
                    </Span>
                  )}
                </P>
              </Grid>
            )}
          </Grid>
          {Object.keys(INVITEE_STATUSES).map((status) => (
            <LearnersStatusList
              key={status}
              listStatus={status as InviteeListOptions}
              statusFilter={statusFilter}
              invitees={filteredInvitees}
              deleteInvitee={handleDeleteInvitee}
              event={event}
              isPastExperience={isPastExperience}
            />
          ))}
        </>
      ) : null}
      <DeleteLearnerDialog
        open={Boolean(inviteeId)}
        onClose={() => setInviteeId('')}
        handleConfirmDelete={handleConfirmDelete}
        deleting={isPending}
      />
      <Snackbar open={isError} autoHideDuration={6000} onClose={reset}>
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          {t('ui.event-management.events.invitee.error-deleting-invitee')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LearnersList;
