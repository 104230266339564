import {
  PractitionerData,
  OrganisationResponse,
  PractitionerSummaryData,
} from 'api';
import { PractitionersHttpResponse } from 'test/types';
import { Role } from 'types/types';

export const PractitionerOrganisationsResponse: {
  organisations: OrganisationResponse[];
} = {
  organisations: [
    {
      name: 'Neat Group',
      id: '9fadfe64-5ce0-40de-8325-4a44cd8ae4ea',
      supportedRoles: [],
    },
    {
      name: 'IH Org',
      id: 'bea2bba7-713e-48a8-93c8-5a5e70ce097e',
      supportedRoles: [],
    },
    {
      name: "Jack's Cornbeef Emporium",
      id: 'fbab3d46-57d0-48a4-9429-4d7122aa014a',
      supportedRoles: [],
    },
  ],
};

export const PractitionerSummaryResponse: PractitionersHttpResponse = {
  practitioners: [
    {
      fullName: 'Demo standard user',
      emailAddress: 'paul.craigie+5@waracle.com',
      id: '5572ac1c-502e-45b9-bfda-5db3a34a6126',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Iain Prac3',
      emailAddress: 'iainn666+prac3@gmail.com',
      id: 'd5417afc-b317-42b1-be06-96d71f04ef6d',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Iain Practitioner',
      emailAddress: 'iainn666+prac@gmail.com',
      id: '148e1d25-1d49-4fee-b4aa-ff900858080d',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Iain Practitioner 543',
      emailAddress: 'iainn666+prac543@gmail.com',
      id: 'd298f2e9-1f86-4d69-a5fd-db529e9fee41',
      jobTitle: 'ADMIN',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD', 'SUPER_ADMIN'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Lorraine Lowe',
      emailAddress: 'llowe@insights.com',
      id: '407f9d84-8267-4215-a032-da1a0a6389e2',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['SUPER_ADMIN'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'New Guy',
      emailAddress: 'iainn666+newguy@gmail.com',
      id: 'f2b484c1-7eb2-4615-8ddb-5914bba464d9',
      jobTitle: 'ADMIN',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['SUPER_ADMIN'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Nicola CS User ',
      emailAddress: 'ngolden@insights.com',
      id: '9614781b-f0c3-4937-9ea6-0c75353336e2',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'EarthGreen',
      enabled: true,
      roles: ['SUPER_ADMIN'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Paul Demo',
      emailAddress: 'paul.craigie+4@waracle.com',
      id: '5b610d9b-3dd6-4d2b-a0f5-fbfa7c40fd92',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Paul Percival',
      emailAddress: 'paul@plan9.co.uk',
      id: 'e1de2ac5-092b-49c4-81db-d60b3e8bb7e9',
      jobTitle: 'ADMIN',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['SUPER_ADMIN'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
    {
      fullName: 'Test1',
      emailAddress: 'nicolagolden10+nsu@gmail.com',
      id: '46cc0d80-965e-4e65-84cd-fac5735f95b8',
      jobTitle: 'LICENSED_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      enabled: true,
      roles: ['STANDARD'],
      licensedProducts: ['DISCOVERY_SELF_AWARE_PROFILE'],
    },
  ],
};
export const StandardPractitioner: PractitionerData = {
  fullName: 'Test User',
  emailAddress: 'test@email.com',
  id: 'test-id',
  leadingColourEnergy: 'CoolBlue',
  licensedProducts: [],
  jobTitle: 'CLIENT_PRACTITIONER',
  roles: ['STANDARD'],
  enabled: true,
};

export const mockPractitioners: { practitioners: PractitionerSummaryData[] } = {
  practitioners: [
    {
      createdAt: '1970-01-01T00:00:00Z',
      emailAddress: 'email1@insights.com',
      fullName: 'User One',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'FieryRed',
      totalEvents: 1,
      enabled: true,
      roles: ['STANDARD', 'SUPER_ADMIN'],
    },
    {
      createdAt: '1970-01-02T00:00:00Z',
      emailAddress: 'email2@insights.com',
      fullName: 'User Two',
      id: 'f82b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'SunshineYellow',
      totalEvents: 2,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-03T00:00:00Z',
      emailAddress: 'email3@insights.com',
      fullName: 'User Three',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef5',
      jobTitle: 'LICENSED_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      totalEvents: 3,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-04T00:00:00Z',
      emailAddress: 'email4@insights.com',
      fullName: 'User Four',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef6',
      jobTitle: 'ADMIN',
      leadingColourEnergy: 'EarthGreen',
      totalEvents: 4,
      enabled: true,
      roles: ['SUPER_ADMIN'],
    },
    {
      createdAt: '1970-01-05T00:00:00Z',
      emailAddress: 'email5@insights.com',
      fullName: 'User Five',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef7',
      jobTitle: 'ADMIN',
      totalEvents: 5,
      enabled: true,
      roles: ['STANDARD', 'SUPER_ADMIN'],
    },
    {
      createdAt: '1970-01-06T00:00:00Z',
      emailAddress: 'email6@insights.com',
      fullName: 'User Six',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef8',
      jobTitle: 'INSIGHTS_ADMIN',
      totalEvents: 6,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-07T00:00:00Z',
      emailAddress: 'email7@insights.com',
      fullName: 'User Seven',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef9',
      jobTitle: 'INSIGHTS_ADMIN',
      totalEvents: 7,
      enabled: true,
      roles: ['SUPER_ADMIN'],
    },
    {
      createdAt: '1970-01-08T00:00:00Z',
      emailAddress: 'email8@insights.com',
      fullName: 'User Eight',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef0',
      jobTitle: 'LICENSED_PRACTITIONER',
      totalEvents: 8,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-09T00:00:00Z',
      emailAddress: 'email9@insights.com',
      fullName: 'User Nine',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef1',
      jobTitle: 'LICENSED_PRACTITIONER',
      totalEvents: 9,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-10T00:00:00Z',
      emailAddress: 'email10@insights.com',
      fullName: 'User Ten',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef2',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 10,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-11T00:00:00Z',
      emailAddress: 'email11@insights.com',
      fullName: 'User Eleven',
      id: 'f72b43d7-8f91-4223-ad60-526fa273bef3',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 11,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-12T00:00:00Z',
      emailAddress: 'email12@insights.com',
      fullName: 'User Twelve',
      id: 'f02b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 12,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-13T00:00:00Z',
      emailAddress: 'email13@insights.com',
      fullName: 'User Thirteen',
      id: 'f12b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 13,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-14T00:00:00Z',
      emailAddress: 'email14@insights.com',
      fullName: 'User Fourteen',
      id: 'f22b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      totalEvents: 14,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-15T00:00:00Z',
      emailAddress: 'email15@insights.com',
      fullName: 'User Fifteen',
      id: 'f32b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      totalEvents: 15,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-16T00:00:00Z',
      emailAddress: 'email16@insights.com',
      fullName: 'User Sixteen',
      id: 'f42b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 16,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-17T00:00:00Z',
      emailAddress: 'email17@insights.com',
      fullName: 'User Seventeen',
      id: 'f52b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 17,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-18T00:00:00Z',
      emailAddress: 'email18@insights.com',
      fullName: 'User Eighteen',
      id: 'f62b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 18,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-19T00:00:00Z',
      emailAddress: 'email19@insights.com',
      fullName: 'User Nineteen',
      id: 'f732b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 19,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-20T00:00:00Z',
      emailAddress: 'email20@insights.com',
      fullName: 'User Twenty',
      id: 'f74b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'CLIENT_PRACTITIONER',
      totalEvents: 20,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-21T00:00:00Z',
      emailAddress: 'email21@insights.com',
      fullName: 'User Twenty One',
      id: 'f75b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      totalEvents: 21,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-22T00:00:00Z',
      emailAddress: 'email22@insights.com',
      fullName: 'User Twenty Two',
      id: 'f76b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      leadingColourEnergy: 'EarthGreen',
      totalEvents: 22,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-23T00:00:00Z',
      emailAddress: 'email23@insights.com',
      fullName: 'User Twenty Three',
      id: 'f77b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      totalEvents: 23,
      enabled: true,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-24T00:00:00Z',
      emailAddress: 'email24@insights.com',
      fullName: 'User Twenty Four',
      id: 'f78b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'LICENSED_PRACTITIONER',
      leadingColourEnergy: 'SunshineYellow',
      totalEvents: 24,
      enabled: false,
      roles: ['STANDARD'],
    },
    {
      createdAt: '1970-01-25T00:00:00Z',
      emailAddress: 'email25@insights.com',
      fullName: 'User Twenty Five',
      id: 'f79b43d7-8f91-4223-ad60-526fa273bef4',
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'FieryRed',
      totalEvents: 25,
      enabled: true,
      roles: ['SUPER_ADMIN'],
    },
  ],
};

export const AdvancedUserPractitioner: PractitionerData = {
  fullName: 'Test User',
  emailAddress: 'test@email.com',
  id: 'test-id',
  leadingColourEnergy: 'CoolBlue',
  licensedProducts: [],
  jobTitle: 'CLIENT_PRACTITIONER',
  roles: ['STANDARD', 'ADVANCED_USER'],
  enabled: true,
};

export const GroupManagerPractitioner: PractitionerData = {
  fullName: 'Test User',
  emailAddress: 'test@email.com',
  id: 'test-id',
  leadingColourEnergy: 'CoolBlue',
  licensedProducts: [],
  jobTitle: 'CLIENT_PRACTITIONER',
  roles: ['STANDARD', 'ADVANCED_USER', 'GROUP_MANAGER'],
  enabled: true,
};

export const generateMockPractitioner = (
  size = 2,
  roles = ['STANDARD'] as Role[],
  startIndex = 0,
) => {
  const practitioners: PractitionerSummaryData[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startIndex; i < size + startIndex; i++) {
    practitioners.push({
      createdAt: '1970-01-01T00:00:00Z',
      emailAddress: `email${i}@insights.com`,
      fullName: `Test User ${i}`,
      id: `test-id-${i}`,
      jobTitle: 'INSIGHTS_ADMIN',
      leadingColourEnergy: 'FieryRed',
      totalEvents: 1,
      enabled: true,
      roles,
    });
  }
  return practitioners;
};
