import React, { PropsWithChildren } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { chapterDescriptionMap } from './chapterDescriptions';

export type Props = PropsWithChildren<{
  chapterId: string;
  units: number;
  title: string;
  mandatory: boolean;
  selected: boolean;
  inactive?: boolean;
}>;

const ChapterCard = ({
  chapterId,
  units,
  title,
  inactive,
  mandatory,
  selected,
  children,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: `0 ${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
          spacingSizeMap.S,
        )} ${theme.spacing(spacingSizeMap.XS)}`,
        borderColor: selected ? theme.palette.primary.dark : 'undefined',
        opacity: inactive ? '0.5' : undefined,
      })}
    >
      <CardContent>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
          <P variant="h3">{title}</P>
        </Box>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
          <P variant="body-bold" color="textSecondary">
            {t('ui.event-management.events.assign-chapters.units-per-learner', {
              count: units,
            })}
          </P>
        </Box>
        <P color="textSecondary">{t(chapterDescriptionMap[chapterId])}</P>
      </CardContent>
      <CardActions
        sx={{
          flexDirection: 'column',
          flexGrow: '2',
          justifyContent: 'flex-end',
          '> :not(:first-of-type)': {
            margin: '0',
          },
        }}
      >
        {mandatory ? (
          <Button variant="contained" color="primary" fullWidth disabled>
            {t(
              'ui.event-management.events.assign-chapters.assigned-by-default',
            )}
          </Button>
        ) : (
          children
        )}
      </CardActions>
    </Card>
  );
};

export default ChapterCard;
