import React, { useState } from 'react';
import {
  Dialog,
  InputText,
  InputType,
  P,
  RadioGroupControl,
  Text,
} from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { NUMERICAL_ONLY_REGEX } from 'variables';

export type Props = {
  open: boolean;
  profileCost: number;
  availableUnits: number;
  recoverableUnits: number;
  doTransferUnits: (amount: number, isAdding: boolean) => void;
  onClose: () => void;
};

type TransferUnitsData = {
  amount: number;
};

const AlertWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.primary.light,
  borderRadius: '4px',
}));

const DetailGridP = styled(P)({
  textAlign: 'right',
  overflowWrap: 'anywhere',
});

const UnitDetails = ({
  title,
  value,
  testId,
  margin = 0,
}: {
  title: string;
  value: string;
  testId: string;
  margin?: number;
}) => {
  return (
    <Grid item container sx={{ marginTop: margin, marginBottom: margin }}>
      <Grid item xs={9}>
        <Text variant="body-bold" color="textSecondary">
          {title}
        </Text>
      </Grid>
      <Grid item xs={3}>
        <DetailGridP variant="body-bold" data-testid={testId}>
          {value}
        </DetailGridP>
      </Grid>
    </Grid>
  );
};

const TransferUnitsDialog = ({
  open,
  onClose,
  profileCost,
  availableUnits,
  recoverableUnits,
  doTransferUnits,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TransferUnitsData>({
    defaultValues: {
      amount: 1,
    },
  });
  const [transationType, setTransactionType] = useState<string>('ADD_UNITS');
  const isAdding = transationType === 'ADD_UNITS';
  const [amount] = watch(['amount']);

  const transferUnits = async () => {
    doTransferUnits(amount, isAdding);
    onClose();
    reset();
    setTransactionType('ADD_UNITS');
  };

  const handleClose = () => {
    onClose();
    reset();
    setTransactionType('ADD_UNITS');
  };

  const errorMessages: Record<string, string> = {
    required: isAdding
      ? t('ui.event-management.events.transfer-units-dialog.add-validation.min')
      : t(
          'ui.event-management.events.transfer-units-dialog.recover-validation.min',
        ),
    pattern: t(
      'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
    ),
    min: isAdding
      ? t('ui.event-management.events.transfer-units-dialog.add-validation.min')
      : t(
          'ui.event-management.events.transfer-units-dialog.recover-validation.min',
        ),
    max: isAdding
      ? t('ui.event-management.events.transfer-units-dialog.add-validation.max')
      : t(
          'ui.event-management.events.transfer-units-dialog.recover-validation.max',
        ),
  };
  const helperText = errors.amount
    ? errorMessages[errors.amount.type]
    : undefined;
  const unitCost = `${profileCost} ${t(
    'ui.event-management.events.unit-management.value.units',
  )}`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit(transferUnits)}
      title={t('ui.event-management.events.transfer-units-dialog.title')}
      content={
        <>
          {recoverableUnits > 0 && (
            <>
              <P>
                {t('ui.event-management.events.transfer-units-dialog.options')}
              </P>
              <RadioGroupControl
                options={[
                  {
                    value: 'ADD_UNITS',
                    label: t(
                      'ui.event-management.events.transfer-units-dialog.add',
                    ),
                  },
                  {
                    value: 'RECOVER_UNITS',
                    label: t(
                      'ui.event-management.events.transfer-units-dialog.recover',
                    ),
                  },
                ]}
                name="transationType"
                title={t(
                  'ui.event-management.events.transfer-units-dialog.options',
                )}
                value={transationType}
                onChange={setTransactionType}
              />
            </>
          )}
          <Box sx={{ height: spacingSizeMap.L }} />
          <P variant="body">
            {isAdding
              ? t('ui.event-management.events.transfer-units-dialog.body')
              : t(
                  'ui.event-management.events.transfer-units-dialog.body.recover',
                )}
          </P>
          <UnitDetails
            testId="profile-cost"
            title={
              isAdding
                ? t('ui.event-management.events.transfer-units-dialog.cost')
                : t(
                    'ui.event-management.events.transfer-units-dialog.recoverable',
                  )
            }
            value={isAdding ? unitCost : String(recoverableUnits)}
            margin={spacingSizeMap.S}
          />
          <InputText
            id="request-units-amount"
            required
            type={InputType.NUMBER}
            fullWidth
            {...register('amount', {
              required: true,
              min: 1,
              max: isAdding ? availableUnits : recoverableUnits,
              pattern: NUMERICAL_ONLY_REGEX, // regex to only allow numerical inputs
            })}
            min={1}
            max={isAdding ? availableUnits : recoverableUnits}
            label={
              isAdding
                ? t(
                    'ui.event-management.events.transfer-units-dialog.units.label',
                  )
                : t(
                    'ui.event-management.events.transfer-units-dialog.units.label.recover',
                  )
            }
            error={Boolean(errors.amount)}
            helperText={helperText}
          />
          <Box sx={{ marginBottom: spacingSizeMap.S }} />
          <AlertWrapper>
            <UnitDetails
              testId="current-balance"
              title={t(
                'ui.event-management.events.transfer-units-dialog.balance',
              )}
              value={String(availableUnits)}
            />
            <UnitDetails
              testId="final-balance"
              title={t(
                'ui.event-management.events.transfer-units-dialog.after',
              )}
              value={
                isAdding
                  ? String(availableUnits - Number(amount))
                  : String(availableUnits + Number(amount))
              }
            />
          </AlertWrapper>
        </>
      }
      primaryButtonText={
        isAdding
          ? t('ui.event-management.events.transfer-units-dialog.confirm')
          : t(
              'ui.event-management.events.transfer-units-dialog.confirm.recover',
            )
      }
      secondaryButtonText={t(
        'ui.event-management.events.transfer-units-dialog.cancel',
      )}
    />
  );
};

export default TransferUnitsDialog;
