import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library';
import { LinearProgress, styled } from '@mui/material';
import { User } from 'types/types';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import useBulkUpload from './useBulkUpload';

type Props = {
  organisationId: string;
  onBulkAddLearners: (learners: User[]) => void;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
  marginBottom: theme.spacing(spacingSizeMap.L),
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.XS),
  },
}));

const BulkUploadTeam = ({ organisationId, onBulkAddLearners }: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const hook = useBulkUpload({
    onBulkAddLearners,
    organisationId,
  });

  return (
    <Box
      mt={(theme) => theme.spacing(spacingSizeMap.M)}
      mb={(theme) => theme.spacing(spacingSizeMap.L)}
      data-testid="upload-bulk-learners"
    >
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
      <Grid container spacing={2} direction="column">
        <Grid sx={{ flexBasis: 'auto' }} item xs={12} md={8}>
          <P>{t('ui.event-management.team.bulk-upload.guidance')}</P>
        </Grid>
        <Grid sx={{ flexBasis: 'auto' }} item xs={12} md={4}>
          <input
            id="bulk-upload"
            ref={inputRef}
            data-testid="bulk-upload"
            name={t('ui.event-management.events.add-learners.tab.upload')}
            style={{ display: 'none' }}
            type="file"
            onChange={(evt) => hook.handleSelectFile(evt.target)}
            accept=".csv"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="bulk-upload">
            <TextField
              value={hook.fileName}
              variant="outlined"
              disabled
              type="text"
              fullWidth
              placeholder={t(
                'ui.event-management.events.add-learners.placeholder',
              )}
              InputProps={{
                sx: {
                  adornedStart: (theme) => ({
                    paddingLeft: theme.spacing(spacingSizeMap.XS),
                  }),
                },
                startAdornment: (
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    sx={(theme) => ({
                      marginRight: theme.spacing(spacingSizeMap.XS),
                    })}
                  >
                    {t('ui.event-management.events.add-learners.upload-file')}
                  </Button>
                ),
              }}
            />
          </label>
        </Grid>
      </Grid>
      {hook.progress > 0 && (
        <LinearProgress variant="determinate" value={hook.progress} />
      )}
      <StyledDiv>
        {hook.errorMessage && (
          <Alert severity="error" icon={false}>
            <P variant="body-bold">{hook.errorMessage}</P>
          </Alert>
        )}
        {hook.failedList.length > 0 && (
          <Alert severity="error" icon={false}>
            <P variant="body-bold">
              {t('ui.event-management.team.bulk-upload.error', {
                count: hook.failedList.length,
              })}
            </P>
            <ul>
              {hook.failedList.map((email) => (
                <li key={email}>{email}</li>
              ))}
            </ul>
          </Alert>
        )}
      </StyledDiv>
    </Box>
  );
};
export default BulkUploadTeam;
