import { ProfileType } from '@insights-ltd/design-library';
import { FilteredLearnerResponse, LearnerAndInviteeResponse } from 'api';
import { ProfilesResponse } from 'api/organisations/organisationApitypes';
import { LearnersHttpResponse } from 'test/types';
import { Dialects } from 'types/dialects';
import { TupleOfLength } from 'types/types';
import { DIALECT_CODES } from 'variables';

export const profilesResponse: ProfilesResponse = {
  profiles: [
    {
      id: 'unique-profile-id-1',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'HE',
      dialect: 'en-GB',
      colourScores: {
        conscious: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
        lessConscious: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
        opposite: {
          blue: 10,
          green: 9,
          red: 8,
          yellow: 7,
        },
      },
      type: 'DISCOVERY_PERSONAL_PROFILE',
      supportedDialects: Object.values(DIALECT_CODES) as Dialects[],
    },
    {
      id: 'unique-profile-id-2',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'THEY',
      dialect: 'en-GB',
      colourScores: {
        lessConscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        conscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        opposite: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
      },
      type: 'DISCOVERY_PERSONAL_PROFILE',
      supportedDialects: Object.values(DIALECT_CODES) as Dialects[],
    },
    {
      id: 'unique-profile-id-4',
      createdOn: '2021-02-25T16:25:55.497081',
      evaluationSubmittedOn: '2021-02-25T16:25:55.497081',
      pronoun: 'THEY',
      dialect: 'en-GB',
      colourScores: {
        lessConscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        conscious: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
        opposite: {
          blue: 7,
          green: 8,
          red: 9,
          yellow: 10,
        },
      },
      type: 'EXPLORE_PROFILE',
      supportedDialects: Object.values(DIALECT_CODES) as Dialects[],
    },
  ],
};

export const LearnerResponse = {
  id: 'learner-id-1',
  fullName: 'Patty Bouvier',
  knownAs: 'Patty',
  primaryEmail: 'patty@example.com',
  latestEvaluation: {
    id: '660b8417-3461-4ea7-9bfc-6900a8d2ef49',
    submittedOn: '2022-04-12T07:44:05.511009Z',
    latestProfile: null,
    latestProfiles: [
      {
        id: 'fc9253a5-b4c8-4486-9e13-5802739c5daa',
        createdAt: '2022-04-27T16:29:12.460234Z',
        chapters: ['FOUNDATION', 'MANAGEMENT'],
        profileType: 'DISCOVERY_PROFILE',
      },
    ],
  },
};

export const LearnersSearchResponse: LearnersHttpResponse = {
  learners: [
    {
      id: 'dec1bfa0-c84a-4def-8efb-2d15f4a1ec6d',
      fullName: 'Patty Bouvier',
      knownAs: 'Patty',
      primaryEmail: 'patty@example.com',
      organisation: {
        id: 'insights',
        name: 'Insights',
        supportedRoles: [],
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-id-2',
      fullName: 'Homer Simpson',
      knownAs: 'Homer',
      primaryEmail: 'homer@gmail.com',
      organisation: {
        id: 'insights',
        name: 'Insights',
        supportedRoles: [],
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
  ],
};

export const filteredLearners: {
  limitExceeded: boolean;
  learners: TupleOfLength<3, LearnerAndInviteeResponse>;
} = {
  learners: [
    {
      id: 'some-id-1',
      fullName: 'name - Stu Kennedy',
      knownAs: 'Stu',
      primaryEmail: 'stu@stukennedy.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      leadingColourEnergy: 'EarthGreen',
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'some-id-2',
      fullName: 'name - Bob Fish',
      knownAs: 'Bob',
      primaryEmail: 'bob@fish.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      preferredDialect: 'en-GB',
      leadingColourEnergy: 'EarthGreen',
    },
    {
      id: 'some-id-3',
      fullName: "name - Jenny Wren's $pecial Characters",
      knownAs: 'Jen',
      primaryEmail: 'jen@wren.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
  ],
  limitExceeded: false,
};

export const orgLearners: FilteredLearnerResponse = {
  learners: [
    {
      id: 'learner-1',
      fullName: 'Learner 1',
      knownAs: 'L1',
      primaryEmail: 'learner1@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      leadingColourEnergy: 'EarthGreen',
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-2',
      fullName: 'Learner 2',
      knownAs: 'L2',
      primaryEmail: 'learner2@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      leadingColourEnergy: 'EarthGreen',
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
    {
      id: 'learner-3',
      fullName: 'Learner 3',
      knownAs: 'L3',
      primaryEmail: 'learner3@insights.com',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      leadingColourEnergy: 'EarthGreen',
      preferredDialect: 'en-GB',
      latestEvaluation: {
        id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
      latestProfile: {
        id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
        createdAt: '2023-05-26T17:20:34.57332Z',
        profileType: 'DISCOVERY_PERSONAL_PROFILE',
        evaluation: {
          id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
          submittedOn: '2023-05-26T17:20:34.57332Z',
        },
      },
    },
  ],
  limitExceeded: false,
};

const profileTypes: ProfileType[] = [
  'DFC_PROFILE',
  'DISCOVERY_PERSONAL_PROFILE',
  'DISCOVERY_PROFILE',
  'IDTL_PROFILE',
  'EXPLORE_PROFILE',
];

const getProfileTypeByNumber = (itemNumber: number): ProfileType => {
  const index = itemNumber % profileTypes.length;
  return profileTypes[index];
};

export const createMockLearner = (
  number: number,
  profileType: ProfileType | 'ALL' = 'DISCOVERY_PERSONAL_PROFILE',
): LearnerAndInviteeResponse => {
  return {
    id: `some-id-${number}`,
    fullName: `name - ${number}`,
    knownAs: `${number}`,
    primaryEmail: `${number}@email.com`,
    organisation: {
      id: `org-${number}-id`,
      name: `Org ${number}`,
      supportedRoles: ['STANDARD', 'SUPER_ADMIN'],
    },
    preferredDialect: 'en-GB',
    latestEvaluation: {
      id: `evaluation-for-${number}`,
      submittedOn: '2023-05-26T17:20:34.57332Z',
    },
    latestProfile: {
      id: `profile-for-${number}`,
      createdAt: '2023-05-26T17:20:34.57332Z',
      profileType:
        profileType === 'ALL' ? getProfileTypeByNumber(number) : profileType,
      evaluation: {
        id: `evaluation-for-${number}`,
        submittedOn: '2023-05-26T17:20:34.57332Z',
      },
    },
  };
};

export const createMockLearners = (
  numberOfLearners: number,
  profileType: ProfileType | 'ALL' = 'DISCOVERY_PERSONAL_PROFILE',
): LearnerAndInviteeResponse[] =>
  Array.from(Array(numberOfLearners).keys()).map((_, index) =>
    createMockLearner(index, profileType),
  );

export const lotsOfLearners: LearnerAndInviteeResponse[] = createMockLearners(
  100,
  'DISCOVERY_PERSONAL_PROFILE',
);

export const maxLearners: LearnerAndInviteeResponse[] = createMockLearners(
  300,
  'DISCOVERY_PERSONAL_PROFILE',
);

export const exampleLearner: LearnerAndInviteeResponse = {
  id: 'learner@insights.com',
  fullName: 'Learner One',
  knownAs: 'L1',
  primaryEmail: 'learner@insights.com',
  organisation: {
    id: 'insights',
    name: 'Insights',
    supportedRoles: ['STANDARD', 'SUPER_ADMIN'],
  },
  preferredDialect: 'en-GB',
  latestEvaluation: {
    id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
    submittedOn: '2023-05-26T17:20:34.57332Z',
  },
  latestProfile: {
    id: 'd8d88c01-3c25-4344-b01c-6068735aa0ff',
    createdAt: '2023-05-26T17:20:34.57332Z',
    profileType: 'DISCOVERY_PERSONAL_PROFILE',
    evaluation: {
      id: '8215b47f-3e5d-4c6a-b47e-481fe8c2439c',
      submittedOn: '2023-05-26T17:20:34.57332Z',
    },
  },
};
