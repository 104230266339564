import { authenticatedFetch } from 'api/fetch';
import {
  EvaluatorLink,
  GetCompletedEvaluationResponse,
} from 'api/httpEntities';
import { cleanObject } from 'api/utils';
import { EvaluatorSearchFilter } from './evaluatorLinksApiTypes';

const baseUrl = '/api/v1/evaluator-links';

export const deleteEvaluatorLink = async (id: string): Promise<void> => {
  const url = `${baseUrl}/${id}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });

  await json();
};

export const getEvaluatorLink = async (id: string) => {
  const { json } = await authenticatedFetch<EvaluatorLink>(`${baseUrl}/${id}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return json();
};

export const getCompletedEvaluators = async (
  id: string,
  searchTerm: string,
  purchaseStatus: EvaluatorSearchFilter,
) => {
  const queries = new URLSearchParams(
    cleanObject({ searchTerm, limit: '300', purchaseStatus }),
  );
  const url = `${baseUrl}/${id}/evaluations?${queries.toString()}`;
  const { json } = await authenticatedFetch<GetCompletedEvaluationResponse>(
    url,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  return json();
};
