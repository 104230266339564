import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Snackbar,
  styled,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
import { dateInPast, H1, H2 } from '@insights-ltd/design-library';
import { queryStatus } from 'utils/queryStatus';
import {
  ChapterStatus,
  InviteeResponse,
  useEventInvitees,
  useGetEvent,
  useUpdateInviteeChapters,
} from 'api';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import InviteeCard from 'components/InviteeCard';
import { getChapterKey } from 'utils/getChapterKey';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import { useQueryClient } from '@tanstack/react-query';
import SummaryCard, { ChaptersSummary } from 'components/SummaryCard';
import { experienceSupportsChapters } from 'domain/event';
import { readyToPurchase } from 'domain/invitee';
import { InviteeListOptions, LicensedProduct } from 'types/types';
import { CHAPTERS } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { sortInviteesAlphabetically } from 'utils/mappers/sorting';
import BackButton from 'components/BackButton/BackButton';
import { backButtonStyles } from 'components/layout/BreadcrumbLayout/BreadcrumbLayout';
import SaveSelectionBar from './SaveSelectionBar';
import ChapterCard from './ChapterCard';

const chaptersForProduct = (productType: LicensedProduct) =>
  CHAPTERS.filter((chapter) => chapter.productTypes.includes(productType));

type InviteesForChapter = Record<string, boolean>;

const StyledFullHeight = styled(FullHeight, {
  shouldForwardProp: (prop) => prop !== 'editMode',
})<{ editMode?: boolean }>(({ editMode = false }) => ({
  paddingBottom: editMode ? '0' : 'inherit',
}));

const StyledAssignedSummaryCard = styled(SummaryCard)(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.XS),
  background: theme.palette.primary.light,
}));

const StyledPurchasedSummaryCard = styled(SummaryCard)(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.L),
  background: theme.palette.grey[300],
}));

const summaryData = (
  chapterStatus: ChapterStatus,
  invitees: InviteeResponse[],
  chapterCosts: Record<string, number>,
): ChaptersSummary => {
  const chapters = invitees
    .map((invitee) => {
      return invitee.chapters?.reduce((arr, obj) => {
        if (obj.status === chapterStatus) arr.push(obj.name);
        return arr;
      }, [] as string[]);
    })
    .flat();

  const chapterSet = new Set(chapters);
  const arrSummary: ChaptersSummary = [];
  chapterSet.forEach((x) => {
    const chapterCount = chapters.filter((name) => name === x).length;
    arrSummary.push({
      chapterName: x,
      count: chapterCount,
      price: chapterCosts[x] * chapterCount,
    });
  });

  return arrSummary;
};

const toRequestData = (
  chapter: string,
  invitees: InviteeResponse[],
  inviteesForChapter: InviteesForChapter,
) =>
  invitees
    .map(({ id, chapters }) => ({
      inviteeId: id,
      chapters,
    }))
    .map(({ inviteeId, chapters }) => {
      const chapterNames = chapters.map(({ name }) => {
        return name;
      });
      const chapterSet = new Set(chapterNames);

      if (inviteesForChapter[inviteeId]) {
        chapterSet.add(chapter);
      } else {
        chapterSet.delete(chapter);
      }
      return { inviteeId, chapters: Array.from(chapterSet) };
    });

const AssignChapters = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState('');
  const { status: eventStatus, data: event } = useGetEvent(eventId || '');
  const { status: inviteesStatus, data: invitees } = useEventInvitees(
    eventId || '',
  );
  const {
    mutate: updateChapters,
    status: updateChaptersStatus,
    reset,
  } = useUpdateInviteeChapters(queryClient);
  const responseStatus = queryStatus(eventStatus, inviteesStatus);
  const chapterCosts: Record<string, number> =
    event?.chapters.reduce((costs, { name, cost }) => {
      return {
        ...costs,
        [name]: cost,
      };
    }, {}) ?? ({} as Record<string, number>);
  const [selectedChapter, setSelectedChapter] = useState('');
  const [purchasedChapters, setPurchasedChapters] = useState<
    Record<string, boolean>
  >({});
  const [inviteesForChapter, setInviteesForChapter] =
    useState<InviteesForChapter>({});
  const setInviteeForSelectedChapter = (id: string, checked: boolean) =>
    setInviteesForChapter((prev) => ({ ...prev, [id]: checked }));
  const cancelSelection = () => {
    setSelectedChapter('');
    setInviteesForChapter({});
  };

  const learnerContainer = useRef<any>(null);

  useEffect(() => {
    if (invitees) {
      const numberOfInvitees = invitees.length;
      const chapterCount: Record<string, number> = invitees.reduce(
        (prevCount: Record<string, number>, invitee) => {
          const count = prevCount;
          invitee.chapters.forEach(({ name, status }) => {
            if (status === 'PURCHASED') {
              count[name] += 1;
            }
          });
          return count;
        },
        {},
      );

      const fullyPurchasedChapters: Record<string, boolean> = Object.keys(
        chapterCount,
      ).reduce((chaptersPurchased, key) => {
        return {
          ...chaptersPurchased,
          [key]: chapterCount[key] === numberOfInvitees,
        };
      }, {});

      setPurchasedChapters(fullyPurchasedChapters);
    }
  }, [invitees]);

  useEffect(() => {
    if (selectedChapter !== '') {
      invitees?.forEach((invitee) => {
        const inviteeChapterStatus = invitee.chapters.find(
          ({ name }) => name === selectedChapter,
        );
        if (inviteeChapterStatus && inviteeChapterStatus.status === 'PENDING') {
          setInviteeForSelectedChapter(invitee.id, true);
        }
      });

      if (learnerContainer.current?.scrollIntoView) {
        learnerContainer.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedChapter, invitees]);

  if (responseStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (responseStatus === 'error') {
    return (
      <FullScreenError
        message={t(
          'ui.event-management.events.assign-chapters.error-loading-chapter',
        )}
      />
    );
  }

  if (
    dateInPast(event!.startsAt, event!.timezone) ||
    !experienceSupportsChapters(event!.eventType)
  ) {
    return <Navigate to={`/experiences/${event!.id}`} />;
  }

  const filteredInvitees = (
    searchText === ''
      ? invitees!
      : invitees!.filter(
          (invitee) =>
            invitee.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
            invitee.email.toLowerCase().includes(searchText.toLowerCase()),
        )
  )
    .map((invitee) => {
      if (
        readyToPurchase(invitee) &&
        (invitee.profileStatus === 'REUSED' ||
          invitee.profileStatus === 'PURCHASED')
      ) {
        return {
          ...invitee,
          status: 'PROFILE_GENERATED' as InviteeListOptions,
        };
      }
      return invitee;
    })
    .sort(sortInviteesAlphabetically);

  const purchasedList = invitees
    ? summaryData('PURCHASED', invitees, chapterCosts)
    : [];
  const assignedList = invitees
    ? summaryData('PENDING', invitees, chapterCosts)
    : [];
  const totalPrice = assignedList.reduce((sum, { price }) => sum + price, 0);

  const saveChapterSelection = async () => {
    const inviteeChapters = toRequestData(
      selectedChapter,
      invitees!,
      inviteesForChapter,
    );
    try {
      await updateChapters({ eventId: eventId || '', inviteeChapters });
      setSelectedChapter('');
      setInviteesForChapter({});
    } catch {
      /* Nothing needed as state is captured in status */
    }
  };

  const selectAllChapters = () =>
    setInviteesForChapter(
      invitees!.reduce((acc, invitee) => ({ ...acc, [invitee.id]: true }), {}),
    );

  const updatingChapters = updateChaptersStatus === 'pending';
  const editMode = Boolean(selectedChapter);

  const inviteeSelectedCount = Object.values(inviteesForChapter).filter(
    (selected) => selected,
  ).length;

  const parentLink = `/experiences/${eventId}`;

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.assign-chapters')}</title>
      </Helmet>
      <StyledFullHeight backgroundColor="white">
        <Box
          sx={{
            height: 'calc(100% - 100px)',
            '@supports ( -moz-appearance:none )': {
              height: 'calc(100% - 72px)',
            },
            '@supports (background: -webkit-canvas(safari))': {
              height: 'calc(100% - 72px)',
            },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              marginBottom: editMode ? '0' : '5rem',
            }}
          >
            <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
              <Breadcrumbs
                crumbs={{
                  '/': t('ui.event-management.events.nav.home'),
                  '/experiences': t('ui.event-management.events.title'),
                  [`/experiences/${eventId}`]: t(event!.name),
                }}
                activeText={t(
                  'ui.event-management.events.summary.assign-chapters',
                )}
              />
            </Box>
            <BackButton
              parentLink={parentLink}
              i18nKey="ui.event-management.events.add-learners-and-contributors.learner.back-to-experience-overview"
              sx={backButtonStyles}
            />
            <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
              <H1 variant="h2">
                {t('ui.event-management.events.assign-chapters')}
              </H1>
            </Box>
            <Grid
              container
              spacing={2}
              sx={(theme) => ({
                marginBottom: theme.spacing(spacingSizeMap.XL),
              })}
            >
              {assignedList.length > 0 && (
                <Grid data-testid="assignedSummaryCard" item xs={12} md={4}>
                  <StyledAssignedSummaryCard
                    chapterSummary={assignedList}
                    title={t(
                      'ui.event-management.events.assign-chapters.assigned',
                    )}
                  />
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: theme.spacing(spacingSizeMap.M),
                      color: theme.palette.grey[700],
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    <span>
                      {t(
                        'ui.event-management.events.assign-chapters.summary-box.total-spend',
                      )}
                    </span>
                    <span data-testid="totalPrice">
                      {totalPrice}&nbsp;
                      {t(
                        'ui.event-management.events.assign-chapters.summary-box.units',
                      )}
                    </span>
                  </Box>
                </Grid>
              )}
              {purchasedList.length > 0 && (
                <Grid data-testid="assignedSummaryBox" item xs={12} md={4}>
                  <StyledPurchasedSummaryCard
                    chapterSummary={purchasedList}
                    title={t(
                      'ui.event-management.events.assign-chapters.purchased',
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              sx={(theme) => ({
                paddingBottom: theme.spacing(spacingSizeMap.L),
              })}
              container
            >
              <Box
                mb={(theme) => theme.spacing(spacingSizeMap.S)}
                mt={(theme) => theme.spacing(spacingSizeMap.S)}
              >
                <H2 variant="h3">
                  {t('ui.event-management.events.assign-chapters.header')}
                </H2>
              </Box>
              <Box
                mb={(theme) => theme.spacing(spacingSizeMap.S)}
                mt={(theme) => theme.spacing(spacingSizeMap.S)}
                sx={{ width: '100%' }}
              >
                <Grid container spacing={2}>
                  {chaptersForProduct(event!.productType).map(
                    ({ id, titleKey, mandatory }) => {
                      const inactive =
                        selectedChapter !== '' && id !== selectedChapter;
                      const count = invitees?.filter(
                        (invitee) =>
                          invitee.chapters
                            ?.map((chapter) => chapter.name)
                            .includes(id),
                      ).length;
                      return (
                        <Grid key={titleKey} item xs={12} md={4}>
                          <ChapterCard
                            chapterId={id}
                            units={
                              event!.chapters.find(
                                (chapter) => chapter.name === id,
                              )!.cost
                            }
                            title={t(titleKey)}
                            mandatory={mandatory}
                            selected={id === selectedChapter}
                            inactive={inactive}
                          >
                            {count &&
                            (selectedChapter === '' ||
                              id === selectedChapter) ? (
                              <Paper
                                sx={(theme) => ({
                                  width: '100%',
                                  margin: `${theme.spacing(
                                    spacingSizeMap.S,
                                  )} 0`,
                                  padding: theme.spacing(spacingSizeMap.S),
                                  backgroundColor: theme.palette.primary.light,
                                })}
                                elevation={0}
                                color="primary"
                              >
                                {t(
                                  'ui.event-management.events.assign-chapters.assigned-count',
                                  { count },
                                )}
                              </Paper>
                            ) : (
                              ''
                            )}
                            {id === selectedChapter ? (
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={saveChapterSelection}
                                disabled={updatingChapters}
                                endIcon={
                                  updatingChapters && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                      }}
                                    />
                                  )
                                }
                              >
                                {t(
                                  'ui.event-management.events.assign-chapters.save-selection',
                                )}
                              </Button>
                            ) : (
                              !purchasedChapters[id] && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  disabled={inactive}
                                  onClick={() => setSelectedChapter(id)}
                                >
                                  {t(
                                    'ui.event-management.events.assign-chapters.assign-to-learners',
                                  )}
                                </Button>
                              )
                            )}
                          </ChapterCard>
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              </Box>
            </Grid>
            <Box
              mb={(theme) => theme.spacing(spacingSizeMap.S)}
              mt={(theme) => theme.spacing(spacingSizeMap.S)}
            >
              <H2 variant="h3">
                {selectedChapter === ''
                  ? t('ui.event-management.events.assign-chapters.learners', {
                      count: filteredInvitees.length,
                    })
                  : t(
                      'ui.event-management.events.assign-chapters.assign-to-invitees',
                      {
                        chapter: t(getChapterKey(selectedChapter)),
                        count: inviteeSelectedCount,
                      },
                    )}
              </H2>
            </Box>
            <Box
              mb={(theme) => theme.spacing(spacingSizeMap.L)}
              mt={(theme) => theme.spacing(spacingSizeMap.S)}
            >
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{ zIndex: 0 }}
                    variant="outlined"
                    fullWidth
                    label={t(
                      'ui.event-management.events.chapter-learners-list.search.placeholder',
                    )}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {editMode ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={selectAllChapters}
                    >
                      {t(
                        'ui.event-management.events.chapter-learners-list.select-all',
                      )}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
            <Grid ref={learnerContainer} container spacing={2}>
              {filteredInvitees.map((invitee) => {
                const inviteeChapterStatus = invitee.chapters.find(
                  ({ name }) => name === selectedChapter,
                );
                const disabled = inviteeChapterStatus?.status === 'PURCHASED';

                return (
                  <Grid key={invitee.id} item xs={12} md={4}>
                    <InviteeCard
                      {...invitee}
                      chapterStatusToDisplay={['PENDING', 'PURCHASED']}
                      editMode={editMode}
                      checked={inviteesForChapter[invitee.id] ?? false}
                      setChecked={(checked) =>
                        setInviteeForSelectedChapter(invitee.id, checked)
                      }
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
          {editMode ? (
            <SaveSelectionBar
              chapter={t(getChapterKey(selectedChapter))}
              count={inviteeSelectedCount}
              onCancel={cancelSelection}
              onSave={saveChapterSelection}
              updating={updatingChapters}
            />
          ) : null}
        </Box>
        <Snackbar
          open={updateChaptersStatus === 'error'}
          autoHideDuration={6000}
          onClose={reset}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={reset}
            severity="error"
          >
            {t(
              'ui.event-management.events.assign-chapters.update-chapters-error',
            )}
          </Alert>
        </Snackbar>
      </StyledFullHeight>
    </>
  );
};

export default AssignChapters;
