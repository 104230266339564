import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { P } from '@insights-ltd/design-library';
import HeaderLayout from './HeaderLayout';
import { NavBarItem } from './types';

type Props = {
  open: boolean;
  closeDialog: () => void;
  navBarItems: NavBarItem[];
};

const MobileNavBarDialog = ({ open, closeDialog, navBarItems }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} onClose={closeDialog}>
      <HeaderLayout>
        <Grid container justifyContent="flex-end">
          <IconButton
            onClick={closeDialog}
            aria-label={t('ui.event-management.events.close-label')}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </HeaderLayout>
      <List>
        {navBarItems.map(({ text, to }) => (
          <P variant="body-bold" color="primary" key={text}>
            <ListItem
              button
              component={RouterLink}
              to={to}
              onClick={closeDialog}
            >
              {text}
            </ListItem>
          </P>
        ))}
      </List>
    </Dialog>
  );
};

export default MobileNavBarDialog;
