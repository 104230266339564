import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Group,
  RequestError,
  useAddOrganisation,
  useAddOrganisationsToGroup,
  useGetAllGroups,
} from 'api';
import AddOrganisationForm from 'components/OrganisationForm/OrganisationForm';
import { OrganisationData } from 'components/OrganisationForm/types';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useQueryClient } from '@tanstack/react-query';
import SelectGroup from 'components/SelectGroup/SelectGroup';
import { useAuth } from 'contexts/AuthContext';
import { FullScreenError, Text } from '@insights-ltd/design-library';
import PermissionGuard from 'components/PermissionGuard';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import OrganisationRemovalWarning from 'components/SelectGroup/OrganisationRemovalWarning';

const Label = () => (
  <Trans
    i18nKey="ui.event-management.organisations.add.select-group"
    components={{ styles: <Text color="textSecondary" /> }}
  />
);

const AddOrganisation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: addOrganisation, isPending: isSavingOrg } =
    useAddOrganisation(queryClient);
  const { data: allGroups, status: groupStatus } = useGetAllGroups({
    enabled: user?.permissions.Organisation_Group_ReadAll === 'Global',
  });
  const { isPending: isSavingGroup, mutate: addToGroup } =
    useAddOrganisationsToGroup(queryClient);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const { openErrorModal } = useRequestErrorContext();
  const isSaving = isSavingOrg || isSavingGroup;

  const translationKey =
    'ui.event-management.organisations.add.title.create-organisation';

  const onSubmit: any = (formData: OrganisationData, onError: any) =>
    addOrganisation(formData, {
      onSuccess: (organisation) => {
        if (selectedGroup) {
          addToGroup(
            {
              groupId: selectedGroup.id,
              organisations: [organisation],
            },
            {
              onSuccess: () => {
                navigate(`/organisations/${organisation!.id}`);
              },
              onError: (response) => {
                if ((response as RequestError).status >= 500) {
                  openErrorModal();
                }
              },
            },
          );
        } else {
          navigate(`/organisations/${organisation!.id}`);
        }
      },
      onError: (response) => {
        onError(response);
        if ((response as RequestError).status >= 500) {
          openErrorModal();
        }
      },
    });

  if (groupStatus === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.add-organisation"
      activeTextKey={translationKey}
      crumbs={{
        '/dashboard': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
      }}
      headingKey={translationKey}
    >
      <AddOrganisationForm
        organisation={{ name: '' }}
        onSubmit={onSubmit}
        showWarning
      >
        <PermissionGuard
          requiredPermissions={[
            { action: 'Organisation_Group_Update', scope: 'Global' },
          ]}
        >
          <SelectGroup
            selectedGroup={selectedGroup}
            disabled
            options={allGroups ?? []}
            onSelect={(group) => {
              setSelectedGroup(group);
            }}
            selectedLabel={<Label />}
            fieldLabel={<Label />}
          />
          {selectedGroup && (
            <OrganisationRemovalWarning
              selectedGroupName={selectedGroup.name}
            />
          )}
        </PermissionGuard>
        <Grid
          sx={{ marginTop: '2.5rem' }}
          container
          justifyContent="space-between"
        >
          <div />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            endIcon={
              isSaving && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )
            }
          >
            {t('ui.event-management.organisations.add.create-button')}
          </Button>
        </Grid>
      </AddOrganisationForm>
    </BreadcrumbLayout>
  );
};

export default AddOrganisation;
