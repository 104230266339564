import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import {
  InputSelectV2,
  InputText,
  InputType,
} from '@insights-ltd/design-library';
import FormHeader from 'components/FormHeader';
import { PractitionerRequest, RequestError } from 'api';
import { Button, Grid, MenuItem, styled } from '@mui/material';
import useGetProducts from 'components/hooks/useGetProducts';
import {
  COLOUR_ENERGY,
  NO_SPECIAL_CHARACTERS_REGEX,
  VALID_EMAIL_REGEX,
} from 'variables';
import { JungianColours, LicensedProduct, Option, Role } from 'types/types';
import CheckboxGroup from 'components/CheckboxGroup/CheckboxGroup';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';
import {
  isPreferredDialect,
  useGetApplicationPreferredDialects,
} from 'domain/dialect';
import { Dialects, PreferredDialect } from 'types/dialects';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import EditRolePermission from './EditRolePermission';

type EditAccountFormProps = {
  formData: {
    fullName: string;
    emailAddress: string;
    leadingColourEnergy?: JungianColours;
    licensedProducts: LicensedProduct[];
    jobTitle: string;
    roles: Role[];
    preferredDialect?: PreferredDialect | Dialects;
  };
  formHeaderTitle: string;
  submitHandler: (
    data: PractitionerRequest,
    onError: (error: RequestError) => void,
  ) => void;
  emailChangeIsPermitted?: boolean;
  roleChangeIsPermitted?: boolean;
  productChangeIsPermitted?: boolean;
  isSuperAdmin: boolean;
  showProducts: boolean;
  showPreferredDialect?: boolean;
};

const StyledForm = styled('form')(({ theme }) => ({
  '& > *': {
    marginTop: `${theme.spacing(spacingSizeMap.S)}`,
  },
  '& label': {
    color: theme.palette.text.primary,
  },
}));

const StyledDiv = styled('div')(() => ({
  marginBottom: '1rem',
}));

const StyledFormHeader = styled(FormHeader)(({ theme }) => ({
  color: `${theme.palette.green.dark}`,
  backgroundColor: `${theme.palette.green.light}`,
}));

const EditAccountForm = ({
  formData,
  formHeaderTitle,
  submitHandler,
  emailChangeIsPermitted,
  roleChangeIsPermitted,
  productChangeIsPermitted,
  isSuperAdmin,
  showProducts,
  showPreferredDialect = false,
}: EditAccountFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<EditAccountFormProps['formData']>({
    defaultValues: {
      ...formData,
      preferredDialect: formData.preferredDialect ?? 'en-GB',
    },
  });
  const [preferredDialect, leadingColourEnergy] = watch([
    'preferredDialect',
    'leadingColourEnergy',
  ]);
  const { openErrorModal } = useRequestErrorContext();

  const onSubmit = handleSubmit((form: PractitionerRequest) => {
    submitHandler(form, (error: RequestError) => {
      if (error.status === 500) {
        openErrorModal();
      }
    });
  });

  const products = useGetProducts();

  const showRoles = isSuperAdmin || roleChangeIsPermitted;

  const emailHelperText = !emailChangeIsPermitted
    ? ''
    : (errors.emailAddress?.type === 'notUnique' &&
        t('ui.event-management.my-account.edit.error.unique-email-required')) ||
      (errors.emailAddress &&
        t('ui.event-management.my-account.edit.error.value-email-required'));

  const fullNameHelperText =
    errors.fullName?.type === 'required'
      ? t('ui.event-management.my-account.edit.error.value-full-name-required')
      : errors.fullName?.type === 'pattern' &&
        t(
          'ui.event-management.my-account.edit.error.value-full-name-no-special-characters',
        );

  const { ref: languageRef, ...languageFormProps } = register(
    'preferredDialect',
    {
      required: true,
      pattern: NO_SPECIAL_CHARACTERS_REGEX,
    },
  );

  const { ref: colourEnergyRef, ...colourEnergyFormProps } = register(
    'leadingColourEnergy',
  );

  const items = useGetApplicationPreferredDialects();

  return (
    <>
      <StyledFormHeader icon={PersonIcon} title={formHeaderTitle} />
      <StyledForm onSubmit={onSubmit} noValidate>
        {showPreferredDialect ? (
          <>
            <StyledDiv>
              <InputSelectV2
                data-testid="preferredDialectInput"
                innerRef={languageRef}
                id="preferredDialect"
                labelText={t(
                  'ui.event-management.my-account.edit.preferred-language',
                )}
                inputProps={{ 'data-testid': 'preferredDialectInput' } as any}
                error={Boolean(errors.preferredDialect)}
                value={preferredDialect}
                required
                fullWidth
                {...languageFormProps}
              >
                {Object.entries(items).map<React.ReactNode>(
                  ([value, language]) => (
                    <MenuItem key={value} value={value}>
                      {t(language)}
                    </MenuItem>
                  ),
                )}
              </InputSelectV2>
            </StyledDiv>
            {!isPreferredDialect(preferredDialect) && (
              <Alert>
                {t(
                  'ui.event-management.my-account.edit.preferred-language.supported-language',
                )}
              </Alert>
            )}
          </>
        ) : null}
        <InputText
          id="fullName"
          label={t('ui.event-management.my-account.edit.full-name')}
          placeholder={t(
            'ui.event-management.my-account.edit.full-name.placeholder',
          )}
          type={InputType.TEXT}
          helperText={fullNameHelperText}
          error={Boolean(errors.fullName)}
          required
          fullWidth
          {...register('fullName', {
            required: true,
            pattern: NO_SPECIAL_CHARACTERS_REGEX,
          })}
        />
        <InputText
          disabled={!emailChangeIsPermitted}
          id="emailAddress"
          label={t('ui.event-management.my-account.edit.email')}
          placeholder={t(
            'ui.event-management.my-account.edit.email.placeholder',
          )}
          type={InputType.EMAIL}
          helperText={emailHelperText}
          error={Boolean(errors.emailAddress)}
          required
          fullWidth
          {...register('emailAddress', {
            required: true,
            pattern: VALID_EMAIL_REGEX,
          })}
        />
        <StyledDiv>
          <InputSelectV2
            id="leadingColourEnergy"
            value={leadingColourEnergy === null ? '' : leadingColourEnergy}
            error={false}
            fullWidth
            labelText={t(
              'ui.event-management.my-account.edit.lead-colour-energy',
            )}
            labelOptionalText={t(
              'ui.event-management.wallet.request-units-dialog.optional-field',
            )}
            {...colourEnergyFormProps}
          >
            {Object.entries(COLOUR_ENERGY).map<React.ReactNode>(
              ([value, colorTextKey]) => (
                <MenuItem key={value} value={value}>
                  {t(colorTextKey)}
                </MenuItem>
              ),
            )}
            <MenuItem value="">
              {t('ui.event-management.colour.none.name')}
            </MenuItem>
          </InputSelectV2>
        </StyledDiv>
        {showProducts && (
          <CheckboxGroup
            sx={{ marginTop: '0' }}
            control={control}
            rules={{
              required: true,
              validate: { CustomMinLength: (v: string) => v.length > 0 },
            }}
            name="licensedProducts"
            title={t('ui.event-management.products.title')}
            errorMessage={t('ui.event-management.products.error-required')}
            error={Boolean(errors.licensedProducts)}
            options={Object.entries(products).map(
              ([value, option]: [string, Option<LicensedProduct>]) => ({
                value: value as LicensedProduct,
                label: t(option.label),
                disabled: !productChangeIsPermitted,
              }),
            )}
            setValue={setValue}
          />
        )}
        <EditRolePermission
          showRoles={showRoles}
          setValue={setValue}
          roleChangeIsPermitted={roleChangeIsPermitted}
          isSuperAdmin={isSuperAdmin}
          register={register}
          formData={formData}
        />
        <Grid container justifyContent="flex-end" sx={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            {t('ui.event-management.my-account.edit.save')}
          </Button>
        </Grid>
      </StyledForm>
    </>
  );
};

export default EditAccountForm;
