import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet';
import { H2, P, PaginationControls } from '@insights-ltd/design-library';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import Breadcrumbs from 'components/Breadcrumbs';
import PermissionGuard from 'components/PermissionGuard';
import { useAuth } from 'contexts/AuthContext';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import OrganisationList from 'components/OrganisationList/OrganisationList';
import { useGetAllOrganisationsAndGroups } from 'components/hooks/useGetAllOrganisationsAndGroups';
import {
  FilterCategory,
  isMatchedToOrgFilter,
} from 'utils/isMatchedToOrgFilter';
import { SearchFilterV2 } from 'components/SearchFilter/SearchFilter';
import SearchCount from 'components/SearchCount/SearchCount';
import { styled } from '@mui/material';
import usePagination from '@insights-ltd/design-library/src/components/hooks/usePagination';
import {
  SearchState,
  useSearchContext,
} from 'components/SearchFilter/SearchProvider';
import {
  getFilteredOrgs,
  useGetActiveSearchCategory,
} from './Organisations.helper';

export const ITEMS_PER_PAGE = 9;
const minCharacters = 2;

const Description = styled(P)({
  maxWidth: '720px',
  lineHeight: '28px,',
});

const Organisations = () => {
  const {
    searchState: { searchTerm, category },
    setSearchState,
  } = useSearchContext();
  const { t } = useTranslation();
  const {
    status,
    data,
    organisations: orgs,
    groupsCount,
    orgsCount,
    orgsInGroupsCount,
    organisationsInGroups,
    availableOrganisations,
  } = useGetAllOrganisationsAndGroups();
  const { user } = useAuth();
  const isAdminUser = user?.roles.includes('SUPER_ADMIN');
  const canSeeGroups = user?.permissions.Organisation_Group_ReadAll !== 'None';

  const activeCategory = useGetActiveSearchCategory({
    user,
    organisations: availableOrganisations,
    searchTerm,
    category,
  });

  const totalTranslations: Record<FilterCategory, string> = {
    ORGANISATIONS: 'ui.event-management.organisations.total-organisations',
    ALL: 'ui.event-management.organisations.total-results',
    GROUPS:
      searchTerm && searchTerm.length >= minCharacters
        ? 'ui.event-management.organisations.total-groups'
        : 'ui.event-management.organisations.groups',
  };

  const filterOptions: { label: string; value: FilterCategory }[] = canSeeGroups
    ? [
        {
          label: t('ui.event-management.list-filter.organisation'),
          value: 'ORGANISATIONS',
        },
        {
          label: t('ui.event-management.list-filter.group'),
          value: 'GROUPS',
        },
      ]
    : [
        {
          label: t('ui.event-management.list-filter.organisation'),
          value: 'ORGANISATIONS',
        },
      ];

  const totalResultsText = canSeeGroups
    ? totalTranslations[activeCategory]
    : 'ui.event-management.organisations.title';

  const resultsTranslations: Record<'ORGANISATIONS' | 'GROUPS', string> = {
    ORGANISATIONS:
      'ui.event-management.organisations.search-results.organisations',
    GROUPS: 'ui.event-management.organisations.search-results.groups',
  };

  const OrganisationLabels: { all: string; filtered: string } = {
    all: totalResultsText,
    filtered:
      activeCategory === 'ALL'
        ? 'ui.event-management.organisations.search-results'
        : resultsTranslations[activeCategory],
  };

  const filterProps = {
    filterOptions,
    initialSearchFilter:
      activeCategory === 'ALL' ? 'ORGANISATIONS' : activeCategory,
    handleFilterChange: (value: FilterCategory) => {
      setSearchState({ category: value });
    },
  };

  const handleSetText = (term: string) => {
    const payload: SearchState = { searchTerm: term };
    setSearchState(payload);
  };

  const organisations = activeCategory === 'ORGANISATIONS' ? orgs : data;
  const isSearching = searchTerm !== '';
  const filteredOrgs = getFilteredOrgs(
    organisations,
    isMatchedToOrgFilter(
      searchTerm && searchTerm?.length >= minCharacters ? searchTerm : '',
      organisationsInGroups,
      activeCategory,
      minCharacters,
    ),
    status,
  );

  const paginator = usePagination(filteredOrgs, ITEMS_PER_PAGE);
  const { pageNumber, handlePageChange, pageCount, pagedItems } = paginator;

  const title =
    (searchTerm?.length ?? 0) < minCharacters
      ? t(OrganisationLabels.all)
      : t(OrganisationLabels.filtered);

  const getFullCount = () => {
    if (isSearching) {
      return filteredOrgs.length;
    }
    if (activeCategory === 'GROUPS') {
      return groupsCount;
    }

    if (activeCategory === 'ORGANISATIONS') {
      return orgsCount - orgsInGroupsCount;
    }
    return orgsCount - orgsInGroupsCount + groupsCount;
  };

  const displayCount = getFullCount();

  const getPage = () => {
    if (pageNumber > pageCount) handlePageChange(pageCount);
    return pageNumber;
  };

  const dynamicSearch = (callback: (value: any) => void) => (value: any) => {
    callback(value);
    handlePageChange(1);
  };

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisations.error-loading')}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.organisations')}</title>
      </Helmet>
      <FullHeight>
        <Container
          maxWidth="lg"
          sx={(theme) => ({
            marginBottom: theme.spacing(spacingSizeMap.L),
          })}
        >
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
              }}
              activeText={t('ui.event-management.organisations.title')}
            />
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12} md="auto">
                <H2 variant="h2">
                  {t('ui.event-management.organisations.title')}
                </H2>
              </Grid>
              <PermissionGuard
                requiredPermissions={[
                  { action: 'Organisation_Create', scope: 'Global' },
                ]}
              >
                <Grid
                  sx={(theme) => ({
                    '& *:nth-of-type(2)': {
                      marginLeft: theme.spacing(spacingSizeMap.XS),
                    },
                  })}
                  item
                  xs={12}
                  md="auto"
                >
                  <Button
                    component={RouterLink}
                    to="/organisations/add"
                    variant="contained"
                    color="primary"
                  >
                    <AddIcon />
                    &nbsp;
                    {t('ui.event-management.organisations.create-organisation')}
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/groups/add"
                    variant="outlined"
                    color="primary"
                  >
                    <AddIcon />
                    &nbsp;
                    {t('ui.event-management.organisations.add-group')}
                  </Button>
                </Grid>
              </PermissionGuard>
            </Grid>
          </Box>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
            <>
              {canSeeGroups ? (
                <Description color="textSecondary">
                  {t('ui.event-management.organisations.description')}
                </Description>
              ) : null}
              <SearchFilterV2
                sx={(theme) => ({
                  padding: `${theme.spacing(spacingSizeMap.S)} !important`,
                })}
                placeholder={t(
                  'ui.event-management.evaluator-links.search.placeholder',
                )}
                handleTermChange={dynamicSearch(handleSetText)}
                handleSearch={() => {}}
                showButton={false}
                inputState={searchTerm}
                {...filterProps}
                minCharacters={minCharacters}
              />
            </>
          </Box>
          <Box
            sx={{
              marginBottom: '-0.5rem',
            }}
          >
            <Grid container alignItems="flex-start">
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  paddingBottom:
                    pagedItems.length && pageCount > 1 ? '2px' : '10px',
                }}
              >
                <Grid item xs={12} md="auto">
                  <H2 variant="h3">
                    {title}
                    <SearchCount
                      count={displayCount}
                      sx={{
                        marginLeft: '0.5rem',
                      }}
                    />
                  </H2>
                </Grid>
              </Grid>
              <Grid
                item
                xs={8}
                md="auto"
                sx={{ marginTop: '2px', marginBottom: '-8px' }}
              >
                {pagedItems.length && pageCount > 1 ? (
                  <PaginationControls
                    show
                    paginator={{
                      ...paginator,
                      pageNumber: getPage(),
                    }}
                    itemsPerPage={ITEMS_PER_PAGE}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Box>

          <OrganisationList
            showBanners={isAdminUser || false}
            organisations={pagedItems}
            groupOrganisations={organisationsInGroups}
          />
          <Box
            mt={(theme) => theme.spacing(spacingSizeMap.L)}
            mb={(theme) => theme.spacing(spacingSizeMap.L)}
          />
        </Container>
      </FullHeight>
    </>
  );
};

export default Organisations;
