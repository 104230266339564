import React from 'react';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { Link, styled, SxProps } from '@mui/material';
import { Span, TextVariants, TextColor } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';

type BackButtonProps = {
  parentLink: string;
  i18nKey: string;
  sx?: SxProps;
  variant?: TextVariants | undefined;
  color?: TextColor | undefined;
};

const StyledDiv = styled('div')({});

const BackButton = ({
  parentLink,
  i18nKey,
  sx,
  variant = 'body-bold',
  color = 'primary',
}: BackButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledDiv sx={sx}>
      <ArrowBackIosNewRounded color="primary" sx={sx} />
      <Span variant={variant} color={color}>
        <Link component={RouterLink} to={parentLink}>
          {t(i18nKey)}
        </Link>
      </Span>
    </StyledDiv>
  );
};

export default BackButton;
