import React from 'react';
import { styled } from '@mui/material';
import { LabelType } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import getTheme from '@insights-ltd/design-library/src/themes';
import { arctanScale } from './teamWheelUtils';

const localTheme = getTheme();

export const setWheelDotLabel = ({
  isAggregated,
  duplicateNumber,
  label,
  type,
}: {
  isAggregated: boolean;
  duplicateNumber: number;
  label: string;
  type: LabelType;
}) => {
  if (duplicateNumber > 0 && !isAggregated) {
    return (
      <g>
        <g fill={localTheme.grey[100]} textAnchor="middle" fontSize="27">
          <text transform="matrix(1 0 0 1 22 38)">{label}</text>
        </g>
        <g fill={localTheme.grey[100]} textAnchor="middle" fontSize="18">
          <text
            transform="matrix(1 0 0 1 44 32)"
            data-testid={`${label}-${duplicateNumber}-superscript`}
          >
            {duplicateNumber}
          </text>
        </g>
      </g>
    );
  }

  if (type === 'ANONYMOUS') {
    return null;
  }

  return (
    <g fill={localTheme.grey[100]} textAnchor="middle" fontSize="30">
      <text transform="matrix(1 0 0 1 28 38)">
        {isAggregated ? duplicateNumber : label}
      </text>
    </g>
  );
};

type WheelDotProps = {
  isAggregated: boolean;
  label: string;
  colour: string;
  transform: { x: number; y: number };
  duplicateNumber: number;
  scale: number;
  labelType: LabelType;
  onPointerDown: React.PointerEventHandler<SVGGraphicsElement>;
  onPointerUp: React.PointerEventHandler<SVGGraphicsElement>;
  onPointerMove: React.PointerEventHandler<SVGGraphicsElement>;
};

const StyledG = styled('g')({
  cursor: 'move',
  userSelect: 'none',
});

const WheelDot = ({
  isAggregated,
  label,
  colour,
  transform,
  duplicateNumber,
  scale: size,
  labelType,
  onPointerDown,
  onPointerUp,
  onPointerMove,
}: WheelDotProps) => {
  const { x, y } = transform;

  const wheelLabel = setWheelDotLabel({
    isAggregated,
    duplicateNumber,
    label,
    type: labelType,
  });

  const anonymousPrefix = labelType === 'ANONYMOUS' ? 'anon-' : '';
  const scale =
    isAggregated && labelType === 'ANONYMOUS'
      ? size * arctanScale(duplicateNumber)
      : size;
  const offset = -27 * scale;

  return (
    <StyledG
      width="54"
      height="54"
      fill="none"
      transform={`matrix(1 0 0 1 ${x} ${y})`}
      data-testid={`${anonymousPrefix}${label}-${duplicateNumber}-position`}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerUp}
    >
      <g
        transform={`matrix(${scale} 0 0 ${scale} ${offset} ${offset})`}
        data-testid={`${anonymousPrefix}${label}-${duplicateNumber}-scale-offset`}
      >
        <circle
          cx="27"
          cy="27"
          r="27"
          fill={colour}
          stroke={localTheme.grey[100]}
        />
        {wheelLabel}
      </g>
    </StyledG>
  );
};

export default WheelDot;
