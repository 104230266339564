import { ScoresResponse } from 'api/events/eventApiTypes';
import { authenticatedFetch } from 'api/fetch';
import {
  LearnerPurchaseRequest,
  GetTeamLearnersResponse,
  TeamResponse,
} from 'api/httpEntities';
import { cleanObject } from 'api/utils';
import { Dialects, ProfileDialectStrategies } from 'types/dialects';
import {
  CreateMultiOrgTeamRequest,
  GetProfileIDsBodyType,
  GetTeamLearnersRequest,
  GetTeamRequest,
  MultiOrgTeamEditRequest,
  ProfileIds,
  ScoreCount,
  SingleOrgTeamEditRequest,
  TeamLearnerData,
  TeamLearnerPurchaseStatus,
  WheelPosition,
  WheelPositionByOrganisation,
  WheelPositionSummaryByOrganisationResponse,
  WheelPositionSummaryResponse,
} from './teamApiTypes';

const baseUrl = '/api/v1/teams';

function transformStructure(wheelPositions: WheelPosition[]) {
  return wheelPositions.reduce(
    (
      prev: { wheelPosition: WheelPosition; totalScoreCount: number },
      wheel: WheelPosition,
    ) => {
      const resultObj = prev;
      const key = Object.keys(wheel)[0];
      resultObj.wheelPosition[key] = wheel[key];
      resultObj.totalScoreCount += wheel[key].scoreCount.conscious;
      return resultObj;
    },
    { wheelPosition: {}, totalScoreCount: 0 },
  );
}

export function transformTeamWheelByOrganisationStructure(
  activeOrganisations: Record<string, boolean> = {},
  wheelPositions: WheelPositionByOrganisation[] = [],
) {
  return wheelPositions.reduce(
    (
      prev: { wheelPositions: WheelPosition; totalScoreCount: number },
      wheel: WheelPositionByOrganisation,
    ) => {
      const resultObj = prev;
      const key = Object.keys(wheel)[0];
      resultObj.wheelPositions[key] = {
        scoreCount: {
          conscious: 0,
          lessConscious: 0,
        },
      };

      wheel[key].scoreCounts.forEach(
        ({ organisationId, conscious, lessConscious }) => {
          if (activeOrganisations[organisationId]) {
            resultObj.wheelPositions[key].scoreCount.conscious += conscious;
            resultObj.wheelPositions[key].scoreCount.lessConscious +=
              lessConscious;
          }
        },
      );

      resultObj.totalScoreCount +=
        resultObj.wheelPositions[key].scoreCount.conscious;
      return resultObj;
    },
    { wheelPositions: {}, totalScoreCount: 0 },
  );
}

export const getTeams = async (
  queryParams: GetTeamRequest,
): Promise<{
  teams: TeamResponse[];
  limitExceeded: boolean;
}> => {
  const queries = new URLSearchParams(
    cleanObject(queryParams as Record<string, string>),
  );

  const url =
    queryParams.organisationId && !queryParams.searchTerm
      ? `/api/v1/practitioners/me/organisations/${queryParams.organisationId}/teams`
      : baseUrl;

  const { json } = await authenticatedFetch<{
    teams: TeamResponse[];
    limitExceeded: boolean;
  }>(queries.size > 0 ? `${url}?${queries.toString()}` : url);

  const { teams: unsortedTeams, ...rest } = await json();

  return {
    teams: unsortedTeams.sort(({ name: nameA }, { name: nameB }) =>
      nameA.localeCompare(nameB),
    ),
    ...rest,
  };
};

export const getTeam = async (teamId: string): Promise<TeamResponse> => {
  const { json } = await authenticatedFetch<TeamResponse>(
    `${baseUrl}/${teamId}`,
  );

  return json();
};

export const updateMultiOrgTeam = async ({
  teamId,
  formData,
}: MultiOrgTeamEditRequest): Promise<TeamResponse> => {
  const url = `${baseUrl}/${teamId}`;
  const { json } = await authenticatedFetch<TeamResponse>(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return json();
};

export const updateSingleOrgTeam = async ({
  teamId,
  organisationId,
  formData,
}: SingleOrgTeamEditRequest): Promise<TeamResponse> => {
  const url = `/api/v1/organisations/${organisationId}/teams/${teamId}`;
  const { json } = await authenticatedFetch<TeamResponse>(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return json();
};

export const getTeamLearners = async ({
  teamId,
  searchTerm = '',
  purchaseStatus = 'ALL',
}: GetTeamLearnersRequest): Promise<GetTeamLearnersResponse> => {
  const paramVals: {
    purchaseStatus: TeamLearnerPurchaseStatus;
    searchTerm?: string;
    limit?: number;
  } = {
    purchaseStatus,
    limit: 300,
  };

  if (searchTerm) {
    paramVals.searchTerm = searchTerm;
  }
  const params = new URLSearchParams({
    ...paramVals,
    limit: `${paramVals.limit}`,
  });

  const url = `${baseUrl}/${teamId}/learners?${params.toString()}`;

  const { json } = await authenticatedFetch<GetTeamLearnersResponse>(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  return json();
};

export const addTeamLearner = async ({
  teamId,
  learnerId,
}: TeamLearnerData): Promise<void> => {
  const url = `${baseUrl}/${teamId}/learners/${learnerId}`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  await json();
};

export const removeTeamLearner = async ({
  teamId,
  learnerId,
}: TeamLearnerData): Promise<void> => {
  const url = `${baseUrl}/${teamId}/learners/${learnerId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });
  await json();
};

export const getTeamWheelScores = async (
  teamId: string,
): Promise<ScoresResponse> => {
  const url = `${baseUrl}/${teamId}/wheel`;
  const { json } = await authenticatedFetch<ScoresResponse>(url);

  return json();
};

export type SummaryResponse = {
  wheelPositions: Record<number, ScoreCount>;
  totalScoreCount: number;
};
export const getTeamScoreSummary = async (
  teamId: string,
): Promise<SummaryResponse> => {
  const url = `${baseUrl}/${teamId}/wheel/summary`;
  const { json } = await authenticatedFetch<WheelPositionSummaryResponse>(url);
  const data = await json();
  const { wheelPosition, totalScoreCount } = transformStructure(
    data.wheelPositions,
  );
  return { wheelPositions: wheelPosition, totalScoreCount };
};

export const getTeamScoreSummaryByOrganisation = async (
  teamId: string,
): Promise<WheelPositionSummaryByOrganisationResponse> => {
  const url = `${baseUrl}/${teamId}/wheel/summary-by-organisation`;
  const { json } =
    await authenticatedFetch<WheelPositionSummaryByOrganisationResponse>(url);
  return json();
};
export const deleteTeam = async (teamId: string): Promise<void> => {
  const url = `/api/v1/teams/${teamId}`;
  const { json } = await authenticatedFetch(url, { method: 'DELETE' });

  await json();
};

export const purchaseProfilesForTeam = async ({
  teamId,
  request,
}: {
  teamId: string;
  request: LearnerPurchaseRequest;
}) => {
  const { success } = await authenticatedFetch(
    `${baseUrl}/${teamId}/profiles`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    },
  );

  return success;
};

export const addAllLearnersToTeam = async (teamId: string) => {
  const url = `${baseUrl}/${teamId}/learners`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  await json();
};

export const removeAllLearnersToTeam = async (teamId: string) => {
  const url = `${baseUrl}/${teamId}/learners`;
  const { json } = await authenticatedFetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  await json();
};

export const createMultiOrgTeam = async (body: CreateMultiOrgTeamRequest) => {
  const url = baseUrl;
  const { json } = await authenticatedFetch<TeamResponse>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  return json();
};

export const getDiscoveryProfileIds = async (
  teamId: string,
  body: GetProfileIDsBodyType,
): Promise<ProfileIds> => {
  const url = `${baseUrl}/${teamId}/profiles?type=DISCOVERY_PROFILE`;
  const { json } = await authenticatedFetch<ProfileIds>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return json();
};

export type ShareType = 'link' | 'attachment';

export const shareProfiles = async ({
  teamId,
  body,
  shareType,
}: {
  teamId: string;
  body: { profileIds: string[]; dialect?: string };
  shareType: ShareType;
}) => {
  const url = `${baseUrl}/${teamId}/profiles/emails?format=${shareType}`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return json();
};

export const downloadTeamProfiles = async ({
  teamId,
  body,
}: {
  teamId: string;
  body: {
    profileIds: string[];
    dialect?: Dialects;
    generateUsing?: ProfileDialectStrategies;
  };
}) => {
  const url = `${baseUrl}/${teamId}/profiles/email/me`;
  const { json } = await authenticatedFetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  return json();
};
