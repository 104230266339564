import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Dialog, RadioGroupControl, Span } from '@insights-ltd/design-library';
import { Trans, useTranslation } from 'react-i18next';

export type Props = {
  open: boolean;
  profileCount: number;
  onSendViaLink: () => void;
  onSendViaEmail: () => void;
  onCancel: () => void;
};

const DistributionMethodDialog = ({
  open,
  onSendViaLink,
  onSendViaEmail,
  onCancel,
  profileCount,
}: Props) => {
  const { t } = useTranslation();
  const [distribution, setDistribution] = useState('LINK');

  const onSubmit = () => {
    if (distribution === 'EMAIL') {
      onSendViaEmail();
    } else {
      onSendViaLink();
    }
    setDistribution('LINK');
  };

  const handleOnClose = () => {
    setDistribution('LINK');
    onCancel();
  };

  return (
    <Dialog
      onSubmit={onSubmit}
      onClose={handleOnClose}
      open={open}
      content={
        <>
          <Box>
            <Span>
              <Trans
                i18nKey="ui.event-management.distribute-profiles.message.bold"
                components={{ bold: <Span variant="body-bold" /> }}
                values={{ count: profileCount }}
              />
            </Span>
          </Box>
          <RadioGroupControl
            options={[
              {
                value: 'LINK',
                label: (
                  <strong>
                    {t('ui.event-management.distribute-profiles.link', {
                      count: profileCount,
                    })}
                  </strong>
                ) as unknown as string,
              },
              {
                value: 'EMAIL',
                label: (
                  <strong>
                    {t('ui.event-management.distribute-profiles.email', {
                      count: profileCount,
                    })}
                  </strong>
                ) as unknown as string,
              },
            ]}
            name="roles"
            title={t('ui.event-management.users.create.permission-roles')}
            value={distribution}
            onChange={setDistribution}
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightBold,
              // New stuff
            })}
          />
        </>
      }
      primaryButtonText={t(
        'ui.event-management.events.download-profiles.distribute',
      )}
      disablePrimaryButton={distribution === ''}
      secondaryButtonText={t(
        'ui.event-management.distribute-profiles.cancel.button',
      )}
      title={t('ui.event-management.distribute-profiles.title')}
    />
  );
};

export default DistributionMethodDialog;
