import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import { P } from '@insights-ltd/design-library';
import LearnersIcon from 'components/Svgs/icons/Learners';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

const AddLearnersAndContributorsCard = ({
  hasLearner,
  disabled = false,
  pastExperience = false,
  learnersCount = 0,
  contributorsCount = 0,
}: {
  hasLearner: boolean;
  disabled: boolean;
  pastExperience: boolean;
  learnersCount: number;
  contributorsCount: number;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const displayCardText = () => {
    if (pastExperience) {
      return 'ui.event-management.events.summary.view-learners-and-contributors';
    }
    if (hasLearner) {
      return 'ui.event-management.events.summary.manage-learners-and-contributors';
    }
    return 'ui.event-management.events.summary.dfc.add-learners-and-contributors';
  };

  const displayCardSubtext = () => {
    if (learnersCount > 0 && contributorsCount <= 0) {
      return t(
        'ui.event-management.events.summary.overview.manage-learners.no-of-learners',
        { count: learnersCount },
      );
    }

    if (contributorsCount > 0) {
      return `${t(
        'ui.event-management.events.overview.summary.no-of-learners',
        {
          count: learnersCount,
        },
      )} ${t('ui.event-management.events.summary.dfc.no-of-contributors', {
        count: contributorsCount,
      })}`;
    }

    return t('ui.event-management.events.summary.get-started');
  };

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        height: '100%',
        border: `1px solid ${theme.palette.grey[500]}`,
        borderBottom: `0.2em solid ${theme.palette.primary.main}`,
      })}
    >
      <CardActionArea
        onClick={() => navigate(`${pathname}/learners-and-contributors`)}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          fontFamily: 'inherit',
        }}
        disabled={disabled}
      >
        <CardContent>
          <LearnersIcon />
          <Box
            mb={(theme) => theme.spacing(spacingSizeMap.S)}
            mt={(theme) => theme.spacing(spacingSizeMap.S)}
          >
            <P variant="h3" color="primary">
              {t(displayCardText())}
            </P>
          </Box>
          <P variant="body-bold" color="textSecondary">
            {displayCardSubtext()}
          </P>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AddLearnersAndContributorsCard;
