import React from 'react';
import { ScoreCount } from 'api';
import type { InitialledScore } from 'pages/DownloadTeamWheel/scoreUtils';
import { createDuplicateScoreMap } from 'pages/DownloadTeamWheel/scoreUtils';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { useDotSize } from 'pages/DownloadTeamWheel/hooks/useWheelOverrides';
import { TEAM_WHEEL_CENTER_COORDS } from 'variables';
import { useDraggableTeamWheelDot } from './TeamWheelDotsHooks';
import WheelDot from './WheelDot';
import {
  TeamWheelDotProps,
  getSummaryWheelProps,
  getWheelProps,
} from './TeamWheel.helper';
import {
  clampToSegment,
  getPositionFromScore,
  INNER_LAYER_DISTANCE,
  LAYER_DISTANCE,
} from './teamWheelUtils';

type TeamWheelDotsProps = {
  scores: InitialledScore[];
  wheelPositions: Record<number, ScoreCount> | undefined;
};

const TeamWheelDot = ({
  dotKey,
  wheelPosition,
  duplicateScore,
  duplicateNumber,
  label,
  colour,
}: TeamWheelDotProps) => {
  const [{ scoreType, labelType, scoresGroupingType }] = useWheelContext();
  const { dotSize } = useDotSize();

  const initialPosition = React.useMemo(
    () =>
      clampToSegment(
        wheelPosition,
        getPositionFromScore(
          wheelPosition,
          TEAM_WHEEL_CENTER_COORDS,
          [
            INNER_LAYER_DISTANCE + LAYER_DISTANCE / 2 + 5,
            INNER_LAYER_DISTANCE + (3 * LAYER_DISTANCE) / 2 + 5,
            INNER_LAYER_DISTANCE + (5 * LAYER_DISTANCE) / 2 + 5,
          ],
          duplicateScore,
          dotSize,
        ),
        TEAM_WHEEL_CENTER_COORDS,
      ),
    [dotSize, duplicateScore, wheelPosition],
  );
  const { onPointerDown, onPointerUp, onPointerMove, transform } =
    useDraggableTeamWheelDot(
      initialPosition,
      (coords) =>
        clampToSegment(wheelPosition, coords, TEAM_WHEEL_CENTER_COORDS),
      scoreType,
      dotKey,
    );

  return (
    <WheelDot
      scale={dotSize}
      duplicateNumber={duplicateNumber}
      label={label}
      colour={colour}
      transform={transform}
      labelType={labelType}
      isAggregated={scoresGroupingType === 'AGGREGATED'}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
    />
  );
};

const TeamWheelDots = ({ scores, wheelPositions }: TeamWheelDotsProps) => {
  const [
    {
      scoreType,
      labelType,
      scoresGroupingType,
      dotType,
      isLearnerCountExceeded,
    },
  ] = useWheelContext();
  const duplicateScoreMap = createDuplicateScoreMap(scores, scoreType);
  const wheelTypes = { scoreType, labelType, scoresGroupingType, dotType };
  const formattedScores =
    isLearnerCountExceeded && wheelPositions
      ? getSummaryWheelProps(wheelPositions, scoreType).filter(
          (props) => props.duplicateNumber !== 0,
        )
      : getWheelProps(scores, duplicateScoreMap, wheelTypes);

  return (
    <g id="data">
      {formattedScores.map((score: TeamWheelDotProps) => {
        const {
          key,
          label,
          colour,
          dotKey,
          activeScore,
          wheelPosition,
          duplicateScore,
          duplicateNumber,
        } = score;

        return (
          <TeamWheelDot
            key={key}
            {...{
              label,
              colour,
              dotKey,
              activeScore,
              wheelPosition,
              duplicateScore,
              duplicateNumber,
            }}
          />
        );
      })}
    </g>
  );
};
export default TeamWheelDots;
