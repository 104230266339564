import React from 'react';
import { useTheme } from '@mui/material';
import { TEAM_WHEEL_CENTER_COORDS } from 'variables';
import {
  getPositionFromScore,
  generateScores,
  CoordPair,
} from './teamWheelUtils';

type WheelNumberingProps = {
  centerCoords?: CoordPair;
};
const WheelNumbering = ({
  centerCoords = TEAM_WHEEL_CENTER_COORDS,
}: WheelNumberingProps) => {
  const theme = useTheme();
  return (
    <g id="Numbers" fill={theme.palette.grey[600]}>
      {generateScores().map((score) => {
        const { x, y } = getPositionFromScore(
          score,
          { x: centerCoords.x - 5, y: centerCoords.y + 2 },
          [70, 122, 180],
        );
        return (
          <text
            key={score}
            transform={`matrix(1 0 0 1 ${x} ${y})`}
            fontSize="8"
          >
            {score}
          </text>
        );
      })}
    </g>
  );
};
export default WheelNumbering;
