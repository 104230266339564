import React, { ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Link, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library';
import { useAuth } from 'contexts/AuthContext';
import LeadingColourEnergyIndicator from 'components/LeadingColourEnergyIndicator/LeadingColourEnergyIndicator';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import InsightsLogo from 'components/Svgs/InsightsLogo';

type Props = {
  children: ReactNode;
};

const StyledInsightsLogo = styled(InsightsLogo)(({ theme }) => ({
  width: '128px',
  height: '34px',
  marginRight: '0',
  marginBottom: theme.spacing(spacingSizeMap.S),
  fill: theme.palette.blue.main,
}));

const UserInfo = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Grid
      container
      alignItems="center"
      sx={(theme) => ({
        height: '100%',
        padding: `0 ${theme.spacing(spacingSizeMap.M)}`,
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
      })}
    >
      <Grid item>
        <Box display="flex" alignItems="center">
          <LeadingColourEnergyIndicator
            leadingColourEnergy={
              user?.leadingColourEnergy === null
                ? undefined
                : user?.leadingColourEnergy
            }
          />
          <P data-testid="header-user-name" variant="body-bold">
            {user?.fullName}
          </P>
        </Box>
        <P variant="body-bold">
          <Link
            component={RouterLink}
            to="/account"
            sx={(theme) => ({
              color:
                pathname === '/account'
                  ? theme.palette.grey[700]
                  : theme.palette.blue.main,
            })}
          >
            {t('ui.event-management.my-account.title')}
          </Link>
        </P>
      </Grid>
    </Grid>
  );
};

const HeaderLayout = ({ children }: Props) => {
  const aboveMobile = useIsAboveMobile('md');

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
      })}
    >
      <Container maxWidth="lg" translate="no">
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            item
            alignItems="center"
            sx={(theme) => ({
              paddingTop: theme.spacing(spacingSizeMap.M),
            })}
          >
            <StyledInsightsLogo />
            <Grid item xs height="100%">
              {children}
            </Grid>
          </Grid>
          {aboveMobile && (
            <Grid item xs={6} sm={6} md={3}>
              <UserInfo />
            </Grid>
          )}
        </Grid>
      </Container>
    </AppBar>
  );
};

export default HeaderLayout;
