import {
  DatePickerV2,
  formatDate,
  P,
  Span,
} from '@insights-ltd/design-library';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { EventResponse } from 'api';
import {
  isAfterTodayOrDeadlineFieldRules,
  getErrorMessage,
} from 'components/EventForms/validation';
import { DateTime } from 'luxon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

type EventReminder = {
  reminderAt: DateTime | null;
};

export type Props = {
  event: EventResponse;
  open: boolean;
  onClose: () => void;
  onSetReminder: SubmitHandler<EventReminder>;
  onCancelReminder: () => void;
  reminderAt: DateTime | null;
};

const SetReminderDialog = ({
  event,
  open,
  onClose,
  onSetReminder,
  onCancelReminder,
  reminderAt,
}: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<EventReminder>({
    defaultValues: { reminderAt },
  });

  const hasReminder = Boolean(reminderAt);
  const title = hasReminder
    ? t('ui.event-management.events.learners.change-reminder-modal.title')
    : t('ui.event-management.events.learners.set-reminder-modal.title');

  const primaryButtonText = hasReminder
    ? t('ui.event-management.events.learners.change-reminder-modal.action')
    : t('ui.event-management.events.learners.set-reminder-modal.action');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBlock: 0,
          paddingInline: 2,
        }}
      >
        <P variant="h4">{title}</P>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon sx={(theme) => ({ fill: theme.palette.blue.main })} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 2 }}
      >
        <P variant="body" id="alert-dialog-description">
          {t(
            'ui.event-management.events.learners.set-reminder-modal.content-1',
          )}
        </P>
        <P variant="body">
          <Trans
            i18nKey="ui.event-management.events.learners.set-reminder-modal.content-2.bold"
            values={{
              today: formatDate(new Date().toISOString()),
              deadline: formatDate(event.deadlineAt),
            }}
            components={{ bold: <Span variant="body-bold" /> }}
          />
        </P>
        <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
          <Controller
            control={control}
            name="reminderAt"
            render={({ field: { onChange: onReactHookFormChange, value } }) => (
              <DatePickerV2
                error={Boolean(errors.reminderAt)}
                errorMessage={t(getErrorMessage(errors.reminderAt))}
                labelText={t(
                  'ui.event-management.events.learners.set-reminder-modal.date-label',
                )}
                onChange={(newValue) => {
                  onReactHookFormChange(newValue);
                }}
                value={value}
                placeholder={t(
                  'ui.event-management.events.create.label.select-date',
                )}
              />
            )}
            rules={isAfterTodayOrDeadlineFieldRules(event.deadlineAt)}
          />
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}
      >
        <Button
          fullWidth
          onClick={handleSubmit(onSetReminder)}
          variant="contained"
        >
          {primaryButtonText}
        </Button>
        {hasReminder ? (
          <Button
            fullWidth
            onClick={onCancelReminder}
            variant="outlined"
            color="error"
          >
            {t(
              'ui.event-management.events.learners.change-reminder-modal.cancel-reminder',
            )}
          </Button>
        ) : (
          <Button fullWidth onClick={onClose} variant="outlined">
            {t('ui.event-management.events.learners.set-reminder-modal.cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SetReminderDialog;
