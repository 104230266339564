import { spacingSizeMap } from '@insights-ltd/design-library';
import { Grid } from '@mui/material';
import AccountOrganisationCard, {
  Props as ItemProps,
} from 'components/OrganisationInfoCard/AccountOrganisationCard';
import OrganisationInfoCardList from 'components/OrganisationInfoCardList';
import React from 'react';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

type Props = {
  items?: ItemProps[];
  allowDemotion?: boolean;
  organisationsInGroups: GroupOrganisationHashMap;
  onOrganisationRemove?: (id: string) => void;
};

const OrganisationSection = ({
  items = [],
  allowDemotion = false,
  organisationsInGroups,
  onOrganisationRemove,
}: Props) => {
  if (items.length > 6) {
    return <OrganisationInfoCardList items={items} />;
  }
  return (
    <Grid
      container
      sx={(theme) => ({
        '> *:nth-of-type(odd) > div': {
          marginRight: theme.spacing(spacingSizeMap.XS),
        },
        '> * > div': {
          marginBottom:
            items.length > 2 ? theme.spacing(spacingSizeMap.XS) : undefined,
        },
      })}
    >
      {items.map((organisation) => {
        const { id, name } = organisation;
        return (
          <AccountOrganisationCard
            key={id}
            id={id}
            name={name}
            type="Organisation"
            allowDemotion={allowDemotion && items.length > 1}
            parent={organisationsInGroups[id]}
            onClick={() => {
              if (onOrganisationRemove) {
                onOrganisationRemove(id);
              }
            }}
          />
        );
      })}
    </Grid>
  );
};

export default OrganisationSection;
