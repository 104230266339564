import { Divider, Grid, Link, styled } from '@mui/material';
import PermissionGuard from 'components/PermissionGuard';
import { H2, P } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import ExclamationIcon from 'components/Svgs/Exclamation';

const StyledExclamationIcon = styled(ExclamationIcon)(({ theme }) => ({
  marginRight: theme.spacing(spacingSizeMap.XS),
}));

const QuickActions = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Grid container alignItems="center">
        <StyledExclamationIcon />
        <H2 variant="body-large" color="textPrimary">
          {t('ui.event-management.dashboard.quick-actions.heading')}
        </H2>
      </Grid>
      <Divider
        sx={(theme) => ({
          margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
        })}
      />
      <PermissionGuard
        requiredPermissions={[
          { action: 'Organisation_Create', scope: 'Global' },
        ]}
      >
        <Link component={RouterLink} to={{ pathname: '/organisations/add' }}>
          <P color="primary">
            {t('ui.event-management.dashboard.quick-actions.new-organisation')}
          </P>
        </Link>
      </PermissionGuard>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Experience_Create',
            scope: 'Local',
          },
        ]}
      >
        <Link component={RouterLink} to="/experiences/create">
          <P color="primary">
            {t('ui.event-management.dashboard.quick-actions.new-event')}
          </P>
        </Link>
      </PermissionGuard>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Organisation_InvitePractitioner',
            scope: 'Global',
          },
        ]}
      >
        <Link component={RouterLink} to="/organisations/invite-user">
          <P color="primary">
            {t('ui.event-management.dashboard.quick-actions.new-user')}
          </P>
        </Link>
      </PermissionGuard>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Team_Create',
            scope: 'Local',
          },
        ]}
      >
        <Link component={RouterLink} to="/teams/create">
          <P color="primary">
            {t('ui.event-management.teams.create-new-team')}
          </P>
        </Link>
      </PermissionGuard>
      <PermissionGuard
        requiredPermissions={[
          {
            action: 'Unit_Update',
            scope: 'Global',
          },
        ]}
      >
        <Link component={RouterLink} to={{ pathname: '/account/manage-units' }}>
          <P color="primary">{t('ui.event-management.title.manage-units')}</P>
        </Link>
      </PermissionGuard>
    </div>
  );
};

export default QuickActions;
