import { Box, Grid, Pagination } from '@mui/material';
import { P } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'components/hooks/usePagination';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

export const PaginationControlsContainer = ({
  label,
  pagination,
}: {
  label: React.ReactNode;
  pagination: React.ReactNode;
}) => {
  return (
    <>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
      <Grid
        container
        item
        alignItems="baseline"
        justifyContent="space-between"
        wrap="nowrap"
        width="auto"
        marginRight="auto"
      >
        <Grid container item alignItems="center">
          <Box mr={3} flexWrap="nowrap">
            {label}
          </Box>
          {pagination}
        </Grid>
      </Grid>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
    </>
  );
};

export const ItemCountLabel = ({
  currentPage,
  totalItems,
  itemsPerPage,
}: {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
}) => {
  const { t } = useTranslation();
  const start = itemsPerPage * currentPage - (itemsPerPage - 1);
  const end = itemsPerPage * currentPage;

  return (
    <P color="textSecondary">
      {t('ui.event-management.transactions.item_count_label', {
        start: start > totalItems ? totalItems : start,
        end: end > totalItems ? totalItems : end,
        max: totalItems,
      })}
    </P>
  );
};

export const PaginationControls = ({
  itemsPerPage,
  paginator,
}: {
  itemsPerPage: number;
  paginator: Paginator<any>;
}) => {
  const {
    totalCount,
    pageNumber,
    pageCount,
    getPaginationAriaLabel,
    handlePageChange,
  } = paginator;
  return (
    <PaginationControlsContainer
      label={
        <ItemCountLabel
          currentPage={pageNumber}
          totalItems={totalCount}
          itemsPerPage={itemsPerPage}
        />
      }
      pagination={
        <Pagination
          count={pageCount}
          getItemAriaLabel={getPaginationAriaLabel}
          hidePrevButton={pageNumber === 1}
          hideNextButton={pageNumber === pageCount}
          onChange={(_, page) => handlePageChange(page)}
          page={pageNumber}
        />
      }
    />
  );
};
