import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import EventsList from 'components/EventsList';
import { EventSummaryResponse, OrganisationResponse } from 'api';
import SectionHeader from 'components/SectionHeader';
import { isMultiOrg } from 'utils/isMultiOrg';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type ExperienceCategory = 'active' | 'archived' | 'cancelled';

const ExperiencesSection = ({
  cancelledEvents = [],
  activeEvents,
  practitionerOrganisations,
  isAdminUser = false,
  textKeys,
}: {
  activeEvents: EventSummaryResponse[];
  cancelledEvents: EventSummaryResponse[];
  practitionerOrganisations?: OrganisationResponse[];
  isAdminUser?: boolean;
  textKeys?: Record<ExperienceCategory, string>;
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<ExperienceCategory>('active');
  const { cancelled, active, archived } = textKeys ?? {};

  const upcomingEvents = Array.isArray(activeEvents)
    ? activeEvents.filter(({ endsAt }) => Date.parse(endsAt) >= Date.now())
    : [];
  const archivedEvents = Array.isArray(activeEvents)
    ? activeEvents?.filter(({ endsAt }) => Date.parse(endsAt) < Date.now())
    : [];

  const showOrgs = isMultiOrg(practitionerOrganisations) || isAdminUser;
  return (
    <>
      <SectionHeader
        icon={<CalendarTodayIcon />}
        title={t('ui.event-management.my-account.experiences')}
      />
      <TabContext value={tabValue}>
        <TabList
          onChange={(_, value) => setTabValue(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            label={t('ui.event-management.my-account.experiences.upcoming', {
              count: upcomingEvents.length,
            })}
            value="active"
          />
          <Tab
            label={t('ui.event-management.my-account.experiences.past', {
              count: archivedEvents.length,
            })}
            value="archived"
          />
          <Tab
            label={t('ui.event-management.my-account.experiences.cancelled', {
              count: cancelledEvents?.length,
            })}
            value="cancelled"
          />
        </TabList>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
        <TabPanel value="active" sx={{ padding: '0' }}>
          <EventsList
            events={upcomingEvents}
            showOrgs={showOrgs}
            noResultsText={active ? t(active) : undefined}
          />
        </TabPanel>
        <TabPanel value="archived" sx={{ padding: '0' }}>
          <EventsList
            events={archivedEvents}
            showOrgs={showOrgs}
            noResultsText={archived ? t(archived) : undefined}
          />
        </TabPanel>
        <TabPanel value="cancelled" sx={{ padding: '0' }}>
          <EventsList
            events={cancelledEvents}
            showOrgs={showOrgs}
            experienceView="cancelled"
            noResultsText={cancelled ? t(cancelled) : undefined}
          />
        </TabPanel>
      </TabContext>
      <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
    </>
  );
};
export default ExperiencesSection;
