import React from 'react';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type RadioGroupControlProps = {
  onChange: (newValue: string) => void;
  title: string;
  value: string;
  name?: string;
  options: { value: string; label: string }[];
};
const RadioGroupControl = ({
  onChange,
  title,
  value,
  name,
  options,
}: RadioGroupControlProps) => {
  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
  ) => {
    onChange(newValue);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label={title}
        value={value}
        onChange={handleChange}
        name={name}
      >
        <Grid container spacing={2}>
          {options.map((option) => (
            <Grid item key={option.value}>
              <Card
                variant="outlined"
                sx={(theme) => ({
                  padding: `0 0 0 ${theme.spacing(spacingSizeMap.S)}`,
                  backgroundColor:
                    value === option.value
                      ? theme.palette.primary.light
                      : undefined,
                  borderColor:
                    value === option.value
                      ? theme.palette.primary.dark
                      : undefined,
                })}
              >
                <FormControlLabel
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
export default RadioGroupControl;
