import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { P } from '@insights-ltd/design-library';
import { useLocation, useNavigate } from 'react-router-dom';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { DownloadShareModal } from './DownloadOrshareModal/DownloadOrShareProfileModal';

type LearnerSelectionBarProps = {
  evaluationIds: string[];
  learnerIds: string[];
  teamId?: string;
  setCheckedItems?: (arg: any) => void;
};

const LearnerSelectionBar = ({
  evaluationIds,
  learnerIds,
  teamId,
  setCheckedItems,
}: LearnerSelectionBarProps) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDownloadShareModal, setOpenDownloadShareModal] = useState(false);
  const navigateToPurchaseProfiles = () => {
    navigate('purchase-profiles', { state: evaluationIds });
  };
  const isTeam = location.pathname.includes('/teams');
  return (
    <AppBar
      component="div"
      position="fixed"
      sx={(theme) => ({
        top: 'auto',
        bottom: '0',
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[100],
      })}
    >
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({ padding: `${theme.spacing(spacingSizeMap.S)} 0` })}
        >
          {aboveMobile ? (
            <Grid item>
              <P variant="body-bold">
                {t(
                  'ui.event-management.events.download-profiles.learners-selected',
                  { count: evaluationIds.length },
                )}
              </P>
            </Grid>
          ) : null}
          <Grid item sx={{ flexGrow: '1' }} />
          <Grid
            item
            sx={(theme) => ({
              '> * + *': {
                marginLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
              },
            })}
          >
            {evaluationIds.length > 0 && !isTeam && (
              <Button
                disabled={evaluationIds.length === 0}
                variant="outlined"
                color="primary"
                onClick={() => navigate('add-to-team')}
              >
                {t('ui.event-management.evaluator-links.teams.add-to-team')}
              </Button>
            )}
            {evaluationIds.length > 0 && (
              <Button
                data-testid="purchase-button"
                disabled={evaluationIds.length === 0}
                variant="contained"
                color="primary"
                onClick={navigateToPurchaseProfiles}
              >
                {t(
                  'ui.event-management.events.purchase-profiles.purchase-button',
                )}
              </Button>
            )}

            {isTeam && evaluationIds.length && (
              <>
                <DownloadShareModal
                  isOpen={openDownloadShareModal}
                  setIsOpen={setOpenDownloadShareModal}
                  teamId={teamId || ''}
                  learnerIds={learnerIds}
                  setCheckedItems={setCheckedItems}
                />
                <Button
                  data-testid="download-or-share-button"
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenDownloadShareModal(true)}
                >
                  {t('ui.event-management.events.download-or-share-profiles')}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default LearnerSelectionBar;
