import React from 'react';
import { Span } from '@insights-ltd/design-library';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  message: string;
};

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.M),
  padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.M,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
}));

const Notification = ({ message }: Props) => {
  return (
    <StyledDiv>
      <Span variant="body-bold">{message}</Span>
    </StyledDiv>
  );
};

export default Notification;
