import { authenticatedFetch } from 'api/fetch';
import {
  CreditUnitsRequest,
  TransferUnitsRequest,
  TransferUnitsResponse,
} from 'api/httpEntities';

const baseUrl = '/api/v1/wallets';

export const creditUnitsToPractitionerWallet = async ({
  practitionerWalletId,
  amount,
  comment,
  reference,
  organisationId,
}: CreditUnitsRequest): Promise<void> => {
  const { json } = await authenticatedFetch(
    `${baseUrl}/${practitionerWalletId}/credit-units`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount, comment, reference, organisationId }),
    },
  );

  await json();
};

export const removeUnitsFromPractitionerWallet = async ({
  practitionerWalletId,
  amount,
  comment,
  reference,
  organisationId,
}: CreditUnitsRequest): Promise<void> => {
  const { json } = await authenticatedFetch(
    `${baseUrl}/${practitionerWalletId}/remove-units`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount, comment, reference, organisationId }),
    },
  );

  await json();
};

export const transferUnitsBetweenWallets = async ({
  fromWalletId,
  toWalletId,
  amount,
  comment,
  organisationId,
  emailStrategy,
}: TransferUnitsRequest): Promise<TransferUnitsResponse> => {
  const { json } = await authenticatedFetch(
    `${baseUrl}/${fromWalletId}/transfer-units/${toWalletId}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount, comment, organisationId, emailStrategy }),
    },
  );

  return json();
};
