import OrganisationIcon from 'components/Svgs/icons/Organisation';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import { Alert, styled } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';
import {
  OrganisationResponse,
  useCreditUnitsToPractitionerWallet,
  useGetPractitionersSummary,
  useGetPractitionerWallet,
  useRemoveUnitsFromPractitionerWallet,
} from 'api';
import FormHeader from 'components/FormHeader';
import { FullScreenError } from 'components/FullScreen';
import OrganisationAutocomplete from 'components/OrganisationPicker/OrganisationAutocomplete';
import PractitionerAutocomplete from 'components/UserPicker/UserAutocomplete';
import { InputText, InputType, Span } from '@insights-ltd/design-library';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getMuiTheme, {
  spacingSizeMap,
} from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { getErrorMessage } from 'components/EventForms/validation';
import { NUMERICAL_ONLY_REGEX } from '../../variables';

type FormData = {
  creditAmount: number;
  removalAmount: number;
  comment: string;
  reference: string;
};
type UnitsFormProps = {
  organisations: OrganisationResponse[];
  initialOrganisationId?: string;
  initialPractitionerId?: string;
};
type Transaction = 'ADD' | 'REMOVE';
type TransactionTranslation = Record<Transaction, string>;

const MINIMUM_TRANSACTION_AMOUNT = 1;
const MAXIMUM_TRANSACTION_AMOUNT = 1000000;
const TRANSACTION_TRANSLATION: TransactionTranslation = {
  ADD: 'ui.event-management.manage-units.add',
  REMOVE: 'ui.event-management.manage-units.remove',
};

const StyledIcon = (icon: any, color: string) =>
  styled(icon)({
    fill: color,
    height: '20px',
  });

const StyledForm = styled('form')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const StyledFormDiv = styled('div')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(spacingSizeMap.L),
  },
}));

const StyledSummary = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
    spacingSizeMap.M,
  )}`,
  background: theme.palette.primary.light,
  borderRadius: '4px',
}));

const StyledInputText = styled(InputText)(({ theme }) => ({
  '& > span': {
    fontSize: `${theme.spacing(spacingSizeMap.S)}`,
  },
}));

const ManageUnitsForm = ({
  organisations,
  initialOrganisationId = '',
  initialPractitionerId = '',
}: UnitsFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organisationId, setOrganisationId] = useState<string>(
    initialOrganisationId,
  );
  const [practitionerId, setPractitionerId] = useState<string>(
    initialPractitionerId,
  );
  const [transactionType, setTransactionType] = useState<Transaction>('ADD');
  const { data: practitioners, status: getPractitionersStatus } =
    useGetPractitionersSummary(String(organisationId), {
      enabled: Boolean(organisationId),
    });
  const { status: getWalletStatus, data: wallet } = useGetPractitionerWallet(
    String(practitionerId),
    {
      enabled: Boolean(practitionerId),
    },
  );
  const {
    mutate: mutateCredit,
    isPending: creditIsPending,
    isError: creditIsError,
    reset: creditReset,
  } = useCreditUnitsToPractitionerWallet();
  const {
    mutate: mutateRemoval,
    isPending: removalIsPending,
    isError: removalIsError,
    reset: removalReset,
  } = useRemoveUnitsFromPractitionerWallet();
  const isLoading = creditIsPending || removalIsPending;
  const isError = creditIsError || removalIsError;
  const isCrediting = transactionType === 'ADD';
  const resetMutation = isCrediting ? creditReset : removalReset;
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      creditAmount: MINIMUM_TRANSACTION_AMOUNT,
      removalAmount: MINIMUM_TRANSACTION_AMOUNT,
    },
  });

  const handleOrganisationChange = (value: string) => {
    setOrganisationId(value);
    setPractitionerId('');
  };
  const handlePractitionerChange = (value: string) => {
    setPractitionerId(value);
  };
  const handleTransactionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionType(e.target.value as Transaction);
    setValue('creditAmount', MINIMUM_TRANSACTION_AMOUNT);
    setValue('removalAmount', MINIMUM_TRANSACTION_AMOUNT);
    clearErrors();
  };

  const fullName =
    practitioners?.find((p) => p.id === practitionerId)?.fullName || '';
  const creditAmount = Number(watch('creditAmount', 0));
  const removalAmount = Number(watch('removalAmount', 0));
  const oldBalance: number = wallet?.availableUnits || 0;
  const newBalance: number = isCrediting
    ? oldBalance + creditAmount
    : oldBalance - removalAmount;
  const exceedsRemoveThreshold = newBalance < 0;
  const showSummary = creditAmount > 0 || removalAmount > 0;

  if (getPractitionersStatus === 'error' || getWalletStatus === 'error') {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  let summaryMessage = t('ui.event-management.manage-units.add.summary', {
    fullName,
    oldBalance,
    newBalance,
  });
  if (!isCrediting) {
    summaryMessage = exceedsRemoveThreshold
      ? t('ui.event-management.manage-units.remove.summary.error', { fullName })
      : t('ui.event-management.manage-units.remove.summary', {
          fullName,
          oldBalance,
          newBalance,
        });
  }
  const buttonLabel = isCrediting
    ? t('ui.event-management.manage-units.add.button.label')
    : t('ui.event-management.manage-units.remove.button.label');
  const errorMessage = isCrediting
    ? t('ui.event-management.manage-units.add.error', { fullName })
    : t('ui.event-management.manage-units.remove.error', { fullName });

  const theme = getMuiTheme();

  const removalHelperText = () => {
    let removalHelperTextString = t(
      'ui.event-management.manage-units.remove.amount.required.error',
      {
        minimum: MINIMUM_TRANSACTION_AMOUNT,
      },
    );

    if (errors.removalAmount) {
      if (errors.removalAmount.type === 'max') {
        removalHelperTextString = t(
          'ui.event-management.manage-units.maximum.error',
          {
            minimum: MAXIMUM_TRANSACTION_AMOUNT,
          },
        );
      } else if (
        errors.removalAmount.type === 'min' ||
        errors.removalAmount.type === 'required'
      ) {
        removalHelperTextString = t(
          'ui.event-management.manage-units.remove.amount.required.error',
          {
            minimum: MINIMUM_TRANSACTION_AMOUNT,
          },
        );
      } else if (errors.removalAmount.type === 'pattern') {
        removalHelperTextString = t(
          'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
        );
      }
    }

    if (exceedsRemoveThreshold) {
      removalHelperTextString = t(
        'ui.event-management.manage-units.remove.amount.threshold.error',
      );
    }

    return removalHelperTextString || '';
  };

  const creditHelperText = () => {
    let creditHelperTextString = t(
      'ui.event-management.manage-units.add.amount.error',
      {
        minimum: MINIMUM_TRANSACTION_AMOUNT,
      },
    );
    if (errors.creditAmount && errors.creditAmount!.type === 'max') {
      creditHelperTextString = t(
        'ui.event-management.manage-units.maximum.error',
        {
          maximum: MAXIMUM_TRANSACTION_AMOUNT,
        },
      );
    }
    if (
      errors.creditAmount &&
      (errors.creditAmount!.type === 'min' ||
        errors.creditAmount!.type === 'required')
    ) {
      creditHelperTextString = t(
        'ui.event-management.manage-units.add.amount.error',
        {
          minimum: MINIMUM_TRANSACTION_AMOUNT,
        },
      );
    }
    if (errors.creditAmount && errors.creditAmount!.type === 'pattern') {
      creditHelperTextString = t(
        'ui.event-management.events.transfer-units-dialog.validation.non-numeric',
      );
    }

    return creditHelperTextString || '';
  };

  return (
    <StyledForm
      onSubmit={handleSubmit(
        ({
          creditAmount: credit,
          removalAmount: removal,
          comment,
          reference,
        }: FormData) => {
          const params = {
            practitionerWalletId: wallet!.id,
            amount: isCrediting ? credit : removal,
            comment,
            reference,
            organisationId,
          };
          const mutate = isCrediting ? mutateCredit : mutateRemoval;
          mutate(params, {
            onSuccess: () => {
              navigate(
                `/organisations/${organisationId}/practitioners/${practitionerId}`,
                {
                  state: {
                    amount: isCrediting ? params.amount : undefined,
                  },
                },
              );
            },
          });
        },
      )}
      noValidate
    >
      <div>
        <FormHeader
          icon={StyledIcon(OrganisationIcon, `${theme.palette.pink.dark}`)}
          color="pink"
          title={t('ui.event-management.users.create.organisation')}
        />
        <OrganisationAutocomplete
          options={organisations!}
          onSelect={(organisation) => {
            handleOrganisationChange(organisation.id);
          }}
          selectedValue={organisationId}
          loading={false}
          disableClearable
        />
      </div>
      {getPractitionersStatus === 'success' && (
        <>
          <FormHeader
            icon={PersonIcon}
            title={t('ui.event-management.manage-units.user.title')}
          />
          <PractitionerAutocomplete
            options={practitioners!}
            onSelect={(practitioner) => {
              handlePractitionerChange(practitioner.id);
            }}
            selectedValue={practitionerId}
            loading={false}
            disableClearable
          />
        </>
      )}
      {getWalletStatus === 'success' && (
        <StyledFormDiv>
          <FormHeader
            icon={BarChartIcon}
            title={t('ui.event-management.manage-units.actions.title')}
            color="orange"
          />
          <RadioGroup
            aria-label="transactionType"
            value={transactionType}
            onChange={handleTransactionChange}
          >
            {Object.entries(TRANSACTION_TRANSLATION).map(
              ([value, labelKey]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio color="primary" />}
                  label={t(labelKey, { fullName })}
                />
              ),
            )}
          </RadioGroup>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid sx={{ marginBottom: '1rem' }}>
              <StyledInputText
                id="reference"
                label={t('ui.event-management.manage-units.reference.label')}
                placeholder={t(
                  'ui.event-management.manage-units.reference.placeholder',
                )}
                helperText={t(
                  'ui.event-management.manage-units.reference.error',
                )}
                error={Boolean(errors.reference)}
                fullWidth
                {...register('reference', { required: true })}
              />
            </Grid>
            {isCrediting ? (
              <StyledInputText
                id="creditAmount"
                label={t('ui.event-management.manage-units.add.amount.label')}
                key="creditAmount"
                type={InputType.NUMBER}
                min={MINIMUM_TRANSACTION_AMOUNT}
                max={MAXIMUM_TRANSACTION_AMOUNT}
                step="1"
                helperText={creditHelperText()}
                error={Boolean(errors.creditAmount)}
                required
                fullWidth
                {...register('creditAmount', {
                  required: true,
                  min: MINIMUM_TRANSACTION_AMOUNT,
                  max: MAXIMUM_TRANSACTION_AMOUNT,
                  pattern: NUMERICAL_ONLY_REGEX,
                })}
              />
            ) : (
              <StyledInputText
                id="removalAmount"
                label={t(
                  'ui.event-management.manage-units.remove.amount.label',
                )}
                key="removalAmount"
                type={InputType.NUMBER}
                min={MINIMUM_TRANSACTION_AMOUNT}
                max={MAXIMUM_TRANSACTION_AMOUNT}
                step="1"
                helperText={removalHelperText()}
                error={Boolean(errors.removalAmount)}
                required
                fullWidth
                {...register('removalAmount', {
                  required: true,
                  min: MINIMUM_TRANSACTION_AMOUNT,
                  max: MAXIMUM_TRANSACTION_AMOUNT,
                  pattern: NUMERICAL_ONLY_REGEX,
                  validate: {
                    threshold: (val: number) => oldBalance - val >= 0,
                  },
                })}
              />
            )}
          </Grid>
          <StyledInputText
            id="comment"
            label={t('ui.event-management.manage-units.comment.label')}
            placeholder={t(
              'ui.event-management.manage-units.comment.placeholder',
            )}
            fullWidth
            helperText={
              !errors.comment
                ? t('ui.event-management.manage-units.information.label')
                : t(getErrorMessage(errors.comment))
            }
            error={Boolean(errors.comment)}
            type={InputType.TEXT}
            {...register('comment', { required: true })}
            sx={{
              marginTop: '1rem',
            }}
          />
          {showSummary && (
            <StyledSummary>
              <Span variant="body-bold">{summaryMessage}</Span>
            </StyledSummary>
          )}
          <Grid container justifyContent="space-between">
            <div />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isLoading}
            >
              {buttonLabel}
            </Button>
          </Grid>
          <Snackbar
            open={isError}
            autoHideDuration={6000}
            onClose={resetMutation}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={resetMutation}
            >
              <AlertTitle>
                {t('ui.event-management.manage-units.transaction.error.title')}
              </AlertTitle>
              {errorMessage}
            </Alert>
          </Snackbar>
        </StyledFormDiv>
      )}
    </StyledForm>
  );
};

export default ManageUnitsForm;
