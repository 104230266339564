import React from 'react';
import FormHeader from 'components/FormHeader';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  InputType,
  RadioGroupControl,
  Text,
} from '@insights-ltd/design-library';
import CheckboxGroup from 'components/CheckboxGroup/CheckboxGroup';
import useGetProducts from 'components/hooks/useGetProducts';
import { Control, FieldValues, Path } from 'react-hook-form';
import { Box, Button, Grid, styled } from '@mui/material';
import UnescapedTrans from 'components/i18n/UnescapedTrans';
import { PermissionRole } from 'pages/AddUser/AddUserForm/types';
import {
  getPermissionRolesText,
  RoleOptions,
} from 'pages/AddUser/AddUserForm/helper';
import { useAuth } from 'contexts/AuthContext';
import { LicensedProduct, Option } from 'types/types';

type UserDetailsSectionProps<T extends FieldValues> = {
  email: string;
  control: Control<T>;
  permissionRole: PermissionRole;
  setPermissionRole: any;
  setValue: any;
  roleOptions: RoleOptions;
  existingPractitioner: any | undefined;
};

const StyledPersonIcon = styled(PersonIcon)(({ theme }) => ({
  fill: theme.palette.pink.main,
  height: '20px',
}));

const StyledP = styled('p')({
  lineHeight: '28px',
  color: '#6D6D6D',
  marginTop: '-1.5rem',
});

const StyledList = styled('ul')(({ theme }) => ({
  lineHeight: '28px',
  color: theme.palette.text.primary,
  paddingLeft: '20px',
}));

const StyledInputText = styled(InputText)(({ theme }) => ({
  '& > label': {
    color: theme.palette.text.primary,
  },
  marginBottom: '1rem',
}));

const StyledDiv = styled('div')({
  marginBottom: '0.5rem',
});

const UserDetailsSection = <T extends FieldValues>({
  email,
  permissionRole,
  setPermissionRole,
  control,
  setValue,
  roleOptions,
  existingPractitioner,
}: UserDetailsSectionProps<T>) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const hasUpdateRolesPermission = user?.permissions.Practitioner_UpdateRoles;
  const products = useGetProducts();

  return (
    <div data-testid="create-user-details">
      <FormHeader
        icon={StyledPersonIcon}
        title={t('ui.event-management.users.create.details')}
        color="pink"
      />
      <StyledP>
        <UnescapedTrans i18nKey="ui.event-management.users.invite.header.description" />
      </StyledP>
      <StyledInputText
        id="emailAddress"
        label={t('ui.event-management.users.create.email')}
        placeholder={email}
        type={InputType.EMAIL}
        required
        disabled
        fullWidth
      />
      <CheckboxGroup
        control={control}
        rules={{
          required: true,
          validate: { CustomMinLength: (v) => v.length > 0 },
        }}
        name={'licensedProducts' as Path<T>}
        title={t('ui.event-management.products.title')}
        errorMessage={t('ui.event-management.products.error-required')}
        error={false}
        options={Object.entries(products).map(
          ([value, option]: [string, Option<LicensedProduct>]) => ({
            value: value as LicensedProduct,
            label: t(option.label),
            disabled: option.disabled,
          }),
        )}
        setValue={setValue}
      />
      <StyledDiv />
      {!existingPractitioner || hasUpdateRolesPermission ? (
        <StyledDiv sx={{ marginTop: '16px', marginBottom: '0' }}>
          <Text variant="body-bold" as="h2">
            {t('ui.event-management.users.invite.permission-role.label')}
          </Text>
          <RadioGroupControl
            options={roleOptions}
            name="roles"
            title="Select a permission role"
            value={permissionRole}
            onChange={setPermissionRole}
            variant="boxed"
            sx={{
              marginTop: '0.5rem',
              '& > div > div:not(:first-of-type)': {
                paddingLeft: '0.5rem !important',
              },
            }}
          />
          <StyledList>
            {getPermissionRolesText(t)[permissionRole].map((text: any) => (
              <li key={text}>{text}</li>
            ))}
          </StyledList>
        </StyledDiv>
      ) : null}
      <Box mt="40px">
        <Grid container justifyContent="space-between">
          <div />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            {t('ui.event-management.users.create.crumb')}
          </Button>
        </Grid>
      </Box>
    </div>
  );
};

export default UserDetailsSection;
