import React from 'react';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { P } from '@insights-ltd/design-library';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  chapter: string;
  count: number;
  updating: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const SaveSelectionBar = ({
  chapter,
  count,
  updating,
  onCancel,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();

  return (
    <AppBar
      component="div"
      position="sticky"
      sx={(theme) => ({
        zIndex: 0,
        top: 'auto',
        bottom: '0',
        height: '72px',
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[100],
      })}
    >
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({
            padding: `${theme.spacing(spacingSizeMap.S)} 0`,
          })}
        >
          {aboveMobile ? (
            <Grid item>
              <P variant="body-bold">
                {t(
                  'ui.event-management.events.assign-chapters.save-bar.assign-to-invitees',
                  { chapter, count },
                )}
              </P>
            </Grid>
          ) : null}
          <Grid item sx={{ flexGrow: '1' }} />
          <Grid
            item
            sx={(theme) => ({
              '& *': {
                margin: `${theme.spacing(spacingSizeMap.S)} 0 ${theme.spacing(
                  spacingSizeMap.S,
                )} ${theme.spacing(spacingSizeMap.S)}`,
              },
            })}
          >
            <Button
              sx={{ maxHeight: '48px' }}
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              {t('ui.event-management.events.assign-chapters.cancel')}
            </Button>
            <Button
              sx={{
                marginLeft: '8px',
                maxHeight: '48px',
              }}
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={updating}
              endIcon={
                updating && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )
              }
            >
              {t('ui.event-management.events.assign-chapters.save-selection')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default SaveSelectionBar;
