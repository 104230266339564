import { Alert, Box, Grid, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Score,
  WheelPositionByOrganisation,
  transformTeamWheelByOrganisationStructure,
} from 'api';
import { H1, H2, P } from '@insights-ltd/design-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import BackButton from 'components/BackButton/BackButton';
import { backButtonStyles } from 'components/layout/BreadcrumbLayout/BreadcrumbLayout';
import DownloadAll from './DownloadAll';
import {
  getInitialsFromScores,
  getWheelPositionCountsFromScores,
} from './scoreUtils';
import SelectLanguageControl from './SelectLanguageControl';
import SelectTypeControl from './SelectTypeControl';
import { TabTeamEnergyChart, TabTeamNames, TabTeamWheel } from './tabs';
import { WheelState, useWheelContext } from './hooks/useWheelContext';

type TeamWheelType = 'Experience' | 'Team';
type ScoresViewType = 'wheel' | 'names';

type TeamWheelProps = {
  type: TeamWheelType;
  test: boolean;
  rawScores: (Score & { organisationId?: string; organisationName?: string })[];
  name: string;
  teamLearnerCount: number;
  purchasedCount: number;
  eventId: string | undefined;
  teamId: string | undefined;
  rawWheelPositions: WheelPositionByOrganisation[];
};

const TeamWheel = ({
  type,
  test,
  rawScores,
  name,
  purchasedCount,
  eventId,
  teamId,
  rawWheelPositions,
  teamLearnerCount,
}: TeamWheelProps) => {
  const { t } = useTranslation();
  const [scoresView, setScoresView] = useState<ScoresViewType>('wheel');
  const [downloadErrorFileName, setDownloadErrorFileName] =
    useState<string>('');
  const [
    { scoresGroupingType, isLearnerCountExceeded, activeOrganisations = {} },
    setState,
  ] = useWheelContext();

  const processedWheelPositions = transformTeamWheelByOrganisationStructure(
    activeOrganisations,
    rawWheelPositions,
  ).wheelPositions;

  const scoresWithInitials = getInitialsFromScores(rawScores);
  const wheelDisplayScores =
    scoresGroupingType === 'DISTINCT'
      ? scoresWithInitials
      : getWheelPositionCountsFromScores(rawScores);

  const handleReportError = (fileName: string) =>
    setDownloadErrorFileName(fileName);

  const resetOrganisationFilter = () => {
    const newValue: Record<string, boolean> = {};

    Object.keys(activeOrganisations ?? {}).forEach((id) => {
      newValue[id] = true;
    });

    setState((prevState: WheelState) => ({
      ...prevState,
      activeOrganisations: newValue,
    }));
  };

  const parentLink =
    type === 'Experience' ? `/experiences/${eventId}` : `/teams/${teamId}`;

  const backTextKey =
    type === 'Experience'
      ? 'ui.event-management.events.add-learners-and-contributors.learner.back-to-experience-overview'
      : 'ui.event-management.teams.wheel.back';

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={8}>
          <BackButton
            parentLink={parentLink}
            i18nKey={backTextKey}
            sx={backButtonStyles}
          />
          <H1 variant="h2">
            {t('ui.event-management.events.team-wheel.heading')}
          </H1>
          <Box
            sx={(theme) => ({
              mt: theme.spacing(spacingSizeMap.M),
              mb: theme.spacing(spacingSizeMap.L),
            })}
          >
            {isLearnerCountExceeded && (
              <Alert
                severity="warning"
                variant="standard"
                icon={false}
                sx={{ margin: '2rem 0 ' }}
              >
                <P variant="body-bold" color="textPrimary">
                  {t(
                    'ui.event-management.events.team-wheel.cannot-download.warning',
                  )}
                </P>
              </Alert>
            )}
            <H2 variant="h4" color="textSecondary">
              {t(
                'ui.event-management.events.download-team-wheel.purchase.count',
                { count: purchasedCount, total: teamLearnerCount },
              )}
            </H2>
            {type === 'Team' ? (
              <Typography
                sx={(theme) => ({
                  color: theme.palette.orange.dark,
                  fontWeight: theme.typography.fontWeightBold,
                  marginTop: '0.5rem',
                })}
              >
                {t(
                  'ui.event-management.events.download-team-wheel.discovery_message',
                )}
              </Typography>
            ) : null}
          </Box>
          {!['', 'Invalid svgId!'].includes(downloadErrorFileName) && (
            <Box sx={(theme) => ({ py: theme.spacing(spacingSizeMap.M) })}>
              <Alert variant="standard" severity="error">
                <P variant="body-small" color="error">
                  {t('ui.event-management.events.team-wheel.download.error', {
                    fileName: downloadErrorFileName,
                  })}
                </P>
              </Alert>
            </Box>
          )}
        </Grid>
        <Grid item xs={4}>
          {!isLearnerCountExceeded && (
            <DownloadAll
              wheelDisplayScores={wheelDisplayScores}
              initialledScores={scoresWithInitials}
              scores={rawScores}
              reportError={handleReportError}
              name={name}
              disabled={purchasedCount < 1}
            />
          )}
        </Grid>
      </Grid>
      <TabContext value={scoresView}>
        <Box sx={(theme) => ({ mb: theme.spacing(spacingSizeMap.L) })}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <SelectLanguageControl />
            </Grid>
            <Grid container item xs={12} md={8} justifyContent="space-around">
              <Grid item xs={12} md={8}>
                <SelectTypeControl />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <TabList
              onChange={(_, value) => {
                setScoresView(value);
                resetOrganisationFilter();
              }}
              textColor="primary"
              indicatorColor="primary"
              aria-label="team-wheel-tabs"
            >
              <Tab
                label={t('ui.event-management.events.team-wheel.wheel-tab')}
                value="wheel"
              />
              <Tab
                label={t('ui.event-management.events.team-wheel.names-tab')}
                value="names"
              />
              <Tab
                label={t('ui.event-management.events.team-wheel.charts-tab')}
                value="charts"
                disabled={isLearnerCountExceeded}
              />
            </TabList>
          </Grid>
        </Grid>
        <TabPanel
          style={{ padding: 0 }}
          value="wheel"
          aria-label={t(
            'ui.event-management.events.team-wheel.tabs.wheel-view',
          )}
        >
          <TabTeamWheel
            type={type}
            rawScores={rawScores}
            name={name}
            availableCount={purchasedCount}
            setDownloadErrorFileName={setDownloadErrorFileName}
            wheelPositions={processedWheelPositions}
          />
        </TabPanel>
        <TabPanel
          style={{ padding: 0 }}
          value="names"
          aria-label={t(
            'ui.event-management.events.team-wheel.tabs.names-view',
          )}
        >
          <TabTeamNames
            type={type}
            test={test}
            rawScores={rawScores}
            name={name}
            availableCount={purchasedCount}
            setDownloadErrorFileName={setDownloadErrorFileName}
          />
        </TabPanel>
        <TabPanel
          style={{ padding: 0 }}
          value="charts"
          aria-label={t(
            'ui.event-management.events.team-wheel.tabs.charts-view',
          )}
        >
          <TabTeamEnergyChart
            rawScores={rawScores}
            name={name}
            availableCount={purchasedCount}
            setDownloadErrorFileName={setDownloadErrorFileName}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default TeamWheel;
