import { GroupResponse, Group, GetGroupResponse } from 'api/httpEntities';
import { generateMockOrgsResponse } from './organisations';

export const generateMockGroups = (numberOfGroups = 1): Group[] => {
  const groups: Group[] = [];
  for (let i = 0; i < numberOfGroups; i += 1) {
    groups.push({
      id: `test-${i}`,
      name: `Test ${i}`,
      type: i % 2 > 0 ? 'CUSTOMER' : 'PARTNER',
    });
  }

  return groups;
};

export const generateMockGroupResponses = (
  numberOfGroups = 1,
  numberOfOrgsPerGroup = 2,
): GroupResponse[] => {
  return generateMockGroups(numberOfGroups).map((group) => ({
    ...group,
    organisations: generateMockOrgsResponse(numberOfOrgsPerGroup),
  }));
};

export const generateMockOrganisationGroup = (testIds: string[]) => {
  const testId = testIds[0];
  return generateMockGroups(1).map((group) => ({
    ...group,
    organisations: [
      {
        id: testId,
        name: 'Test Organisation',
        supportedRoles: ['STANDARD' as const],
      },
    ],
  }));
};

export const getGroupResponse: GroupResponse = generateMockGroupResponses(1)[0];

export const getGroupPartnerTypeResponse: GroupResponse =
  generateMockGroupResponses(1)[0];

export const getGroupCustomerTypeResponse: GroupResponse =
  generateMockGroupResponses(2)[1];

export const getGroupsResponse: GetGroupResponse = {
  organisationGroups: generateMockGroupResponses(4, 4),
};

export const getGroupResponseWithNoOrgs: GetGroupResponse = {
  organisationGroups: [generateMockGroupResponses(2, 0)[1]],
};

export const GROUP_TEST_ID_0 = 'test-0';
export const GROUP_TEST_ID_1 = 'test-1';
export const GROUP_TEST_ID_2 = 'test-2';
