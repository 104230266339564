import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { H2 } from '@insights-ltd/design-library';
import { Theme, styled, SxProps } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

const StyledH2 = styled(H2)({
  marginLeft: '12px !important',
});

const StyledDiv = styled('div')({});

const SectionHeader = ({
  icon: Icon,
  title,
  sx = {},
}: {
  icon: React.ReactElement;
  title: string;
  sx?: SxProps<Theme>;
}) => (
  <StyledDiv sx={sx}>
    <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {Icon}
      <StyledH2 variant="body-large">{title}</StyledH2>
    </Grid>
    <Box my={(theme) => theme.spacing(spacingSizeMap.S)}>
      <Divider />
    </Box>
  </StyledDiv>
);

export default SectionHeader;
