import { P } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

const StyledNoLearnerMsg = styled(P)(({ theme }) => ({
  paddingTop: theme.spacing(spacingSizeMap.S),
}));

const LearnersListEmptyMsg = () => {
  const { t } = useTranslation();
  return (
    <StyledNoLearnerMsg>
      {t('ui.event-management.learners.teams.no-learners')}
    </StyledNoLearnerMsg>
  );
};

export default LearnersListEmptyMsg;
