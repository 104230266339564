import LanguageIcon from '@mui/icons-material/Translate';
import { Stack } from '@mui/material';
import { P } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRONOUNS_SINGULAR_INCLUSIVE, getDialect } from 'variables';
import { Dialects, Pronoun } from 'types/dialects';

const PronounsDialectLabel = ({
  dialect,
  pronoun,
}: {
  dialect: Dialects;
  pronoun?: Pronoun;
}) => {
  const { t } = useTranslation();
  const { textKey: dialectKey } = getDialect(dialect) || {
    textKey: 'ui.event-management.learner.profiles.unsupported-language',
  };

  const pronounKey =
    (pronoun &&
      PRONOUNS_SINGULAR_INCLUSIVE.find(
        (p) => p.value === pronoun?.toUpperCase(),
      )?.textKey) ??
    '';

  return (
    <Stack direction="row" gap={1} alignItems="top">
      <LanguageIcon fontSize="small" sx={{ mt: '0.2rem' }} />
      <P variant="body-bold">
        {t(dialectKey)} - {t(pronounKey)}
      </P>
    </Stack>
  );
};

export default PronounsDialectLabel;
