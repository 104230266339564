import React, { useState, useEffect } from 'react';
import { Box, Button, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  id?: string;
  link: string;
  linkCopied?: boolean;
  onCopy?: (id: string) => void;
};

const StyledSpan = styled('span')(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.S),
  fontWeight: theme.typography.fontWeightBold,
}));

const LinkDisplay = ({ id, link, linkCopied, onCopy }: Props) => {
  const { t } = useTranslation();

  const copy = t(
    'ui.event-management.evaluator-links.completed-evaluators.link.button.copy',
  );
  const copied = t(
    'ui.event-management.evaluator-links.completed-evaluators.link.button.copied',
  );

  const [isCopied, setIsCopied] = useState<boolean>(!!linkCopied);

  const handleCopy = async () => {
    navigator.clipboard.writeText(link).then();
    if (!isCopied) {
      setIsCopied(true);
      if (onCopy && id) {
        onCopy(id);
      }
    }
  };

  useEffect(() => {
    setIsCopied(!!linkCopied);
  }, [copied, copy, linkCopied]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledSpan>
        {t(
          'ui.event-management.evaluator-links.completed-evaluators.link.label',
        )}
      </StyledSpan>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <TextField
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            fieldset: {
              borderColor: `${theme.palette.grey[600]} !important`,
            },
          })}
          inputProps={{
            readOnly: true,
            style: {
              width: '534px',
              height: '48px',
              padding: '0 14px',
              cursor: 'pointer',
            },
          }}
          variant="outlined"
          defaultValue={link}
          onClick={() => handleCopy()}
          disabled
        />
        <Button
          sx={(theme) => ({
            maxWidth: '100px',
            maxHeight: '48px',
            minWidth: '100px',
            minHeight: '48px',
            width: '100%',
            height: '100%',
            margin: `0 ${theme.spacing(spacingSizeMap.S)}`,
            padding: '0 !important',
            color: theme.palette.blue.main,
            border: `2px solid ${theme.palette.blue.main}`,
          })}
          variant="outlined"
          onClick={() => handleCopy()}
        >
          {isCopied ? copied : copy}
        </Button>
      </Box>
    </Box>
  );
};

export default LinkDisplay;
