import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { H4, P, Span } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { BulkUploadInviteeData } from './bulkUploadUtils';

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  inviteeList: BulkUploadInviteeData[];
};

const ExtractRowNumbersAsText = (entries: BulkUploadInviteeData[]): string =>
  entries.reduce<string>(
    (previous, current) =>
      previous === ''
        ? `${current.rowNumber + 1}`
        : `${previous}, ${current.rowNumber + 1}`,
    '',
  );

const ConfirmUploadDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  inviteeList,
}: Props) => {
  const { t } = useTranslation();
  const inviteeValidList = inviteeList.filter(
    (invitee) => invitee.validEmail && invitee.validFullName,
  );
  const inviteeInvalidList = inviteeList.filter(
    (invitee) => !invitee.validEmail || !invitee.validFullName,
  );
  const inviteeInvalidEmails = inviteeInvalidList.filter(
    (invitee) => !invitee.validEmail,
  );
  const inviteeEmptyFields = inviteeInvalidList.filter(
    (invitee) => invitee.email === '' || invitee.fullName === '',
  );

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="confirm-upload-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="confirm-upload-dialog-title">
        {t('ui.event-management.events.add-learners.button.upload-learners')}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans
            i18nKey="ui.event-management.events.add-learners.confirm-upload.body.bold"
            components={{ bold: <Span variant="body-bold" /> }}
            values={{ count: inviteeList.length }}
          />
        </DialogContentText>
        <Box
          mt={(theme) => theme.spacing(spacingSizeMap.S)}
          mb={(theme) => theme.spacing(spacingSizeMap.S)}
        >
          <H4>
            <Trans
              i18nKey="ui.event-management.events.add-learners.invalid-entries-text.bold"
              components={{ bold: <Span variant="body-bold" /> }}
              values={{ count: inviteeInvalidList.length }}
            />
          </H4>
        </Box>
        <Box>
          <Grid
            container
            direction="column"
            spacing={spacingSizeMap.M}
            mx={0}
            my={0}
          >
            {inviteeInvalidEmails.length > 0 && (
              <Box
                mt={(theme) => theme.spacing(spacingSizeMap.XS)}
                mb={(theme) => theme.spacing(spacingSizeMap.XS)}
              >
                <P color="error" variant="body-bold">
                  {t(
                    inviteeInvalidEmails.length < 2
                      ? 'ui.event-management.events.add-learners.invalid-email-text'
                      : 'ui.event-management.events.add-learners.invalid-email-text_plural',
                  )}
                </P>
                <P color="error">
                  {t(
                    inviteeInvalidEmails.length < 2
                      ? 'ui.event-management.events.add-learners.invalid-row-text'
                      : 'ui.event-management.events.add-learners.invalid-row-text_plural',
                    { rows: ExtractRowNumbersAsText(inviteeInvalidEmails) },
                  )}
                </P>
              </Box>
            )}
            {inviteeEmptyFields.length > 0 && (
              <Box
                mt={(theme) => theme.spacing(spacingSizeMap.XS)}
                mb={(theme) => theme.spacing(spacingSizeMap.XS)}
              >
                <P color="error" variant="body-bold">
                  {t(
                    inviteeEmptyFields.length < 2
                      ? 'ui.event-management.events.add-learners.empty-field-text'
                      : 'ui.event-management.events.add-learners.empty-field-text_plural',
                  )}
                </P>
                <P color="error">
                  {t(
                    inviteeEmptyFields.length < 2
                      ? 'ui.event-management.events.add-learners.invalid-row-text'
                      : 'ui.event-management.events.add-learners.invalid-row-text_plural',
                    { rows: ExtractRowNumbersAsText(inviteeEmptyFields) },
                  )}
                </P>
              </Box>
            )}
          </Grid>
        </Box>
        <Box
          mt={(theme) => theme.spacing(spacingSizeMap.XS)}
          mb={(theme) => theme.spacing(spacingSizeMap.XS)}
        >
          <Divider />
        </Box>
        <Box
          mt={(theme) => theme.spacing(spacingSizeMap.S)}
          mb={(theme) => theme.spacing(spacingSizeMap.S)}
        >
          <H4>
            <Trans
              i18nKey="ui.event-management.events.add-learners.valid-entries-text.bold"
              components={{ bold: <Span variant="body-bold" /> }}
              values={{ count: inviteeValidList.length }}
            />
          </H4>
        </Box>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          <Grid container direction="column">
            {inviteeValidList.map((invitee) => (
              <Grid item key={invitee.email} data-testid="bulk-upload-invitee">
                <H4>{invitee.fullName}</H4>
                <P>{invitee.email}</P>
                <Box
                  mt={(theme) => theme.spacing(spacingSizeMap.XS)}
                  mb={(theme) => theme.spacing(spacingSizeMap.XS)}
                >
                  <Divider light />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={(theme) => theme.spacing(spacingSizeMap.S)}
          mb={(theme) => theme.spacing(spacingSizeMap.S)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onConfirm}
          disabled={isLoading}
          endIcon={
            isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )
          }
        >
          {t('ui.event-management.events.add-learners.confirm-upload.button', {
            count: inviteeValidList.length,
          })}
        </Button>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
        <Button variant="outlined" color="primary" fullWidth onClick={onClose}>
          {t('ui.event-management.events.add-learners.cancel-upload.button')}
        </Button>
        <Box mb={(theme) => theme.spacing(spacingSizeMap.S)} />
      </DialogContent>
    </MuiDialog>
  );
};

export default ConfirmUploadDialog;
