import { useTranslation } from 'react-i18next';

export const useGetViewPermissionOptions = (isMultiOrg: boolean) => {
  const { t } = useTranslation();
  if (isMultiOrg) {
    return [
      {
        value: 'PRIVATE',
        label: t('ui.event-management.team.view-permissions'),
      },
    ];
  }
  return [
    {
      value: 'PRIVATE',
      label: t('ui.event-management.teams.permissions.option-private'),
    },
    {
      value: 'ORGANISATION',
      label: t('ui.event-management.teams.permissions.option-organisation'),
    },
  ];
};

export type TeamVariant = 'single-org' | 'multi-org';
