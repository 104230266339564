import React, { useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Team from 'pages/Team';
import { ChangePassword } from 'pages/ChangePassword';
import ManageUnits from 'pages/ManageUnits';
import TransferUnits from 'pages/TransferUnits';
import CompletedEvaluators from 'pages/CompletedEvaluators/CompletedEvaluators';
import CreateGroup from 'pages/CreateGroup/CreateGroup';
import Group from 'pages/Group/Group';
import EditGroup from 'pages/EditGroup/EditGroup';
import { DEFAULT_ROUTE } from 'variables';
import FloatingButton from 'components/FloatingButton';
import { QuestionMark } from '@mui/icons-material';
import { styled } from '@mui/material';
import AddUser from 'pages/AddUser/AddUser';
import { useTranslation } from 'react-i18next';
import AddPractitionerToOrgs from 'pages/AddPractitionerToOrgs/AddPractitionerToOrgs';
import EditEvaluatorLinkLearner from 'pages/EditLearner/EditEvaluatorLinkLearner';
import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import CreateEvent from './pages/CreateEvent';
import CreateTeam from './pages/CreateTeam';
import Event from './pages/Event';
import EventLearners from './pages/EventLearners';
import LearnersAndContributors from './pages/LearnersAndContributors';
import AddOrganisation from './pages/AddOrganisation';
import Organisations from './pages/Organisations';
import Learners from './pages/Learners';
import Learner from './pages/Learner';
import EditLearner from './pages/EditLearner';
import EditOrganisation from './pages/EditOrganisation';
import SignIn from './pages/SignIn';
import ForgottenPassword from './pages/ForgottenPassword';
import SignUp from './pages/SignUp';
import PasswordReset from './pages/PasswordReset';
import Onboard from './pages/Onboard';
import EditEvent from './pages/EditEvent';
import AssignChapters from './pages/AssignChapters';
import DownloadProfiles from './pages/DownloadProfiles';
import DownloadTeamWheel from './pages/DownloadTeamWheel';
import Organisation from './pages/Organisation';
import EditAccount from './pages/EditAccount';
import EditPractitioner from './pages/EditPractitioner';
import Account from './pages/Account';
import Contact from './pages/Contact';
import Thanks from './pages/Contact/Thanks';
import PractitionerAccount from './pages/PractitionerAccount';
import Transactions from './pages/Transactions';
import EditTeam from './pages/EditTeam';
import Header from './components/Header';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import EvaluatorLinks from './pages/EvaluatorLinks';
import EvaluatorLinkLearner from './pages/Learner/EvaluatorLinkLearner';
import CreateGroupOrganisation from './pages/CreateGroupOrganisation/CreateGroupOrganisation';
import { useGATrackPage } from './useGATrackPage';
import { isPreferredDialect } from './domain/dialect';

function redirectParam(path: string) {
  return ['', '/'].includes(path) ? '' : `?redirectTo=${path}`;
}

const RouteAwareNavigate = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const path = redirect === 'false' ? '' : redirectParam(pathname);
  return <Navigate to={`/signin${path}`} replace />;
};

const HandleUnknownRoute = () => {
  const [searchParams] = useSearchParams();
  const redirectRoute = searchParams.get('redirectTo') || DEFAULT_ROUTE;
  return <Navigate to={redirectRoute} />;
};

const UnauthenticatedRoutes = () => (
  <Routes>
    <Route path="/signin" element={<SignIn />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/reset-password" element={<PasswordReset />} />
    <Route path="/forgotten-password" element={<ForgottenPassword />} />
    <Route path="/onboard" element={<Onboard />} />
    <Route path="*" element={<RouteAwareNavigate />} />
  </Routes>
);

const FloatingButtonPostion = styled('div')({
  position: 'fixed',
  right: '1.5rem',
  bottom: '6rem',
});

const StyledDiv = styled('div')({
  flex: '1 0 auto',
});

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <StyledDiv>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/experiences" element={<Events />} />
          <Route path="/experiences/create" element={<CreateEvent />} />
          <Route path="/experiences/:eventId" element={<Event />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/thanks" element={<Thanks />} />
          <Route
            path="/experiences/:eventId/learners"
            element={<EventLearners />}
          />
          <Route
            path="/experiences/:eventId/learners-and-contributors"
            element={<LearnersAndContributors />}
          />
          <Route
            path="/experiences/:eventId/assign-chapters"
            element={<AssignChapters />}
          />
          <Route
            path="/experiences/:eventId/download-profiles"
            element={<DownloadProfiles />}
          />
          <Route
            path="/experiences/:eventId/download-team-wheel"
            element={<DownloadTeamWheel type="Experience" />}
          />
          <Route path="/experiences/:eventId/edit/*" element={<EditEvent />} />
          <Route path="/teams/create" element={<CreateTeam />} />
          <Route path="/teams/:teamId/edit" element={<EditTeam />} />
          <Route
            path="/teams/:teamId/created/*"
            element={<Team createSuccess />}
          />
          <Route
            path="/teams/:teamId/download-team-wheel"
            element={<DownloadTeamWheel type="Team" />}
          />
          <Route path={'/teams/:teamId/*'} element={<Team />} />
          <Route
            path="/account/manage-units"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Unit_Update', scope: 'Global' },
                  { action: 'Unit_ReadAll', scope: 'Global' },
                ]}
              >
                <ManageUnits />
              </ProtectedRoute>
            }
          />
          <Route path="/account/transfer-units" element={<TransferUnits />} />
          <Route path="/organisations/invite-user" element={<AddUser />} />
          <Route path="/organisations" element={<Organisations />} />
          <Route
            path="/organisations/add"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Create', scope: 'Global' },
                ]}
              >
                <AddOrganisation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_ReadSingle', scope: 'Local' },
                ]}
              >
                <Organisation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/insights/edit"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Insights_Update', scope: 'Global' },
                ]}
              >
                <EditOrganisation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId/edit"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Update', scope: 'Local' },
                ]}
              >
                <EditOrganisation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId/edit"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Group_Update', scope: 'Local' },
                ]}
              >
                <EditGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/add"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Group_Create', scope: 'Global' },
                ]}
              >
                <CreateGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Organisation_Group_ReadSingle', scope: 'Local' },
                ]}
              >
                <Group />
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups/:groupId/create-organisation"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  {
                    action: 'Organisation_Group_Create_Organisation_In_Group',
                    scope: 'Local',
                  },
                ]}
              >
                <CreateGroupOrganisation />
              </ProtectedRoute>
            }
          />
          <Route path="/learners" element={<Learners />} />
          <Route
            path="/learners/:organisationId/:learnerId/edit"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Learner_Update', scope: 'Local' },
                ]}
              >
                <EditLearner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/learners/:organisationId/:learnerId/*"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Learner_ReadSingle', scope: 'Local' },
                ]}
              >
                <Learner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId/practitioners/:practitionerId/edit"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Practitioner_Update', scope: 'Local' },
                ]}
              >
                <EditPractitioner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId/practitioners/:practitionerId/invite"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  {
                    action: 'Add_User_to_Partner_Organisation',
                    scope: 'Global',
                  },
                ]}
              >
                <AddPractitionerToOrgs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId/practitioners/:practitionerId/transactions"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Practitioner_ReadSingle', scope: 'Local' },
                ]}
              >
                <Transactions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/organisations/:organisationId/practitioners/:practitionerId"
            element={
              <ProtectedRoute
                requiredPermissions={[
                  { action: 'Practitioner_ReadSingle', scope: 'Local' },
                ]}
              >
                <PractitionerAccount />
              </ProtectedRoute>
            }
          />
          <Route path="/account/edit" element={<EditAccount />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/account/transactions/:practitionerId"
            element={<Transactions />}
          />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route path="/evaluator-links" element={<EvaluatorLinks />} />
          <Route
            path="/evaluator-links/:evaluatorLinkId/*"
            element={<CompletedEvaluators />}
          />
          <Route
            path="/evaluator-links/:evaluatorLinkId/:organisationId/:learnerId/*"
            element={<EvaluatorLinkLearner />}
          />

          <Route
            path="/evaluator-links/:evaluatorLinkId/:organisationId/:learnerId/edit"
            element={<EditEvaluatorLinkLearner />}
          />
          <Route path="*" element={<HandleUnknownRoute />} />
        </Routes>
      </StyledDiv>
      {!location.pathname.includes('/contact') && (
        <FloatingButtonPostion>
          <FloatingButton
            icon={<QuestionMark />}
            text={t('ui.event-management.contact.floating-button')}
            onClick={() => navigate('/contact')}
          />
        </FloatingButtonPostion>
      )}
      <Footer />
    </>
  );
};

const AppRoutes = () => {
  useGATrackPage();
  const { user } = useAuth();
  const { i18n } = useTranslation();
  const location = useLocation();

  useMemo(() => {
    if (
      isPreferredDialect(user?.preferredDialect) &&
      i18n.language !== user?.preferredDialect
    ) {
      i18n.changeLanguage(user?.preferredDialect);
    }
  }, [user?.preferredDialect, i18n]);

  if (location.pathname.length > 1 && location.pathname.endsWith('/')) {
    return <Navigate to={location.pathname.slice(0, -1)} />;
  }

  return user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};

export default AppRoutes;
