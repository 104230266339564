import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { P } from '@insights-ltd/design-library';
import Logo from 'components/Svgs/InsightsLogo';
import { PRIVACY_POLICY_URL } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import Background from 'svgs/self-service-background.svg';

const Wrapper = styled('div')({
  width: '100vw',
  height: '100vh',
  overflow: 'scroll',
});

const StyledLogo = styled(Logo)(({ theme }) => ({
  flexShrink: '0',
  width: '178px',
  height: '48px',
  margin: `${theme.spacing(spacingSizeMap.S)} 0 ${theme.spacing(
    spacingSizeMap.S,
  )} 0`,
  fill: theme.palette.grey[100],
}));

const StyledDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '4rem',
  marginBottom: '1rem',
});

const StyledP = styled(P)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '4rem',
  marginBottom: '17px',
});

const SelfServiceLayout = ({ children }: any) => {
  const { t } = useTranslation();
  const currentYear = new Date().getUTCFullYear();
  return (
    <Wrapper>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        sx={{
          minHeight: `calc(100% - 4rem)`,
          backgroundImage: `url("${Background}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Grid item container direction="column" alignItems="center">
          <StyledLogo />
          {children}
        </Grid>
      </Grid>
      <StyledDiv>
        <StyledP variant="body-small" color="textSecondary">
          {t('ui.event-management.copyright-message', { currentYear })}
        </StyledP>
        <Link
          sx={{ fontSize: '14px' }}
          color="primary"
          href={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noopener"
        >
          {t('ui.event-management.footer.our-privacy-notice')}
        </Link>
      </StyledDiv>
    </Wrapper>
  );
};

export default SelfServiceLayout;
