import React from 'react';
import { useTranslation } from 'react-i18next';
import { P } from '@insights-ltd/design-library';
import { AppBar, Button, Container, Grid } from '@mui/material';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

export type Props = {
  count: number;
  onShare: () => void;
  onDownload: () => void;
};

const DownloadSelectionBar = ({ count, onShare, onDownload }: Props) => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile();

  return (
    <AppBar
      component="div"
      position="sticky"
      sx={(theme) => ({
        zIndex: 0,
        top: 'auto',
        bottom: '0',
        height: '72px',
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[100],
      })}
    >
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({
            padding: `${theme.spacing(spacingSizeMap.S)} 0`,
          })}
        >
          {aboveMobile ? (
            <Grid item>
              <P variant="body-bold">
                {t(
                  'ui.event-management.events.download-profiles.learners-selected',
                  { count },
                )}
              </P>
            </Grid>
          ) : null}
          <Grid item sx={{ flexGrow: '1' }} />
          <Grid
            item
            sx={(theme) => ({
              '> * + *': {
                marginLeft: theme.spacing(spacingSizeMap.S),
              },
            })}
          >
            <Button
              sx={{ maxHeight: '48px' }}
              disabled={count === 0}
              variant="contained"
              color="primary"
              onClick={onShare}
            >
              {t(
                'ui.event-management.events.download-profiles.share-profiles',
                { count },
              )}
            </Button>
            <Button
              sx={{
                marginLeft: '8px',
                maxHeight: '48px',
              }}
              disabled={count === 0}
              variant="outlined"
              color="primary"
              onClick={onDownload}
            >
              {t(
                'ui.event-management.events.download-profiles.download-profiles',
                { count },
              )}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default DownloadSelectionBar;
