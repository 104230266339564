import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { useSendContactForm } from 'api';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { H1, P } from '@insights-ltd/design-library';
import { Box, Divider, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import FullHeight from 'components/FullHeight';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import ContactForm from './ContactForm';
import { ContactFormData } from './types';
import ContactEmailErrorDialog from './ContactEmailErrorDialog';

const Description = styled(P)({
  maxWidth: '720px',
  lineHeight: '28px,',
});

const Contact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const { mutate: sendContactEmails } = useSendContactForm();

  const onSubmit: any = (formData: ContactFormData) => {
    sendContactEmails(formData, {
      onSuccess: () => {
        navigate('/contact/thanks');
      },
      onError: () => {
        setIsError(true);
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.contact.page-title')}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container>
          <Box py={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Breadcrumbs
              crumbs={{
                '/': t('ui.event-management.dashboard.home.title'),
              }}
              activeText={t('ui.event-management.contact.breadcrumb-title')}
            />
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <H1 variant="h2">
                {t('ui.event-management.contact.page-header')}
              </H1>
            </Grid>
          </Grid>
          <Box my={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Divider />
          <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
          <Container
            maxWidth={false}
            sx={{ padding: '0 !important', float: 'left', maxWidth: '720px' }}
          >
            <Box mb={(theme) => theme.spacing(spacingSizeMap.S)}>
              <Description color="textSecondary">
                {t('ui.event-management.contact.description')}
              </Description>
            </Box>
            <Box>
              <ContactForm onSubmit={onSubmit} />
            </Box>
            <Box mb={(theme) => theme.spacing(spacingSizeMap.L)} />
          </Container>
        </Container>
      </FullHeight>
      <ContactEmailErrorDialog
        open={isError}
        onClose={() => setIsError(false)}
      />
    </>
  );
};

export default Contact;
