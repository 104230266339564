import { InputAdornment, styled } from '@mui/material';
import { InputText, InputType } from '@insights-ltd/design-library';
import SvgSearch from '@insights-ltd/design-library/src/components/Svgs/streamline-regular/SearchV2';
import React, { Ref, forwardRef, useImperativeHandle, useRef } from 'react';

type Props = {
  id: string;
  testId: string;
  name: string;
  placeholder: string;
  value: string | null;
  onChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear: () => void;
  clearText: string;
  type: InputType;
  showClearNowButton?: boolean;
};

interface StyledInputTextProps {
  value: string;
}

const StyledInputText = styled(InputText)<StyledInputTextProps>(
  ({ theme, value }) => ({
    width: '260px',
    backgroundColor: theme.palette.common.white,
    '& > div': {
      width: '100%',
      padding: '10px',
    },
    '& > * > input': {
      width: '120px',
      ...(value
        ? {
            [theme.breakpoints.down('sm')]: {
              width: `59%`,
            },
          }
        : {
            margin: 0,
          }),
    },
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
    },
  }),
);

const StyledSearchIcon = styled(SvgSearch)({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'text',
  transition: 'box-shadow 200ms',
});

const StyledButton = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  font: 'inherit',
  outline: 'inherit',
  padding: `0 !important`,
  margin: '0 3px 0 0!important',
  color: theme.palette.blue.main,
  cursor: 'pointer',
  '&> span': {
    padding: '0 !important',
  },
}));

const SearchInput = (
  {
    id,
    name,
    testId,
    placeholder,
    value,
    onChange,
    onClear,
    onKeyDown,
    clearText,
    type,
    showClearNowButton = true,
  }: Props,
  ref?: Ref<any>,
) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      searchInputRef.current?.focus();
    },
  }));

  return (
    <StyledInputText
      id={id}
      ref={searchInputRef}
      name={name}
      data-testid={testId}
      icon={
        <InputAdornment position="start">
          <StyledSearchIcon />
        </InputAdornment>
      }
      placeholder={placeholder}
      onChange={(e: { target: { value: string } }) => onChange(e.target.value)}
      endIcon={
        value && showClearNowButton ? (
          <InputAdornment position="end">
            <StyledButton
              data-testid="filter-clear-button"
              type="button"
              onClick={onClear}
            >
              {clearText}
            </StyledButton>
          </InputAdornment>
        ) : null
      }
      onKeyDown={onKeyDown}
      value={value ?? ''}
      type={type}
    />
  );
};

export default forwardRef(SearchInput);
