import React from 'react';
import { Dialog, Span, Text } from '@insights-ltd/design-library';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import MailSentIcon from 'components/Svgs/icons/MailSent';
import { EventType } from 'types/types';

export type Props = {
  open: boolean;
  dialect?: string;
  onClose: () => void;
  singleProfile?: boolean;
  learnerInvites?: boolean;
  eventType?: EventType;
};

const EmailProgressDialog = ({
  open,
  onClose,
  singleProfile,
  dialect,
  learnerInvites = false,
  eventType,
}: Props) => {
  const { t } = useTranslation();
  const n = singleProfile ? 1 : 2;
  const isDFC = eventType === 'INSIGHTS_DISCOVERY_FULL_CIRCLE';

  let title = t('ui.event-management.email-profiles.title', {
    count: n,
  });
  let header = t('ui.event-management.email-profiles.message.heading', {
    count: n,
  });
  let body = t('ui.event-management.email-profiles.message.body', {
    count: n,
  });

  if (dialect) {
    title = t('ui.event-management.email-profiles.title.language', {
      lang: t(dialect),
    });
  }

  if (learnerInvites) {
    title = t('ui.event-management.email-profiles.title.invite', {
      count: n,
      type: isDFC
        ? t('ui.event-management.email-profiles.title.contributor-invite')
        : t('ui.event-management.email-profiles.title.learner-invite'),
    });
    header = t('ui.event-management.email-profiles.message.heading.invite', {
      count: n,
      type: isDFC
        ? t(
            'ui.event-management.email-profiles.title.contributor-invite_plural',
          )
        : t('ui.event-management.email-profiles.title.learner-invite_plural'),
    });
    body = t('ui.event-management.email-profiles.message.body.invite', {
      count: n,
      type: isDFC
        ? t(
            'ui.event-management.email-profiles.title.contributor-invite_plural',
          )
        : t('ui.event-management.email-profiles.title.learner-invite_plural'),
      page: isDFC
        ? t(
            'ui.event-management.email-profiles.message.body.invite-manage-learners-contributors',
          )
        : t(
            'ui.event-management.email-profiles.message.body.invite-manage-learners',
          ),
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      content={
        <Box margin={1} textAlign="center">
          <Box marginBottom={2}>
            <MailSentIcon width={120} />
          </Box>
          <Box marginBottom={2}>
            <Text variant="h4">{header}</Text>
          </Box>
          <Box marginBottom={2}>
            <Span>{body}</Span>
          </Box>
        </Box>
      }
      secondaryButtonText={t('ui.event-management.email-profiles.ok.button')}
    />
  );
};

export default EmailProgressDialog;
