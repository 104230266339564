import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import { Alert as AlertMUI, Button, styled } from '@mui/material';
import { Dialog, formatDate, P, Tag } from '@insights-ltd/design-library';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import { Contributor, InviteeListOptions } from 'types/types';
import { useRemoveContributor, useSendInvites } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type Props = {
  contributors: Contributor[];
  eventId: string;
  crudOperationsDisabled: boolean;
};

type GroupedContributors = Record<InviteeListOptions, Contributor[]>;

const ContributorGroup = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  marginTop: theme.spacing(spacingSizeMap.S),
}));

const StyledDiv = styled('div')({
  display: 'flex',
  margin: 0,
  padding: 0,
});

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  left: theme.spacing(spacingSizeMap.M),
}));

const StyledHollowCircle = styled('div')({
  width: '25px',
  maxWidth: '10px',
  height: '10px',
  border: '2px solid #0E567B',
  borderRadius: '50%',
  marginTop: '10px',
});

const StyledP = styled(P)({
  paddingLeft: '14px',
});

const ContributorRowBase = ({
  contributorInfo,
  actions,
  contributor,
}: {
  contributorInfo: React.ReactNode;
  actions: React.ReactNode;
  contributor: Contributor;
}) => {
  const { t } = useTranslation();

  const date = contributor?.discoveryProfile?.createdAt.slice(0, 10);
  const value: string | undefined = date;

  const formattedDate = value ? formatDate(value) : '';

  const completedEvaluator = formattedDate || '';

  const hasDfcProfile = contributor?.discoveryProfile
    ? t('ui.event-management.events.invitee.profile-reuse-yes')
    : t(
        'ui.event-management.events.add-learners-and-contributors.contributors.no-discovery-profile',
      );

  const contributorArray = Array(contributor);

  const completedEvaluatorForLearner = contributorArray.map(
    (contributorSubmitted) => {
      if (contributorSubmitted?.evaluation?.submittedOn === undefined) {
        return null;
      }
      const newDate = formatDate(contributorSubmitted.evaluation.submittedOn);

      return (
        <P
          variant="body-bold"
          color="textSecondary"
          key={contributorSubmitted.id}
        >
          {t(
            'ui.event-management.events.add-learners-and-contributors.contributor.completed',
          )}{' '}
          {newDate}
        </P>
      );
    },
  );

  return (
    <Grid
      container
      sx={(theme) => ({
        padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
          spacingSizeMap.M,
        )}`,
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.grey[500]}`,
        },
      })}
    >
      <Grid item xs={4}>
        {contributorInfo}
      </Grid>
      <Grid item xs={4}>
        <P>
          {hasDfcProfile ===
          t('ui.event-management.events.invitee.profile-reuse-yes') ? (
            <span style={{ fontWeight: 'bold' }}>
              {`${hasDfcProfile} - ${completedEvaluator}`}
            </span>
          ) : (
            `${hasDfcProfile}`
          )}
        </P>
      </Grid>
      <Grid item xs={4} container justifyContent="space-between">
        <Grid item>{completedEvaluatorForLearner}</Grid>
        <Grid item>{actions}</Grid>
      </Grid>
    </Grid>
  );
};

const ConfirmDeleteContributorDialog = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      title={t(
        'ui.event-management.events.add-learners-and-contributors.contributor.delete.modal.title',
      )}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      primaryButtonText={t(
        'ui.event-management.events.add-learners-and-contributors.contributor.delete.modal.confirm',
      )}
      secondaryButtonText={t(
        'ui.event-management.events.add-learners-and-contributors.contributor.delete.modal.cancel',
      )}
      content={
        <>
          <P>
            {t(
              'ui.event-management.events.add-learners-and-contributors.contributor.delete.modal.content',
            )}
          </P>
          <Alert
            kind="error"
            iconType="warning"
            sx={{ marginTop: '1.5rem', borderRadius: '0.3rem' }}
          >
            {t(
              'ui.event-management.events.add-learners-and-contributors.contributor.delete.modal.warning',
            )}
          </Alert>
        </>
      }
    />
  );
};

const DeleteContributorButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Button
      aria-label={t(
        'ui.event-management.events.add-learners-and-contributors.contributor.delete.label',
      )}
      sx={{ padding: 0, minWidth: 0 }}
      onClick={onClick}
      disabled={disabled}
    >
      <P variant="body-bold" color="error">
        {t('ui.event-management.invite-list.remove-user')}
      </P>
    </Button>
  );
};

const AddedContributorRow = ({
  eventId,
  contributor,
  crudOperationsDisabled,
}: {
  eventId: string;
  contributor: Contributor;
  crudOperationsDisabled: boolean;
}) => {
  const { mutate: removeContributor, isPending: removeContributorIsPending } =
    useRemoveContributor(eventId);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  return (
    <>
      <ContributorRowBase
        contributor={contributor}
        contributorInfo={
          <Grid container>
            <Grid item xs={12}>
              <StyledDiv>
                <StyledHollowCircle />
                <StyledP
                  sx={(theme) => ({
                    wordBreak: 'break-word',
                    paddingRight: `${theme.spacing(spacingSizeMap.S)}`,
                  })}
                >
                  {contributor.fullName}
                </StyledP>
              </StyledDiv>
            </Grid>
            <Grid item xs={12}>
              <StyledLink
                href={`mailto:${contributor.email}`}
                sx={(theme) => ({
                  wordBreak: 'break-word',
                  paddingRight: `${theme.spacing(spacingSizeMap.XL)}`,
                })}
              >
                {contributor.email}
              </StyledLink>
            </Grid>
          </Grid>
        }
        actions={
          crudOperationsDisabled &&
          contributor.status !== 'PROFILE_GENERATED' && (
            <DeleteContributorButton
              onClick={() => setConfirmDeleteOpen(true)}
              disabled={removeContributorIsPending}
            />
          )
        }
      />
      <ConfirmDeleteContributorDialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        onSubmit={() =>
          removeContributor(contributor.id, {
            onSuccess: () => setConfirmDeleteOpen(false),
          })
        }
      />
    </>
  );
};

const InviteSentContributorRow = ({
  eventId,
  contributor,
  crudOperationsDisabled,
}: {
  eventId: string;
  contributor: Contributor;
  crudOperationsDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const { mutate: removeContributor, isPending: removeContributorIsPending } =
    useRemoveContributor(eventId);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const {
    mutate: sendLinkToContributor,
    isPending: sendLinkToContributorLoading,
    isError,
    reset,
  } = useSendInvites();

  return (
    <>
      <ContributorRowBase
        contributor={contributor}
        contributorInfo={
          <Grid container>
            <Grid item xs={12}>
              <StyledDiv>
                <StyledHollowCircle />
                <StyledP>{contributor.fullName}</StyledP>
              </StyledDiv>
            </Grid>
            <Grid item xs={12}>
              <StyledLink href={`mailto:${contributor.email}`}>
                {contributor.email}
              </StyledLink>
            </Grid>
          </Grid>
        }
        actions={
          crudOperationsDisabled && (
            <>
              <Button
                disabled={sendLinkToContributorLoading}
                onClick={() =>
                  sendLinkToContributor({
                    eventId,
                    inviteeIds: [contributor.id],
                  })
                }
              >
                {t(
                  'ui.event-management.events.add-learners-and-contributors.contributor.resend-link.button',
                )}
              </Button>
              <DeleteContributorButton
                onClick={() => setConfirmDeleteOpen(true)}
                disabled={removeContributorIsPending}
              />
            </>
          )
        }
      />
      <ConfirmDeleteContributorDialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        onSubmit={() =>
          removeContributor(contributor.id, {
            onSuccess: () => setConfirmDeleteOpen(false),
          })
        }
      />
      <Snackbar open={isError} autoHideDuration={6000} onClose={reset}>
        <AlertMUI
          elevation={6}
          variant="filled"
          onClose={reset}
          severity="error"
        >
          {t(
            'ui.event-management.events.add-learners-and-contributors.contributor.resend-link.error',
          )}
        </AlertMUI>
      </Snackbar>
    </>
  );
};

const TagWithMargin = styled(Tag)(({ theme }) => ({
  marginTop: theme.spacing(spacingSizeMap.M),
}));

const Contributors = ({
  contributors,
  eventId,
  crudOperationsDisabled,
}: Props) => {
  const { t } = useTranslation();
  const groupedContributors = useMemo(
    () =>
      contributors.reduce<GroupedContributors>(
        (acc, contributor) => ({
          ...acc,
          [contributor.status]: [...acc[contributor.status], contributor],
        }),
        {
          ADDED: [],
          INVITE_REQUESTED: [],
          INVITE_SENT: [],
          PROFILE_GENERATED: [],
          READY_TO_PURCHASE: [],
        },
      ),
    [contributors],
  );

  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={(theme) => ({
          padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
            spacingSizeMap.M,
          )}`,
          background: theme.palette.grey[400],
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
        })}
      >
        <Grid item xs={4}>
          <P variant="body-bold">
            {t(
              'ui.event-management.events.add-learners-and-contributors.contributors.header.full-name-email-address',
            )}
          </P>
        </Grid>
        <Grid item xs={4}>
          <P variant="body-bold">
            {t(
              'ui.event-management.events.add-learners-and-contributors.contributors.header.discovery-profile',
            )}
          </P>
        </Grid>
        <Grid item xs={4}>
          <P variant="body-bold" color="textPrimary">
            {t(
              'ui.event-management.events.add-learners-and-contributors.contributors.header.evaluator.complete',
            )}
          </P>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          paddingInline: theme.spacing(spacingSizeMap.S),
          paddingBottom: theme.spacing(spacingSizeMap.S),
        })}
      >
        {groupedContributors.ADDED.length > 0 && (
          <>
            <TagWithMargin
              label={t(
                'ui.event-management.events.add-learners-and-contributors.contributors.no-evaluator-link-sent',
              )}
              color="grey"
            />
            <ContributorGroup item xs={12}>
              {groupedContributors.ADDED.map((contributor) => (
                <AddedContributorRow
                  key={contributor.id}
                  contributor={contributor}
                  eventId={eventId}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              ))}
            </ContributorGroup>
          </>
        )}
        {groupedContributors.INVITE_REQUESTED.length > 0 && (
          <>
            <TagWithMargin
              label={t(
                'ui.event-management.events.add-learners-and-contributors.contributors.no-evaluator-link-sent',
              )}
              color="grey"
            />
            <ContributorGroup item xs={12}>
              {groupedContributors.INVITE_REQUESTED.map((contributor) => (
                <AddedContributorRow
                  key={contributor.id}
                  contributor={contributor}
                  eventId={eventId}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              ))}
            </ContributorGroup>
          </>
        )}
        {groupedContributors.INVITE_SENT.length > 0 && (
          <>
            <TagWithMargin
              label={t(
                'ui.event-management.events.add-learners-and-contributors.contributors.evaluator-link-sent',
              )}
              color="orange"
            />
            <ContributorGroup item xs={12}>
              {groupedContributors.INVITE_SENT.map((contributor) => (
                <InviteSentContributorRow
                  key={contributor.id}
                  contributor={contributor}
                  eventId={eventId}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              ))}
            </ContributorGroup>
          </>
        )}
        {groupedContributors.READY_TO_PURCHASE.length > 0 && (
          <>
            <TagWithMargin
              label={t(
                'ui.event-management.events.add-learners-and-contributors.contributor.evaluator.completed',
              )}
              color="darkGreen"
            />
            <ContributorGroup item xs={12}>
              {groupedContributors.READY_TO_PURCHASE.map((contributor) => (
                <AddedContributorRow
                  key={contributor.id}
                  contributor={contributor}
                  eventId={eventId}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              ))}
            </ContributorGroup>
          </>
        )}
        {groupedContributors.PROFILE_GENERATED.length > 0 && (
          <>
            <TagWithMargin
              label={t(
                'ui.event-management.events.invitee.status.profile-included.in.profile',
              )}
              color="blue"
            />
            <ContributorGroup item xs={12}>
              {groupedContributors.PROFILE_GENERATED.map((contributor) => (
                <AddedContributorRow
                  key={contributor.id}
                  contributor={contributor}
                  eventId={eventId}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              ))}
            </ContributorGroup>
          </>
        )}
      </Grid>
    </>
  );
};

export default Contributors;
