import React from 'react';
import { P, spacingSizeMap, Text } from '@insights-ltd/design-library';
import SvgShareExternalLink from '@insights-ltd/design-library/src/components/Svgs/streamline-custom/ShareExternalLinkV2';
import { Box, Button, Grid, styled } from '@mui/material';
import InsightsLogo from 'components/Svgs/InsightsLogo';
import { useTranslation } from 'react-i18next';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';

const StyledInsightsLogo = styled(InsightsLogo)(({ theme }) => ({
  width: '128px',
  height: '34px',
  fill: 'black',
  paddingRight: theme.spacing(spacingSizeMap.S),
  borderRight: `1px solid ${theme.palette.grey[500]}`,
}));
const StyledText = styled(Text)(({ theme }) => ({
  marginLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
}));

export const FAQsection = () => {
  const { t } = useTranslation();
  const aboveMobile = useIsAboveMobile('md');

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[500]}`,
        marginTop: theme.spacing(spacingSizeMap.S),
        padding: theme.spacing(spacingSizeMap.S),
        borderRadius: '4px',
      })}
    >
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        container
        direction={aboveMobile ? 'row' : 'column-reverse'}
      >
        <Grid item>
          <Box display="flex" justifyContent="center">
            <StyledInsightsLogo />
            <StyledText>
              {t('ui.event-management.dashboard.faq-title')}
            </StyledText>
          </Box>
        </Grid>

        <Grid item>
          <Button
            data-testid="access-support-button"
            variant="contained"
            startIcon={<SvgShareExternalLink stroke="#fff" />}
            sx={{ padding: '10px 20px 10px 20px', marginBottom: 'px' }}
            onClick={() =>
              window.open('https://support.insights.com/', '_blank')
            }
          >
            {t('ui.event-management.dashboard.button.access-support')}
          </Button>
        </Grid>
      </Grid>
      <Box width="75%">
        <P>{t('ui.event-management.dashboard.faq-desc')}</P>
      </Box>
    </Box>
  );
};
