import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { P, Span } from '@insights-ltd/design-library';

import { TransactionResponse } from 'api';
import UnescapedTrans from 'components/i18n/UnescapedTrans';
import SectionHeader from 'components/SectionHeader';
import { useAuth } from 'contexts/AuthContext';
import { isoToLocaleString } from 'utils/dates';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import ChartIcon from 'components/Svgs/Chart';

const myAccountDescription = {
  CREDIT: 'ui.event-management.my-account.transactions.credited.bold',
  REMOVE: 'ui.event-management.my-account.transactions.removed.bold',
};
const profileViewDescription = {
  CREDIT: 'ui.event-management.account.transactions.credited.bold',
  REMOVE: 'ui.event-management.account.transactions.removed.bold',
};

const TransactionOperation = ({
  transaction,
  isProfileView,
}: {
  transaction: TransactionResponse;
  isProfileView?: boolean;
}) => {
  const { user } = useAuth();
  switch (transaction.operation) {
    case 'CREDIT':
    case 'REMOVE': {
      const description = isProfileView
        ? profileViewDescription
        : myAccountDescription;
      return (
        <UnescapedTrans
          i18nKey={description[transaction.operation]}
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            createdBy: transaction.createdBy.fullName,
            recipient: transaction.recipient.fullName,
            units: transaction.units,
          }}
        />
      );
    }
    case 'TRANSFER': {
      let translationKey =
        'ui.event-management.account.transactions.transferred.bold';
      if (
        transaction.recipient.fullName === user?.fullName ||
        transaction.sender.fullName === user?.fullName
      ) {
        const senderIsMe = user?.fullName === transaction.sender.fullName;
        translationKey = senderIsMe
          ? 'ui.event-management.my-account.transactions.transferred.sent.bold'
          : 'ui.event-management.my-account.transactions.transferred.received.bold';
      }
      return (
        <UnescapedTrans
          i18nKey={translationKey}
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            createdBy: transaction.createdBy.fullName,
            senderFullName: transaction.sender.fullName,
            recipientFullName: transaction.recipient.fullName,
            units: transaction.units,
          }}
        />
      );
    }
    case 'PURCHASE': {
      return (
        <UnescapedTrans
          i18nKey="ui.event-management.my-account.transactions.profile-chapters-purchased.bold"
          components={{ bold: <Span variant="body-bold" /> }}
          values={{
            recipientFullName: transaction.recipient.fullName,
            units: transaction.units,
          }}
        />
      );
    }
    default: {
      const operationType = (transaction as { operation: never }).operation;
      throw new Error(
        `Unexpected transaction operation type: ${operationType}`,
      );
    }
  }
};

const TransactionRow = ({
  date,
  operation,
}: {
  date: string;
  operation: React.ReactNode;
}) => (
  <>
    <Grid item container direction="row">
      <Box mr={2}>
        <P>{date}</P>
      </Box>
      <P>{operation}</P>
    </Grid>
    <Box mb={(theme) => theme.spacing(spacingSizeMap.XS)} />
  </>
);

const ViewAllMineLink = ({ practitionerId }: { practitionerId: string }) => {
  const { t } = useTranslation();

  return (
    <Span variant="body-small">
      <Link
        display="block"
        component={RouterLink}
        to={`/account/transactions/${practitionerId}`}
      >
        {t('ui.event-management.my-account.transactions.view_all')}
      </Link>
    </Span>
  );
};

const ViewAllLink = ({
  practitionerId,
  organisationId,
}: {
  practitionerId: string;
  organisationId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Span variant="body-small">
      <Link
        display="block"
        component={RouterLink}
        to={`/organisations/${organisationId}/practitioners/${practitionerId}/transactions`}
      >
        {t('ui.event-management.my-account.transactions.view_all')}
      </Link>
    </Span>
  );
};

const TransactionsSection = ({
  transactions,
  practitionerId,
  organisationId,
  isProfileView,
}: {
  transactions: TransactionResponse[];
  organisationId?: string;
  practitionerId: string;
  isProfileView?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const hasResults = !!transactions.length;

  return (
    <>
      <SectionHeader
        icon={<ChartIcon />}
        title={t('ui.event-management.transactions.title')}
      />
      {hasResults ? (
        <>
          <Grid container direction="column">
            {transactions.slice(0, 3).map((transaction) => (
              <TransactionRow
                key={`transaction-${transaction.createdAt + Math.random()}`}
                date={isoToLocaleString(transaction.createdAt, locale)}
                operation={
                  <TransactionOperation
                    transaction={transaction}
                    isProfileView={isProfileView}
                  />
                }
              />
            ))}
          </Grid>
          <Box my={(theme) => theme.spacing(spacingSizeMap.S)}>
            <Divider />
          </Box>
          <Box my={(theme) => theme.spacing(spacingSizeMap.S)}>
            {!isProfileView ? (
              <ViewAllMineLink practitionerId={practitionerId} />
            ) : (
              <ViewAllLink
                practitionerId={practitionerId}
                organisationId={organisationId}
              />
            )}
          </Box>
        </>
      ) : (
        <p>{t('ui.event-management.my-account.transactions.empty')}</p>
      )}
      <Box mb="40px" />
    </>
  );
};

export default TransactionsSection;
