import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';
import { useTheme } from '@mui/material';

const QuadrantTitles = () => {
  const theme = useTheme();
  const [{ fontSize }] = useWheelContext();
  const { t } = useTranslation();

  return (
    <g id="colour-slices-text">
      <defs>
        <path id="reformer-curve" d="M148 50 A 260 260 0 0 1 372 50" />
        <path id="motivator-curve" d="M470 146 A 260 260 0 0 1 470 374" />
        <path id="director-curve" d="M376 54 A 260 260 0 0 1 466 144" />
        <path id="inspirer-curve" d="M382 482 A 260 260 0 0 0 482 382" />
        <path id="helper-curve" d="M140 482 A 260 260 0 0 0 380 482" />
        <path id="supporter-curve" d="M40 384 A 260 260 0 0 0 140 482" />
        <path id="coordinator-curve" d="M50 374 A 260 260 0 0 1 50 146" />
        <path id="observer-curve" d="M54 144 A 260 260 0 0 1 144 54" />
      </defs>
      <g fill={theme.palette.grey[100]} textAnchor="middle" fontSize={fontSize}>
        <text>
          <textPath href="#reformer-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.reformer')}
          </textPath>
        </text>
        <text>
          <textPath href="#director-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.director')}
          </textPath>
        </text>
        <text>
          <textPath href="#motivator-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.motivator')}
          </textPath>
        </text>
        <text>
          <textPath href="#inspirer-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.inspirer')}
          </textPath>
        </text>
        <text>
          <textPath href="#helper-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.helper')}
          </textPath>
        </text>
        <text>
          <textPath href="#supporter-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.supporter')}
          </textPath>
        </text>
        <text>
          <textPath href="#coordinator-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.coordinator')}
          </textPath>
        </text>
        <text>
          <textPath href="#observer-curve" startOffset="50%">
            {t('ui.event-management.team-wheel.title.observer')}
          </textPath>
        </text>
      </g>
    </g>
  );
};
export default QuadrantTitles;
