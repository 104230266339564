import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { H3, Span } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';

export type Props = {
  open: boolean;
  onAbort?: () => void;
  dialect?: string;
};

const baseUrl =
  import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL;

const DownloadProgressDialog = ({ open, onAbort, dialect }: Props) => {
  const { t } = useTranslation();
  const dialectString = dialect && t(dialect);
  const title = dialect
    ? t('ui.event-management.events.download-profiles.preparing-dialect', {
        dialect: dialectString,
      })
    : t('ui.event-management.events.download-profiles.preparing');

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="progress-dialog-title"
      aria-describedby="progress-dialog-description"
      open={open}
    >
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <img
          src={`${baseUrl}/img/waiting_animation.gif`}
          alt="progress-graphic"
          width={200}
        />
        <Box paddingLeft={4} paddingRight={4}>
          <H3>{title}</H3>
          <Span>
            {t(
              'ui.event-management.events.download-profiles.preparing.message',
            )}
          </Span>
        </Box>
      </DialogContent>
      {onAbort && (
        <DialogActions>
          <Button
            fullWidth
            onClick={onAbort}
            color="primary"
            variant="outlined"
          >
            {t('ui.event-management.events.download-profiles.cancel-download')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DownloadProgressDialog;
