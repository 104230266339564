import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { JungianColours } from 'types/types';
import { styled } from '@mui/material';
import defaultTheme from '@insights-ltd/design-library/src/themes/default';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type LeadingColourEnergyIndicatorProps = {
  leadingColourEnergy?: JungianColours;
  className?: string;
};

interface StyledDivProps {
  leadingColourEnergy?: JungianColours;
}

const colours = defaultTheme.fourColourProfileColours;

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'leadingColourEnergy',
})<StyledDivProps>(({ theme: localTheme, leadingColourEnergy }) => {
  let colour = localTheme.palette.grey[400];

  switch (leadingColourEnergy) {
    case 'CoolBlue':
      colour = colours.coolBlue;
      break;
    case 'EarthGreen':
      colour = colours.earthGreen;
      break;
    case 'FieryRed':
      colour = colours.fieryRed;
      break;
    case 'SunshineYellow':
      colour = colours.sunshineYellow;
      break;
    default:
      // Keep default colour
      break;
  }

  return {
    flexShrink: 0,
    width: '0.75rem',
    height: '0.75rem',
    marginTop: 'auto',
    marginRight: localTheme.spacing(spacingSizeMap.XS),
    marginBottom: 'auto',
    borderRadius: '50%',
    backgroundColor: colour,
  };
});

const LeadingColourEnergyIndicator = ({
  leadingColourEnergy,
  className,
}: LeadingColourEnergyIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <StyledDiv
      title={t('ui.event-management.leading-colour-energy.title', {
        leadingColourEnergy,
      })}
      leadingColourEnergy={leadingColourEnergy}
      className={className}
    />
  );
};

export default LeadingColourEnergyIndicator;
