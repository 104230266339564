import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import { P } from '@insights-ltd/design-library';
import { TeamResponse } from 'api';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

export type Props = {
  team?: TeamResponse | null;
  open: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
  deleting: boolean;
};

const DeleteTeamDialog = ({
  team,
  open,
  onClose,
  onConfirmDelete,
  deleting,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <P variant="h4">
            {t('ui.event-management.learners.teams.delete-dialog.title')}
          </P>
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <DialogContentText
            id="delete-dialog-heading"
            color="textPrimary"
            variant="h6"
          >
            {t('ui.event-management.learners.teams.delete-dialog.heading', {
              teamName: team?.name,
            })}
          </DialogContentText>
          <DialogContentText
            id="delete-dialog-description"
            color="textSecondary"
            variant="body1"
          >
            {t('ui.event-management.learners.teams.delete-dialog.body')}
          </DialogContentText>
          <Box py={(theme) => theme.spacing(spacingSizeMap.XS)} />
          <Alert
            variant="standard"
            severity="error"
            sx={(theme) => ({
              background: theme.palette.error.light,
              color: theme.palette.error.dark,
            })}
          >
            {t('ui.event-management.learners.teams.delete-dialog.alert')}
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions disableSpacing={false}>
        <Box display="flex" flexDirection="column" width="100%">
          <Button
            fullWidth
            onClick={onConfirmDelete}
            color="primary"
            variant="contained"
            disabled={deleting}
          >
            {t('ui.event-management.learners.teams.delete-dialog.confirm')}
          </Button>
          <Box pt={(theme) => theme.spacing(spacingSizeMap.XS)} />
          <Button
            fullWidth
            onClick={onClose}
            color="primary"
            variant="outlined"
            disabled={deleting}
          >
            {t('ui.event-management.learners.teams.delete-dialog.cancel')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTeamDialog;
