import React from 'react';
import { useTranslation } from 'react-i18next';
import { H2, Span } from '@insights-ltd/design-library';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const StyledSpan = styled(Span)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
}));

type SectionProps = {
  title: string;
  value: number;
  testId: string;
};
const Section = ({ title, value, testId }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={(theme) => ({ padding: theme.spacing(2), alignItems: 'left' })}>
      <H2 variant="h4">{t(title)}</H2>
      <Span variant="h2" data-testid={testId}>
        {value}
      </Span>
      <StyledSpan variant="body-bold" color="textSecondary">
        {t('ui.event-management.events.unit-management.value.units')}
      </StyledSpan>
    </Box>
  );
};
export default Section;
