import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, Span, Text } from '@insights-ltd/design-library';
import Alert from '@insights-ltd/design-library/src/components/Alert/Alert';
import {
  InputSelectV2,
  MenuItem,
} from '@insights-ltd/design-library/src/components/InputSelectV2';
import { styled } from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { isExploreExperience } from 'domain/event';
import { EventResponse } from 'api';
import { DeleteType } from './types';

type Props = {
  open: boolean;
  deleteType: DeleteType;
  onClose: () => void;
  handleConfirmDelete: () => void;
  isLoading: boolean;
  availableUnits: number;
  event: EventResponse;
  setRefundedPractitioner: Dispatch<SetStateAction<any>>;
};

const textKeys: Record<DeleteType, { [key: string]: string }> = {
  cancel: {
    title: 'ui.event-management.events.confirm-cancel.title',
    body: 'ui.event-management.events.confirm-cancel.body-text',
    confirmButton: 'ui.event-management.events.confirm-cancel.confirm-button',
    cancelButton: 'ui.event-management.events.confirm-cancel.cancel-button',
    description: 'ui.event-management.events.confirm-cancel.description',
    warning: 'ui.event-management.events.confirm-cancel.warning',
    exploreDescription:
      'ui.event-management.events.explore.confirm-cancel.description',
    exploreBudgetAllUsedDescription:
      'ui.event-management.events.explore.confirm-cancel.budget-all-used.description',
    exploreBudgetUsedAndSinglePersonnelDescription:
      'ui.event-management.events.explore.confirm-cancel.budget-used.single-personnel.description.bold',
    exploreBudgetUsedAndMultiplePersonnelDescription:
      'ui.event-management.events.explore.confirm-cancel.budget-used.multiple-personnel.description.bold',
    exploreBudgetUsedAndMultiplePersonnelSelectLabel:
      'ui.event-management.events.explore.confirm-cancel.budget-used.multiple-personnel.select-label',
  },
  delete: {
    title: 'ui.event-management.events.confirm-delete.title',
    body: 'ui.event-management.events.confirm-delete.body-text',
    confirmButton: 'ui.event-management.events.confirm-delete.confirm-button',
    cancelButton: 'ui.event-management.events.confirm-delete.cancel-button',
    warning: 'ui.event-management.events.confirm-delete.cannot-undo',
  },
};

const Description = styled(Text)(({ theme: t }) => ({
  fontWeight: `${t.typography.fontWeightBold} !important`,
}));

const Content = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '0.5rem',
});

const WarningMessage = styled(Alert)<{ multiline: boolean }>(
  ({ theme, multiline }) => ({
    '& div:nth-of-type(2)': {
      lineHeight: multiline ? '1.75rem' : '1rem',
    },
    marginTop: theme.spacing(spacingSizeMap.S),
    borderRadius: '0.25rem',
  }),
);

const DeleteOrCancelDialog = ({
  open,
  onClose,
  handleConfirmDelete,
  isLoading,
  deleteType,
  event,
  availableUnits,
  setRefundedPractitioner,
}: Pick<
  Props,
  | 'open'
  | 'onClose'
  | 'handleConfirmDelete'
  | 'isLoading'
  | 'deleteType'
  | 'event'
  | 'availableUnits'
  | 'setRefundedPractitioner'
>) => {
  const { t } = useTranslation();
  const {
    confirmButton,
    cancelButton,
    title,
    description,
    warning,
    body,
    exploreDescription,
    exploreBudgetAllUsedDescription,
    exploreBudgetUsedAndSinglePersonnelDescription,
    exploreBudgetUsedAndMultiplePersonnelDescription,
    exploreBudgetUsedAndMultiplePersonnelSelectLabel,
  } = textKeys[deleteType];

  const isExplore = isExploreExperience(event.eventType);

  const warningIsMutiline = t(warning).length > 33;

  const content = (
    <Content>
      {isExplore ? (
        <>
          <Text>{t(body)}</Text>
          <br />

          {availableUnits === 0 && (
            <>
              <Text>{t(exploreBudgetAllUsedDescription)}</Text>
              <br />
              <Description>{t(exploreDescription)}</Description>
              <br />
            </>
          )}

          {availableUnits > 0 && event.practitioners.length === 1 && (
            <>
              <Description>{t(exploreDescription)}</Description>
              <br />
              <Text>
                <Trans
                  i18nKey={exploreBudgetUsedAndSinglePersonnelDescription}
                  components={{ bold: <Span variant="body-bold" /> }}
                  values={{
                    fullName: event.practitioners[0].fullName,
                    emailAddress: event.practitioners[0].emailAddress,
                    availableUnits,
                  }}
                />
              </Text>
              <br />
            </>
          )}

          {availableUnits > 0 && event.practitioners.length > 1 && (
            <>
              <Description>{t(exploreDescription)}</Description>
              <br />
              <Text>
                <Trans
                  i18nKey={exploreBudgetUsedAndMultiplePersonnelDescription}
                  components={{ bold: <Span variant="body-bold" /> }}
                  values={{
                    availableUnits,
                  }}
                />
              </Text>
              <br />
              <Description>
                {t(exploreBudgetUsedAndMultiplePersonnelSelectLabel)}
              </Description>
              <InputSelectV2
                id="personnel-list"
                labelText=""
                defaultValue={event.practitioners[0].id}
                onChange={(e) => setRefundedPractitioner(e.target.value || '')}
              >
                {event.practitioners.map((practitioner) => (
                  <MenuItem value={practitioner.id} key={practitioner.id}>
                    {practitioner.fullName} - ({practitioner.emailAddress})
                  </MenuItem>
                ))}
              </InputSelectV2>
              <br />
            </>
          )}
        </>
      ) : (
        <>
          <Text>{t(body)}</Text>
          <br />
          <Description>{t(description)}</Description>
        </>
      )}
      <WarningMessage
        kind="error"
        iconType="warning"
        multiline={warningIsMutiline}
      >
        {t(warning)}
      </WarningMessage>
    </Content>
  );
  return (
    <Dialog
      dialogProps={{
        title: t(title),
        maxWidth: 'md',
      }}
      title={t(title)}
      content={content}
      contentProps={{
        style: {
          minHeight: '100%',
        },
      }}
      open={open}
      primaryButtonText={t(confirmButton)}
      secondaryButtonText={t(cancelButton)}
      onSubmit={handleConfirmDelete}
      onClose={onClose}
      variant="delete"
      isLoading={isLoading}
    />
  );
};

const DeleteOrCancelEventDialog = ({
  open,
  onClose,
  deleteType,
  handleConfirmDelete,
  isLoading,
  event,
  availableUnits,
  setRefundedPractitioner,
}: Props) => {
  return (
    <DeleteOrCancelDialog
      open={open}
      onClose={onClose}
      handleConfirmDelete={handleConfirmDelete}
      isLoading={isLoading}
      deleteType={deleteType}
      event={event}
      availableUnits={availableUnits}
      setRefundedPractitioner={setRefundedPractitioner}
    />
  );
};

export default DeleteOrCancelEventDialog;
