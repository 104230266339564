import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';
import { H1, Span } from '@insights-ltd/design-library';
import { useAuth } from 'contexts/AuthContext';
import LearnersIcon from 'components/Svgs/icons/Learners';
import OrganisationsIcon from 'components/Svgs/icons/OrganisationsWithBackground';
import EvaluationLinks from 'components/Svgs/icons/EvaluatorLinksWithBackground';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useIsAboveMobile from 'components/hooks/useIsAboveMobile';
import useGetMyOrganisations from 'components/hooks/useGetMyOrganisations';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import CalendarIcon from 'components/Svgs/Calendar';
import QuickActions from './QuickActions';
import DashboardCard from './DashboardCard';
import { FAQsection } from './FAQsection';

const sxDashboardCardContainer = () => ({
  flexBasis: 'auto',
});

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    allMyOrganisations,
    hasMultiOrgs: showOrganisationsView,
    status,
  } = useGetMyOrganisations();

  const aboveMobile = useIsAboveMobile('md');

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.dashboard.error-loading')}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.dashboard')}</title>
      </Helmet>
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          marginTop: theme.spacing(spacingSizeMap.L),
          marginBottom: theme.spacing(spacingSizeMap.L),
        })}
      >
        <H1 variant="h2">
          {t('ui.event-management.dashboard.heading', {
            fullName: user?.fullName,
          })}
        </H1>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Grid
              sx={(theme) => ({
                marginTop: theme.spacing(spacingSizeMap.M),
              })}
              container
              spacing={2}
              justifyContent="space-between"
              direction={aboveMobile ? 'row' : 'column-reverse'}
            >
              <Grid sx={sxDashboardCardContainer} item xs={12} md={6}>
                <DashboardCard
                  href="/experiences"
                  Icon={CalendarIcon}
                  textPrimary={t(
                    'ui.event-management.dashboard.card-header.experience',
                  )}
                  textSecondary={t(
                    'ui.event-management.dashboard.card-body.experience',
                  )}
                />
              </Grid>
              <Grid sx={sxDashboardCardContainer} item xs={12} md={6}>
                {showOrganisationsView ? (
                  <DashboardCard
                    href="/organisations"
                    Icon={OrganisationsIcon}
                    textPrimary={t(
                      'ui.event-management.dashboard.card-header.organisations',
                    )}
                    textSecondary={t(
                      'ui.event-management.dashboard.card-body.organisations',
                    )}
                  />
                ) : (
                  <DashboardCard
                    href={`/organisations/${allMyOrganisations?.[0].id}`}
                    Icon={OrganisationsIcon}
                    textPrimary={t(
                      'ui.event-management.dashboard.card-header.my-organisation',
                      { name: allMyOrganisations?.[0].name },
                    )}
                  >
                    <Trans
                      i18nKey="ui.event-management.dashboard.card-body.organisations.standard.bold"
                      components={{ bold: <Span variant="body-bold" /> }}
                    />
                  </DashboardCard>
                )}
              </Grid>
              <Grid sx={sxDashboardCardContainer} item xs={12} md={6}>
                <DashboardCard
                  href="/learners"
                  Icon={LearnersIcon}
                  textPrimary={t(
                    'ui.event-management.dashboard.card-header.learners',
                  )}
                  textSecondary={t(
                    'ui.event-management.dashboard.card-body.learners',
                  )}
                />
              </Grid>
              <Grid sx={sxDashboardCardContainer} item xs={12} md={6}>
                <DashboardCard
                  href="/evaluator-links"
                  Icon={EvaluationLinks}
                  textPrimary={t(
                    'ui.event-management.dashboard.card-header.evaluator-links',
                  )}
                  textSecondary={t(
                    'ui.event-management.dashboard.card-body.evaluator-links',
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={(theme) => ({
              padding: theme.spacing(spacingSizeMap.M),
              paddingLeft: theme.spacing(spacingSizeMap.L),
              marginTop: theme.spacing(spacingSizeMap.S),
            })}
          >
            <QuickActions />
          </Grid>
          <Grid item md={8}>
            <FAQsection />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
