import {
  H1,
  spacingSizeMap,
  InputSelectV2,
} from '@insights-ltd/design-library';
import { Card, Grid, MenuItem } from '@mui/material';
import { useGetApplicationPreferredDialects } from 'domain/dialect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialects, PreferredDialect } from 'types/dialects';

type Props = {
  onSelect: (dialect: PreferredDialect) => void;
  value?: Dialects;
};

const SignUpLanguageSelection = ({ onSelect, value }: Props) => {
  const preferredDialects = useGetApplicationPreferredDialects();
  const { t } = useTranslation();

  const items = [
    <MenuItem key="default" disabled value={undefined}>
      {t('ui.event-management.sign-up.preferred-language.placeholder')}
    </MenuItem>,
    ...Object.entries(preferredDialects).map<React.ReactNode>(
      ([item, language]) => (
        <MenuItem key={item} value={item}>
          {language}
        </MenuItem>
      ),
    ),
  ];

  return (
    <Card
      sx={(theme) => ({
        maxWidth: '500px',
        padding: `${theme.spacing(spacingSizeMap.L)} ${theme.spacing(
          spacingSizeMap.L,
        )}`,
      })}
    >
      <Grid container>
        <Grid item xs={12}>
          <H1 variant="h3">
            {t('ui.event-management.sign-up.select-your-language.heading')}
          </H1>
        </Grid>
        <Grid item mt={2} xs={12}>
          <InputSelectV2
            data-testid="preferred-dialect-select"
            id="preferredDialect"
            labelText={t(
              'ui.event-management.sign-up.preferred-language.input-label',
            )}
            type="text"
            variant="outlined"
            sx={{
              width: '100%',
              '& > div': {
                width: '100%',
              },
              input: {
                width: '100% !important',
              },
            }}
            placeholder={t(
              'ui.event-management.sign-up.preferred-language.placeholder',
            )}
            defaultValue={undefined}
            onChange={(event) => {
              onSelect(event.target.value as PreferredDialect);
            }}
            value={value}
          >
            {items}
          </InputSelectV2>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SignUpLanguageSelection;
