import React from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

import { RequestError } from 'api';
import RequestErrorModal from 'components/RequestErrorDialog/RequestErrorModal';
import { Dialog, Span } from '@insights-ltd/design-library';

const PurchaseError = ({
  isError,
  error,
  reset,
  resetWallet,
}: {
  isError: boolean;
  error: unknown | null;
  reset: () => void;
  resetWallet: () => void;
}) => {
  const { t } = useTranslation();

  if (
    isError &&
    error instanceof RequestError &&
    error.status >= 400 &&
    error.status < 500
  ) {
    const unitBalanceError = error.errorCodes.find(
      (code) => code === 'INSUFFICIENT_UNITS',
    );

    const profileAlreadyExists = error.errorCodes.find(
      (code) => code === 'PROFILE_ALREADY_EXISTS',
    );

    if (profileAlreadyExists) {
      return (
        <Dialog
          title={t(
            'ui.event-management.learners.purchase-profiles.failed-purchase.title',
          )}
          open={isError}
          content={
            <Span>
              {t(
                'ui.event-management.learners.purchase-profiles.failed-purchase.description',
              )}
            </Span>
          }
          onClose={() => {
            resetWallet();
            reset();
          }}
          primaryButtonText={t(
            'ui.event-management.learners.purchase-profiles.failed-purchase.confirm',
          )}
        />
      );
    }

    if (unitBalanceError) {
      resetWallet();
      reset();

      return null;
    }

    return (
      <Snackbar open={isError} autoHideDuration={6000} onClose={reset}>
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          <AlertTitle>
            {t('ui.event-management.events.purchase-profiles.purchase-error')}
          </AlertTitle>
        </Alert>
      </Snackbar>
    );
  }
  return (
    <RequestErrorModal
      open={isError}
      onClose={() => {
        resetWallet();
        reset();
      }}
    />
  );
};

export default PurchaseError;
