import { SxProps, Theme, styled } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { Span } from '@insights-ltd/design-library';

const StyledDiv = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.blue.light,
  padding: `10px`,
  lineHeight: '28px',
  display: 'flex',
  alignItems: 'center',
}));

const StyledSpan = styled(Span)({
  cursor: 'pointer',
  '::before': {
    content: '" "',
    whiteSpace: 'pre-wrap',
  },
  '::after': {
    content: '" "',
    whiteSpace: 'pre-wrap',
  },
});

type Props = {
  i18nKey: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  sx?: SxProps<Theme>;
};

const SearchResultInfo = ({ i18nKey, onClick, sx }: Props) => {
  return (
    <StyledDiv data-testid="search-result-info" sx={sx}>
      <Trans
        shouldUnescape
        i18nKey={i18nKey}
        components={{
          searchText: (
            <StyledSpan onClick={onClick} variant="body-bold" color="primary" />
          ),
        }}
      />
    </StyledDiv>
  );
};

export default SearchResultInfo;
