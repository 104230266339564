import { UserPermissions } from 'permissions';
import { Role } from 'types/types';

const STANDARD_PERMISSIONS: UserPermissions = {
  Add_User_to_Partner_Organisation: 'None',
  Experience_Create: 'Local',
  Experience_ReadSingle: 'Local',
  Experience_ReadAll: 'Local',
  Experience_ReadExpired: 'Local',
  Experience_Update: 'Local',
  Experience_Delete: 'Local',
  Organisation_Create: 'None',
  Organisation_ReadSingle: 'Local',
  Organisation_ReadAll: 'Local',
  Organisation_Update: 'None',
  Organisation_Insights_Update: 'None',
  Organisation_Delete: 'None',
  Organisation_Add_User: 'None',
  Organisation_InvitePractitioner: 'None',
  Practitioner_ReadAll: 'Local',
  Practitioner_ReadAllLicensedProducts: 'None',
  Practitioner_ReadSingle: 'Local',
  Practitioner_Update: 'Self',
  Practitioner_UpdateRoles: 'None',
  Practitioner_UpdateLicensedProducts: 'None',
  Learner_ReadAll: 'Local',
  Learner_ReadSingle: 'Local',
  Learner_Update: 'Local',
  Learner_Delete: 'None',
  Unit_ReadAll: 'None',
  Unit_Transfer: 'Local',
  Unit_Update: 'None',
  Unit_Request: 'Local',
  Team_Create: 'Local',
  Organisation_Group_ReadSingle: 'None',
  Organisation_Group_ReadAll: 'None',
  Organisation_Group_Create: 'None',
  Organisation_Group_Update: 'None',
  Organisation_Group_Delete: 'None',
  Organisation_Group_Create_Organisation_In_Group: 'None',
  Evaluator_Link_Read: 'Local',
  Evaluator_Link_Create: 'Local',
  Evaluator_Link_Delete: 'Local',
  Multi_Organisation_Team_Create: 'None',
};
export const ROLE_MAP: Record<Role, UserPermissions> = {
  SUPER_ADMIN: {
    Experience_Create: 'Global',
    Experience_ReadSingle: 'Global',
    Experience_ReadAll: 'Global',
    Experience_ReadExpired: 'Global',
    Experience_Update: 'Global',
    Experience_Delete: 'Global',
    Organisation_Create: 'Global',
    Organisation_ReadSingle: 'Global',
    Organisation_ReadAll: 'Global',
    Organisation_Update: 'Global',
    Organisation_Insights_Update: 'None',
    Organisation_Delete: 'None',
    Organisation_Add_User: 'Global',
    Organisation_InvitePractitioner: 'Global',
    Practitioner_ReadAll: 'Global',
    Practitioner_ReadAllLicensedProducts: 'Global',
    Practitioner_ReadSingle: 'Global',
    Practitioner_Update: 'Global',
    Practitioner_UpdateRoles: 'Global',
    Practitioner_UpdateLicensedProducts: 'Global',
    Learner_ReadAll: 'Global',
    Learner_ReadSingle: 'Global',
    Learner_Update: 'Global',
    Learner_Delete: 'Global',
    Unit_ReadAll: 'Global',
    Unit_Transfer: 'None',
    Unit_Update: 'Global',
    Unit_Request: 'None',
    Team_Create: 'Local',
    Organisation_Group_ReadSingle: 'Global',
    Organisation_Group_ReadAll: 'Global',
    Organisation_Group_Create: 'Global',
    Organisation_Group_Update: 'Global',
    Organisation_Group_Delete: 'Global',
    Organisation_Group_Create_Organisation_In_Group: 'Global',
    Evaluator_Link_Read: 'Global',
    Evaluator_Link_Create: 'Global',
    Evaluator_Link_Delete: 'Global',
    Add_User_to_Partner_Organisation: 'None',
    Multi_Organisation_Team_Create: 'Global',
  },
  GROUP_MANAGER: {
    ...STANDARD_PERMISSIONS,
    Organisation_Group_ReadAll: 'Global',
    Organisation_Add_User: 'Global',
    Organisation_Group_ReadSingle: 'Global',
    Organisation_Group_Create_Organisation_In_Group: 'Global',
    Organisation_Update: 'Global',
    Add_User_to_Partner_Organisation: 'Global',
  },
  ADVANCED_USER: {
    ...STANDARD_PERMISSIONS,
    Organisation_Group_ReadAll: 'Global',
    Organisation_Group_ReadSingle: 'Global',
    Organisation_Group_Create_Organisation_In_Group: 'Global',
    Organisation_Update: 'Global',
    Organisation_Add_User: 'Global',
    Add_User_to_Partner_Organisation: 'Global',
  },
  STANDARD: STANDARD_PERMISSIONS,
};

export const BETA_ROLE_MAP: Record<Role, UserPermissions> = {
  ...ROLE_MAP,
};
