import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Add, Remove } from '@mui/icons-material';
import { P } from '@insights-ltd/design-library';
import {
  AddContributorRequest,
  DfcInviteeResponse,
  EventResponse,
  RequestError,
  useAddContributor,
  useAddLearnerToAll,
  useDeleteInvitee,
} from 'api';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { VALID_EMAIL_REGEX } from 'variables';
import { Contributor } from 'types/types';
import { Stack, styled } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import DeleteLearnerDialog from 'pages/EventLearners/DeleteLearnerDialog';
import {
  CONTRIBUTOR_ERROR_MESSAGES,
  LEARNER_ERROR_MESSAGES,
} from 'api/apiErrorMessages';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import Contributors from './Contributors';

type CreateContributorForm = {
  fullName: string;
  email: string;
};

const StyledDiv = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
    spacingSizeMap.S,
  )}`,
  borderBottom: `3px solid ${theme.palette.grey[700]}`,
}));

const useToggle = (): {
  isToggleOn: boolean;
  toggle: () => void;
} => {
  const [isToggleOn, setToggleOn] = useState(false);

  return {
    isToggleOn,
    toggle: useCallback(
      () => setToggleOn((oldToggleState) => !oldToggleState),
      [],
    ),
  };
};

const ThinButton = styled(Button)({ padding: 0 });

const ToggleButton = ({
  isToggleOn,
  toggle,
  numContributors,
}: {
  isToggleOn: boolean;
  toggle: () => void;
  numContributors: number;
}) => {
  const { t } = useTranslation();
  let props;
  if (isToggleOn) {
    props = {
      endIcon: <Remove />,
      children: t(
        'ui.event-management.events.add-learners-and-contributors.learner.hide-contributors',
      ),
    };
  } else {
    props =
      numContributors === 0
        ? {
            endIcon: <Add />,
            children: t(
              'ui.event-management.events.add-learners-and-contributors.learner.add-contributors',
            ),
          }
        : {
            endIcon: <Add />,
            children: t(
              'ui.event-management.events.add-learners-and-contributors.learner.manage-contributors',
            ),
          };
  }
  return <ThinButton onClick={toggle} {...props} />;
};

const AddContributorForm = ({
  addContributor,
  learnerId,
  disabled,
}: {
  addContributor: UseMutateFunction<void, RequestError, AddContributorRequest>;
  learnerId: string;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset: resetForm,
  } = useForm<CreateContributorForm>();

  const onAddContributor = (contributorData: CreateContributorForm) => {
    addContributor(
      { ...contributorData, learnerInviteeId: learnerId },
      {
        onSuccess: () => resetForm(),
        onError: (e) => {
          if (
            e.errorCodes.find(
              (code) =>
                code ===
                LEARNER_ERROR_MESSAGES.LEARNER_CANNOT_BE_OWN_CONTRIBUTOR,
            )
          ) {
            setError('email', {
              message: t(
                'ui.event-management.events.add-learners-and-contributors.add-contributor.email-address.error.learner-as-contributor',
              ),
            });
          } else if (
            e.errorCodes.find(
              (code) =>
                code === CONTRIBUTOR_ERROR_MESSAGES.DUPLICATE_CONTRIBUTOR,
            )
          ) {
            setError('email', {
              message: t(
                'ui.event-management.events.add-learners-and-contributors.add-learner.error.email-address-taken',
              ),
            });
          }
        },
      },
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onAddContributor)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            id="fullName"
            label={t(
              'ui.event-management.events.add-learners-and-contributors.add-contributor.full-name.label',
            )}
            required
            variant="outlined"
            fullWidth
            type="text"
            {...register('fullName', {
              required: t(
                'ui.event-management.events.create.error.name-required',
              ),
            })}
            inputProps={{
              'aria-label': t(
                'ui.event-management.events.add-learners-and-contributors.add-contributor.full-name.label',
              ),
            }}
            disabled={disabled}
            error={Boolean(errors.fullName)}
            sx={(theme) => ({
              '& .MuiInputBase-input': {
                backgroundColor: theme.palette.background.paper,
              },
            })}
            helperText={errors.fullName?.message}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="email"
            label={t(
              'ui.event-management.events.add-learners-and-contributors.add-contributor.email-address.label',
            )}
            required
            variant="outlined"
            fullWidth
            type="email"
            {...register('email', {
              required: t(
                'ui.event-management.events.create.error.value-email-required',
              ),
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: t(
                  'ui.event-management.events.create.error.value-email-required',
                ),
              },
            })}
            disabled={disabled}
            error={Boolean(errors.email)}
            inputProps={{
              'aria-label': t(
                'ui.event-management.events.add-learners-and-contributors.add-contributor.email-address.label',
              ),
            }}
            sx={(theme) => ({
              '& .MuiInputBase-input': {
                backgroundColor: theme.palette.background.paper,
              },
            })}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={(theme) => ({
              marginTop: theme.spacing(spacingSizeMap.XS),
              whiteSpace: 'nowrap',
            })}
            disabled={disabled}
          >
            {t(
              'ui.event-management.events.add-learners-and-contributors.add-contributor.button.name',
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const LearnersAndContributorsSummary = ({
  event,
  learner,
  contributors,
  learnerIds,
  crudOperationsDisabled,
  allContributorsRemoved,
}: {
  event: EventResponse;
  learner: DfcInviteeResponse;
  contributors: Contributor[];
  learnerIds: string[];
  crudOperationsDisabled: boolean;
  allContributorsRemoved: boolean;
}) => {
  const { t } = useTranslation();
  const { isToggleOn, toggle } = useToggle();

  const { mutate: onAddContributor, isPending: isLoadingAddContributor } =
    useAddContributor(event.id);

  const [inviteeId, setInviteeId] = useState<string | null>(null);

  const {
    mutate: deleteLearner,
    isPending: isLoadingDeleteLearner,
    isError: isErrorDeleteLearner,
    reset: resetDeleteInvitee,
  } = useDeleteInvitee();

  const {
    mutate: addLearnerToAll,
    isPending: addingLearnerToAll,
    isError: errorAddingLearnerToAll,
    reset: resetAddLearnerToAll,
  } = useAddLearnerToAll(event.id);

  const handleConfirmDelete = () =>
    deleteLearner(
      {
        eventId: event.id,
        inviteeId: learner.id,
      },
      { onSuccess: () => setInviteeId(null) },
    );

  const handleDeleteLearner = () => {
    setInviteeId(learner.id);
  };

  const totalSubmittedContributors = contributors.filter(
    (submittedContributor) => {
      return submittedContributor.evaluation?.submittedOn !== undefined;
    },
  );

  const contributorCount = contributors.length;

  const StyledCompletedCount = styled('span')({
    fontWeight: 'bold',
  });

  const completed = t(
    'ui.event-management.events.add-learners-and-contributors.contributor.completed',
  );

  return (
    <Grid container spacing={2} data-testid="learners-list-row">
      <Grid item xs={12}>
        <Grid
          container
          sx={(theme) => ({
            borderRadius: theme.spacing(0.5),
            border: `1px solid ${theme.palette.grey[500]}`,
            borderColor: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
            overflow: 'hidden',
          })}
        >
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              padding: `${theme.spacing(spacingSizeMap.S)} ${theme.spacing(
                spacingSizeMap.M,
              )} `,
              borderBottom: isToggleOn
                ? `1px solid ${theme.palette.grey[500]}`
                : undefined,
              backgroundColor: theme.palette.background.paper,
            })}
          >
            <Grid container>
              <Grid item xs={12} lg={4}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ wordBreak: 'break-word', paddingRight: '1rem' }}
                  >
                    <P variant="body">{learner.fullName}</P>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ wordBreak: 'break-word', paddingRight: '1rem' }}
                  >
                    <Link
                      sx={{ lineHeight: '1.75rem' }}
                      href={`mailto:${learner.email}`}
                    >
                      {learner.email}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <P color="textSecondary">
                      {t(
                        'ui.event-management.events.add-learners-and-contributors.learner.number-of-contributors',
                        { count: contributors.length },
                      )}{' '}
                      <span> </span>
                      {contributorCount <= 0 ? null : (
                        <StyledCompletedCount>
                          {`(${totalSubmittedContributors.length}/${contributorCount} ${completed})`}
                        </StyledCompletedCount>
                      )}
                    </P>
                  </Grid>
                  <Grid item xs={12}>
                    {allContributorsRemoved ? null : (
                      <ToggleButton
                        isToggleOn={isToggleOn}
                        toggle={toggle}
                        numContributors={contributors.length}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
                {crudOperationsDisabled && !allContributorsRemoved && (
                  <Stack
                    direction="row"
                    spacing={2}
                    flexWrap="wrap"
                    sx={{ justifyContent: { lg: 'end' } }}
                  >
                    {addingLearnerToAll ? (
                      <P variant="body-bold">
                        {t(
                          'ui.event-management.events.add-learners-and-contributors.learner.adding-to-all-learners',
                        )}
                      </P>
                    ) : (
                      <Button
                        onClick={() => {
                          addLearnerToAll({
                            learnerId: learner.id,
                            inviteeIds: learnerIds,
                          });
                        }}
                        disabled={false}
                        sx={{ padding: 0 }}
                      >
                        <P variant="body-bold" color="primary">
                          {t(
                            'ui.event-management.events.add-learners-and-contributors.learner.add-to-all-learners',
                          )}
                        </P>
                      </Button>
                    )}
                    <Button
                      aria-label={t(
                        'ui.event-management.events.add-learners-and-contributors.learner.delete.label',
                      )}
                      onClick={handleDeleteLearner}
                      sx={{
                        padding: 0,
                        minWidth: 0,
                        minHeight: 0,
                      }}
                    >
                      <P variant="body-bold" color="error">
                        {t('ui.event-management.invite-list.remove-user')}
                      </P>
                    </Button>
                  </Stack>
                )}
                {allContributorsRemoved && (
                  <P variant="body-bold" color="textSecondary">
                    {t(
                      'ui.event-management.events.add-learners-and-contributors.learner.contributors-removed',
                    )}
                  </P>
                )}
                <DeleteLearnerDialog
                  open={Boolean(inviteeId)}
                  onClose={() => setInviteeId(null)}
                  handleConfirmDelete={handleConfirmDelete}
                  deleting={isLoadingDeleteLearner}
                  contributor={contributors}
                />
                <Snackbar
                  open={isErrorDeleteLearner}
                  autoHideDuration={6000}
                  onClose={resetDeleteInvitee}
                >
                  <Alert
                    elevation={6}
                    variant="filled"
                    onClose={resetDeleteInvitee}
                    severity="error"
                  >
                    {t(
                      'ui.event-management.events.invitee.error-deleting-invitee',
                    )}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Grid>
          {isToggleOn && (
            <>
              <Grid
                item
                xs={12}
                sx={(theme) => ({
                  padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
                    3,
                  )} ${theme.spacing(spacingSizeMap.M)}`,
                })}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item>
                        {contributors.length >= 12 ? null : (
                          <StyledDiv>
                            <P variant="body-bold">
                              {t(
                                'ui.event-management.events.add-learners-and-contributors.add-contributors.title',
                              )}
                            </P>
                          </StyledDiv>
                        )}
                      </Grid>
                      <Grid item />
                    </Grid>
                  </Grid>
                  {crudOperationsDisabled && (
                    <Grid item xs={12}>
                      {contributors.length >= 12 ||
                      learner.profileStatus === 'PURCHASED' ? (
                        <P variant="body-bold">
                          {contributors.length >= 12
                            ? t(
                                'ui.event-management.events.add-learners-and-contributors.max-contributor.limit',
                              )
                            : t(
                                'ui.event-management.events.add-learners-and-contributors.profile.purchased',
                              )}
                        </P>
                      ) : (
                        <AddContributorForm
                          addContributor={onAddContributor}
                          learnerId={learner.id}
                          disabled={
                            contributors.length >= 12 || isLoadingAddContributor
                          }
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {contributors.length > 0 && (
                <Contributors
                  eventId={event.id}
                  contributors={contributors}
                  crudOperationsDisabled={crudOperationsDisabled}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={errorAddingLearnerToAll}
        autoHideDuration={6000}
        onClose={resetAddLearnerToAll}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={resetAddLearnerToAll}
          severity="error"
        >
          {t(
            'ui.event-management.events.add-learners-and-contributors.learner.add-to-all-learners-failed',
          )}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default LearnersAndContributorsSummary;
