import { Box, FormLabel } from '@mui/material';
import { RadioGroupControl } from 'components/RadioGroupControl';
import { H3 } from '@insights-ltd/design-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScoreType } from 'types/types';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useWheelContext, WheelState } from './hooks/useWheelContext';

const SelectTypeControl = () => {
  const { t } = useTranslation();
  const [{ scoreType }, setState] = useWheelContext();

  const handleChange = (newValue: string) => {
    setState((prevState: WheelState) => ({
      ...prevState,
      scoreType: newValue as ScoreType,
    }));
  };

  return (
    <>
      <FormLabel component="legend">
        <H3 variant="h3">
          {t('ui.event-management.events.team-wheel.select-type')}
        </H3>
      </FormLabel>
      <Box sx={(theme) => ({ mb: theme.spacing(spacingSizeMap.M) })} />
      <RadioGroupControl
        title={t('ui.event-management.events.team-wheel.select-type')}
        onChange={handleChange}
        value={scoreType}
        options={[
          {
            value: 'CONSCIOUS',
            label: t('ui.event-management.events.team-wheel.type.conscious'),
          },
          {
            value: 'LESS_CONSCIOUS',
            label: t(
              'ui.event-management.events.team-wheel.type.less-conscious',
            ),
          },
        ]}
      />
    </>
  );
};

export default SelectTypeControl;
