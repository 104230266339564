import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';
import { BackgroundColors } from 'types/types';

const FullHeight = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: BackgroundColors }>(
  ({ theme, backgroundColor = 'grey' }) => ({
    height: '100%',
    paddingBottom: theme.spacing(spacingSizeMap.L),
    backgroundColor:
      backgroundColor === 'grey'
        ? theme.palette.grey[200]
        : theme.palette.grey[100],
  }),
);

export default FullHeight;
