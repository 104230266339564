import React from 'react';
import { Box, Button, Card, CardContent, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { P, Span } from '@insights-ltd/design-library';

import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { Link } from 'react-router-dom';
import { isoToLocaleString } from 'utils/dates';

type Props = {
  evaluatorCompletedAt: string;
  profileExists: boolean;
};

const PurchaseCardBoxP = styled(P)(({ theme }) => ({
  marginBottom: `${theme.spacing(spacingSizeMap.XS)}`,
}));

const PurchaseCardBoxDiv = styled('div')(({ theme }) => ({
  marginBottom: `${theme.spacing(spacingSizeMap.S)}`,
}));

const EvaluationCompletedDiv = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const EvaluationCompleted = ({ date }: { date: string }) => {
  const { t } = useTranslation();
  return (
    <EvaluationCompletedDiv>
      <Trans
        t={t}
        i18nKey="ui.event-management.learner.purchase.evaluator-completed.body"
        values={{ date }}
        components={{
          strong: <Span variant="body-bold" />,
        }}
      />
    </EvaluationCompletedDiv>
  );
};

const PurchaseCard = ({ profileExists, evaluatorCompletedAt }: Props) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;

  const date = isoToLocaleString(evaluatorCompletedAt, locale);
  const content = !profileExists ? (
    <Box>
      <PurchaseCardBoxP variant="body-large">
        {t(`ui.event-management.learner.purchase.evaluator-completed`, {
          date,
        })}
      </PurchaseCardBoxP>
      <P>{t(`ui.event-management.learner.purchase.ready-for-purchase`)}</P>
    </Box>
  ) : (
    <Box>
      <PurchaseCardBoxDiv>
        <PurchaseCardBoxP variant="body-large">
          {t(
            `ui.event-management.learner.purchase.additional-purchases.header`,
          )}
        </PurchaseCardBoxP>
        <EvaluationCompleted date={date} />
      </PurchaseCardBoxDiv>
      <P variant="body">
        {t('ui.event-management.learner.purchase.additional-purchases.body')}
      </P>
    </Box>
  );
  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '16.25rem',
        height: '100%',
        border: `1px solid ${theme.palette.blue.main}`,
        backgroundColor: theme.palette.blue.light,
      })}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {content}
        <Button
          component={Link}
          to="purchase-profiles"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('ui.event-management.learner.purchase.button')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PurchaseCard;
