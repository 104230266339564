import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import useIsPermitted from 'components/hooks/useIsPermitted';
import {
  PractitionerRequest,
  useGetOrganisation,
  useGetPractitionerById,
  useUpdatePractitioner,
} from 'api';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { DEFAULT_ROUTE } from 'variables';
import { queryStatus } from 'utils/queryStatus';
import { isSuperAdmin } from 'utils/role';
import { EditAccountForm } from 'pages/EditAccount/EditAccountForm';
import { useRequestErrorContext } from 'components/RequestErrorDialog/RequestErrorProvider';

const EditPractitioner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdatePractitioner();
  const { practitionerId, organisationId } = useParams<{
    practitionerId: string;
    organisationId: string;
  }>();
  const { status: getPractitionerStatus, data } =
    useGetPractitionerById(practitionerId);
  const { data: organisation, status: getOrganisationStatus } =
    useGetOrganisation(organisationId);
  const { status: isPermittedStatus, isPermitted } = useIsPermitted(
    [{ action: 'Practitioner_Update', resourceId: practitionerId }],
    {
      enabled: Boolean(practitionerId),
    },
  );
  const canBeSuperAdmin = isSuperAdmin(organisation?.supportedRoles);

  const {
    status: roleChangeIsPermittedStatus,
    isPermitted: roleChangeIsPermitted,
  } = useIsPermitted(
    [{ action: 'Practitioner_UpdateRoles', resourceId: practitionerId }],
    { enabled: Boolean(practitionerId) },
  );
  const {
    status: productChangeIsPermittedStatus,
    isPermitted: productChangeIsPermitted,
  } = useIsPermitted(
    [
      {
        action: 'Practitioner_UpdateLicensedProducts',
        resourceId: practitionerId,
      },
    ],
    { enabled: Boolean(practitionerId) },
  );
  const {
    status: readLicensedProductsIsPermittedStatus,
    isPermitted: readLicensedProductsIsPermitted,
  } = useIsPermitted(
    [
      {
        action: 'Practitioner_ReadAllLicensedProducts',
        resourceId: practitionerId,
      },
    ],
    { enabled: Boolean(practitionerId) },
  );

  const status = queryStatus(
    roleChangeIsPermittedStatus,
    productChangeIsPermittedStatus,
    isPermittedStatus,
    getPractitionerStatus,
    getOrganisationStatus,
    readLicensedProductsIsPermittedStatus,
  );
  const { openErrorModal } = useRequestErrorContext();

  useEffect(() => {
    if (isPermittedStatus === 'success' && !isPermitted) {
      navigate(DEFAULT_ROUTE);
    }
  }, [isPermittedStatus, isPermitted, navigate]);

  if (status === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.account.edit.error.load')}
      />
    );
  }
  const { id, enabled, leadingColourEnergy, ...formData } = data!;

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.edit-practitioner"
      activeTextKey="ui.event-management.account.edit.title"
      crumbs={{
        '/': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/organisations/${organisation!.id}`]: organisation!.name,
        [`/organisations/${organisation!.id}/practitioners/${data!.id}`]:
          data!.fullName,
      }}
      headingKey="ui.event-management.account.edit.title"
    >
      <EditAccountForm
        roleChangeIsPermitted={roleChangeIsPermitted}
        emailChangeIsPermitted
        productChangeIsPermitted={productChangeIsPermitted}
        showProducts={readLicensedProductsIsPermitted}
        isSuperAdmin={canBeSuperAdmin}
        formHeaderTitle={t('ui.event-management.account.edit.details')}
        formData={{
          ...formData,
          leadingColourEnergy:
            leadingColourEnergy === null ? undefined : leadingColourEnergy,
        }}
        showPreferredDialect
        submitHandler={(updateData: PractitionerRequest, onError) => {
          if (isPending) return;
          mutate(
            {
              formData: updateData,
              practitionerId,
            },
            {
              onSuccess: () =>
                navigate(
                  `/organisations/${organisationId}/practitioners/${practitionerId}`,
                ),
              onError: (err) => {
                onError(err);
                openErrorModal();
              },
            },
          );
        }}
      />
    </BreadcrumbLayout>
  );
};

export default EditPractitioner;
