export const reload = () => window.location.reload();

export const cleanObject = (obj: Record<string, string>) =>
  Object.keys(obj).reduce((result: Record<string, string>, key: string) => {
    if (obj[key] !== undefined && obj[key] !== '') {
      // eslint-disable-next-line no-param-reassign
      result[key] = obj[key];
    }
    return result;
  }, {});
