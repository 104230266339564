import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { SxProps, Theme } from '@mui/material';
import getTheme from '@insights-ltd/design-library/src/themes';

const localTheme = getTheme();

type Props = {
  onClick: () => void;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

const LinkButton = ({ onClick, children, sx = {} }: Props) => (
  <ButtonBase
    data-testid="link-button"
    color="primary"
    disableTouchRipple
    sx={{
      padding: '0.5rem 1rem',
      color: localTheme.brandColours.blue,
      fontFamily: localTheme.typography.family,
      fontWeight: localTheme.typography.boldWeight,
      fontSize: '16px',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </ButtonBase>
);

export default LinkButton;
