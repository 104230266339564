import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { OrganisationAutocompleteV2 as OrganisationAutocomplete } from 'components/OrganisationPicker/OrganisationAutocomplete';
import { OrganisationResponse } from 'api';
import { IconButton, styled } from '@mui/material';
import { P } from '@insights-ltd/design-library';
import SvgClose from '@insights-ltd/design-library/src/components/Svgs/streamline-regular/CloseV2';
import { GroupOrganisationHashMap } from 'utils/createGroupOrganisationMap';

const StyledOrganisationAutocomplete = styled(OrganisationAutocomplete)({
  width: '100%',
  '& > div > div': {
    marginRight: '0',
  },
});

const Divider = styled('div')(({ theme }) => ({
  width: '140.5px',
  border: `1px solid ${theme.palette.grey[500]}`,
  height: '0',
}));

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '18px 0 10px',
});

const Span = styled('span')(({ theme }) => ({
  fontWeight: '600',
  margin: '0 0.75rem',
  color: theme.palette.text.secondary,
}));

type Props = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  organisationId: string;
  organisations: OrganisationResponse[];
  organisationsInGroups?: GroupOrganisationHashMap;
  handleOrganisationChange: (value: OrganisationResponse | null) => void;
  hasAllOrgsOption: boolean;
};

const ChangeOrgDialog = ({
  open,
  onClose,
  isLoading,
  organisationId,
  organisations,
  organisationsInGroups,
  handleOrganisationChange,
  hasAllOrgsOption,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      sx={{
        '& > div > div': {
          maxWidth: '360px',
          maxHeight: '295px',
        },
      }}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '11px 1rem',
          '> p': {
            lineHeight: '30px',
          },
        }}
      >
        <P variant="h4">
          {t('ui.event-management.learners.organisation.change-org')}
        </P>
        <IconButton
          sx={(theme) => ({
            padding: '0',
            '& > svg': {
              stroke: theme.palette.blue.main,
              strokeWidth: '3',
              transform: 'scale(67%)',
            },
          })}
          aria-label="close"
          onClick={onClose}
          size="large"
        >
          <SvgClose />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '17px 1rem 2rem', minHeight: '0 !important' }}
        dividers
        id="alert-dialog-description"
      >
        {hasAllOrgsOption && (
          <>
            <Button
              fullWidth
              onClick={() => {
                onClose();
                handleOrganisationChange(null);
              }}
              color="primary"
              variant="outlined"
              disabled={isLoading}
              sx={(theme) => ({
                border: `2px solid ${theme.palette.blue.main} !important`,
              })}
            >
              {t(
                'ui.event-management.learners.organisation.change-org.view-all',
              )}
            </Button>
            <StyledDiv>
              <Divider />
              <Span>
                {t('ui.event-management.learners.organisation.change-org.or')}
              </Span>
              <Divider />
            </StyledDiv>
          </>
        )}
        <StyledOrganisationAutocomplete
          options={organisations}
          onSelect={(organisation) => {
            onClose();
            handleOrganisationChange(organisation);
          }}
          organisationsInGroups={organisationsInGroups}
          selectedValue={organisationId}
          loading={isLoading}
          disableClearable
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeOrgDialog;
