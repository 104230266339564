import { TeamResponse } from 'api';

const teamsData: { teams: TeamResponse[] } = {
  teams: [
    {
      id: '4407b104-5ed7-4728-b3dd-e1ee1dcf143d',
      name: 'Name - 1',
      visibility: 'PRIVATE',
      learnerCount: 0,
      organisationContext: {
        type: 'SINGLE',
        distinctLearnerCount: 10,
        organisations: [
          {
            id: 'org-1-id',
            name: 'Org 1',
            supportedRoles: ['STANDARD'],
          },
        ],
      },
      migrated: true,
      location: '> here > is > a > path',
    },
    {
      id: '82e33cd8-bf85-4420-8a1c-14235e95b16f',
      name: 'Name - 2',
      visibility: 'ORGANISATION',
      learnerCount: 2,
      organisationContext: {
        type: 'SINGLE',
        distinctLearnerCount: 10,
        organisations: [
          {
            id: 'org-2-id',
            name: 'Org 2',
            supportedRoles: ['STANDARD'],
          },
        ],
      },
      migrated: false,
      location: null,
    },
    {
      id: '824er4234d8-bf85-4420-8a1c-14235e95b16f',
      name: 'Name - 3',
      visibility: 'ORGANISATION',
      learnerCount: 2,
      organisationContext: {
        type: 'MULTIPLE',
        distinctLearnerCount: 10,
        organisations: [
          {
            id: 'org-2-id',
            name: 'Org 2',
            supportedRoles: ['STANDARD'],
          },
          {
            id: 'org-3-id',
            name: 'Org 3',
            supportedRoles: ['STANDARD'],
          },
        ],
      },
      migrated: false,
      location: null,
    },
  ],
};
export default teamsData;
