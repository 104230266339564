import React, { useMemo, useState } from 'react';
import FullHeight from 'components/FullHeight';
import Breadcrumbs from 'components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { useParams, useNavigate } from 'react-router-dom';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import { ARBITRARY_MAX_LENGTH } from 'variables/constants';
import FormHeader from 'components/FormHeader/FormHeader';
import { H2, P, RadioGroupControl } from '@insights-ltd/design-library';
import GroupSvg from 'components/Svgs/icons/Hierarchy';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import {
  Group,
  GroupType,
  RequestError,
  useDeleteGroup,
  useGetGroup,
  useUpdateGroup,
} from 'api';
import DeleteGroupDialog from './DeleteGroupDialog';

const translations: Record<string, string> = {
  title: 'ui.event-management.manage-group.title',
  breadcrumb: 'ui.event-management.manage-group.breadcrumb',
  saveGroup: 'ui.event-management.manage-group.save-group',
};

type GroupFormData = Omit<Group, 'id'>;

const StyledForm = styled('form')({
  width: '100%',
  flexGrow: 1,
});

const StyledP = styled(P)(({ theme }) => ({
  marginBottom: `${theme.spacing(spacingSizeMap.XS)} !important`,
}));

const AvatarIcon = () => {
  return (
    <Avatar
      sx={(theme) => ({
        backgroundColor: theme.palette.orange.light,
      })}
    >
      <GroupSvg />
    </Avatar>
  );
};

const GroupDescription = ({ textKey }: { textKey: string }) => (
  <Trans i18nKey={textKey} />
);

const EditGroup: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { groupId } = useParams<{ groupId: string }>();
  const [isSaving, setIsSaving] = useState(false);
  const { data: group, status } = useGetGroup(groupId || '', {
    enabled: !isSaving,
  });
  const navigate = useNavigate();
  const groupType = group?.type ?? 'CUSTOMER';

  const [deleteDialogOpen, setOpenDeleteDialog] = useState(false);

  const { mutate: updateGroup } = useUpdateGroup(queryClient);

  const { mutate: deleteGroup } = useDeleteGroup();

  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: group,
  });

  useMemo(() => {
    if (group) {
      register('type', { required: true });
      setValue('type', group?.type);
      register('name', { required: true });
      setValue('name', group?.name);
      register('id', { required: true });
      setValue('id', group?.id);
    }
  }, [register, setValue, group]);

  const labels: { value: GroupType; label: string }[] = [
    {
      value: 'CUSTOMER',
      label: t('ui.event-management.create-group.group-type.customer'),
    },
    {
      value: 'PARTNER',
      label: t('ui.event-management.create-group.group-type.partner'),
    },
  ];

  const breadcrumbText: Record<GroupType, string> = {
    CUSTOMER: t('ui.event-management.group.breadcrumb.customer', {
      groupName: group?.name ?? '',
    }),
    PARTNER: t('ui.event-management.group.breadcrumb.partner', {
      groupName: group?.name ?? '',
    }),
  };

  const errorMessages: Record<keyof GroupFormData, Record<string, string>> = {
    name: {
      required: t('ui.event-management.create-group.group-name.error.required'),
      notUnique: t(
        'ui.event-management.create-group.group-name.error.not-unique',
      ),
      pattern: t('ui.event-management.create-group.group-name.error.no-spaces'),
    },
    type: {},
  };

  const groupText: Record<GroupType, string> = {
    CUSTOMER: t(
      'ui.event-management.create-group.group-type.customer.description',
    ),
    PARTNER: t(
      'ui.event-management.create-group.group-type.partner.description',
    ),
  };

  const getErrorMessage = (
    field: keyof GroupFormData,
    formErrors: DeepMap<Partial<Group>, FieldError>,
  ) => {
    if (!formErrors[field]) return undefined;

    return errorMessages[field][formErrors[field]!.type];
  };

  const submitForm: React.FormEventHandler<HTMLFormElement> = handleSubmit(
    (event) => {
      const groupData = {
        ...event,
        name: event.name.trim(),
      };

      setValue('name', groupData.name);

      if (groupData.name === '') {
        return setError('name', { type: 'required' });
      }

      return updateGroup(groupData, {
        onSuccess: () => {
          navigate(`/groups/${groupId}`);
        },
        onError: (error: any) => {
          if (
            (error as RequestError).errorCodes?.includes(
              'ORGANISATION_GROUP_NAME_ALREADY_TAKEN',
            )
          ) {
            setError('name', { type: 'notUnique' });
          }
        },
      });

      // handle failed cases in NP-5299
    },
  );

  const handleConfirmDelete = () => {
    setIsSaving(true);
    deleteGroup(groupId!, {
      onSuccess: () => {
        navigate('/organisations');
        setIsSaving(false);
      },
    });
  };

  if (status === 'pending' || !group) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.organisation.error-loading')}
      />
    );
  }
  return (
    <>
      <Helmet>
        <title>{t(translations.editGroup)}</title>
      </Helmet>
      <FullHeight backgroundColor="white">
        <Container
          sx={(theme) => ({
            padding: `${theme.spacing(spacingSizeMap.M)} 0`,
            [theme.breakpoints.up('lg')]: {
              padding: `${theme.spacing(spacingSizeMap.M)}`,
            },
          })}
        >
          <Breadcrumbs
            crumbs={{
              '/': t('ui.event-management.dashboard.home.title'),
              '/organisations': t('ui.event-management.organisations.title'),
              [`/groups/${group?.id}`]: breadcrumbText[groupType],
            }}
            activeText={t(translations.breadcrumb)}
            sx={{ paddingBottom: '3px' }}
          />
        </Container>
        <Container>
          <Box mb={(theme) => theme.spacing(spacingSizeMap.M)}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12} md="auto">
                <H2 variant="h2">{t(translations.title)}</H2>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  variant="outlined"
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                    border: '1px solid',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      borderColor: theme.palette.error.main,
                      backgroundColor: `${theme.palette.error.main}16`,
                    },
                  })}
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  {t('ui.event-management.manage-group.delete-group.label')}
                </Button>
                <DeleteGroupDialog
                  open={deleteDialogOpen}
                  onClose={() => setOpenDeleteDialog(false)}
                  handleConfirmDelete={handleConfirmDelete}
                  groupName={group.name}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container>
          <Box mt="28px">
            <Divider />
          </Box>
          <StyledForm onSubmit={submitForm}>
            <Grid
              container
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Grid item xs={12} sm={8}>
                <FormHeader
                  title={t('ui.event-management.create-group.group-details')}
                  icon={AvatarIcon}
                />
                <Grid item xs={12}>
                  <TextField
                    sx={(theme) => ({
                      backgroundColor: theme.palette.common.white,
                    })}
                    id="name"
                    label={t(
                      'ui.event-management.create-group.group-name.label',
                    )}
                    placeholder={t(
                      'ui.event-management.create-group.group-name.placeholder',
                    )}
                    variant="outlined"
                    fullWidth
                    type="text"
                    defaultValue={group.name}
                    inputProps={{ maxLength: ARBITRARY_MAX_LENGTH }}
                    error={Boolean(errors.name)}
                    helperText={getErrorMessage('name', errors)}
                    {...register('name', {
                      required: true,
                    })}
                  />
                </Grid>
                <Grid
                  sx={(theme) => ({
                    marginTop: theme.spacing(spacingSizeMap.S),
                    '& .MuiRadio-colorPrimary.Mui-disabled': {
                      color: `${theme.palette.grey[500]} !important`,
                    },
                  })}
                >
                  <StyledP variant="body-bold">
                    {t('ui.event-management.create-group.group-type')}
                  </StyledP>
                  <RadioGroupControl
                    options={labels}
                    name="type"
                    variant="boxed"
                    title={t('ui.event-management.create-group.group-type')}
                    value={groupType}
                    onChange={() => {}}
                    required
                    disabled
                    data-testid="radioControlGroup"
                  />
                  <StyledP
                    sx={(theme) => ({
                      marginTop: `${theme.spacing(
                        spacingSizeMap.M,
                      )} !important`,
                      marginBottom: `${theme.spacing(
                        spacingSizeMap.M,
                      )} !important`,
                      whiteSpace: 'pre-line',
                    })}
                  >
                    <GroupDescription textKey={groupText[groupType]} />
                  </StyledP>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={8}
                item
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing(spacingSizeMap.L),
                })}
              >
                <Button type="submit" color="primary" variant="contained">
                  {t(translations.saveGroup)}
                </Button>
              </Grid>
            </Grid>
          </StyledForm>
        </Container>
      </FullHeight>
    </>
  );
};

export default EditGroup;
