import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import BreadcrumbLayout from 'components/layout/BreadcrumbLayout';
import { Wallet } from 'components/Wallet';
import { useTranslation } from 'react-i18next';
import { OrganisationResponse } from 'api';
import OrganisationAutocomplete from 'components/OrganisationPicker/OrganisationAutocomplete';
import FormHeader from 'components/FormHeader';
import OrganisationIcon from 'components/Svgs/icons/Organisation';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import RequestUnitsDialog from 'pages/Account/views/RequestUnitsDialog';
import TransferUnitsForm from './TransferUnitsForm';
import { useGetTransferUnits } from './useGetTransferUnits';

const StyledOrganisationIcon = styled(OrganisationIcon)(({ theme }) => ({
  fill: theme.palette.pink.dark,
  height: '20px',
}));

const TransferUnits = () => {
  const { t } = useTranslation();
  const {
    userId,
    user,
    isPending,
    isError,
    practitioner,
    practitionerId,
    wallet,
    organisation,
    organisations,
    hasMultiOrgs,
    isMyAccount,
  } = useGetTransferUnits();
  const currentOrg = organisation || organisations?.[0];
  const [selectedOrg, setOrganisation] = useState<
    OrganisationResponse | null | undefined
  >(currentOrg);
  const [requestUnitsDialogOpen, setRequestUnitsDialogOpen] =
    React.useState(false);

  useEffect(() => {
    if (typeof selectedOrg === 'undefined' && currentOrg) {
      setOrganisation(currentOrg);
    }
  }, [selectedOrg, setOrganisation, organisations, currentOrg]);

  if (isPending) {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (isError) {
    return <FullScreenError message={t('ui.event-management.generic.error')} />;
  }

  const breadCrumbs: { [key: string]: string } = isMyAccount
    ? {
        '/': t('ui.event-management.dashboard.home.title'),
        '/account': t('ui.event-management.my-account.title'),
      }
    : {
        '/': t('ui.event-management.dashboard.home.title'),
        '/organisations': t('ui.event-management.organisations.title'),
        [`/organisations/${organisation?.id}`]: organisation?.name || '',
        [`/organisations/${organisation?.id}/practitioners/${practitioner?.id}`]:
          practitioner?.fullName || '',
      };

  return (
    <BreadcrumbLayout
      titleKey="ui.event-management.title.transfer-units"
      activeTextKey="ui.event-management.title.transfer-units"
      crumbs={breadCrumbs}
      headingKey="ui.event-management.title.transfer-units"
      fullWidth
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={8}>
          {hasMultiOrgs ? (
            <>
              <Grid
                sx={(theme) => ({
                  marginBottom: theme.spacing(spacingSizeMap.M),
                })}
                item
              >
                <FormHeader
                  icon={StyledOrganisationIcon}
                  color="pink"
                  title={t('ui.event-management.users.create.organisation')}
                />
                <OrganisationAutocomplete
                  options={organisations!}
                  onSelect={(
                    org: React.SetStateAction<
                      OrganisationResponse | null | undefined
                    >,
                  ) => {
                    setOrganisation(org);
                  }}
                  selectedValue={selectedOrg?.id}
                  loading={isPending}
                  disableClearable
                />
              </Grid>
              <br />
            </>
          ) : null}
          <TransferUnitsForm
            myWallet={wallet!}
            userId={userId!}
            organisationId={selectedOrg?.id ?? ''}
            initialPractitionerId={practitionerId}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Wallet
            availableUnits={wallet!.availableUnits}
            organisationId={selectedOrg?.id ?? ''}
            practitionerId={userId}
            isMyWallet
            openRequestUnitsDialog={() => setRequestUnitsDialogOpen(true)}
          />
        </Grid>
        {user ? (
          <RequestUnitsDialog
            open={requestUnitsDialogOpen}
            onClose={() => setRequestUnitsDialogOpen(false)}
            organisations={organisations!}
            requestingUser={user}
          />
        ) : null}
      </Grid>
    </BreadcrumbLayout>
  );
};

export default TransferUnits;
