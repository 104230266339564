import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PlusIcon from '@mui/icons-material/Add';
import { H2 } from '@insights-ltd/design-library';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import useHistoryState from 'components/hooks/useHistoryState';
import Notification from 'components/Notification';
import { SearchFilterV2 } from 'components/SearchFilter/SearchFilter';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { styled } from '@mui/material';
import { useSearchContext } from 'components/SearchFilter/SearchProvider';
import SearchResultInfo from 'components/SearchResultInfo/SearchResultInfo';
import {
  SearchResultsMessages,
  getSearchResultMessage,
} from 'utils/getSearchResultMessage';
import { useDebounceValue } from 'components/hooks/useDebouncedValue';
import { MINIMUM_SEARCH_CHARACTER_LIMIT } from 'variables/constants';
import LearnersList from './LearnersList';
import LearnerSearchMessage from './LearnerSearchMessage';
import useGetLearners from './useGetLearners';

const ITEMS_PER_PAGE = 10;

type Props = {
  organisationId?: string | null;
  handleOpenOrgSelector: () => void;
};

const searchResultsMessages: SearchResultsMessages = {
  lastPageKey: 'ui.event-management.learners.result.limit-exceeded.last-page',
  searchTermKey:
    'ui.event-management.learners.result.limit-exceeded.search-term',
  defaultKey: 'ui.event-management.learners.result.limit-exceeded',
};

const StyledLearnerCount = styled('span')(({ theme }) => ({
  padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
  color: theme.palette.grey[100],
  backgroundColor: theme.palette.pink.main,
  borderRadius: '4px',
}));

const LearnerSearchResults = ({
  organisationId,
  searchTerm,
  handleSearchMessageClick,
}: {
  organisationId?: string | null;
  searchTerm: string;
  handleSearchMessageClick?: () => void;
}) => {
  const { t } = useTranslation();
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const {
    status,
    learners = [],
    isFetched,
    limitExceeded,
  } = useGetLearners(searchTerm, organisationId);
  const showWhenUnfiltered =
    !!organisationId && searchTerm?.length < MINIMUM_SEARCH_CHARACTER_LIMIT;

  const hasValidSearchTerm =
    searchTerm !== '' && searchTerm.length >= MINIMUM_SEARCH_CHARACTER_LIMIT;

  const searchResultsMessage = hasValidSearchTerm
    ? t('ui.event-management.learners.filtered-learners', {
        searchTerm,
      })
    : t('ui.event-management.learners.results-title');

  if (status === 'pending') {
    return (
      <FullScreenSpinner message={t('ui.event-management.loading')} inline />
    );
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.event-management.learners.error-loading-learners')}
      />
    );
  }

  const searchResultMessage = getSearchResultMessage(
    searchResultsMessages,
    isLastPage,
    searchTerm,
  );

  const handlePageChange = ({ lastPage }: { lastPage?: boolean }) => {
    setIsLastPage(!!lastPage);
  };

  return (
    <>
      <Box
        mt={(theme) => theme.spacing(spacingSizeMap.M)}
        mb={(theme) => theme.spacing(spacingSizeMap.XS)}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm="auto">
            {isFetched && (showWhenUnfiltered || hasValidSearchTerm) && (
              <H2 variant="h3">
                {searchResultsMessage}
                &nbsp;
                <StyledLearnerCount data-testid="learner-count">
                  {limitExceeded ? '300+' : learners.length}
                </StyledLearnerCount>
              </H2>
            )}
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              sx={{ padding: '0.5rem 1rem' }}
              variant="contained"
              color="primary"
              component={Link}
              to="/teams/create"
              startIcon={<PlusIcon />}
              fullWidth
            >
              {t('ui.event-management.teams.create-team')}
            </Button>
          </Grid>
          {limitExceeded ? (
            <Grid item xs={12} mt={0}>
              <SearchResultInfo
                onClick={handleSearchMessageClick}
                i18nKey={searchResultMessage}
                sx={(theme) => ({
                  marginBottom: theme.spacing(spacingSizeMap.XS),
                  padding: theme.spacing(spacingSizeMap.S),
                })}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
      {learners.length > 0 ? (
        <LearnersList
          onPageChange={handlePageChange}
          items={learners}
          itemsPerPage={ITEMS_PER_PAGE}
        />
      ) : (
        isFetched &&
        searchTerm !== '' &&
        t('ui.event-management.learners.list.no-results')
      )}
    </>
  );
};

const LearnersTab = ({ organisationId, handleOpenOrgSelector }: Props) => {
  const { t } = useTranslation();
  const {
    searchState: { searchTerm = '' },
    setSearchState,
  } = useSearchContext();
  const [deletedLearner] = useHistoryState<string>('deletedLearner');
  const debouncedSearchTerm = useDebounceValue(searchTerm);

  let notification = null;
  if (deletedLearner) {
    notification = t('ui.event-management.learners.teams.delete-success', {
      teamName: deletedLearner,
    });
  }

  const commitToSearch = (newSearchTerm: string) => {
    setSearchState({ searchTerm: newSearchTerm });
  };

  const ref = useRef<HTMLInputElement>(null);

  const handleSearchMessageClick = () => {
    ref.current?.focus();
  };
  return (
    <>
      {!organisationId &&
        searchTerm.length < MINIMUM_SEARCH_CHARACTER_LIMIT && (
          <LearnerSearchMessage
            i18nKey="ui.event-management.learners.search-limit-message"
            onClick={handleOpenOrgSelector}
          />
        )}
      <SearchFilterV2
        label={t('ui.event-management.list-filter.search')}
        placeholder={t('ui.event-management.learners.search.placeholder')}
        handleTermChange={commitToSearch}
        fieldName={t('ui.event-management.list-filter.by-name-email')}
        initialValue={searchTerm}
        inputState={searchTerm}
        allowEmptySearches={false}
        showButton={false}
        minCharacters={2}
        ref={ref}
      />
      {notification && <Notification message={notification} />}
      <LearnerSearchResults
        organisationId={organisationId}
        searchTerm={debouncedSearchTerm}
        handleSearchMessageClick={handleSearchMessageClick}
      />
    </>
  );
};

export default LearnersTab;
