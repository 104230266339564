import {
  EventResponse,
  EventResponseWithChapters,
  EventSummaryResponse,
  OrganisationResponse,
} from 'api';
import { ScoresResponse } from 'api/events/eventApiTypes';
import { TupleOfLength } from 'types/types';

export const EventWithChapterCosts: EventResponseWithChapters = {
  id: '0fcf5121-83d5-4a96-9bc8-b1d38754ee13',
  name: 'demo 4',
  dialect: 'en-GB',
  startsAt: '2022-07-22T11:00:00Z',
  endsAt: '2022-07-22T17:00:00Z',
  deadlineAt: '2022-07-21T23:59:59.999Z',
  eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
  productType: 'DISCOVERY_PERSONAL_PROFILE',
  eventStatus: 'ACTIVE',
  timezone: 'GMT',
  location: {
    type: 'REMOTE',
    meetingLink: 'https://meeting.com',
    details: '',
  },
  notificationFrequency: 'NEVER',
  chapters: [
    {
      name: 'FOUNDATION',
      cost: 100,
    },
    {
      name: 'EFFECTIVE_SELLING',
      cost: 200,
    },
    {
      name: 'MANAGEMENT',
      cost: 150,
    },
    {
      name: 'PERSONAL_ACHIEVEMENT',
      cost: 150,
    },
    {
      name: 'INTERVIEW',
      cost: 150,
    },
  ],
  practitioners: [
    {
      fullName: 'Paul Neat 1',
      emailAddress: 'paul.craigie+2@waracle.com',
      jobTitle: 'CLIENT_PRACTITIONER',
      leadingColourEnergy: 'CoolBlue',
      id: '91dcecc3-6434-4574-8edc-f54dfec50dac',
      licensedProducts: [],
      enabled: true,
      roles: [],
    },
  ],
  organisation: {
    id: '9fadfe64-5ce0-40de-8325-4a44cd8ae4ea',
    name: 'organisation name',
    supportedRoles: ['STANDARD'],
  },
};

export const ErrorInsufficientUnitsResponse = {
  ts: '2022-02-24T15:27:30.954671981Z',
  status: 400,
  message: 'failed to purchase profile(s)',
  errors: [
    {
      code: 'INSUFFICIENT_UNITS',
    },
  ],
};

export const scoresResponse: ScoresResponse = {
  scores: [
    {
      id: 'learner-id-1',
      fullName: 'Jane Doe',
      wheelPositions: {
        conscious: 7,
        lessConscious: 47,
        opposite: 55,
      },
      colourScores: {
        conscious: {
          blue: 268,
          green: 288,
          yellow: 301,
          red: 280,
          preferred: ['yellow'],
        },
        lessConscious: {
          blue: 299,
          green: 320,
          yellow: 332,
          red: 312,
          preferred: ['green', 'yellow', 'red'],
        },
        opposite: {
          blue: 332,
          green: 312,
          yellow: 299,
          red: 320,
          preferred: ['green', 'blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-2',
      fullName: 'Jane Doe',
      wheelPositions: {
        conscious: 7,
        lessConscious: 47,
        opposite: 55,
      },
      colourScores: {
        conscious: {
          blue: 268,
          green: 288,
          yellow: 301,
          red: 280,
          preferred: ['yellow'],
        },
        lessConscious: {
          blue: 299,
          green: 320,
          yellow: 332,
          red: 312,
          preferred: ['green', 'yellow', 'red'],
        },
        opposite: {
          blue: 332,
          green: 312,
          yellow: 299,
          red: 320,
          preferred: ['green', 'blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-3',
      fullName: 'Jane Doe',
      wheelPositions: {
        conscious: 7,
        lessConscious: 47,
        opposite: 55,
      },
      colourScores: {
        conscious: {
          blue: 268,
          green: 288,
          yellow: 301,
          red: 280,
          preferred: ['yellow'],
        },
        lessConscious: {
          blue: 299,
          green: 320,
          yellow: 332,
          red: 312,
          preferred: ['green', 'yellow', 'red'],
        },
        opposite: {
          blue: 332,
          green: 312,
          yellow: 299,
          red: 320,
          preferred: ['green', 'blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-4',
      fullName: 'Jane Q Doe',
      wheelPositions: {
        conscious: 9,
        lessConscious: 48,
        opposite: 56,
      },
      colourScores: {
        conscious: {
          blue: 280,
          green: 312,
          yellow: 292,
          red: 284,
          preferred: ['green'],
        },
        lessConscious: {
          blue: 308,
          green: 316,
          yellow: 320,
          red: 288,
          preferred: ['green', 'blue', 'yellow'],
        },
        opposite: {
          blue: 320,
          green: 288,
          yellow: 308,
          red: 316,
          preferred: ['blue', 'yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-5',
      fullName: '2LicencesBothValidInFuture Delegate23',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-6',
      fullName: 'FoundationChapterOnly Delegate24',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-7',
      fullName: 'DialectDanish Delegate15',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-8',
      fullName: 'DialectEnglish Delegate12',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-9',
      fullName: 'MergeFrom Admin5',
      wheelPositions: {
        conscious: 34,
        lessConscious: 34,
        opposite: 26,
      },
      colourScores: {
        conscious: {
          blue: 550,
          green: 510,
          yellow: 150,
          red: 230,
          preferred: ['green', 'blue'],
        },
        lessConscious: {
          blue: 450,
          green: 370,
          yellow: 50,
          red: 90,
          preferred: ['green', 'blue'],
        },
        opposite: {
          blue: 50,
          green: 90,
          yellow: 450,
          red: 370,
          preferred: ['yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-10',
      fullName: 'ProfileLicenceNoBank Admin3',
      wheelPositions: {
        conscious: 112,
        lessConscious: 153,
        opposite: 145,
      },
      colourScores: {
        conscious: {
          blue: 220,
          green: 550,
          yellow: 110,
          red: 240,
          preferred: ['green'],
        },
        lessConscious: {
          blue: 490,
          green: 360,
          yellow: 380,
          red: 50,
          preferred: ['green', 'blue', 'yellow'],
        },
        opposite: {
          blue: 380,
          green: 50,
          yellow: 490,
          red: 360,
          preferred: ['blue', 'yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-11',
      fullName: 'Delegate17 DialectJapaneseさん',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-12',
      fullName: '2LicencesOneExpiredOneValidInFuture Delegate22',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-13',
      fullName: '2LicencesWithStartDatesMatchingProfileAccessDate Delegate10',
      wheelPositions: {
        conscious: 29,
        lessConscious: 28,
        opposite: 36,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 400,
          yellow: 310,
          red: 120,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 290,
          green: 480,
          yellow: 490,
          red: 200,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 200,
          yellow: 290,
          red: 480,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-14',
      fullName: '2LicencesWithBank Admin1',
      wheelPositions: {
        conscious: 32,
        lessConscious: 33,
        opposite: 25,
      },
      colourScores: {
        conscious: {
          blue: 310,
          green: 500,
          yellow: 110,
          red: 150,
          preferred: ['green', 'blue'],
        },
        lessConscious: {
          blue: 490,
          green: 450,
          yellow: 290,
          red: 100,
          preferred: ['green', 'blue'],
        },
        opposite: {
          blue: 290,
          green: 100,
          yellow: 490,
          red: 450,
          preferred: ['yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-15',
      fullName: 'ProfileAccessDateInFuture Delegate18',
      wheelPositions: {
        conscious: 27,
        lessConscious: 27,
        opposite: 35,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 310,
          yellow: 450,
          red: 120,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 150,
          green: 480,
          yellow: 490,
          red: 290,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 290,
          yellow: 150,
          red: 480,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-16',
      fullName: 'Delegate3FoundationChapter',
      wheelPositions: {
        conscious: 30,
        lessConscious: 30,
        opposite: 22,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 500,
          yellow: 440,
          red: 10,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 160,
          green: 590,
          yellow: 490,
          red: 100,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 100,
          yellow: 160,
          red: 590,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-17',
      fullName: 'PreWorkshopAsciiLoginNoProfileAccessDate Delegate20',
      wheelPositions: {
        conscious: 30,
        lessConscious: 30,
        opposite: 22,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 500,
          yellow: 440,
          red: 10,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 160,
          green: 590,
          yellow: 490,
          red: 100,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 100,
          yellow: 160,
          red: 590,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-18',
      fullName: '2LicencesBothExpired Delegate21',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-19',
      fullName: 'NonProfilePlusLicence Delegate9',
      wheelPositions: {
        conscious: 132,
        lessConscious: 133,
        opposite: 125,
      },
      colourScores: {
        conscious: {
          blue: 210,
          green: 500,
          yellow: 110,
          red: 410,
          preferred: ['green', 'red'],
        },
        lessConscious: {
          blue: 490,
          green: 190,
          yellow: 390,
          red: 100,
          preferred: ['blue', 'yellow'],
        },
        opposite: {
          blue: 390,
          green: 100,
          yellow: 490,
          red: 190,
          preferred: ['blue', 'yellow'],
        },
      },
    },
    {
      id: 'learner-id-20',
      fullName: 'AllChapters Delegate4',
      wheelPositions: {
        conscious: 35,
        lessConscious: 35,
        opposite: 27,
      },
      colourScores: {
        conscious: {
          blue: 370,
          green: 100,
          yellow: 50,
          red: 310,
          preferred: ['blue', 'red'],
        },
        lessConscious: {
          blue: 550,
          green: 290,
          yellow: 230,
          red: 500,
          preferred: ['blue', 'red'],
        },
        opposite: {
          blue: 230,
          green: 500,
          yellow: 550,
          red: 290,
          preferred: ['green', 'yellow'],
        },
      },
    },
    {
      id: 'learner-id-21',
      fullName: 'NoProfile Delegate2',
      wheelPositions: {
        conscious: 33,
        lessConscious: 32,
        opposite: 24,
      },
      colourScores: {
        conscious: {
          blue: 410,
          green: 310,
          yellow: 240,
          red: 110,
          preferred: ['green', 'blue'],
        },
        lessConscious: {
          blue: 360,
          green: 490,
          yellow: 190,
          red: 290,
          preferred: ['green', 'blue'],
        },
        opposite: {
          blue: 190,
          green: 290,
          yellow: 360,
          red: 490,
          preferred: ['yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-22',
      fullName: 'HasProfilePlusLicenceButNoProfileAccessDate Delegate11',
      wheelPositions: {
        conscious: 29,
        lessConscious: 28,
        opposite: 36,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 400,
          yellow: 310,
          red: 120,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 290,
          green: 480,
          yellow: 490,
          red: 200,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 200,
          yellow: 290,
          red: 480,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-23',
      fullName: 'PreWorkshopAsciiLogin Delegate19',
      wheelPositions: {
        conscious: 30,
        lessConscious: 30,
        opposite: 22,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 500,
          yellow: 440,
          red: 10,
          preferred: ['green', 'yellow'],
        },
        lessConscious: {
          blue: 160,
          green: 590,
          yellow: 490,
          red: 100,
          preferred: ['green', 'yellow'],
        },
        opposite: {
          blue: 490,
          green: 100,
          yellow: 160,
          red: 590,
          preferred: ['blue', 'red'],
        },
      },
    },
    {
      id: 'learner-id-24',
      fullName: 'DialectGerman Delegate16',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-25',
      fullName: 'MergeTo Admin6',
      wheelPositions: {
        conscious: 33,
        lessConscious: 32,
        opposite: 24,
      },
      colourScores: {
        conscious: {
          blue: 350,
          green: 310,
          yellow: 250,
          red: 130,
          preferred: ['green', 'blue'],
        },
        lessConscious: {
          blue: 350,
          green: 470,
          yellow: 250,
          red: 290,
          preferred: ['green', 'blue'],
        },
        opposite: {
          blue: 250,
          green: 290,
          yellow: 350,
          red: 470,
          preferred: ['yellow', 'red'],
        },
      },
    },
    {
      id: 'learner-id-26',
      fullName: 'NoLicence2 Delegate8',
      wheelPositions: {
        conscious: 1,
        lessConscious: 56,
        opposite: 48,
      },
      colourScores: {
        conscious: {
          blue: 210,
          green: 110,
          yellow: 30,
          red: 350,
          preferred: ['red'],
        },
        lessConscious: {
          blue: 570,
          green: 250,
          yellow: 390,
          red: 490,
          preferred: ['blue', 'yellow', 'red'],
        },
        opposite: {
          blue: 390,
          green: 490,
          yellow: 570,
          red: 250,
          preferred: ['green', 'blue', 'yellow'],
        },
      },
    },
    {
      id: 'learner-id-27',
      fullName: 'DialectFrench Delegate14',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-28',
      fullName: 'AllLicenceTypesHeld Delegate25',
      wheelPositions: {
        conscious: 24,
        lessConscious: 25,
        opposite: 33,
      },
      colourScores: {
        conscious: {
          blue: 110,
          green: 220,
          yellow: 440,
          red: 550,
          preferred: ['yellow', 'red'],
        },
        lessConscious: {
          blue: 160,
          green: 50,
          yellow: 490,
          red: 380,
          preferred: ['yellow', 'red'],
        },
        opposite: {
          blue: 490,
          green: 380,
          yellow: 160,
          red: 50,
          preferred: ['green', 'blue'],
        },
      },
    },
    {
      id: 'learner-id-29',
      fullName: 'DialectDutch Delegate13',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-30',
      fullName: 'NoLicence Delegate7',
      wheelPositions: {
        conscious: 48,
        lessConscious: 9,
        opposite: 1,
      },
      colourScores: {
        conscious: {
          blue: 510,
          green: 520,
          yellow: 540,
          red: 10,
          preferred: ['green', 'blue', 'yellow'],
        },
        lessConscious: {
          blue: 60,
          green: 590,
          yellow: 90,
          red: 80,
          preferred: ['green'],
        },
        opposite: {
          blue: 90,
          green: 80,
          yellow: 60,
          red: 590,
          preferred: ['red'],
        },
      },
    },
    {
      id: 'learner-id-31',
      fullName: '3Chapters Delegate1',
      wheelPositions: {
        conscious: 132,
        lessConscious: 133,
        opposite: 125,
      },
      colourScores: {
        conscious: {
          blue: 210,
          green: 500,
          yellow: 110,
          red: 410,
          preferred: ['green', 'red'],
        },
        lessConscious: {
          blue: 490,
          green: 190,
          yellow: 390,
          red: 100,
          preferred: ['blue', 'yellow'],
        },
        opposite: {
          blue: 390,
          green: 100,
          yellow: 490,
          red: 190,
          preferred: ['blue', 'yellow'],
        },
      },
    },
  ],
};

export const mockedCancelledEvents: {
  events: TupleOfLength<6, Required<EventSummaryResponse>>;
} = {
  events: [
    {
      id: '8bb7a297-544e-4053-af8d-3c31ce21f32e',
      name: 'Cancelled Event5',
      startsAt: '2022-02-25T11:00:00Z',
      endsAt: '2022-02-25T16:00:00Z',
      timezone: 'GMT+01:00',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
      },
      cancelledAt: null,
    },
    {
      id: '1b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Cancelled Event2',
      startsAt: '2020-07-06T00:00:00Z',
      endsAt: '2020-09-02T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      cancelledAt: '2020-09-02T00:00:00Z',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
    },
    {
      id: 'ea13af0d-e4bd-40ab-8665-524407452ae1',
      name: 'Cancelled Event4',
      startsAt: '2022-03-10T08:00:00Z',
      endsAt: '2022-03-11T14:00:00Z',
      timezone: 'GMT+01:00',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
      },
      cancelledAt: null,
    },
    {
      id: '4b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Cancelled Event3',
      startsAt: '2020-07-02T00:00:00Z',
      endsAt: '2020-09-01T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      cancelledAt: '2020-09-01T00:00:00Z',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
    },
    {
      id: '2b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Cancelled Event1',
      startsAt: '2020-08-02T00:00:00Z',
      endsAt: '2020-09-03T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      cancelledAt: '2020-10-01T00:00:00Z',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
    },
    {
      id: '0e71e458-4619-4d5f-8997-ef2b029592be',
      name: 'Cancelled Event6',
      startsAt: '2022-10-19T15:00:00Z',
      endsAt: '2022-10-20T15:00:00Z',
      timezone: 'GMT+09:00',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
      },
      cancelledAt: null,
    },
  ],
};

export const pastExperiences: {
  events: TupleOfLength<2, Required<EventSummaryResponse>>;
} = {
  events: [
    {
      id: 'd75193bd-5962-47db-b151-b66f695df5ce',
      name: 'Buying Dog',
      startsAt: '2020-08-29T13:00:00Z',
      endsAt: '2020-08-29T14:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'Insights',
        id: 'insights',
        supportedRoles: ['STANDARD'],
      },
      cancelledAt: null,
    },
    {
      id: '9c9c102d-d9fd-419f-bda5-17d32d839114',
      name: 'Special Characters . * / - & \\',
      startsAt: '2020-08-28T14:00:00Z',
      endsAt: '2020-08-28T15:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'LMS ORG',
        id: 'lms-org-id',
        supportedRoles: ['STANDARD'],
      },
      cancelledAt: null,
    },
  ],
};
export const upcomingExperiences: {
  events: TupleOfLength<2, Required<EventSummaryResponse>>;
} = {
  events: [
    {
      id: '4b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Eating Stuff',
      startsAt: '2020-09-02T00:00:00Z',
      endsAt: '2020-09-02T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
        supportedRoles: ['STANDARD'],
      },
      cancelledAt: null,
    },
    {
      id: '9h27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'LMS Training',
      startsAt: '2020-09-03T00:00:00Z',
      endsAt: '2020-09-03T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'LMS Org',
        id: '7b27c611-1e2f-4e79-a211-a067946a6e2b',
        supportedRoles: ['STANDARD'],
      },
      cancelledAt: null,
    },
  ],
};

export const cancelledExperiences: {
  events: TupleOfLength<2, Required<EventSummaryResponse>>;
} = {
  events: [
    {
      id: '5b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Eating Stuff',
      startsAt: '2022-09-02T00:00:00Z',
      endsAt: '2022-09-02T03:00:00Z',
      cancelledAt: '2022-08-30T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
        supportedRoles: ['STANDARD'],
      },
    },
    {
      id: '6b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Clothing Stuff',
      startsAt: '2021-09-02T00:00:00Z',
      endsAt: '2021-09-02T03:00:00Z',
      cancelledAt: '2022-09-01T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
        supportedRoles: ['STANDARD'],
      },
    },
  ],
};

export const mockEventsData: {
  events: TupleOfLength<5, Required<EventSummaryResponse>>;
} = {
  events: [
    {
      id: '9b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Eating Stuff',
      startsAt: '2020-09-02T00:00:00Z',
      endsAt: '2020-09-02T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
      cancelledAt: null,
    },
    {
      id: '9h27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'LMS Training',
      startsAt: '2020-09-03T00:00:00Z',
      endsAt: '2020-09-03T03:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS Org',
        id: '7b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
      cancelledAt: null,
    },
    {
      id: 'd75193bd-5962-47db-b151-b66f695df5ce',
      name: 'Buying Dog',
      startsAt: '2020-08-29T13:00:00Z',
      endsAt: '2020-08-29T14:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights',
        id: 'insights',
      },
      cancelledAt: null,
    },
    {
      id: '9c9c102d-d9fd-419f-bda5-17d32d839114',
      name: 'Special Characters . * / - & \\',
      startsAt: '2020-08-28T14:00:00Z',
      endsAt: '2020-08-28T15:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS ORG',
        id: 'lms-org-id',
      },
      cancelledAt: null,
    },
    {
      id: '9ca8924a-fc22-48a0-a420-8f36e6bbb520',
      name: 'Long running experience',
      startsAt: '2020-01-01T00:00:00Z',
      endsAt: '2022-01-01T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS ORG',
        id: 'lms-org-id',
      },
      cancelledAt: null,
    },
  ],
};

export const learnerEventsResponse: {
  events: TupleOfLength<5, Required<EventResponse>>;
} = {
  events: [
    {
      id: '9b27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'Eating Stuff',
      dialect: 'en-GB',
      eventStatus: 'ACTIVE',
      startsAt: '2020-09-02T00:00:00Z',
      endsAt: '2020-09-02T03:00:00Z',
      deadlineAt: '2020-09-01T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      notificationFrequency: 'NEVER',
      location: {
        type: 'REMOTE',
        meetingLink: 'https://meeting.com',
        details: '',
      },
      practitioners: [],
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights Org',
        id: '8b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
      cancelledAt: '',
      customEmailMessage: '',
      wallet: {
        id: '',
        availableUnits: 0,
      },
    },
    {
      id: '9h27c610-1e2f-4e79-a211-a067946a6e2b',
      name: 'LMS Training',
      dialect: 'en-GB',
      eventStatus: 'ACTIVE',
      startsAt: '2020-09-03T00:00:00Z',
      endsAt: '2020-09-03T03:00:00Z',
      deadlineAt: '2020-09-01T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      notificationFrequency: 'NEVER',
      location: {
        type: 'REMOTE',
        meetingLink: 'https://meeting.com',
        details: '',
      },
      practitioners: [],
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS Org',
        id: '7b27c611-1e2f-4e79-a211-a067946a6e2b',
      },
      cancelledAt: '',
      customEmailMessage: '',
      wallet: {
        id: '',
        availableUnits: 0,
      },
    },
    {
      id: 'd75193bd-5962-47db-b151-b66f695df5ce',
      name: 'Buying Dog',
      dialect: 'en-GB',
      eventStatus: 'ACTIVE',
      startsAt: '2020-08-29T13:00:00Z',
      endsAt: '2020-08-29T14:00:00Z',
      deadlineAt: '2020-08-28T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      notificationFrequency: 'NEVER',
      location: {
        type: 'REMOTE',
        meetingLink: 'https://meeting.com',
        details: '',
      },
      practitioners: [],
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'Insights',
        id: 'insights',
      },
      cancelledAt: '',
      customEmailMessage: '',
      wallet: {
        id: '',
        availableUnits: 0,
      },
    },
    {
      id: '9c9c102d-d9fd-419f-bda5-17d32d839114',
      name: 'Special Characters . * / - & \\',
      dialect: 'en-GB',
      eventStatus: 'ACTIVE',
      startsAt: '2020-08-28T14:00:00Z',
      endsAt: '2020-08-28T15:00:00Z',
      deadlineAt: '2020-08-27T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      notificationFrequency: 'NEVER',
      location: {
        type: 'REMOTE',
        meetingLink: 'https://meeting.com',
        details: '',
      },
      practitioners: [],
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS ORG',
        id: 'lms-org-id',
      },
      cancelledAt: '',
      customEmailMessage: '',
      wallet: {
        id: '',
        availableUnits: 0,
      },
    },
    {
      id: '9ca8924a-fc22-48a0-a420-8f36e6bbb520',
      name: 'Long running experience',
      dialect: 'en-GB',
      eventStatus: 'ACTIVE',
      startsAt: '2020-01-01T00:00:00Z',
      endsAt: '2022-01-01T00:00:00Z',
      deadlineAt: '2020-08-27T00:00:00Z',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      notificationFrequency: 'NEVER',
      location: {
        type: 'REMOTE',
        meetingLink: 'https://meeting.com',
        details: '',
      },
      practitioners: [],
      productType: 'DISCOVERY_PERSONAL_PROFILE',
      timezone: 'GMT',
      organisation: {
        supportedRoles: ['SUPER_ADMIN', 'STANDARD'],
        name: 'LMS ORG',
        id: 'lms-org-id',
      },
      cancelledAt: '',
      customEmailMessage: '',
      wallet: {
        id: '',
        availableUnits: 0,
      },
    },
  ],
};

export const generateMockEvents = (
  organisation: OrganisationResponse,
  number = 5,
) => {
  const events: EventSummaryResponse[] = [];
  for (let i = 0; i < number; i += 1) {
    events.push({
      organisation,
      id: `test-${i}`,
      name: `Test event ${i}`,
      startsAt: '2022-10-19T15:00:00Z',
      endsAt: '2022-10-20T15:00:00Z',
      timezone: 'GMT+09:00',
      eventType: 'INSIGHTS_DISCOVERY_WORKSHOP',
      cancelledAt: null,
    });
  }

  return events;
};
